import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  column: {
    background: '#E4E4EF !important',
    minHeight: '10px',
    padding: '0 4px 0px 8px',
    '&.MuiPaper-root': {
      boxShadow: 'none !important',
      borderRadius: 0,
    },
    width: '240px',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E4E4EF',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  columnContainer: {
    display: 'flex !important',
    flexDirection: 'column !important',
  },
  columnPaddingBottom: {
    paddingBottom: '8px',
    background: '#E4E4EF !important',
    borderRadius: '0 0 10px 10px',
  },
  columnPaddingTop: {
    paddingTop: '8px',
    background: '#E4E4EF !important',
  },
  contextMenuItem: {
    cursor: 'pointer',
    height: '32px',
    // transition: 'all .2s',
    '&:hover': {
      backgroundColor: 'rgba(220, 223, 244, 1)',
      '& svg path': {
        fill: '#212346',
      },
    },
  },
  contextMenuItemDisabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  contextPopover: {
    transform: 'unset !important',
    height: 'fit-content',
    borderRadius: theme.spacing(4),
    '& .MuiPaper-root': {
      overflowY: 'hidden',
      maxWidth: 'unset',
      maxHeight: 'unset',
      width: '100%',
      borderRadius: theme.spacing(4),
      boxShadow: '0px 5px 10px -8px rgba(33, 35, 70, 1)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: 'white !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
      position: 'unset',
    },
  },
  contextPopoverSubItems: {
    '& .MuiPaper-root': {
      boxShadow: 'rgb(0 0 0 / 70%) 5px 3px 12px -8px',
    },
  },
  contextLeftUpperCorner: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(0, 4, 4, 4),
    },
    borderRadius: theme.spacing(0, 4, 4, 4),
  },
  contextLeftBottomCorner: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4, 4, 4, 0),
    },
    borderRadius: theme.spacing(4, 4, 4, 0),
  },
  contextLeftSideCorners: {
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(0, 4, 4, 0),
    },
    borderRadius: theme.spacing(0, 4, 4, 0),
  },
  contextMenuSubItem: {
    borderRadius: theme.spacing(0, 4, 4, 0),
    padding: theme.spacing(1.5, 0),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(220, 223, 244, 1)',
      borderRadius: 0,
    },
  },
  agileTableContainer: {
    display: 'flex !important',
    overflowX: 'auto',
    width: '100%',
    flexDirection: 'row !important',
    flexWrap: 'nowrap !important',
    overflowY: 'hidden',
    paddingBottom: '20px',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
      marginRight: '2px',
    },
    '&::-webkit-scrollbar': {
      height: '6px',
      backgroundColor: 'white !important',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  card: {
    background: '#fff',
    padding: '4px 8px',
    marginBottom: '4px',
    '&:last-child': {
      marginBottom: '0px',
    },
    '& MuiPaper-root': {
      boxShadow: 'none !important',
    },
    borderRadius: '8px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  title: {
    padding: '8px 16px',
    background: '#7174AC',
    color: '#fff',
    borderRadius: '10px 10px 0 0',
  },
  disabledItem: {
    opacity: 0.4,
  },
  droppableColumn: {
    minHeight: '8px',
    '& MuiPaper-root': {
      boxShadow: 'none !important',
    },
  },
}));

export default useStyles;
