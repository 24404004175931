import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  loadingWrapper: {
    '&.MuiGrid-root': {
      position: 'absolute !important',
    },
  },
  paginateButton: {
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(65, 66, 78, .15)',
    },
  },
  paginateButtonSelected: {
    backgroundColor: 'rgba(98, 112, 208, 1)',
    color: '#fff',
    cursor: 'text',
    '&:hover': {
      backgroundColor: 'rgba(98, 112, 208, 1)',
    },
  },
  paginateGoToPage: {
    backgroundColor: 'transparent',
    border: 0,
    outline: 'none',
    width: 40,
    textAlign: 'center',
    padding: theme.spacing(0, 1),
  },
}));

export default useStyles;
