import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  pageTitle: {
    float: 'left',
    paddingTop: '10px',
    marginRight: '32px !important',
    width: 'auto !important',
  },
  headerWrap: {
    display: 'block !important',
  },
  buttonSectionWrap: {
    float: 'right',
    position: 'relative',
    width: 'auto !important',
  },
}));

export default useStyles;
