import React, { useState } from 'react';
import { Grid, Typography, Box, Button, IconButton, Modal } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import ProjectSettingsIcons from '../../../../packages/common/shared-ui/src/icons/ProjectSettingsIcons';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';
import useStyles from './ResourcePlanHeader.styles';
import clsx from 'clsx';
import ResourcePlanModal from '../ResourcePlanModal/ResourcePlanModal';
import ResourcePlanFilter from '../ResourcePlanFilter/ResourcePlanFilter';
import { getResplanCsv } from '../../../../packages/common/api';
const ResourcePlanHeader = ({
  pageTitle,
  headerFilters,
  setRefreshKey,
  setQuery,
  setFetchedQuery,
  query,
  setIsSaving,
  setIsLoading,
  setIsClearing,
  incorrectDates,
  fetchedQuery,
  refreshKey,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const downloadResplanCsv = async () => {
    try {
      const data = await getResplanCsv();
      const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `resplan-${dayjs().format('YYYY-MM-DD')}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Grid container position="relative" wrap="nowrap" className={classes.headerWrap}>
      <Grid container marginBottom={0} alignItems="center" className={classes.pageTitle}>
        <Typography variant="h1">{pageTitle}</Typography>
      </Grid>
      <Grid container className={classes.buttonSectionWrap}>
        <Box display="flex" alignItems="center">
          <Box marginRight={0.5}>
            {incorrectDates?.length != 0 ? (
              <Button disableRipple disabled variant="cutGreyPrimary" onClick={() => setIsSaving(true)}>
                <FormattedMessage id="save_text" defaultMessage="Save" />
              </Button>
            ) : (
              <Button disableRipple variant="cutGreyPrimary" onClick={() => setIsSaving(true)}>
                <FormattedMessage id="save_text" defaultMessage="Save" />
              </Button>
            )}
          </Box>
          <Button disableRipple variant="cutGreySecondary" onClick={() => setIsClearing(true)}>
            <Typography variant="h5">
              <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
            </Typography>
          </Button>
        </Box>
        <Box marginLeft={3}>
          <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleOpen}>
            <Box display="flex" alignItems="center" marginRight={1.5}>
              <ProjectSettingsIcons color="#7174AC" width={24} height={24} viewBox="0 0 20 20" />
            </Box>
            <Typography variant="h4" fontWeight={600}>
              <FormattedMessage id="parameters_text" />
            </Typography>
          </Button>
        </Box>
        <IconButton onClick={downloadResplanCsv}>
          <DownloadButton width={24} height={24} viewBox="0 0 24 24" />
        </IconButton>
        <ResourcePlanModal
          open={open}
          handleClose={handleClose}
          setRefreshKey={setRefreshKey}
          setFetchedQuery={setFetchedQuery}
          query={query}
          setQuery={setQuery}
          setIsLoading={setIsLoading}
        />
      </Grid>
      <ResourcePlanFilter
        filters={headerFilters}
        setQuery={setQuery}
        topFilters={3}
        pageTitle={pageTitle}
        setFetchedQuery={setFetchedQuery}
        query={query}
        fetchedQuery={fetchedQuery}
        refreshKey={refreshKey}
      />
    </Grid>
  );
};

export default ResourcePlanHeader;
