import React from 'react';
import { Box, Grid } from '@mui/material';
import UserPageMainInfo from '../UserPageMainInfo';
import userPageStyles from '../../UserPage.styles';
import { EditPageContext } from '../../EditPageContext';
const UserPageCommonTab = ({ userInfo, isEdit }) => {
  const commonClasses = userPageStyles();

  return (
    <EditPageContext.Provider value={isEdit}>
      <Grid container spacing={5}>
        <Grid item lg={6}>
          <Box className={commonClasses.blockWrapper}>
            <UserPageMainInfo userInfo={userInfo}></UserPageMainInfo>
          </Box>
        </Grid>
      </Grid>
    </EditPageContext.Provider>
  );
};

export default React.memo(UserPageCommonTab);
