import React from 'react';
import { SvgIcon } from '@mui/material';

const CalendarTimesheetIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_7013_53519)">
        <path
          d="M12.4848 3.09091H11.9394V2H10.8485V3.09091H5.39393V2H4.30302V3.09091H3.75757C3.15757 3.09091 2.66666 3.58182 2.66666 4.18182V12.9091C2.66666 13.5091 3.15757 14 3.75757 14H12.4848C13.0848 14 13.5757 13.5091 13.5757 12.9091V4.18182C13.5757 3.58182 13.0848 3.09091 12.4848 3.09091ZM12.4848 12.9091H3.75757V5.81818H12.4848V12.9091Z"
          fill={props.color ? props.color : '#7174AC'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7013_53519">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CalendarTimesheetIcon;
