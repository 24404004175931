import React from 'react';

/* eslint-disable no-cyrillic-string/no-cyrillic-string */

export const UserInfo = {
  userFullName: 'Кашинцева Ольга Сергеевна',
  userLogin: 'KashintsevaOS',
  availiable: '100%',
  roleResource: 'нет',
  wipLimit: '100',
  position: 'Руководитель проектного офиса',
  productStaff: 'нет',
  telephone: '+7 (495) 108-68-23',
  initials: 'Кашинцева О.С.',
  employmentDate: '26.09.2016',
  regDate: '16.12.2019',
  company: 'FS2',
  lastConnect: '09.08.2022',
  staffType: 'админ',
};

export const TasksData = [
  {
    id: 1,
    name: 'Contract/Order',
    open: '1',
    close: '0',
  },
  {
    id: 2,
    name: 'PE (Income)',
    open: '5',
    close: '0',
  },
  {
    id: 3,
    name: 'PE (Outcome)',
    open: '1',
    close: '0',
  },
  {
    id: 4,
    name: 'Resource Project Task',
    open: '10',
    close: '0',
  },
  {
    id: 5,
    name: 'Change Request',
    open: '1',
    close: '0',
  },
];

export const ProjectsData = [
  {
    id: 1,
    name: 'RBP',
    role: 'Руководитель проекта, Timesheet Approver, Просмотр рес. плана',
    reg: '30.01.2021',
  },
  {
    id: 2,
    name: 'Int Adm Бэк-офис',
    role: 'Руководитель проекта, Timesheet Approver, Просмотр рес. плана',
    reg: '21.12.2020',
  },
  {
    id: 3,
    name: 'NPA Отпуск Бэк-офис',
    role: 'Руководитель проекта, Timesheet Approver, Просмотр рес. плана',
    reg: '21.12.2020',
  },
  {
    id: 4,
    name: 'Проекты ДФ',
    role: 'Руководитель проекта, Timesheet Approver, Просмотр рес. плана',
    reg: '22.01.2021',
  },
  {
    id: 6,
    name: 'Проекты РБП',
    role: 'Руководитель проекта, Timesheet Approver, Просмотр рес. плана',
    reg: '29.01.2021',
  },
];

export const TasksTypes = [
  {
    id: 1,
    type: 'Contract/Order',
    placeHolder: 'Название',
    before: 'Название',
    after: 'Название',
    changeDate: '16.05.2022 14:59',
    author: 'Кашинцева Ольга Сергеевна',
  },
  {
    id: 2,
    type: 'PE (Income)',
    placeHolder: 'Название',
    before: 'Название',
    after: 'Название',
    changeDate: '16.05.2022 14:59',
    author: 'Кашинцева Ольга Сергеевна',
  },
  {
    id: 3,
    type: 'PE (Outcome)',
    placeHolder: 'Название',
    before: 'Название',
    after: 'Название',
    changeDate: '16.05.2022 14:59',
    author: 'Кашинцева Ольга Сергеевна',
  },
  {
    id: 4,
    type: 'Resource Project Task',
    placeHolder: 'Название',
    before: 'Название',
    after: 'Название',
    changeDate: '16.05.2022 14:59',
    author: 'Кашинцева Ольга Сергеевна',
  },
  {
    id: 5,
    type: 'Change Request',
    placeHolder: 'Название',
    before: 'Название',
    after: 'Название',
    changeDate: '16.05.2022 14:59',
    author: 'Кашинцева Ольга Сергеевна',
  },
];
