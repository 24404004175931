import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  modalPaper: {
    backgroundColor: '#F1F2F8',
    padding: theme.spacing(2.5, 5),
    borderRadius: theme.spacing(4),
    maxWidth: 592,
    maxHeight: '97%',
    overflowY: 'auto',
  },
  filtersWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
    marginLeft: '0 !important',
    width: '100% !important',
  },
  formControlWrap: {
    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& .MuiGrid-root': {
      margin: '0 !important',
    },
  },
  formControl: {
    margin: '0 !important',
    padding: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
    '& .MuiSelect-select': {
      padding: '3px !important',
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 3000,
    },
    '& .MuiPaper-root': {
      backgroundColor: '#ffffff',

      '& ul': {
        padding: 0,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    style: {
      maxHeight: 500,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346;',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px',
      marginRight: '8px !important',
      marginLeft: '4px !important',
    },
  },
  chip: {
    margin: 2,
    backgroundColor: '#FFF',
    '& .MuiChip-label': {
      padding: '8px 0px 8px 8px',
    },
  },
  menuItemCheckbox: {
    color: 'red',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  dragWrap: {
    width: '100% !important',
  },
  columnTtl: {
    padding: '0 16px 5px 16px',
    fontSize: '12px !important',
  },
  notSelectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiList-root': {
      width: '100%',
    },
    '& .MuiListItem-root': {
      padding: '0',
      width: '100%',
      '& .MuiListItemButton-root': {
        padding: '6px 16px',
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  notSelectedFieldsSearch: {
    borderBottom: '1px solid #E4E4EF',
    padding: '6px 16px',
    fontSize: '12px !important',
    color: '#7174AC',
  },
  selectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiListItem-root': {
      padding: '0',
    },
  },
}));

export default useStyles;
