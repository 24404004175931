import React, { memo, useCallback, useReducer } from 'react';
import { Box, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import useStyles from './SpentTimeRow.styles';
import clsx from 'clsx';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
const SpentTimeRow = ({
  setContextMenuData,
  setTableSelectedValues,
  tableSelectedValues,
  contextPopperStylePosition,
  setIsContextMenuOpen,
  isContextMenuOpen,
  originalTimesheetsData,
  currentType,
  isSelected,
  props,
  intl,
}) => {
  const classes = useStyles();
  const [, forceUpdate] = useReducer(x => x - 1, 0);
  const selectRow = useCallback(
    ({ rowId, clear = false }) => {
      setTableSelectedValues(prevState => {
        if (clear) {
          return [rowId];
        }
        if (prevState.includes(rowId)) {
          return prevState.filter(id => id !== rowId);
        }
        return [...prevState, rowId];
      });
      forceUpdate();
    },
    [setTableSelectedValues],
  );

  const onContextMenu = useCallback(
    e => {
      if (e.button === 2 && !isContextMenuOpen) {
        const currentRow = originalTimesheetsData.find(row => row.rowId === props.rowId);
        setContextMenuData(prevState => {
          prevState.currentRow = currentRow;
          prevState.isSecondStepVisible = false;
          prevState.secondStepType = '';
          return prevState;
        });
        contextPopperStylePosition.current.left = e.clientX;
        contextPopperStylePosition.current.top = e.clientY;
        if (!tableSelectedValues.includes(props.rowId)) {
          selectRow({ rowId: props.rowId });
        }
        setIsContextMenuOpen(true);
        forceUpdate();
      }
    },
    [
      isContextMenuOpen,
      originalTimesheetsData,
      setContextMenuData,
      contextPopperStylePosition,
      tableSelectedValues,
      setIsContextMenuOpen,
      props.rowId,
      selectRow,
    ],
  );

  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'user':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      case 'issue':
        return generateRedirectUrl({ host: window.location.origin, path: `/issues/${id}` });
      default:
        return '';
    }
  }, []);

  if (props.row.hasOwnProperty('renderCell')) {
    return (
      <Grid
        container
        className={classes.tableRowGroup}
        alignItems="center"
        justifyContent="flex-start"
        wrap="nowrap"
        key={props.rowId}
      >
        <Typography variant="h4" fontWeight={700}>
          {props.row.groupName}
        </Typography>
        {Object.keys(props.row).map(key => {
          if (!key.split('.').includes('timesheet')) {
            return;
          }
          return (
            <Box key={key} display="flex" flexWrap="nowrap" alignItems="center" marginLeft={2}>
              <Typography variant="h5" marginRight={2}>
                {getTranslatedText(intl, 'filter', key, key)}:
              </Typography>
              <Typography variant="h5" fontWeight={700}>
                {!isNaN(Number(props.row[key])) ? Number(props.row[key]).toFixed(0) : 0}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid
      container
      wrap="nowrap"
      position="relative"
      data-rowid={props.rowId}
      onContextMenu={onContextMenu}
      className={clsx({ [classes.selectedRow]: isSelected })}
    >
      {currentType !== 1 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.tableCell}
          width={props.renderedColumns[0]?.width || 0}
        >
          <Checkbox
            checked={isSelected}
            onChange={() => selectRow({ rowId: props.rowId })}
            disableRipple
            icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
            checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
          />
        </Box>
      )}
      {Object.entries(props.row).map((column, index) => {
        if (column[0] === 'id') {
          return;
        }
        const cellWidth = props.renderedColumns[index + 1]?.hasOwnProperty('computedWidth')
          ? props.renderedColumns[index + 1].computedWidth
          : 0;
        const splittedColumnName = `${column[1]}`.split('$$');
        if (currentType === 1) {
          const emptyElsForRender = new Array(Number(props.renderedColumns[index + 1].position) - 1).fill(0);
          return (
            <Grid container wrap="nowrap" alignItems="center" key={props.renderedColumns[index + 1].field}>
              {emptyElsForRender.map(_ => (
                <Grid
                  item
                  className={classes.tableCell}
                  {...(!props.renderedColumns[index + 2]
                    ? { minWidth: cellWidth, flex: 1 }
                    : { width: cellWidth, minWidth: cellWidth })}
                />
              ))}
              <Grid
                item
                overflow="hidden"
                alignItems="center"
                justifyContent="flex-start"
                className={classes.tableCell}
                {...(!props.renderedColumns[index + 1]
                  ? { minWidth: cellWidth, flex: 1 }
                  : { width: cellWidth, minWidth: cellWidth })}
              >
                <Tooltip title={column[1]} PopperProps={{ className: classes.commentTooltip }}>
                  <Typography variant="h4" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {column[1]}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid
            item
            overflow="hidden"
            alignItems="center"
            justifyContent="flex-start"
            className={classes.tableCell}
            key={index}
            flex={1}
            {...(!props.renderedColumns[index + 2]
              ? { minWidth: cellWidth, flex: 1 }
              : { width: cellWidth, minWidth: cellWidth })}
          >
            <Tooltip title={splittedColumnName[0]} PopperProps={{ className: classes.commentTooltip }}>
              {splittedColumnName.length > 1 ? (
                <a
                  href={handleGetRedirectUrl({ id: splittedColumnName[1], columnType: splittedColumnName[2] })}
                  className={classes.tableCellLink}
                  target="_blank"
                >
                  <Typography variant="h4" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                    {splittedColumnName[0]}
                  </Typography>
                </a>
              ) : (
                <Typography variant="h4" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                  {splittedColumnName[0]}
                </Typography>
              )}
            </Tooltip>
          </Grid>
        );
      }, [])}
    </Grid>
  );
};

export default memo(SpentTimeRow);
