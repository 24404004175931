import React, { useState, useEffect, useCallback, useReducer, useRef, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import ProjectSettingsIcons from '../../../../packages/common/shared-ui/src/icons/ProjectSettingsIcons';
import { useQueryParams } from 'use-query-params';
import { useLocation, useNavigate } from 'react-router';
import { Box, Button, Grid, Typography, CircularProgress, Pagination } from '@mui/material';
import useStyles from './AgileContainer.styles';
import { getAgile, getAgileFilters, getAgileRules } from '../../../../packages/common/api';
import AgileSettingsModal from '../AgileSettingsModal/AgileSettingsModal';
import AgileTable from '../AgileTable';
import AgileFilters from '../AgileFilters/AgileFilters';
import AgileQueryModal from '../AgileQueryModal/AgileQueryModal';
import { useSelector } from 'react-redux';
import { deleteSavedQuery } from '../../../../packages/common/api';
import WarningModal from '../../../../packages/common/shared-ui/src/components/WarningModal';
import clsx from 'clsx';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';

const AgileContainer = ({ intl }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    project: '',
    fields: 'issue.assigned$$issue.tracker',
    savedQueryId: '',
    filters: '',
    statuses: '',
  });
  const [agileData, setAgileData] = useState([]);
  const [agileRules, setAgileRules] = useState([]);
  const [agileFilters, setAgileFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [queryModalTitle, setQueryModalTitle] = useState('');
  const [isQueryOpen, setIsQueryOpen] = useState(false);
  const [isNewQuery, setIsNewQuery] = useState(true);
  const { state: locationState = null } = useLocation();
  const location = useLocation();
  const prevLocationKey = useRef(location.key);

  const [query, setQuery] = useState({
    filters: queryParams.filters ? queryParams.filters : '',
    fields: queryParams.fields ? queryParams.fields : 'issue.assigned$$issue.tracker',
    projectId: queryParams.project,
    statuses: queryParams.statuses ? queryParams.statuses : '',
    limit: '500',
    page: '1',
    offset: '0',
    savedQueryId: queryParams.savedQueryId ? queryParams.savedQueryId : '',
  });
  const pagesCount = useMemo(
    () => (agileData?.rows && Math.ceil(agileData?.rows[0]?.services?.count / Number(query.limit))) || 1,
    [agileData?.rows, query.limit],
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const handleOpenWarningModal = () => setIsDeleteConfirmOpen(true);
  const closeWarningModal = () => setIsDeleteConfirmOpen(false);

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const classes = useStyles();

  const handleToggleDelete = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await deleteSavedQuery({ id: query.savedQueryId });
          navigate(`/agile?project=${query.projectId}`);
          closeWarningModal();
          break;
        case 'delete':
          closeWarningModal();
          break;
      }
    },
    [query.savedQueryId, query.projectId, navigate],
  );

  const handleOpenQuery = useCallback(({ title, isClear }) => {
    setQueryModalTitle(title);
    setIsNewQuery(isClear);
    setIsQueryOpen(true);
  }, []);
  const handleCloseQuery = useCallback(() => setIsQueryOpen(false), []);
  const pageTitle = intl.formatMessage({ id: 'agile_title' });
  const createQueryString = queryObj => {
    let queryString = `project=${queryObj.projectId}`;
    if (queryObj.filters && queryObj.filters !== 'filters=') {
      queryString += `&filters=${queryObj.filters}`;
    }
    if (queryObj.fields) {
      queryString += `&fields=${queryObj.fields}`;
    }
    if (queryObj.statuses && queryObj.statuses !== 'statuses=') {
      queryString += `&statuses=${queryObj.statuses}`;
    }
    if (queryObj.savedQueryId) {
      queryString += `&savedQueryId=${queryObj.savedQueryId}`;
    }
    if (queryObj.limit) {
      queryString += `&limit=${queryObj.limit}`;
    }
    if (queryObj.offset) {
      queryString += `&offset=${queryObj.offset}`;
    }
    return queryString;
  };

  useEffect(() => {
    setQuery(prevQuery => ({
      ...prevQuery,
      filters: queryParams.filters ? queryParams.filters : '',
      fields: queryParams.fields ? queryParams.fields : 'issue.assigned$$issue.tracker',
      projectId: queryParams.project,
      statuses: queryParams.statuses ? queryParams.statuses : '',
      savedQueryId: queryParams.savedQueryId ? queryParams.savedQueryId : '',
      limit: queryParams.limit ? queryParams.limit : '500',
      offset: queryParams.offset ? queryParams.offset : '0',
      page: queryParams.page ? queryParams.page : '1',
    }));
    setAgileFilters([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.project, queryParams.savedQueryId]);

  useEffect(() => {
    let ignore = false;
    const handleResults = results => {
      const errors = results.filter(result => result.status === 'rejected').map(result => result.reason);
      if (errors.length) {
        console.error(errors, 'ERROR IN GET');
      }
      if (!ignore) {
        return results.map(result => (result.value ? result.value.data : null));
      }
    };
    const fetchAgileData = async () => {
      try {
        setIsLoading(true);
        const queryString = createQueryString(query);
        try {
          const agileData = await getAgile(queryString);
          setAgileData(agileData.data);
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const fetchInitialData = async () => {
      try {
        setIsPageLoading(true);
        const queryString = createQueryString(query);
        Promise.allSettled([
          getAgile(queryString),
          getAgileFilters(queryString),
          getAgileRules(`project=${query.projectId}&statuses=${query.statuses}`),
        ]).then(data => {
          if (!ignore) {
            const [agileDataResult, agileFiltersResult, agileRulesResult] = handleResults(data);
            if (agileDataResult) {
              setAgileData(agileDataResult);
              console.log('agileData', agileDataResult);
            }
            if (agileFiltersResult) {
              const queryFilters = query?.filters?.split('$$').map(filter => filter.split(';')[0]);
              const updatedFilters = agileFiltersResult.filters.map(filter => {
                if (queryFilters?.includes(filter.fieldId)) {
                  return { ...filter, isChecked: true };
                }
                return filter;
              });
              const updatedFiltersResult = {
                ...agileFiltersResult,
                filters: updatedFilters,
              };
              setAgileFilters(updatedFiltersResult);
            }
            if (agileRulesResult) {
              setAgileRules(agileRulesResult);
            }
            setIsPageLoading(false);
          }
        });
      } catch (e) {
        console.error(e);
      }
    };
    if (agileFilters.length === 0) {
      fetchInitialData();
    } else {
      const searchString = createQueryString(query);
      navigate(`?${searchString}`, { replace: true, state: locationState });
      fetchAgileData();
    }

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const handlePaginate = useCallback(
    (e, page) => {
      if (page) {
        setIsLoading(true);
        setQuery(prevQuery => ({
          ...prevQuery,
          page: page,
          offset: (page - 1) * query.limit,
        }));
      }
    },
    [query.limit],
  );
  return (
    <>
      {!isPageLoading ? (
        <Page
          title={pageTitle}
          width="100%"
          sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column' }}
        >
          <WarningModal
            simple
            isWarningModalOpen={isDeleteConfirmOpen}
            handleToggle={handleToggleDelete}
            closeWarningModal={closeWarningModal}
            title={`${intl.formatMessage({ id: 'agile_delete_board' })}?`}
          />
          <AgileSettingsModal
            intl={intl}
            open={open}
            handleClose={handleClose}
            agileFilters={agileFilters}
            setQuery={setQuery}
            setAgileFilters={setAgileFilters}
            query={query}
          />
          {queryModalTitle && isQueryOpen && (
            <AgileQueryModal
              locationState={locationState}
              isNewQuery={isNewQuery}
              query={query}
              isModalOpen={isQueryOpen}
              closeModal={handleCloseQuery}
              title={queryModalTitle}
            />
          )}
          <Grid container wrap="nowrap" direction="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexWrap="nowrap" alignItems="baseline" marginRight={6}>
              <Typography variant="h1" marginRight={locationState?.name ? 2.5 : 0}>
                <FormattedMessage id="agile_title" defaultMessage="Agile" />
              </Typography>
              {query.savedQueryId && (
                <Typography variant="h2" fontWeight={600}>
                  {locationState?.name}
                </Typography>
              )}
            </Box>
            <Box display="flex">
              <Box>
                <Button
                  type="submit"
                  disableRipple
                  variant="defaultGreyPrimary"
                  onClick={() =>
                    handleOpenQuery({ title: intl.formatMessage({ id: 'agile_create_board' }), isClear: true })
                  }
                >
                  <Typography variant="h4" fontWeight={400}>
                    <FormattedMessage id="agile_create_board" defaultMessage="Create" />
                  </Typography>
                </Button>
              </Box>
              {query.savedQueryId && (
                <>
                  <Box marginLeft={2.5}>
                    <Button
                      variant="defaultGreyPrimary"
                      disableRipple
                      onClick={() =>
                        handleOpenQuery({ title: intl.formatMessage({ id: 'agile_edit_board' }), isClear: false })
                      }
                    >
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="agile_edit_board" />
                      </Typography>
                    </Button>
                  </Box>
                  <Box marginLeft={2.5}>
                    <Button variant="defaultGreyPrimary" disableRipple onClick={handleOpenWarningModal}>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="agile_delete_board" />
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}
              <Box marginLeft={2.5}>
                <Button variant="defaultGreyPrimaryWithIcon" disableRipple onClick={handleOpen}>
                  <Box display="flex" alignItems="center" marginRight={1.5}>
                    <ProjectSettingsIcons color="#7174AC" width={24} height={24} viewBox="0 0 20 20" />
                  </Box>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="parameters_text" />
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container wrap="wrap">
            <AgileFilters
              filters={agileFilters.filters}
              projectId={queryParams.project}
              query={query}
              setQuery={setQuery}
            />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            overflow={'hidden'}
            flexDirection={'column'}
            sx={{ flex: 1 }}
          >
            <AgileTable
              key={query}
              agileData={agileData}
              agileRulesData={agileRules}
              projectId={queryParams.project}
              intl={intl}
            />
          </Grid>
          {agileData.rows && pagesCount > 1 && (
            <Grid container marginY={5}>
              <Pagination
                onChange={handlePaginate}
                count={pagesCount}
                page={Number(query.page ?? 0)}
                shape="rounded"
                renderItem={item => {
                  if ((item.page === pagesCount || item.page === 1) && item.type === 'page') {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                        minWidth={28}
                        height={28}
                        marginX={0.5}
                        onClick={item.onClick}
                      >
                        <Typography variant="h3" paddingX={1}>
                          {item.page}
                        </Typography>
                      </Box>
                    );
                  }
                  if (item.type === 'start-ellipsis') {
                    return (
                      <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                        ...
                      </Typography>
                    );
                  }
                  if (item.type === 'end-ellipsis') {
                    return (
                      <Typography variant="h3" className={classes.paginateGoToPage} marginX={1.5}>
                        ...
                      </Typography>
                    );
                  }
                  if (item.type === 'previous') {
                    return Number(query.page) !== 1 ? (
                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        onClick={item.onClick}
                        className={classes.paginateButton}
                        marginRight={2}
                        height={28}
                        paddingRight={2}
                        key={item.page}
                      >
                        <ChevronIcon width={20} height={20} direction="left" viewBox="0 0 24 24" color="#41424E" />
                        {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                        <Typography variant="h3" align="center" color="#41424E">
                          Предыдущая
                        </Typography>
                      </Box>
                    ) : null;
                  }
                  if (item.type === 'next') {
                    return Number(query.page) !== pagesCount ? (
                      <Box
                        display="flex"
                        flexWrap="nowrap"
                        alignItems="center"
                        onClick={item.onClick}
                        className={classes.paginateButton}
                        height={28}
                        paddingLeft={2}
                        key={item.page}
                      >
                        {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                        <Typography variant="h3" align="center" color="#41424E">
                          Следующая
                        </Typography>
                        <ChevronIcon width={20} height={20} direction="right" viewBox="0 0 24 24" color="#41424E" />
                      </Box>
                    ) : null;
                  }
                  if (item.type === 'page' && item.page === pagesCount) {
                    return Number(query.page) !== item.page ? (
                      <Typography variant="h3" className={classes.paginateGoToPage}>
                        ...
                      </Typography>
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                        minWidth={28}
                        height={28}
                        marginX={0.5}
                        onClick={item.onClick}
                      >
                        <Typography variant="h3" paddingX={1}>
                          {item.page}
                        </Typography>
                      </Box>
                    );
                  }
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(classes.paginateButton, { [classes.paginateButtonSelected]: item.selected })}
                      minWidth={28}
                      height={28}
                      marginX={0.5}
                      onClick={item.onClick}
                      key={item.page}
                    >
                      <Typography variant="h3" paddingX={1}>
                        {item.page}
                      </Typography>
                    </Box>
                  );
                }}
              />
            </Grid>
          )}
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(AgileContainer);
