import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridRowModes } from '@mui/x-data-grid-pro';
import SortIcon from '../../../../../../packages/common/shared-ui/src/icons/SortIcon';
import issuePageStyles from '../../../../issuePage/IssuePage.styles';
import ArrowDownIcon from '../../../../../../packages/common/shared-ui/src/icons/ArrowDownIcon';
import ChevronIcon from '../../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import dataGridTableStyles from '../../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { getParentIssueQuery, saveConfirmationReinit } from '../../../../../../packages/common/api';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { generateRedirectUrl } from '../../../../../../packages/common/utils/getRedirectUrl';
import { store } from '../../../../app/redux';
import clsx from 'clsx';
import ParentIssueDropdown from '../../../../../../packages/common/shared-ui/src/icons/ParentIssueDropdown';

const ConfirmationDocumentsTable = ({ intl, confirmationDocuments, pageState }) => {
  const [saveDocsLoading, setSaveDocsLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const issueCommonStyles = issuePageStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const [showSaveButton, setShowSaveButton] = useState(null);
  const [confirmationDocsColumns, setConfirmationDocsColumns] = useState([]);
  const [confirmationDocsRows, setConfirmationDocsRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [parentIssueOptions, setParentIssueOptions] = useState([]);
  const location = useLocation();
  const urlParams = useRef(new URLSearchParams(location.search));
  const state = store.getState();
  const handleGetRedirectUrl = useCallback(
    ({ id, columnType }) => {
      const issueLink = state.user.project ? `/issues/${id}?project=${state.user.project.redmineId}` : `/issues/${id}`;
      switch (columnType) {
        case 'user':
          return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
        case 'project':
          return `${window.location.origin}/projects/${id}`;
        case 'issue':
        case 'issue.subject':
        case 'id':
        case 'issue_parent':
          return issueLink;
        default:
          return '';
      }
    },
    [state.user.project],
  );

  useEffect(() => {
    if (confirmationDocuments) {
      setLoadingDocuments(false);
      confirmationDocuments.columns && setConfirmationDocsColumns(confirmationDocuments.columns);
      if (confirmationDocuments.rows) {
        setConfirmationDocsRows(formattedGridRows(confirmationDocuments.rows));
        const editableRows = confirmationDocuments.rows.reduce((acc, item, index) => {
          return {
            ...acc,
            [item.rowId + index]: {
              mode: item.services.canEdit ? GridRowModes.Edit : GridRowModes.View,
            },
          };
        }, {});
        if (editableRows) {
          Object.values(editableRows).filter(item => item.mode === GridRowModes.Edit).length > 0 &&
            setShowSaveButton(true);
          setRowModesModel(editableRows);
        }
      }
    }
  }, [confirmationDocuments, formattedGridRows]);

  const handleField = useCallback(
    (fieldValue, id, type) => {
      const numberFields = ['float', 'int', 'percent'];
      const index = confirmationDocsRows.findIndex(item => item.id === id);
      let updatedObject;
      fieldValue?.target &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue.target.value,
          ['confirmation.fieldRenewValueId']: fieldValue.target.value,
        });
      numberFields.includes(type) &&
        (updatedObject['confirmation.fieldRenewValueId'] = fieldValue.target.value.replace(/\s+/g, ''));
      type === 'date' &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
          ['confirmation.fieldRenewValueId']: fieldValue ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
        });
      type === 'issue_parent' &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue.valueName,
          ['confirmation.fieldRenewValueId']: fieldValue.valueId,
        });
      updatedObject.changed = true;
      setConfirmationDocsRows([
        ...confirmationDocsRows.slice(0, index),
        updatedObject,
        ...confirmationDocsRows.slice(index + 1),
      ]);
    },
    [confirmationDocsRows],
  );

  const handleNumberField = useCallback(
    (fieldValue, id, field) => {
      const reg = new RegExp(/^[0-9,.]*$/);
      if (reg.test(fieldValue.target.value)) {
        handleField(fieldValue, id, field);
      }
    },
    [handleField],
  );
  const handleParentIssueChange = useCallback(async value => {
    let params = {
      search: value,
    };
    urlParams.current.get('issues') && (params.issues = urlParams.current.get('issues').replaceAll(',', '||'));
    try {
      const response = await getParentIssueQuery(params);
      if (response) {
        setParentIssueOptions(response.data);
      }
    } catch (error) {
      console.error('ERROR WITH EDIT', error);
    }
  }, []);
  const formattedConfirmationDocsColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    const editableColumns = ['confirmation.fieldRenewValue'];
    const numberFields = ['float', 'int', 'percent'];
    const hasRenewColumn = confirmationDocsColumns.find(column => column.field === 'fieldRenewValue');
    const columns = confirmationDocsColumns.map(column => {
      return {
        field: column.fieldId,
        headerName:
          hasRenewColumn !== undefined && column.field === 'fieldOriginValue'
            ? intl.formatMessage({
                id: `confirmation_page_origin_reinit`,
              })
            : intl.formatMessage({
                id: `confirmation_page_${column.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
              }),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 150,
        cellClassName: props =>
          props.row.rowType === 'group'
            ? 'tableCell tableGroupCell'
            : props.row.services.fieldType === 'issue_parent'
            ? 'tableCell tableSelectCell'
            : 'tableCell',
        editable: editableColumns.includes(column.fieldId) && true,
        renderEditCell: props => {
          const index = confirmationDocsRows.findIndex(item => item.id === props.row.id);
          if (props.row.services.fieldType === 'issue_parent') {
            return (
              <Autocomplete
                noOptionsText={intl.formatMessage({ id: 'not_found' })}
                onChange={(event, newValue) => handleField(newValue, props.row.id, props.row.services.fieldType)}
                className={clsx(dataGridTableStyle.rowAutocomlpete, {
                  ['changed']: props.row.changed,
                })}
                disablePortal
                filterOptions={x => x}
                options={parentIssueOptions}
                sx={{ width: 253 }}
                disableClearable
                renderOption={(props, option) => {
                  return (
                    <MenuItem {...props} key={option.valueId} value={option.valueId}>
                      {option.valueName}
                    </MenuItem>
                  );
                }}
                value={
                  confirmationDocsRows[index]['confirmation.fieldRenewValue']
                    ? {
                        valueId: confirmationDocsRows[index]['confirmation.fieldRenewValueId'],
                        valueName: confirmationDocsRows[index]['confirmation.fieldRenewValue'],
                      }
                    : ''
                }
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={option => option.valueName || ''}
                PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={ev => handleParentIssueChange(ev.target.value)}
                    placeholder={intl.formatMessage({ id: 'choose' })}
                  />
                )}
                popupIcon={<ChevronIcon direction={'down'} />}
              />
            );
          }
          if (numberFields.includes(props.row.services.fieldType)) {
            return (
              <TextField
                defaultValue={
                  confirmationDocsRows[index]['confirmation.fieldRenewValue']
                    ? confirmationDocsRows[index]['confirmation.fieldRenewValue']
                    : ''
                }
                className={clsx(dataGridTableStyle.rowInput, {
                  ['changed']: props.row.changed,
                })}
                placeholder={intl.formatMessage({ id: 'enter' })}
                variant="standard"
                onChange={event => handleField(event, props.row.id, props.row.services.fieldType)}
              ></TextField>
            );
          }
          if (props.row.services.fieldType === 'list' || props.row.services.fieldType === 'user') {
            return (
              <FormControl sx={{ width: '100%' }}>
                <InputLabel className={dataGridTableStyle.selectLabel} id="select-label">
                  {intl.formatMessage({ id: 'choose' })}
                </InputLabel>
                <Select
                  native={false}
                  labelId="select-label"
                  label={intl.formatMessage({ id: 'choose' })}
                  variant="standard"
                  sx={{ width: '100%', border: 'none' }}
                  className={clsx(dataGridTableStyle.rowSelect, {
                    ['changed']: props.row.changed,
                  })}
                  MenuProps={{ classes: { paper: dataGridTableStyle.paperDropdown } }}
                  IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                  value={
                    confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                      ? confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                      : ''
                  }
                  onChange={event => handleField(event, props.row.id, props.row.services.fieldType)}
                >
                  {props.row.values.map((item, index) => (
                    <MenuItem key={index} value={item.valueId}>
                      {item.valueName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
          if (props.row.services.fieldType === 'date') {
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={clsx(dataGridTableStyle.datePicker, {
                    ['changed']: props.row.changed,
                  })}
                  //format={'DD.MM.YYYY'}
                  value={
                    confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                      ? confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                      : ''
                  }
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                  autoFocus={true}
                  onChange={value => handleField(value, props.row.id, props.row.services.fieldType)}
                  components={{
                    LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    LeftArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                  }}
                />
              </LocalizationProvider>
            );
          }
          return (
            <TextField
              defaultValue={
                confirmationDocsRows[index]['confirmation.fieldRenewValue']
                  ? confirmationDocsRows[index]['confirmation.fieldRenewValue']
                  : ''
              }
              autoFocus
              className={clsx(dataGridTableStyle.rowInput, {
                ['changed']: props.row.changed,
              })}
              placeholder={intl.formatMessage({ id: 'enter' })}
              variant="standard"
              onChange={event => handleField(event, props.row.id, props.row.services.fieldType)}
            ></TextField>
          );
        },
        renderCell: props => {
          const index = confirmationDocsRows.findIndex(item => item.id === props.row.id);
          const linkFields = ['project', 'user', 'issue', 'issue_parent'];
          const stringExists = props.value
            ? !!intl.messages[`confirmation_documents_${props.value.toString().replaceAll(' ', '_').toLowerCase()}`]
            : false;
          const stringValue = stringExists
            ? intl.formatMessage({
                id: `confirmation_documents_${props.value.replaceAll(' ', '_').toLowerCase()}`,
              })
            : props.value;
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding:
                  props.colDef.field === 'confirmation.fieldName' && props.row.level !== 0
                    ? `0 0 0 ${
                        props.row.rowType !== 'normal' && props.row.level > 0
                          ? `${props.row.level * 16}px!important`
                          : props.row.rowType === 'normal' && props.row.level > 0
                          ? `${props.row.level * 16 + 16}px!important`
                          : 0
                      }`
                    : 0,
              }}
            >
              {props.row.rowType === 'group' && props.colDef.field === 'confirmation.fieldName' ? (
                <>
                  <ArrowDownIcon></ArrowDownIcon>
                  <Typography variant="h4" fontWeight={600} marginLeft={'4px'}>
                    {props.row.rowName}
                  </Typography>
                </>
              ) : linkFields.includes(props.row.services.fieldType) && props.field !== 'confirmation.fieldName' ? (
                <Link
                  to={handleGetRedirectUrl({
                    id: props.row[props.field + 'Id'],
                    columnType: props.row.services.fieldType,
                  })}
                >
                  {props.value}
                </Link>
              ) : props.field === 'confirmation.fieldRenewValue' &&
                confirmationDocsRows[index]['confirmation.fieldRenewValue'] ? (
                confirmationDocsRows[index]['confirmation.fieldRenewValue']
              ) : stringValue ? (
                stringValue
              ) : (
                ''
              )}
            </Box>
          );
        },
      };
    });
    return [...columns];
  }, [
    confirmationDocsColumns,
    confirmationDocsRows,
    dataGridTableStyle.datePicker,
    dataGridTableStyle.paperDropdown,
    dataGridTableStyle.rowAutocomlpete,
    dataGridTableStyle.rowInput,
    dataGridTableStyle.rowSelect,
    dataGridTableStyle.selectLabel,
    handleField,
    handleGetRedirectUrl,
    handleParentIssueChange,
    intl,
    parentIssueOptions,
  ]);

  const confirmationDocsColumnsValue = formattedConfirmationDocsColumns();

  const formattedGridRows = useCallback(
    data =>
      data.reduce((acc, row, index) => {
        const newCols = row.columns.reduce((columnAcc, item, i) => {
          let newItem = {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].valueName,
            [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
          };
          if (item.fieldDefinition && item.fieldDefinition.values) {
            newItem.values = item.fieldDefinition.values;
          }
          return newItem;
        }, {});
        delete row.columns;
        return [...acc, { ...newCols, ...row, id: row.rowId + index, level: row.services.level }];
      }, []),
    [],
  );

  const handleCellDoubleClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  const validate = useCallback(() => {
    const numberFields = ['float', 'int', 'percent'];
    const reg = new RegExp(/^[0-9,.]*$/);
    const fields = confirmationDocsRows.filter(item => numberFields.includes(item.services.fieldType));
    const checkedFields = fields.filter(
      item => item['confirmation.fieldRenewValueId'] && !reg.test(item['confirmation.fieldRenewValueId']),
    );
    return checkedFields.length <= 0;
  }, [confirmationDocsRows]);

  const handleSaveClick = useCallback(async () => {
    const validation = validate();
    if (!validation) {
      return;
    }
    setSaveDocsLoading(true);
    const result = Object.groupBy(confirmationDocsRows, ({ rowId }) => rowId);
    const numberFields = ['float', 'int', 'percent'];
    const data = Object.entries(result).map(item => {
      const fieldsData = item[1]
        .filter(item => item.rowType !== 'group')
        .reduce((acc, item) => {
          const valueId =
            numberFields.includes(item.services.fieldType) &&
            item['confirmation.fieldRenewValueId'] &&
            item['confirmation.fieldRenewValueId'].toString().includes(',')
              ? Number(item['confirmation.fieldRenewValueId'].replace(',', '.'))
              : item['confirmation.fieldRenewValueId'];
          return [
            ...acc,
            {
              fieldId: item['confirmation.fieldNameId'],
              valueId: valueId,
            },
          ];
        }, []);
      return {
        issueId: item[0],
        fields: fieldsData,
      };
    });
    try {
      const response = await saveConfirmationReinit(data);
      if (response) {
        const { data: res } = response;
        window.open(`/confirmations/${Number(res)}`, '_self');
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, [confirmationDocsRows, validate]);

  return (
    <>
      {loadingDocuments ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        confirmationDocsRows &&
        confirmationDocsColumns && (
          <Grid container flexDirection={'column'} sx={{ position: 'relative' }}>
            {saveDocsLoading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <Grid
              item
              sx={{ position: 'relative' }}
              borderRadius={'16px'}
              marginTop={'20px'}
              overflow={'hidden'}
              width={'100%'}
            >
              <DataGridPro
                onRowDoubleClick={(params, event) => handleCellDoubleClick(event)}
                onCellDoubleClick={(params, event) => handleCellDoubleClick(event)}
                autoHeight
                hideFooter
                disableRowSelectionOnClick
                disableColumnMenu
                columns={confirmationDocsColumnsValue}
                rows={confirmationDocsRows}
                getRowClassName={params => params.row.groupName && 'groupRow'}
                className={dataGridTableStyle.DataGridTableRoot}
                pageSize={10}
                slots={{
                  columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                  columnSortedAscendingIcon: () => (
                    <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                  columnSortedDescendingIcon: () => (
                    <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                }}
                getRowHeight={() => 'auto'}
                editMode="row"
                rowModesModel={rowModesModel}
              />
            </Grid>
            {showSaveButton && (
              <Grid item>
                <Button
                  onClick={handleSaveClick}
                  variant="defaultBlueSecondary"
                  disableRipple
                  sx={{ marginTop: '8px' }}
                >
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="save" />
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        )
      )}
    </>
  );
};

export default injectIntl(ConfirmationDocumentsTable);
