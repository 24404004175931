import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      '& span': {
        userSelect: 'none',
        ...typography.h5,
      },
    },
  },
};
