import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  calendarWarningRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  calendarWarningPaper: {
    backgroundColor: '#F1F2F8',
    padding: theme.spacing(5),
    borderRadius: theme.spacing(4),
    maxWidth: 310,
  },
  issueWarningPaper: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '16px',
    maxWidth: 362,
  },
}));

export default useStyles;
