const Cookie = require('js-cookie');

class CookiesController {
  constructor({ defaultDomain, defaultExpiration }) {
    this.defaultDomain = defaultDomain;
    this.defaultExpiration = defaultExpiration;
  }

  _checkLocalhost(host) {
    if (host.includes('localhost') || host.includes('127.0.0.1')) {
      return true;
    }

    return false;
  }

  _setCookie({ key, value, sameSite, host, expires }) {
    try {
      const isLocalhost = this._checkLocalhost(host);

      if (isLocalhost) {
        Cookie.set(key, value, {
          expires,
          domain: '127.0.0.1',
        });
        return;
      }
      const url = new URL(host);
      const domain = `.${url.host}`;
      const isSecure = url.protocol === 'https:';

      Cookie.set(key, value, {
        ...(isSecure && { secure: true }),
        sameSite,
        domain,
        expires,
      });
    } catch (err) {
      console.log(err);
    }
  }

  getDefaultExpires() {
    return new Date(Date.now() + this.defaultExpiration);
  }

  getCookie(key) {
    return Cookie.get(key);
  }

  setCookie({ key, value, expires, sameSite = 'None', host = window.location.origin }) {
    this._setCookie({ key, value, expires, sameSite, host });
  }

  removeCookie({ key, value, sameSite = 'None', host = window.location.origin }) {
    this._setCookie({ key, value, expires: new Date(0), sameSite, host });
  }
}

const cookiesController = new CookiesController({
  defaultDomain: '',
  defaultExpiration: 2592000 * 1000,
});

module.exports = cookiesController;
