import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  ProjectWrap: {
    display: 'flex',
    padding: '20px',
    width: '100%',
    minheight: '271px',
    background: '#FFFFFF',
    borderRadius: '8px',
    flexDirection: 'column',
    boxShadow: '0px 5px 10px -8px #212346',
  },
  ProjectGridWrap: {
    flexBasis: '23% !important',
    maxWidth: '23% !important',
    '&:nth-child(4n)': {
      '& div': {
        marginRight: '0px',
      },
    },
  },
  ProjectTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '8px',
    color: '#212346',
  },
  projectFiledTtl: {
    color: '#41424E !important',
  },
  projectFiledValue: {
    color: '#212346 !important',
    overflowWrap: 'break-word',
  },
  ProjectStatus: {
    color: '#37C670',
  },
  ProjectClosed: {
    color: '#E03737',
  },
  ProjectOpened: {
    color: '#37C670',
  },
  projectTtl: {
    cursor: 'pointer',
    overflowWrap: 'break-word',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
