import React from 'react';
import { SvgIcon } from '@mui/material';

const FileIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.0013 3.99996V11.6666C7.0013 13.14 5.80797 14.3333 4.33464 14.3333C2.8613 14.3333 1.66797 13.14 1.66797 11.6666V3.33329C1.66797 2.41329 2.41464 1.66663 3.33464 1.66663C4.25464 1.66663 5.0013 2.41329 5.0013 3.33329V10.3333C5.0013 10.7 4.7013 11 4.33464 11C3.96797 11 3.66797 10.7 3.66797 10.3333V3.99996H2.66797V10.3333C2.66797 11.2533 3.41464 12 4.33464 12C5.25464 12 6.0013 11.2533 6.0013 10.3333V3.33329C6.0013 1.85996 4.80797 0.666626 3.33464 0.666626C1.8613 0.666626 0.667969 1.85996 0.667969 3.33329V11.6666C0.667969 13.6933 2.30797 15.3333 4.33464 15.3333C6.3613 15.3333 8.0013 13.6933 8.0013 11.6666V3.99996H7.0013Z"
        fill="#7174AC"
      />
    </SvgIcon>
  );
};

export default FileIcon;
