import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import userPageStyles from '../../UserPage.styles';
import {
  deleteUserRate,
  editUserRates,
  getCalculationTypes,
  getUserRates,
  saveUserRates,
} from '../../../../../../packages/common/api';
import userPageRatesTabStyles from './UserPageRatesTab.styles';
import PlusIcon from '../../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UserNewEditIcon from '../../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import SubmitIcon from '../../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import ReportDeleteIcon from '../../../../../../packages/common/shared-ui/src/icons/ReportDeleteIcon';
import dayjs from 'dayjs';

const UserPageRatesTab = ({ intl, userId }) => {
  const commonClasses = userPageStyles();
  const classes = userPageRatesTabStyles();
  const [userRates, setUserRates] = useState([]);
  const [types, setTypes] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getTypes = getCalculationTypes();
    const getRates = getUserRates(userId);
    function handleResults(results) {
      const errors = results.filter(result => result.status === 'rejected').map(result => result.reason);
      if (errors.length) {
        console.error(errors, 'ERROR IN GET');
      }
      return results.map(result => result.value);
    }
    Promise.allSettled([getTypes, getRates]).then(data => {
      const [type, rate] = handleResults(data);
      setTypes(type.data);
      setUserRates(rate.data);
      setLoading(false);
    });
  }, [userId]);

  const checkRowValue = useCallback(
    value => {
      if (value.dateFrom === 'Invalid Date') {
        value = { ...value, dateFrom: '' };
      }
      const uniqueValue = userRates.filter(
        item =>
          item.xlanceLiteralsId === value.xlanceLiteralsId &&
          dayjs(item.dateFrom).format('DD.MM.YYYY') === dayjs(value.dateFrom).format('DD.MM.YYYY'),
      );
      if (uniqueValue.length > 1) {
        setError(true);
        return false;
      }
      return value.dateFrom && value.rate;
    },
    [userRates],
  );

  const addObjectHandler = useCallback(() => {
    setUserRates([...userRates, { new: true, xlanceLiteralsId: '', rate: 0, dateFrom: dayjs(new Date()) }]);
  }, [userRates]);

  const selectHandler = useCallback(
    (event, index) => {
      const updatedObject = { ...userRates[index], xlanceLiteralsId: event.target.value };
      setUserRates([...userRates.slice(0, index), updatedObject, ...userRates.slice(index + 1)]);
    },
    [userRates],
  );
  const dateHandler = useCallback(
    (value, index) => {
      const updatedObject = {
        ...userRates[index],
        dateFrom: dayjs(new Date(value.$d)),
      };
      setUserRates([...userRates.slice(0, index), updatedObject, ...userRates.slice(index + 1)]);
    },
    [userRates],
  );
  const handleTextField = useCallback(
    (event, index) => {
      let formattedValue;
      const reg = new RegExp(/\D/g);
      if (reg.test(event.target.value)) {
        formattedValue = 0;
      } else {
        formattedValue = Math.round(event.target.value);
      }
      const updatedObject = { ...userRates[index], rate: formattedValue };
      setUserRates([...userRates.slice(0, index), updatedObject, ...userRates.slice(index + 1)]);
    },
    [userRates],
  );
  const toggleEdit = useCallback(
    index => {
      const updatedObject = { ...userRates[index], edit: true };
      setUserRates([...userRates.slice(0, index), updatedObject, ...userRates.slice(index + 1)]);
    },
    [userRates],
  );
  const saveEditHandler = useCallback(
    async index => {
      const checkedValue = checkRowValue(userRates[index]);
      if (checkedValue) {
        const data = {
          xlanceLiteralsId: userRates[index].xlanceLiteralsId === '' ? null : userRates[index].xlanceLiteralsId,
          rate: userRates[index].rate,
          dateFrom: userRates[index].dateFrom,
        };
        try {
          let response;
          let updatedObject = { ...userRates[index] };
          if (userRates[index].new) {
            response = await saveUserRates(userId, data);
            if (response) {
              updatedObject = { ...userRates[index], id: response.data.id };
              delete updatedObject.new;
            }
          }
          if (userRates[index].edit) {
            const id = userRates[index].id;
            response = await editUserRates(userId, id, data);
            response && delete updatedObject.edit;
          }
          if (response) {
            setError(false);
            setUserRates([...userRates.slice(0, index), updatedObject, ...userRates.slice(index + 1)]);
          }
        } catch (error) {
          console.error('ERROR WITH SAVE', error);
        }
      }
    },
    [checkRowValue, userId, userRates],
  );
  const deleteHandler = useCallback(
    async index => {
      if (userRates[index].new) {
        const newArray = userRates.filter((value, i) => i !== index);
        setUserRates(newArray);
        return;
      }
      try {
        const response = await deleteUserRate(userId, userRates[index].id);
        if (response) {
          const newArray = userRates.filter((value, i) => i !== index);
          setUserRates(newArray);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [userId, userRates],
  );
  const buttonsMap = new Map([
    ['edit', { handler: toggleEdit, icon: <UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['submit', { handler: saveEditHandler, icon: <SubmitIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['delete', { handler: deleteHandler, icon: <ReportDeleteIcon width="16" height="16" viewBox="0 0 16 16" /> }],
  ]);

  const RowButton = ({ children, action, rowIndex }) => {
    children = buttonsMap.get(action).icon;
    return (
      <IconButton onClick={() => buttonsMap.get(action).handler(rowIndex)} className={classes.rowButton}>
        {children}
      </IconButton>
    );
  };

  const RowComponent = ({ item, rowIndex, type }) => {
    let buttons = ['edit', 'delete'];
    item.new && (buttons = ['submit', 'delete']);
    item.edit && (buttons = ['submit', 'delete']);
    return (
      <Grid container className={classes.rowItem}>
        <Grid item sx={{ minWidth: '150px', p: item.new || item.edit ? 0 : '10px' }} className={classes.rowCell}>
          {item.new || item.edit ? (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel className={classes.selectLabel} id="select-label">
                {intl.formatMessage({ id: 'choose' })}
              </InputLabel>
              <Select
                native={false}
                labelId="select-label"
                label={intl.formatMessage({ id: 'choose' })}
                onChange={event => selectHandler(event, rowIndex)}
                variant="standard"
                sx={{ width: '100%', border: 'none' }}
                value={userRates[rowIndex].xlanceLiteralsId}
                className={classes.rowSelect}
                MenuProps={{ classes: { paper: classes.paperDropdown } }}
                IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                renderValue={value => {
                  return types.find(type => type.id === value).name;
                }}
              >
                {types
                  .filter(item => item.active)
                  .map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <Box>{item.xlanceLiteralsId ? types.find(type => type.id === item.xlanceLiteralsId).name : ''}</Box>
          )}
        </Grid>
        <Grid item sx={{ minWidth: '150px', textAlign: 'right' }} className={classes.rowCell}>
          {item.new || item.edit ? (
            <TextField
              autoFocus
              key={rowIndex}
              className={classes.rowInput}
              placeholder={intl.formatMessage({ id: 'enter' })}
              variant="standard"
              value={userRates[rowIndex].rate}
              onChange={event => handleTextField(event, rowIndex)}
            ></TextField>
          ) : (
            item.rate
          )}
        </Grid>
        <Grid item sx={{ flexShrink: 0, flexBasis: '226px' }} p={0} className={classes.rowCell}>
          <Grid container>
            <Grid item sx={{ padding: '10px', flex: 1 }}>
              {item.new || item.edit ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={classes.datePicker}
                    format={'DD.MM.YYYY'}
                    value={userRates[rowIndex].dateFrom}
                    renderInput={params => <TextField {...params} error={false} />}
                    autoFocus={true}
                    onChange={value => dateHandler(value, rowIndex)}
                    components={{
                      LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                      RightArrowIcon: () => (
                        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                      ),
                      RightArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      LeftArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                    }}
                  />
                </LocalizationProvider>
              ) : (
                dayjs(item.dateFrom).format('DD.MM.YYYY')
              )}
            </Grid>
            <Grid item>
              {buttons.map((action, index) => (
                <RowButton key={index} action={action} rowIndex={rowIndex}></RowButton>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {error && (
            <Typography padding={'4px 0'} lineHeight={'20px'} fontSize={16} fontWeight={600} color="#E03737">
              {intl.formatMessage({ id: 'user_page_rate_error' })}
            </Typography>
          )}
          <Grid container>
            <Grid item sx={{ maxWidth: '630px', flex: 1 }}>
              <Box className={commonClasses.blockWrapper} p={0}>
                <Grid container>
                  <Grid item sx={{ minWidth: '150px' }} className={classes.rowHeader}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'user_page_rates_calculation_type' })}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ minWidth: '150px' }} className={classes.rowHeader}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'user_page_rates_rate' })}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ flexShrink: 0, flexBasis: '226px' }} className={classes.rowHeader}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'user_page_rates_date' })}
                    </Typography>
                  </Grid>
                </Grid>
                {userRates.length > 0 &&
                  userRates.map((userRate, index) => (
                    <RowComponent item={userRate} key={index} rowIndex={index}></RowComponent>
                  ))}
                <Box p={'8px'} sx={{ borderTop: '1px solid #E4E4EF' }}>
                  <IconButton onClick={addObjectHandler} p={0}>
                    <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default injectIntl(UserPageRatesTab);
