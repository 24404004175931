import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  mobileCreateRoot: {
    position: 'relative',
    backgroundColor: '#F1F2F8',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  saveRow: {
    '&.MuiIconButton-root': {
      backgroundColor: '#6270D0',
    },
  },
  projectSelectMobile: {
    '&.MuiFormControl-root': {
      marginTop: theme.spacing(9),
    },
    '& .MuiInputLabel-root': {
      top: -12,
      left: -12,
      color: '#111322',
      '&[data-shrink="false"]': {
        top: -4,
        left: 1,
        color: '#7174AC',
      },
      '&.Mui-focused': {
        color: '#111322',
        left: -12,
        top: -12,
      },
    },
    '& .MuiInputBase-root': {
      height: 48,
      backgroundColor: '#ffffff',
      padding: theme.spacing(3, 2, 3, 4),
      borderRadius: theme.spacing(4),
      marginTop: 0,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-root': {
      lineHeight: 'unset',
    },
  },
}));

export default useStyles;
