import React from 'react';
import { SvgIcon } from '@mui/material';

const TrashIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2604_22701)">
        <path
          d="M4.00004 12.6667C4.00004 13.4 4.60004 14 5.33337 14H10.6667C11.4 14 12 13.4 12 12.6667V4.66667H4.00004V12.6667ZM12.6667 2.66667H10.3334L9.66671 2H6.33337L5.66671 2.66667H3.33337V4H12.6667V2.66667Z"
          fill="#DCDFF4"
        />
      </g>
      <defs>
        <clipPath id="clip0_2604_22701">
          <rect width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TrashIcon;
