import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  HeaderIconsRoot: {
    marginRight: theme.spacing(8),
    [theme.breakpoints.maxWidth('sm')]: {
      marginRight: 0,
    },
  },
  icon: {
    width: 32,
    height: 32,
    padding: theme.spacing(1),
    color: theme.palette.common.lightGrey,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  notificationIcon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.maxWidth('sm')]: {
      marginRight: 0,
    },
  },
  faqIcon: {},
}));

export default useStyles;
