import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  exclusionMenu: {
    width: '276px !important',
    height: 246,
    padding: theme.spacing(5),
    borderRadius: theme.spacing(4),
    backgroundColor: '#F1F2F8',
  },
  popper: {
    zIndex: '1999 !important',
  },
  popperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    transform: 'unset',
    backgroundColor: 'rgba(31, 33, 72, 0.15)',
    zIndex: '1999 !important',
  },
  newExceptionField: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input': {
      ...theme.typography.h5,
    },
  },
  newExceptionDatePicker: {
    borderRadius: theme.spacing(4),
    backgroundColor: '#ffffff',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
    '& .MuiInputAdornment-root': {
      marginLeft: 0,
    },
    '& input': {
      padding: '0 0 0 10px !important',
      ...theme.typography.h5,
    },
  },
  paperRoot: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
}));

export default useStyles;
