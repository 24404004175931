import React from 'react';
import { SvgIcon } from '@mui/material';

const UserNewDeleteIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.00065 0.333344C3.31398 0.333344 0.333984 3.31334 0.333984 7.00001C0.333984 10.6867 3.31398 13.6667 7.00065 13.6667C10.6873 13.6667 13.6673 10.6867 13.6673 7.00001C13.6673 3.31334 10.6873 0.333344 7.00065 0.333344ZM10.334 9.39334L9.39398 10.3333L7.00065 7.94001L4.60732 10.3333L3.66732 9.39334L6.06065 7.00001L3.66732 4.60668L4.60732 3.66668L7.00065 6.06001L9.39398 3.66668L10.334 4.60668L7.94065 7.00001L10.334 9.39334Z"
        fill="#DCDFF4"
      />
    </SvgIcon>
  );
};

export default UserNewDeleteIcon;
