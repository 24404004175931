import React from 'react';
import { SvgIcon } from '@mui/material';

const ProjectsViewTypeListIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="8" y="8" width="16" height="4" fill="#DCDFF4" />
        <rect x="8" y="14" width="16" height="4" fill="#DCDFF4" />
        <rect x="8" y="20" width="16" height="4" fill="#DCDFF4" />
      </svg>
    </SvgIcon>
  );
};

export default ProjectsViewTypeListIcon;
