import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import useStyles from './CalendarYearSwitcher.styles';

const CalendarYearSwitcher = ({ year, handleToggleYear }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.yearSwitcher}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flex={1}
      marginBottom={4}
    >
      <IconButton disableRipple className={classes.yearSwitcherButton} direction="left" onClick={handleToggleYear}>
        <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />
      </IconButton>
      <Typography variant="h4">{year}</Typography>
      <IconButton disableRipple className={classes.yearSwitcherButton} direction="right" onClick={handleToggleYear}>
        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
      </IconButton>
    </Box>
  );
};

export default CalendarYearSwitcher;
