import React from 'react';
import { SvgIcon } from '@mui/material';

const ErrorLogo = props => {
  return (
    <SvgIcon {...props}>
      <svg width="240" height="110" viewBox="0 0 240 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 75.4056L16.4558 54.4706L0.365685 33.9472H13.3475L22.7944 46.6493L32.2413 33.9472H45.284L29.1939 54.4706L45.5888 75.4056H32.546L22.7944 62.3506L13.0428 75.4056H0Z"
          fill="#212346"
        />
        <path d="M48.2171 59.1089V50.9348H67.7204V59.1089H48.2171Z" fill="#212346" />
        <path d="M75.4188 75.4056V33.9472H86.6332V66.4082H105.771V75.4056H75.4188Z" fill="#212346" />
        <path
          d="M120.761 76.0525C118.445 76.0525 116.394 75.6408 114.606 74.8175C112.859 73.9942 111.477 72.8769 110.461 71.4656C109.486 70.015 108.999 68.3684 108.999 66.5258C108.999 64.448 109.567 62.8014 110.705 61.5861C111.843 60.3316 113.671 59.4495 116.19 58.9398C118.71 58.391 122.062 58.1166 126.247 58.1166H128.563V57.2933C128.563 55.8819 128.177 54.8822 127.405 54.2941C126.673 53.7061 125.393 53.412 123.565 53.412C122.021 53.412 120.314 53.6473 118.445 54.1177C116.576 54.5882 114.748 55.3134 112.96 56.2936L110.217 49.4132C111.233 48.7075 112.533 48.0803 114.118 47.5314C115.703 46.9826 117.369 46.5709 119.116 46.2965C120.863 45.9829 122.509 45.826 124.053 45.826C129.05 45.826 132.748 46.865 135.145 48.9428C137.583 51.0206 138.802 54.2941 138.802 58.7634V75.4056H128.685V71.2892C128.156 72.7789 127.202 73.955 125.82 74.8175C124.439 75.6408 122.752 76.0525 120.761 76.0525ZM123.199 69.2309C124.703 69.2309 125.962 68.7605 126.978 67.8196C128.034 66.8787 128.563 65.6437 128.563 64.1148V63.0563H126.247C123.849 63.0563 122.082 63.3111 120.944 63.8208C119.847 64.2912 119.299 65.0557 119.299 66.1142C119.299 67.0159 119.624 67.7608 120.274 68.3488C120.965 68.9369 121.94 69.2309 123.199 69.2309Z"
          fill="#212346"
        />
        <path
          d="M144.979 75.4056V46.4729H155.523V50.4129C156.58 48.9232 157.941 47.7862 159.607 47.0022C161.273 46.2181 163.142 45.826 165.214 45.826C168.79 45.826 171.431 46.8453 173.137 48.884C174.884 50.9226 175.758 54.0589 175.758 58.293V75.4056H164.909V58.7046C164.909 56.9404 164.584 55.6859 163.934 54.941C163.325 54.1961 162.41 53.8237 161.192 53.8237C159.566 53.8237 158.266 54.3333 157.291 55.3527C156.316 56.3328 155.828 57.6657 155.828 59.3515V75.4056H144.979Z"
          fill="#212346"
        />
        <path
          d="M197.239 76.0525C193.866 76.0525 190.92 75.4448 188.401 74.2295C185.923 73.0141 183.993 71.2695 182.611 68.9957C181.23 66.7219 180.539 64.0168 180.539 60.8804C180.539 57.7441 181.23 55.0586 182.611 52.824C183.993 50.5501 185.923 48.8252 188.401 47.649C190.92 46.4337 193.866 45.826 197.239 45.826C199.23 45.826 201.2 46.1005 203.151 46.6493C205.142 47.159 206.767 47.8843 208.026 48.8252L205.162 55.9995C204.227 55.2938 203.13 54.745 201.871 54.3529C200.652 53.9217 199.494 53.7061 198.397 53.7061C196.243 53.7061 194.577 54.3333 193.399 55.5879C192.221 56.8424 191.632 58.6066 191.632 60.8804C191.632 63.1935 192.221 64.9969 193.399 66.2906C194.577 67.5452 196.243 68.1724 198.397 68.1724C199.494 68.1724 200.652 67.9764 201.871 67.5844C203.13 67.1531 204.227 66.5847 205.162 65.879L208.026 73.0533C206.767 73.9942 205.142 74.7391 203.151 75.288C201.16 75.7976 199.189 76.0525 197.239 76.0525Z"
          fill="#212346"
        />
        <path
          d="M227.201 76.0525C223.544 76.0525 220.375 75.4448 217.693 74.2295C215.052 72.9749 213.021 71.2107 211.599 68.9369C210.176 66.6631 209.465 63.9776 209.465 60.8804C209.465 57.9401 210.136 55.3331 211.477 53.0592C212.858 50.7854 214.727 49.0212 217.084 47.7666C219.481 46.4729 222.203 45.826 225.251 45.826C228.258 45.826 230.858 46.4337 233.052 47.649C235.287 48.8644 236.993 50.6089 238.172 52.8828C239.391 55.1174 240 57.7441 240 60.7628V63.0563H219.826C220.192 64.9381 221.005 66.2906 222.264 67.1139C223.524 67.9372 225.271 68.3488 227.506 68.3488C229.05 68.3488 230.655 68.1332 232.321 67.702C234.027 67.2315 235.531 66.565 236.831 65.7026L239.512 72.5829C237.968 73.6414 236.079 74.4843 233.844 75.1116C231.61 75.7388 229.395 76.0525 227.201 76.0525ZM225.738 52.5888C224.113 52.5888 222.772 53.0592 221.716 54.0001C220.7 54.941 220.05 56.3132 219.766 58.1166H231.102C230.98 56.274 230.452 54.9018 229.517 54.0001C228.623 53.0592 227.364 52.5888 225.738 52.5888Z"
          fill="#212346"
        />
        <path d="M0 109.736V92.8936H240L0 109.736Z" fill="#6270D0" />
      </svg>
    </SvgIcon>
  );
};

export default ErrorLogo;
