import React, { useEffect, useState, useRef } from 'react';
import { useWebSockets } from './useWebsockets';
import { getCookie } from 'Common/utils/cookies';
import {
  setResplanTableColumns,
  setResplanTableRows,
  setStopLoadingRespanTableBySocket,
  setResplanRowsToUpdate,
  clearResplanTableRows,
  addResplanNewIssue,
  setHasResplanRows,
  setHasResplanUnsavedCash,
} from '../app/redux/reducers/userSlice';
import { useDispatch } from 'react-redux';
let resolveCheckIssuesUpdate;
let resolveSaveResPlan;

const useResPlanWebsockets = (query, refreshKey, setShowUndefinedError) => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [resplanFields, setResplanFields] = useState({ fields: [], rows: [] });
  const [resplanColumns, setResplanColumns] = useState([]);
  const socket = useWebSockets();
  const dispatch = useDispatch();
  const accessToken = getCookie('access_token');
  const timeoutRef = useRef(null);

  const updateResPlanByMount = (groupId, rowId, mountValue) => {
    if (socket) {
      const data = {
        token: accessToken,
        groupId: groupId,
        rowId: rowId,
        mountValue: mountValue,
      };
      console.log('Data prepared for group update:', data);
      socket.emit('updateResPlanByMount', data);
    }
  };
  const updateResPlanByPercent = (groupId, rowId, percentValue) => {
    if (socket) {
      const data = {
        token: accessToken,
        groupId: groupId,
        rowId: rowId,
        percentValue: percentValue,
      };
      console.log('Data prepared for percent update:', data);
      socket.emit('updateResPlanByPercent', data);
    }
  };
  const addOrUpdateResPlanNewIssue = (groupId, rowId, projectId, userId, name, trackerId, contractId) => {
    if (socket) {
      const data = {
        token: accessToken,
        groupId: groupId,
        rowId: rowId,
        newIssue: {
          projectId: projectId,
          userId: userId,
          name: name,
          trackerId: trackerId,
          contractId: contractId,
        },
      };
      console.log('Data prepared for add or update new issue:', data);
      socket.emit('addOrUpdateResPlanNewIssue', data);
    }
  };
  const deleteResPlanNewIssue = (rowId, groupId) => {
    if (socket) {
      const data = {
        token: accessToken,
        groupId: groupId,
        rowId: rowId,
      };
      console.log('Data prepared for delete new issuee:', data);
      socket.emit('deleteResPlanNewIssue', data);
    }
  };
  const saveResPlan = saveType => {
    if (socket) {
      const data = {
        token: accessToken,
        saveType: saveType,
      };
      console.log('Saving resplan data:', data);
      socket.emit('saveResPlan', data);
    }
  };
  const checkIssuesUpdate = () => {
    if (socket) {
      const data = {
        token: accessToken,
      };
      console.log('Checking started');
      socket.emit('checkIssuesUpdate', data);
    }
  };
  const waitForCheckSaveResPlanError = () => {
    return new Promise(resolve => {
      resolveCheckIssuesUpdate = resolve;
    });
  };
  const waitForSaveResPlan = () => {
    return new Promise(resolve => {
      resolveSaveResPlan = resolve;
    });
  };

  const getResPlanTableData = () => {
    if (socket && query) {
      socket.emit('getResPlanTable', { token: accessToken, query: { filters: query, checkedCash: true } });
      dispatch(setHasResplanRows(true));
      timeoutRef.current = setTimeout(() => {
        setIsTableLoading(false);
        dispatch(setHasResplanRows(false));
      }, 3500);
    }
  };

  useEffect(() => {
    const resetData = () => {
      dispatch(setResplanTableRows({ fields: [], rows: [] }));
      dispatch(clearResplanTableRows());
    };

    getResPlanTableData();

    if (socket) {
      socket.on('connect', () => {
        console.log('Connected');
      });

      socket.on('authorization_error', data => {
        console.log('authorization_error', data);
        setIsTableLoading(false);
      });
      socket.on('checkSaveResPlanError', data => {
        console.log('checkSaveResPlanError', data);
        const testError = [
          {
            issueId: 8553,
            issueName: 'Work on project',
            updatedValues: {
              estimatedHours: {
                new: 0,
                old: 300,
              },
              startDate: {
                new: '2022-03-30T16:00:00.000Z',
                old: null,
              },
              endDate: {
                new: '2023-12-30T16:00:00.000Z',
                old: null,
              },
              assignedUser: {
                new: '541',
                old: '116',
              },
            },
          },
          {
            issueId: 8553,
            issueName: 'Work on project',
            updatedValues: {
              estimatedHours: {
                new: 0,
                old: 300,
              },
              startDate: {
                new: '2022-03-30T16:00:00.000Z',
                old: null,
              },
              endDate: {
                new: '2023-12-30T16:00:00.000Z',
                old: null,
              },
              assignedUser: null,
            },
          },
        ];

        if (resolveCheckIssuesUpdate) {
          resolveCheckIssuesUpdate(data);
        }
        setIsTableLoading(false);
      });
      socket.on('resPlanColumns', data => {
        dispatch(setResplanTableColumns(data));
        console.log('socketsColumns', data);
      });
      socket.on('error', data => {
        console.log('error:', data);
        if (data.error === 'Something went wrong. Please try again and contact administrator to get more details') {
          setShowUndefinedError(true);
        } else {
          console.log('error:', data);
        }
      });

      socket.on('resPlanGroup', data => {
        clearTimeout(timeoutRef.current);
        dispatch(setStopLoadingRespanTableBySocket(false));
        dispatch(
          setResplanTableRows({
            fields: data.fields,
            rows: data.rows,
          }),
        );
        console.log('socketsRows', data);
        setIsTableLoading(false);
      });
      socket.on('updateResPlanGroup', data => {
        console.log('Updated group:', data.rows);
        dispatch(setResplanRowsToUpdate(data.rows));
      });
      socket.on('addNewResPlanIssue', data => {
        console.log('Added issue:', data);
        dispatch(addResplanNewIssue(data.rows));
      });

      socket.on('startGetResPlanTable', () => {
        setIsTableLoading(true);
        resetData();
      });

      socket.on('endGetResPlanTable', () => {
        setIsTableLoading(false);
        dispatch(setStopLoadingRespanTableBySocket(true));
        console.log('DONE!');
      });

      socket.on('startSaveResPlanTable', () => {
        console.log('startSaveResplan');
      });
      socket.on('cashNotFound', () => {
        console.log('cashNotFound');
      });
      socket.on('endSaveResPlanTable', () => {
        console.log('endSaveResplan');
        if (resolveSaveResPlan) {
          resolveSaveResPlan(false);
        }
      });
      socket.on('endAddNewResPlanIssue', () => {
        console.log('endAddNewResPlanIssue');
      });
      socket.on('startAddNewResPlanIssue', () => {
        console.log('startAddNewResPlanIssue');
      });
      socket.on('startDeleteNewResPlanIssue', () => {
        console.log('startDeleteNewResPlanIssue');
      });
      socket.on('endDeleteNewResPlanIssue', () => {
        console.log('endDeleteNewResPlanIssue');
      });
      socket.on('resPlanNotSaved', () => {
        console.log('resPlanNotSaved');
        dispatch(setHasResplanUnsavedCash(true));
      });
      socket.on('deleteNewResPlanIssue', data => {
        console.log('deleteNewResPlanIssue', data);
        dispatch(addResplanNewIssue(data.rows));
      });
      socket.on('rowNotFound', () => {
        console.log('rowNotFound');
      });
      socket.on('dateStartGreaterEndDate', () => {
        console.log('dateStartGreaterEndDate');
      });
      socket.on('noEditingRights', () => {
        console.log('noEditingRights');
      });
      socket.on('startUpdateResPlanTable', () => {
        console.log('startUpdateResPlanTable');
      });
      socket.on('endUpdateResPlanTable', () => {
        console.log('endUpdateResPlanTable');
      });
    }

    return () => {
      if (socket) {
        socket.off('connect');
        socket.off('authorization_error');
        socket.off('resPlanColumns');
        socket.off('resPlanGroup');
        socket.off('startGetResPlanTable');
        socket.off('endGetResPlanTable');
        socket.off('updateResPlanGroup');
        socket.off('error');
        socket.off('checkSaveResPlanError');
        socket.off('startSaveResPlanTable');
        socket.off('endSaveResPlanTable');
        socket.off('addNewResPlanIssue');
        socket.off('deleteNewResPlanIssue');
        socket.off('cashNotFound');
        socket.off('endAddNewResPlanIssue');
        socket.off('startAddNewResPlanIssue');
        socket.off('startDeleteNewResPlanIssue');
        socket.off('endDeleteNewResPlanIssue');
        socket.off('rowNotFound');
        socket.off('dateStartGreaterEndDate');
        socket.off('noEditingRights');
        socket.off('startUpdateResPlanTable');
        socket.off('endUpdateResPlanTable');
        socket.off('resPlanNotSaved');
      }

      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, query, socket, refreshKey]);

  return {
    isTableLoading,
    resplanFields,
    resplanColumns,
    setResplanFields,
    updateResPlanByMount,
    updateResPlanByPercent,
    saveResPlan,
    checkIssuesUpdate,
    waitForCheckSaveResPlanError,
    waitForSaveResPlan,
    getResPlanTableData,
    addOrUpdateResPlanNewIssue,
    deleteResPlanNewIssue,
  };
};

export default useResPlanWebsockets;
