import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  fieldsList: {
    '&.MuiPaper-root': {
      maxHeight: 324,
      minHeight: 324,
      // overflow: 'auto',
      boxShadow: 'none',
      borderRadius: theme.spacing(0, 0, 4, 4),
      '& .MuiAutocomplete-option': {
        padding: 0,
      },
      '& .MuiAutocomplete-listbox': {
        padding: 0,
        height: 324,
        maxHeight: 'unset',
      },
    },
    '&.MuiPaper-root::-webkit-scrollbar': {
      display: 'none',
    },
  },
  optionsFilterInput: {
    '&.MuiTextField-root': {
      '& .MuiInputBase-root': {
        padding: 0,
        borderRadius: theme.spacing(4, 4, 0, 0),
        backgroundColor: '#ffffff !important',
        borderBottom: '1px solid #E4E4EF',
        '& input': {
          padding: theme.spacing(2, 2),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&:not(.Mui-error):hover': {
          borderBottom: '1px solid #E4E4EF',
        },
      },
    },
  },
  fieldsListItem: {
    height: 36,
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#F1F2F8',
    },
    '& .MuiAutocomplete-option': {
      '&.Mui-focused': {
        backgroundColor: 'red',
      },
    },
  },
  fieldsListDisabledItem: {
    height: 36,
    padding: theme.spacing(2, 2),
    cursor: 'pointer',
    userSelect: 'none',
    color: '#7174AC',
    // '&:hover': {
    //   backgroundColor: '#F1F2F8',
    // },
    '& .MuiAutocomplete-option': {
      '&.Mui-focused': {
        backgroundColor: 'red',
      },
    },
  },
}));

export default useStyles;
