import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  SettingsBlock: {
    maxWidth: '50%',
  },
  SettingsFieldsHead: {
    width: '33%',
  },
  SettingsFields: {
    width: '50%',
  },
}));

export default useStyles;
