import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  settingsWrap: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 10px -8px #212346',
  },
  settingsLowerContainer: {
    width: '100%',
    background: '#FFFFFF',
    borderRadius: theme.spacing(2),
    boxShadow: '0px 5px 10px -8px #212346',
  },
  settingsMiddleContainer: {
    width: '100%',
  },
}));

export default useStyles;
