import React from 'react';
import { Grid, Typography, TableCell } from '@mui/material';
import useStyles from './SettingsTableCells.styles';

const SettingsTableCells = ({ content, weight, ...props }) => {
  const classes = useStyles();

  return (
    <TableCell {...props}>
      <Grid container wrap="nowrap">
        <Typography variant="h4" fontWeight={weight} whiteSpace="wrap">
          {content}
        </Typography>
      </Grid>
    </TableCell>
  );
};

export default SettingsTableCells;
