import { injectIntl } from 'react-intl';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro';
import SortIcon from '../../../../../packages/common/shared-ui/src/icons/SortIcon';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { getTranslatedText } from '../../../../../packages/common/utils/getTranslatedText';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import {
  deleteConfirmationSettingsRow,
  getConfirmationSettings,
  getConfirmationSettingsRow,
  saveConfirmationSettings,
  saveEditConfirmationSettings,
} from '../../../../../packages/common/api';
import issuePageStyles from '../../../issuePage/IssuePage.styles';
import clsx from 'clsx';
import confirmationSettingsTable from './ConfirmationSettingsTable.styles';
import { Link } from 'react-router-dom';

const ConfirmationSettingsTable = ({ intl }) => {
  const issueCommonStyles = issuePageStyles();
  const tableStyles = confirmationSettingsTable();
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmationSettingsColumns, setConfirmationSettingsColumns] = useState([]);
  const [confirmationSettingsRows, setConfirmationSettingsRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    let ignore = false;
    async function getSettings() {
      const { data: settings } = await getConfirmationSettings();
      if (!ignore) {
        return settings;
      }
    }
    getSettings()
      .then(res => {
        const confirmationSettingsRowsValue = formattedConfirmationSettingsRows(
          res.rows.filter(item => item.rowId !== 'total'),
        );
        setConfirmationSettingsColumns(res.fields);
        setConfirmationSettingsRows(confirmationSettingsRowsValue);
        setLoading(false);
      })
      .catch(error => console.error(error, 'ERROR IN GET'));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = useCallback(
    async id => {
      const index = confirmationSettingsRows.findIndex(item => item.id === id);
      try {
        const { data: response } = await getConfirmationSettingsRow(id);
        if (response) {
          const updatedObject = {
            ...response,
            id: id,
            ['confirmation_trackers.tracker']: response.trackerId,
            ['confirmation_trackers.url']: response.url,
            ['confirmation_trackers.company_group']: response.companyGroupId,
            ['confirmation_trackers.group_one']: response.groupOneId,
            ['confirmation_trackers.group_two']: response.groupTwoId,
            ['confirmation_trackers.group_tree']: response.groupThreeId,
          };
          setConfirmationSettingsRows([
            ...confirmationSettingsRows.slice(0, index),
            updatedObject,
            ...confirmationSettingsRows.slice(index + 1),
          ]);
          setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {
              mode: GridRowModes.Edit,
            },
          }));
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [confirmationSettingsRows],
  );
  const validateRow = useCallback(
    (id, link = false) => {
      let isError;
      let updatedObject;
      const index = confirmationSettingsRows.findIndex(item => item.id === id);
      if (link && confirmationSettingsRows[index].url) {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        );
        isError = !urlPattern.test(confirmationSettingsRows[index].url);
        isError && setErrors([...errors, { type: 'link', message: 'confirmation_trackers_url_error' }]);
      }
      if (!link && confirmationSettingsRows[index].trackerId && confirmationSettingsRows[index].companyGroupId) {
        const uniqueRow = confirmationSettingsRows.filter(
          item =>
            item['confirmation_trackers.trackerId'] === confirmationSettingsRows[index].trackerId &&
            item['confirmation_trackers.company_groupId'] === confirmationSettingsRows[index].companyGroupId,
        );
        isError = uniqueRow.length > 0;
        isError && setErrors([...errors, { type: 'unique', message: 'confirmation_trackers_save_error' }]);
      }
      if (isError) {
        updatedObject = {
          ...confirmationSettingsRows[index],
          error: true,
        };
        setConfirmationSettingsRows([
          ...confirmationSettingsRows.slice(0, index),
          updatedObject,
          ...confirmationSettingsRows.slice(index + 1),
        ]);
        return false;
      } else {
        updatedObject = { ...confirmationSettingsRows[index] };
        delete updatedObject.error;
        setConfirmationSettingsRows([
          ...confirmationSettingsRows.slice(0, index),
          updatedObject,
          ...confirmationSettingsRows.slice(index + 1),
        ]);
        link && setErrors(errors.filter(error => error.type !== 'link'));
        !link && setErrors(errors.filter(error => error.type !== 'unique'));
        return true;
      }
    },
    [confirmationSettingsRows, errors],
  );
  const handleTextField = useCallback(
    (event, id) => {
      const index = confirmationSettingsRows.findIndex(item => item.id === id);

      const updatedObject = { ...confirmationSettingsRows[index], url: event.target.value };
      setConfirmationSettingsRows([
        ...confirmationSettingsRows.slice(0, index),
        updatedObject,
        ...confirmationSettingsRows.slice(index + 1),
      ]);
    },
    [confirmationSettingsRows],
  );
  const handleSelect = useCallback(
    (event, id, field) => {
      const fieldName = field.substring(field.indexOf('.') + 1);
      const makeCamelCase = str =>
        str
          .split('_')
          .map((e, i) => (i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()))
          .join('');
      const index = confirmationSettingsRows.findIndex(item => item.id === id);
      const updatedObject = {
        ...confirmationSettingsRows[index],
        [makeCamelCase(fieldName) + 'Id']: event.target.value,
      };
      setConfirmationSettingsRows([
        ...confirmationSettingsRows.slice(0, index),
        updatedObject,
        ...confirmationSettingsRows.slice(index + 1),
      ]);
    },
    [confirmationSettingsRows],
  );

  const handleSaveClick = useCallback(
    async (id, row) => {
      const index = confirmationSettingsRows.findIndex(item => item.id === id);
      const validation = validateRow(row.id);
      if (!validation) {
        return;
      }
      try {
        let response;
        const data = {
          trackerId: row.trackerId ? Number(row.trackerId) : '',
          url: row.url ? row.url : '',
          companyGroupId: row.companyGroupId ? Number(row.companyGroupId) : '',
          groupOneId: row.groupOneId ? Number(row.groupOneId) : '',
          groupTwoId: row.groupTwoId ? Number(row.groupTwoId) : null,
          groupThreeId: row.groupThreeId ? Number(row.groupThreeId) : null,
        };
        if (rowModesModel[id].mode === 'edit' && !row.new) {
          response = await saveEditConfirmationSettings(id, data);
        }
        if (row.new) {
          response = await saveConfirmationSettings(data);
        }
        if (response) {
          const { data: res } = response;
          const newCols = res.columns.reduce((columnAcc, item, i) => {
            return {
              ...columnAcc,
              [item.fieldId]: item.value,
            };
          }, {});
          const updatedObject = {
            id: res.rowId,
            ...newCols,
          };
          setConfirmationSettingsRows([
            ...confirmationSettingsRows.slice(0, index),
            updatedObject,
            ...confirmationSettingsRows.slice(index + 1),
          ]);
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [confirmationSettingsRows, rowModesModel, validateRow],
  );
  const handleDelete = useCallback(
    async (id, row) => {
      setActionLoading(true);
      if (row.new) {
        const newArray = confirmationSettingsRows.filter(item => item.id !== row.id);
        setConfirmationSettingsRows(newArray);
        return;
      }
      try {
        const response = await deleteConfirmationSettingsRow(id);
        if (response) {
          setConfirmationSettingsRows(confirmationSettingsRows.filter(row => row.id !== id));
          setActionLoading(false);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [confirmationSettingsRows],
  );
  const formattedConfirmationSettingsColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    const editableColumns = ['stage', 'start_date', 'comment'];
    const columns = confirmationSettingsColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, '', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 150,
        cellClassName: props =>
          props.field === 'users.admin'
            ? 'tableCell textCenter'
            : props.field === 'contract_stage.stage'
            ? 'tableCell select'
            : 'tableCell',
        editable: true,
        renderCell: props => {
          if (props.field === 'confirmation_trackers.url') {
            return (
              <Box component={'span'} color={'#3448FF'}>
                {props.value}
              </Box>
            );
          } else {
            {
              return props.value;
            }
          }
        },
        renderEditCell: props => {
          if (props.field === 'confirmation_trackers.url') {
            return (
              <TextField
                error={props.row.error && props.row.error.type === 'link'}
                onBlur={() => validateRow(props.row.id, true)}
                autoFocus
                className={issueCommonStyles.rowInput}
                placeholder={intl.formatMessage({ id: 'enter' })}
                variant="standard"
                value={props.row.url ? props.row.url : ''}
                onChange={event => handleTextField(event, props.row.id)}
              ></TextField>
            );
          } else {
            const fieldName = props.field.substring(props.field.indexOf('.') + 1);
            const makeCamelCase = str =>
              str
                .split('_')
                .map((e, i) => (i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()))
                .join('');
            return (
              <FormControl sx={{ width: '100%' }}>
                <InputLabel className={issueCommonStyles.selectLabel} id="select-label">
                  {intl.formatMessage({ id: 'choose' })}
                </InputLabel>
                <Select
                  native={false}
                  labelId="select-label"
                  label={intl.formatMessage({ id: 'choose' })}
                  variant="standard"
                  sx={{ width: '100%', border: 'none' }}
                  className={issueCommonStyles.rowSelect}
                  MenuProps={{ classes: { paper: issueCommonStyles.paperDropdown } }}
                  IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                  value={props.row[makeCamelCase(fieldName) + 'Id'] ? props.row[makeCamelCase(fieldName) + 'Id'] : ''}
                  renderValue={value => {
                    return props.row[makeCamelCase(fieldName)].find(stage => stage.valueId === value.toString())
                      .valueName;
                  }}
                  onChange={event => handleSelect(event, props.row.id, props.field)}
                >
                  {props.row[makeCamelCase(fieldName)]?.map((item, index) => (
                    <MenuItem key={index} value={item.valueId}>
                      {item.valueName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        },
      };
    });
    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        headerClassName: 'actions',
        width: 70,
        cellClassName: 'actions tableCell',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem onClick={() => handleSaveClick(id, row)} icon={<SubmitIcon />} label="Save" />,
              <GridActionsCellItem onClick={() => handleDelete(id, row)} icon={<DeleteIconBig />} label="Cancel" />,
            ];
          }
          return [
            <GridActionsCellItem
              onClick={() => handleEditClick(id)}
              icon={<UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />}
              label="Edit"
            />,
            <GridActionsCellItem
              onClick={() => handleDelete(id, row)}
              icon={<DeleteIconBig />}
              label="Delete"
              color="inherit"
            />,
          ];
        },
      },
    ];
  }, [
    confirmationSettingsColumns,
    handleDelete,
    handleEditClick,
    handleSaveClick,
    handleSelect,
    handleTextField,
    intl,
    issueCommonStyles.paperDropdown,
    issueCommonStyles.rowInput,
    issueCommonStyles.rowSelect,
    issueCommonStyles.selectLabel,
    rowModesModel,
    validateRow,
  ]);

  const confirmationSettingsColumnsValue = formattedConfirmationSettingsColumns();

  const formattedConfirmationSettingsRows = useCallback(
    data =>
      data.reduce((acc, row) => {
        const newCols = row.columns.reduce((columnAcc, _, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
            [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
          };
        }, {});
        return [...acc, { ...newCols, id: row.rowId }];
      }, []),
    [],
  );
  const addRowHandler = async () => {
    try {
      const { data: response } = await getConfirmationSettingsRow(0);
      if (response) {
        setConfirmationSettingsRows(oldRows => [
          ...oldRows,
          {
            new: true,
            id: `confirmationSettings-row${confirmationSettingsRows.length + 1}`,
            ...response,
            ['confirmation_trackers.tracker']: response.trackerId,
            ['confirmation_trackers.url']: response.url,
            ['confirmation_trackers.company_group']: response.companyGroupId,
            ['confirmation_trackers.group_one']: response.groupOneId,
            ['confirmation_trackers.group_two']: response.groupTwoId,
            ['confirmation_trackers.group_tree']: response.groupThreeId,
          },
        ]);
        setRowModesModel(oldModel => ({
          ...oldModel,
          [`confirmationSettings-row${confirmationSettingsRows.length + 1}`]: {
            mode: GridRowModes.Edit,
          },
        }));
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  };

  const handleRowModesModelChange = useCallback(newRowModesModel => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setConfirmationSettingsRows(confirmationSettingsRows.map(row => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [confirmationSettingsRows],
  );
  const handleCellDoubleClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid container direction={'column'} marginTop={'20px'} sx={{ position: 'relative' }}>
            {actionLoading && (
              <Grid
                container
                position={'absolute'}
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={1}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            {errors.length > 0 && (
              <Box sx={{ position: errors.length > 1 ? 'static' : 'absolute', top: '-20px', left: 0 }}>
                {errors.map((error, index) => (
                  <Typography key={index} variant="h3" color={'#E03737'}>
                    {intl.formatMessage({ id: error.message })}
                  </Typography>
                ))}
              </Box>
            )}
            <Grid item>
              <DataGridPro
                onRowDoubleClick={(params, event) => handleCellDoubleClick(event)}
                onCellDoubleClick={(params, event) => handleCellDoubleClick(event)}
                autoHeight
                hideFooter
                disableRowSelectionOnClick
                disableColumnMenu
                columns={confirmationSettingsColumnsValue}
                rows={confirmationSettingsRows}
                getRowClassName={params => !params.row.active && 'disabled'}
                className={clsx(issueCommonStyles.SpentTimeTableRoot, tableStyles.ConfirmationSettingsTable)}
                pageSize={10}
                slots={{
                  columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                  columnSortedAscendingIcon: () => (
                    <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                  columnSortedDescendingIcon: () => (
                    <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                }}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
              />
            </Grid>
            <Grid
              item
              padding={'8px 16px'}
              sx={{
                lineHeight: 'normal',
                borderRadius: '0 0 16px 16px',
                borderTop: '1px solid #E4E4EF',
                backgroundColor: '#fff',
              }}
            >
              <IconButton onClick={addRowHandler} sx={{ padding: '0' }}>
                <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default injectIntl(ConfirmationSettingsTable);
