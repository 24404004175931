import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import useStyles from './SpreadsheetMobileCreate.styles';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { FormattedMessage } from 'react-intl';
import SpreadsheetMobileCreateRow from '../SpreadsheetMobileCreateRow';

const SpreadsheetMobileCreate = ({
  anotherProjects,
  formattedAnotherProjects,
  pageTitle,
  query,
  setQuery,
  formatWeek,
  getAnotherProjectsData,
  intl,
  setEditedFormattedIssues,
  handleSaveSpreadsheet,
  timeType,
  timeTypes,
}) => {
  const classes = useStyles();
  const [newIssue, setNewIssue] = useState({ issueUniqueId: 0 });
  const [currentWeek, setCurrentWeek] = useState(formatWeek({ startOfWeek: query.start, intl }));
  const [weekSum, setWeekSum] = useState(0);

  useEffect(() => {
    if (anotherProjects.length === 0) {
      getAnotherProjectsData({ start: query.start, end: query.end });
    }
  }, [anotherProjects.length, getAnotherProjectsData, query, setQuery]);

  const handleChangeProject = useCallback(
    e => {
      setNewIssue({ projectId: e.target.value, issueUniqueId: 0 });
      setCurrentWeek(formatWeek({ startOfWeek: query.start, intl }));
    },
    [formatWeek, intl, query.start],
  );

  const handleChangeProjectTask = useCallback(
    e => {
      return setNewIssue(prevState => ({ ...prevState, ...formattedAnotherProjects[e.target.value] }));
    },
    [formattedAnotherProjects],
  );

  const handleSaveMobileRow = useCallback(async () => {
    const saveResult = await handleSaveSpreadsheet();
    setQuery({ ...query, create: null });
  }, [handleSaveSpreadsheet, query, setQuery]);

  return (
    <Grid container direction="column" className={classes.mobileCreateRoot}>
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">{pageTitle}</Typography>
        <IconButton disableRipple className={classes.saveRow} onClick={handleSaveMobileRow}>
          <DoneIcon width={24} height={24} viewBox="0 0 24 24" />
        </IconButton>
      </Grid>
      <Grid container direction="column" marginTop={2.5}>
        <FormControl fullWidth className={classes.projectSelectMobile}>
          <InputLabel id="spreadsheet_choose_project">
            <Typography variant="h4">
              <FormattedMessage id="spreadsheet_table_project" />
            </Typography>
          </InputLabel>
          <Select
            labelId="spreadsheet_choose_project"
            variant="standard"
            onChange={handleChangeProject}
            IconComponent={() => <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />}
            disableUnderline
            defaultValue={0}
          >
            <MenuItem disabled value={0}>
              <Typography variant="h4">
                <FormattedMessage id="spreadsheet_choose_project" />
              </Typography>
            </MenuItem>
            {anotherProjects.map(project => (
              <MenuItem key={project.projectId} value={project.projectId}>
                <Typography variant="h4">{project.projectName}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.projectSelectMobile}>
          <InputLabel id="spreadsheet_choose_task">
            <Typography variant="h4">
              <FormattedMessage id="spreadsheet_table_issues" />
            </Typography>
          </InputLabel>
          <Select
            labelId="spreadsheet_choose_task"
            variant="standard"
            IconComponent={() => <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />}
            onChange={handleChangeProjectTask}
            disabled={!newIssue.projectId}
            disableUnderline
            defaultValue={0}
            value={newIssue.issueUniqueId}
          >
            <MenuItem disabled value={0}>
              <Typography variant="h4">
                <FormattedMessage id="spreadsheet_choose_task" />
              </Typography>
            </MenuItem>
            {Object.keys(formattedAnotherProjects).map(issueKey => {
              const issue = formattedAnotherProjects[issueKey];
              return (
                newIssue.projectId === issue.projectId && (
                  <MenuItem key={issue.issueUniqueId} value={issue.issueUniqueId}>
                    <Typography variant="h4">{issue.issueName}</Typography>
                  </MenuItem>
                )
              );
            })}
          </Select>
        </FormControl>
        {currentWeek.map(day => {
          return (
            <SpreadsheetMobileCreateRow
              key={day.date}
              day={day}
              intl={intl}
              currentWeek={currentWeek}
              disabled={!newIssue.issueUniqueId}
              setWeekSum={setWeekSum}
              newIssue={newIssue}
              timeType={timeType}
              timeTypes={timeTypes}
              setEditedFormattedIssues={setEditedFormattedIssues}
              currentIssue={newIssue}
            />
          );
        })}
        {weekSum !== 0 && (
          <Box marginY={2.5}>
            <Typography variant="h4">
              <FormattedMessage id="spreadsheet_week_sum" /> {weekSum} <FormattedMessage id="hour" />
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SpreadsheetMobileCreate;
