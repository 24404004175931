import React from 'react';
import { Box, Grid, Popover, Typography } from '@mui/material';
import clsx from 'clsx';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import useStyles from '../../../../packages/common/shared-ui/src/theme/components/ContextMenuSecondStep.styles';

const TableSecondStepPopover = ({
  value,
  type,
  data,
  isSecondStepVisible,
  contextPopperStylePosition,
  handleChooseSubItem,
  positionInfo = {
    itemPosition: 1,
    positionDelta: 1,
    totalCount: 3,
  },
}) => {
  const classes = useStyles();
  return (
    <Popover
      open={!!isSecondStepVisible}
      style={{
        left: contextPopperStylePosition.current.left + 182,
        top: contextPopperStylePosition.current.top + positionInfo.itemPosition * 32,
        maxWidth: 180,
        height: positionInfo.totalCount < 8 ? 'auto' : 230,
        overflowY: 'auto',
        boxShadow: positionInfo.totalCount > 8 ? '0px 5px 10px -8px rgba(33, 35, 70, 1)' : 'none',
      }}
      anchorPosition={contextPopperStylePosition.current}
      className={clsx(
        classes.contextPopover,
        classes.contextPopoverSubItems,
        {
          [classes.contextLeftBottomCorner]: positionInfo.itemPosition === 0 && positionInfo.positionDelta > 0,
        },
        {
          [classes.contextLeftUpperCorner]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta <= 0,
        },
        {
          [classes.contextLeftSideCorners]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta > 0,
        },
      )}
      anchorReference="none"
      keepMounted
    >
      <Grid container direction="column">
        {value?.map((item, index) => {
          return (
            <Grid
              container
              className={classes.contextMenuSubItem}
              wrap="nowrap"
              alignItems="center"
              key={index}
              onClick={() => handleChooseSubItem(item.userId ? item.userId : item.statusId, type)}
              data-value="approve"
            >
              {item.isChecked && (
                <Box width={16} height={16}>
                  <DoneIcon color="rgba(55, 198, 112, 1)" viewBox="0 0 24 24" />
                </Box>
              )}
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <Typography variant="h5" paddingLeft={item.isChecked ? 1 : 5}>
                {item.fullName ? item.fullName : item.statusName}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Popover>
  );
};

export default TableSecondStepPopover;
