import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Page from 'Common/shared-ui/src/components/Page';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  Box,
  Grid,
  Select,
  Chip,
  Checkbox,
  IconButton,
  MenuItem,
  Typography,
  ToggleButton,
  FormControl,
  ListItemText,
  ListItemButton,
  InputLabel,
  ListItem,
  List,
  ToggleButtonGroup,
  CircularProgress,
  Button,
  TextField,
} from '@mui/material';
import useStyles from './ProjectsSettings.styles';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import { getProjectsSettings, createProjectsSettings, getProjectsWithQuery } from '../../../packages/common/api';
import _without from 'lodash/without';
import _unionBy from 'lodash/unionBy';
import _union from 'lodash/union';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import { toggleDrawer } from '../app/redux/reducers/userSlice';
import { useNavigate } from 'react-router';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
const ProjectsSettings = ({ intl }) => {
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDrawer = useCallback(() => dispatch(toggleDrawer(false)), [dispatch]);
  const searchPlaceholderText = intl.formatMessage({ id: 'enter' });
  const [settings, setSettings] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersSelected, setFiltersSelected] = React.useState([]);
  const [fieldsSelected, setFieldsSelected] = React.useState([]);
  const [fieldsNotSelected, setFieldsNotSelected] = React.useState([]);
  const [searchFieldsNotSelected, setSearchFieldsNotSelected] = React.useState([]);
  const [searchTextfield, setsearchTextfield] = React.useState('');
  useEffect(() => {
    closeDrawer();
  }, [closeDrawer]);
  const handleChangeFilters = (event, value) => {
    console.log(filtersSelected);
    console.log(value.props.value);
    const filtersIsNotCheked = filtersSelected.filter(filter => filter.isChecked !== false);

    if (value.props.value.isChecked) {
      if (filtersIsNotCheked.length > 1) {
        value.props.value.isChecked = false;
      }
    } else {
      value.props.value.isChecked = true;
    }
    console.log(value.props.value);
    let newValue = _unionBy(filtersSelected, value.props.value, 'field');
    console.log(newValue);
    setFiltersSelected(newValue);
  };
  const handleSaveSettings = async () => {
    setIsLoading(true);
    let fieldsSelectedIds = fieldsSelected.map(item => ({ instance: 'project', field: item.field }));
    let filtersSelectedIds = filtersSelected
      .filter(filter => filter.isChecked)
      .map(item => ({ instance: 'project', field: item.field }));
    let data = {
      settings: {
        filters: filtersSelectedIds,
        fields: fieldsSelectedIds,
      },
    };
    try {
      const response = await createProjectsSettings(data);
      console.log(response);
      setIsLoading(false);
      let hostUrl = generateRedirectUrl({ host: window.location.origin, redmineToken });
      navigate('/projects/', { state: { url: 'projects' } });
    } catch (error) {
      return console.log(error);
    }
  };
  const handleSearchAvailableFields = event => {
    if (event.target.value != '') {
      event.target.value.split('').map((letter, index, array) => {
        const tempSearchFieldsNotSelected = fieldsNotSelected.filter(field => {
          const filterFieldName = field.fieldName.slice(0, array.length);
          return String(event.target.value).toLowerCase() === String(filterFieldName).toLowerCase();
        });
        if (tempSearchFieldsNotSelected.length < 1) {
          tempSearchFieldsNotSelected.push({
            fieldId: 'none',
            fieldName: intl.formatMessage({ id: 'not_found' }),
            checked: false,
          });
        }
        setsearchTextfield(event.target.value);
        setSearchFieldsNotSelected(tempSearchFieldsNotSelected);
      });
    } else {
      setsearchTextfield('');
      setSearchFieldsNotSelected([]);
    }
  };
  const handleDeleteField = (e, field) => {
    if (field.field != 'name' && field.fieldId != 'none') {
      let newFieldsSelected = _without(fieldsSelected, field);
      let newFieldsNotSelected = JSON.parse(JSON.stringify(fieldsNotSelected));
      newFieldsNotSelected.push(field);
      setFieldsNotSelected(newFieldsNotSelected.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
      setsearchTextfield('');
      setSearchFieldsNotSelected([]);
      setFieldsSelected(newFieldsSelected);
    }
  };
  const handleSelectField = (e, field) => {
    if (field.fieldId != 'none') {
      let newFieldsNotSelected = _without(fieldsNotSelected, field);
      let newSearchFieldsNotSelected = _without(searchFieldsNotSelected, field);
      let newFieldsSelected = JSON.parse(JSON.stringify(fieldsSelected));
      newFieldsSelected.push(field);
      setFieldsSelected(newFieldsSelected);
      setSearchFieldsNotSelected(newSearchFieldsNotSelected.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
      setFieldsNotSelected(newFieldsNotSelected.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
    }
  };
  const handleDeleteFilter = (e, value) => {
    e.preventDefault();
    closeDrawer();
    const filtersIsNotCheked = filtersSelected.filter(filter => filter.isChecked !== false);
    if (filtersIsNotCheked.length > 1) {
      value.isChecked = false;
      let newValue = _unionBy(filtersSelected, value, 'field');
      setFiltersSelected(newValue);
    }
  };
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    let [removed] = [];
    let sourceItems;
    let destItems;
    if (source.droppableId !== destination.droppableId) {
      if (destination.droppableId == 'fieldsSelected') {
        destItems = [...fieldsSelected];
        if (searchFieldsNotSelected.length > 0) {
          sourceItems = [...searchFieldsNotSelected];
          let tempFieldsNotSelected = [...fieldsNotSelected];
          [removed] = sourceItems.splice(source.index, 1);
          tempFieldsNotSelected.splice(
            tempFieldsNotSelected.findIndex(({ fieldId }) => fieldId == removed.fieldId),
            1,
          );
          if (sourceItems.length < 1) {
            sourceItems.push({ fieldId: 'none', fieldName: intl.formatMessage({ id: 'not_found' }), checked: false });
          }
          setFieldsNotSelected(tempFieldsNotSelected.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
          setSearchFieldsNotSelected(sourceItems.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
        } else {
          sourceItems = [...fieldsNotSelected];
          [removed] = sourceItems.splice(source.index, 1);
          setFieldsNotSelected(sourceItems.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
        }
        destItems.splice(destination.index, 0, removed);
        setFieldsSelected(destItems);
      } else if (destination.droppableId == 'fieldsNotSelected') {
        destItems = [...fieldsNotSelected];
        sourceItems = [...fieldsSelected];
        if (sourceItems.length > 1) {
          [removed] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setFieldsNotSelected(destItems.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
          setFieldsSelected(sourceItems);
          setsearchTextfield('');
          setSearchFieldsNotSelected([]);
        }
      }
    } else {
      if (destination.droppableId == 'fieldsSelected') {
        destItems = [...fieldsSelected];
        [removed] = destItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setFieldsSelected(destItems);
      } else if (destination.droppableId == 'fieldsNotSelected') {
        if (searchFieldsNotSelected.length > 0) {
          destItems = [...searchFieldsNotSelected];
          [removed] = destItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setSearchFieldsNotSelected(destItems.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
        } else {
          destItems = [...fieldsNotSelected];
          [removed] = destItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, removed);
          setFieldsNotSelected(destItems.sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1)));
        }
      }
    }
  };
  useEffect(() => {
    async function fetchProjectsSettings() {
      try {
        const response = await getProjectsSettings();
        let filters = [];
        let fields = [];
        let SelectedFields = [];
        let notSelectedFields = [];
        console.log(response);
        filters = response.filters.map(function (filter) {
          filter.fieldName = getTranslatedText(intl, 'filter', filter.fieldId, filter.fieldName);
          return filter;
        });
        fields = response.fields.map(function (field) {
          field.fieldName = getTranslatedText(intl, 'filter', field.fieldId, field.fieldName);
          return field;
        });
        notSelectedFields = fields
          .filter(field => !field.isChecked)
          .sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1));
        SelectedFields = fields.filter(field => field.isChecked).sort((a, b) => (a.position > b.position ? 1 : -1));
        setFiltersSelected(filters);
        setFieldsSelected(SelectedFields);
        setFieldsNotSelected(notSelectedFields);
        setSettings(response);
        setIsLoading(false);
        console.log(response);
      } catch (e) {
        console.error(e);
      }
    }
    fetchProjectsSettings();
  }, [intl]);
  const pageTitle = intl.formatMessage({ id: 'page_title_projects_settings' });
  return (
    <Page title={pageTitle} width="100%" className={classes.ProjectsContainerRoot}>
      <Grid
        container
        marginTop={1}
        spacing={5}
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        position="relative"
      >
        <Typography variant="h1">{pageTitle}</Typography>
        <Button onClick={handleSaveSettings} variant="cutBluePrimary" className={classes.saveSettingButton}>
          <FormattedMessage id="save_text" defaultMessage="Save" />
        </Button>
      </Grid>
      <Grid container marginTop={5} spacing={5} position="relative">
        {!isLoading ? (
          <Grid item xs={6} className={classes.settingsWrap}>
            <Grid container spacing={5} position="relative" className={classes.filtersWrap}>
              <Grid item xs={3}>
                <div>{intl.formatMessage({ id: 'filter' })}</div>
              </Grid>
              <Grid item xs={9} className={classes.formControlWrap}>
                <FormControl className={classes.formControl}>
                  <Select
                    id="filters"
                    multiple
                    value={filtersSelected}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      style: {
                        maxHeight: 500,
                      },
                    }}
                    onChange={handleChangeFilters}
                    selectprops={{ IconComponent: () => null }}
                    renderValue={selected => {
                      selected = selected.filter(filter => filter.isChecked);
                      return (
                        <Box className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              key={value.field}
                              label={value.fieldName}
                              clickable
                              disabled={value.field == 'status'}
                              deleteIcon={
                                <DeleteIcon
                                  width={10}
                                  height={10}
                                  viewBox="0 0 10 10"
                                  onMouseDown={event => event.stopPropagation()}
                                />
                              }
                              className={classes.chip}
                              onDelete={e => handleDeleteFilter(e, value)}
                            />
                          ))}
                        </Box>
                      );
                    }}
                  >
                    {filtersSelected.map(filter => (
                      <MenuItem key={filter.field} value={filter} disabled={filter.field == 'status'}>
                        <Checkbox
                          className={classes.menuItemCheckbox}
                          checked={filter.isChecked}
                          disabled={filter.field == 'status'}
                        />
                        <ListItemText primary={filter.fieldName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container marginTop={5} justifyContent="space-between" display="flex" position="relative" spacing={5}>
              <DragDropContext onDragEnd={result => onDragEnd(result)}>
                <Grid item xs={6}>
                  <div className={classes.columnTtl}>{intl.formatMessage({ id: 'available_fields' })}</div>
                  <Grid item xs={12} container className={classes.notSelectedFieldsWrap}>
                    <TextField
                      variant="standard"
                      className={classes.notSelectedFieldsSearch}
                      placeholder={searchPlaceholderText}
                      value={searchTextfield}
                      onChange={handleSearchAvailableFields}
                      InputProps={{ className: classes.notSelectedFieldsSearch, disableUnderline: true }}
                    />
                    <Droppable droppableId="fieldsNotSelected">
                      {(provided, snapshot) => (
                        <List
                          className={classes.selectedFields}
                          disablePadding={true}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {searchFieldsNotSelected.length > 0 ? (
                            <>
                              {searchFieldsNotSelected.map((field, index) => {
                                return (
                                  <Draggable key={field.field} draggableId={field.field} index={index}>
                                    {provided => (
                                      <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <ListItem key={field.field} onClick={e => handleSelectField(e, field)}>
                                          <ListItemButton>
                                            <ListItemText primary={field.fieldName} />
                                          </ListItemButton>
                                        </ListItem>
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {fieldsNotSelected.length > 0 && searchTextfield.length <= 0 ? (
                                <>
                                  {fieldsNotSelected.map((field, index) => {
                                    return (
                                      <Draggable key={field.field} draggableId={field.field} index={index}>
                                        {provided => (
                                          <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <ListItem key={field.field} onClick={e => handleSelectField(e, field)}>
                                              <ListItemButton>
                                                <ListItemText primary={field.fieldName} />
                                              </ListItemButton>
                                            </ListItem>
                                          </Box>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <Box>
                                    <ListItem>
                                      <ListItemButton disabled>
                                        <ListItemText primary={intl.formatMessage({ id: 'not_found' })} />
                                      </ListItemButton>
                                    </ListItem>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.columnTtl}>{intl.formatMessage({ id: 'selected_fields' })}</div>
                  <Grid item xs={12} className={classes.selectedFieldsWrap}>
                    <Droppable droppableId="fieldsSelected">
                      {(provided, snapshot) => (
                        <List
                          className={classes.selectedFields}
                          disablePadding={true}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {fieldsSelected.map((field, index) => (
                            <Draggable
                              key={field.field}
                              draggableId={field.field}
                              isDragDisabled={field.field == 'name' || field.fieldId == 'none'}
                              index={index}
                            >
                              {provided => (
                                <ListItem
                                  disabled={field.field == 'name' || field.fieldId == 'none'}
                                  onClick={e => handleDeleteField(e, field)}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListItemButton>
                                    <ListItemText primary={field.fieldName} />
                                  </ListItemButton>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </DragDropContext>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
            <CircularProgress color="secondary" />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};
export default injectIntl(ProjectsSettings);
