import Axios from 'axios';
import { getCookie } from 'Common/utils/cookies';

const platformAPI = Axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `${window.location.protocol}//${window.location.hostname}:${
          process.env.XL_BACKEND_PORT ? process.env.XL_BACKEND_PORT : 3000
        }`
      : `${window.location.origin}`,
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  },
});

platformAPI.interceptors.request.use(
  config => {
    const updatedConfig = { ...config };
    const accessToken = getCookie('access_token');
    // const accessToken = sessionStorage.getItem('access_token') || getCookie('access_token');

    if (accessToken) {
      updatedConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return updatedConfig;
  },
  error => {
    return Promise.reject(error);
  },
);

export default platformAPI;
