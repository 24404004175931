import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  modalPaper: {
    backgroundColor: '#F1F2F8',
    padding: theme.spacing(2.5, 5),
    borderRadius: theme.spacing(4),
    maxWidth: 592,
    maxHeight: '97%',
    overflowY: 'auto',
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 3000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      '& ul': {
        padding: 0,
      },
    },
  },
  optionsFilterInput: {
    '&.MuiTextField-root': {
      '& .MuiInputBase-root': {
        padding: 0,
        borderRadius: theme.spacing(4, 4, 0, 0),
        backgroundColor: '#ffffff !important',
        borderBottom: '1px solid #E4E4EF',
        '& input': {
          padding: theme.spacing(2, 4),
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&:not(.Mui-error):hover': {
          borderBottom: '1px solid #E4E4EF',
        },
      },
    },
  },
  totalsSelect: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: 353,
    '& > div': {
      padding: 0,
      overflowY: 'hidden !important',
      overflowX: 'scroll !important',
      minHeight: '30px !important',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  chosenTotals: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
    border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(1),
    maxHeight: 30,
    zIndex: 1400,
    ...theme.typography.h5,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  totalsRemove: {
    '&.MuiSvgIcon-root.MuiChip-deleteIcon': {
      position: 'relative !important',
      zIndex: 3000,
      padding: 0,
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
  },
  totalsChip: {
    '&.MuiChip-root': {
      height: 'unset',
      backgroundColor: 'transparent',
    },
  },
  totalsItems: {
    '&.MuiPopover-root': {
      zIndex: 2000,
    },
    '& .MuiPaper-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiList-root': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
}));

export default useStyles;
