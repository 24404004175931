import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  editSaveIssueButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#6270D0',
    },
  },
}));

export default useStyles;
