import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import TrashIcon from 'Common/shared-ui/src/icons/TrashIcon';
import useStyles from './CalendarException.styles';

const CalendarException = ({ exclusions, removeException }) => {
  const classes = useStyles();
  return (
    <Table className={classes.calendarExceptionRoot}>
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell align="left" className={classes.tableCell} size="medium">
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="hours" defaultMessage="Hours" />
            </Typography>
          </TableCell>
          <TableCell align="left" className={classes.tableCell} size="large">
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="prod_calendar_date" defaultMessage="Date" />
            </Typography>
          </TableCell>
          <TableCell align="left" className={classes.tableCell} size="small" />
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(exclusions).map(([exclusion, exclusionObject]) => {
          return (
            <TableRow key={exclusionObject.date} className={classes.exclusions}>
              <TableCell align="center" size="medium" className={classes.tableCell}>
                {exclusionObject.hours}
              </TableCell>
              <TableCell align="left" size="large" className={classes.tableCell}>
                {dayjs(exclusionObject.date).format('DD.MM.YYYY')}
              </TableCell>
              <TableCell align="center" size="small" className={classes.tableCell}>
                <IconButton
                  disableRipple
                  onClick={removeException}
                  id={exclusionObject.id}
                  value={exclusionObject.date}
                >
                  <TrashIcon width="16" height="16" viewBox="0 0 16 16" />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

CalendarException.propTypes = {
  exclusions: PropTypes.shape({}).isRequired,
  removeException: PropTypes.func.isRequired,
};

export default memo(CalendarException);
