import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  textArea: {
    '& .MuiInputBase-root': {
      height: 'auto !important',
    },
  },
  TextField: {
    fontSize: '12px',
  },
  projectMemberTtl: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ProjectTabContentWrap: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  ProjectsTabsWrap: {
    marginTop: '30px',
  },
  ProjectTabContentGridContainer: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  Url: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  projectFieldsWrap: {
    margin: '0 0 20px 0 !important',
    alignItems: 'baseline',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '19px 64px 17px 32px !important',
  },
  projectFieldWrap: {
    width: '100% !important',
    marginBottom: '20px !important',
  },
  projectFieldWrapView: {
    width: '100% !important',
    marginBottom: '5px !important',
  },
  projectFieldMemberRolesActionsWrap: {
    display: 'flex',
  },
  editUser: {
    height: '100%',
    marginRight: '32px !important',
  },
  deleteUser: {
    height: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346; !important',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px !important',
    },
  },
  projectFieldRowView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& $projectFieldLeft': {
      fontWeight: 700,
      fontSize: '14px',
    },
    '& $projectFieldRight': {
      fontSize: '14px',
    },
  },
  projectFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& $projectFieldLeft': {
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      lineHeight: '20px !important',
    },
    '& $projectFieldRight': {
      fontSize: '12px',
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        padding: '0 9px !important',
      },
      '& .MuiInputBase-root': {
        fontSize: '12px',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 12px)',
      },
      '& .MuiIconButton-root.MuiAutocomplete-popupIndicator': {
        width: '24px',
        height: '24px',
      },
    },
  },
  projectFieldDescRow: {
    alignItems: 'flex-start !important',
  },
  projectTrackersWrap: {
    alignItems: 'flex-start !important',
    '& $projectFieldLeft': {
      width: '20% !important',
    },
    '& $projectFieldRight': {
      width: '80% !important',
      '& .MuiTextField-root': {
        marginTop: '0 !important',
      },
      '& .MuiInputBase-root': {
        margin: 2,
        backgroundColor: '#FFF',
        border: '1px solid #E4E4EF',
        borderRadius: '14px',
        '&:before': {
          display: 'none',
        },
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        padding: '0 9px !important',
      },
    },
  },
  autocompleteRolesList: {
    width: '75%',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderColor: '#3448FF',
        '&:hover': {
          borderColor: '#3448FF',
        },
      },
    },
  },
  poperItem: {
    fontSize: '12px !important',
    width: '100%',
    overflow: 'hidden',
  },
  itemSelected: {
    backgroundColor: '#DCDFF4 !important',
  },
  red: {
    color: '#E03737',
  },
  nameInput: {
    '& .MuiInputBase-root': {
      background: '#FFFFFF !important',
      border: '1px solid #E4E4EF !important',
    },
  },
  projectCloseMessage: {
    margin: '30px 0 0 0 !important',
    padding: '19px 17px !important',
    alignItems: 'baseline',
    borderRadius: '16px',
    display: 'inline-flex',
    alignContent: 'center',
    backgroundColor: '#FFF',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
    },
  },
  autocompleteUserAdd: {
    minWidth: '250px',
    maxWidth: '100%',
    color: '#7174AC !important',
    padding: '0px !important',
    '& .MuiOutlinedInput-root': {
      color: '#7174AC !important',
      padding: '0 !important',
      height: '20px !important',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer !Important',
      padding: '0 !important',
    },
    '& .MuiButtonBase-root': {
      display: 'none !Important',
    },
    '& .MuiInputBase-root': {
      '&:before': {
        display: 'none',
      },
    },
    '& fieldset': {
      border: '0 !important',
    },
  },
  ProjectsViewChangeButton: {
    border: '0px !important',
    padding: '0px !important',
  },
  projectFieldLeft: {
    fontWeight: 700,
    width: '40%',
  },
  projectsPageTtl: {
    marginLeft: '0px !important',
  },
  projectFieldRight: {
    width: '60%',
    overflowWrap: 'break-word',
  },
  projectFieldMemberWrap: {
    width: '100% !important',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px !important',
    position: 'relative',
    '& $ProjectUserGroupeIcon': {
      left: '-20px',
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      position: 'absolute',
    },
    '& .MuiTypography-h5': {
      width: '50%',
    },
  },
  ProjectUserGroupeIcon: {
    left: '-20px',
  },
  projectFieldMemberRolesWrap: {
    width: '50%',
    '& .MuiTypography-h5': {
      width: '100%',
      fontSize: '12px !important',
    },
  },
  projectFieldsTtl: {
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '20px !important',
    color: '#082253 !important',
    marginBottom: '18px !important',
  },
  projectFiledTtl: {
    fontSize: '12px!important',
    lineHeight: '20px!important',
  },
  projectFiledTtlActiveUsers: {
    color: '#41424E !important',
  },
  projectFiledTtlBlockedUsers: {
    color: '#7174AC !important',
  },
  projectFiledValue: {
    color: '#41424E !important',
    fontSize: '14px!important',
    lineHeight: '20px!important',
  },
  ProjectsTabs: {
    minHeight: '36px!important',
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiTab-root': {
      background: '#DCDFF4',
      color: '#656985',
      fontSize: '14px',
      textTransform: 'none',
      padding: '8px 16px',
      minHeight: '36px',
      '&:first-child': {
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
      },
      '&:last-child': {
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
      },
    },
    '& .Mui-selected': { background: '#6270D0', color: '#FFFFFF' },
    '& .MuiTabs-flexContainer': { gap: '2px' },
  },
  ProjectsListViewTableRootWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    '& .MuiTableCell-root': {
      border: '1px solid #E4E4EF',
      padding: '10px',
      color: '#212346',
      fontWeight: 700,
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        padding: '18px 10px',
        color: '#082253',
      },
    },
  },
  saveSettingButton: {
    borderRadius: '16px !important',
  },
  uploadButton: {
    fontSize: '12px !important',
    color: '#656985 !important',
    background: '#DCDFF4 !important',
    padding: '6px 16px !important',
  },
  '.MuiButtonBase-root.MuiButton-root': {
    fontSize: '12px !important',
    color: '#656985 !important',
    background: '#DCDFF4 !important',
    padding: '6px 16px !important',
  },
  projectError: {
    color: '#E03737',
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '14px',
  },
  cancelReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
  },
  '.Mui-disabled': {
    background: '#DCDFF4  !important',
  },
  saveReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
    '& .MuiInputBase-root': {
      '&.Mui-disabled': {
        backgroundColor: '#DCDFF4 !important',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#DCDFF4 !important',
      color: '#656985 !important',
    },
  },
}));

export default useStyles;
