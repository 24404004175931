import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  calendarRoot: {
    width: 'fit-content !important',
    borderRadius: theme.spacing(4),
    border: '1px solid #DCDFF4',
    margin: '0 !important',
    marginRight: `${theme.spacing(4)} !important`,
    marginBottom: `${theme.spacing(4)} !important`,
    height: 308,
    '& > div': {
      padding: 0,
      margin: 0,
      overflow: 'hidden',
    },
    '& [role="presentation"]': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 30,
      backgroundColor: '#DCDFF4',
      padding: theme.spacing(1, 0),
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      textTransform: 'capitalize',
      overflowY: 'hidden',
      ...theme.typography.h3,
      fontWeight: 700,
    },
    '& .PrivatePickersSlideTransition-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiTypography-caption': {
      width: 44,
      margin: 0,
      textTransform: 'lowercase',
      color: '#41424E',
      ...theme.typography.h5,
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      display: 'none',
    },
    '& .MuiButtonBase-root.MuiPickersDay-root': {
      display: 'block',
      maxWidth: 44,
      width: '100%',
      borderRadius: 0,
      margin: 0,
      ...theme.typography.h4,
    },
    '& [role=row]': {
      margin: 0,
      backgroundColor: '#ffffff',
    },
    '& .MuiPickersArrowSwitcher-root': {
      display: 'none',
    },
    [theme.breakpoints.maxWidth('lg')]: {
      marginRight: '16px !important',
      marginLeft: '0 !important',
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.maxWidth('xs')]: {
      marginRight: '0 !important',
    },
  },
}));

export default useStyles;
