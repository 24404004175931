import React, { useEffect } from 'react';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import { useLocation, useNavigate } from 'react-router';
import { useQueryParams } from 'use-query-params';
import { getProject } from '../../../packages/common/api';
import { setProject } from './redux/reducers/userSlice';
import { useDispatch } from 'react-redux';
import { getTimesheetInfo } from '../../../packages/common/api';
const RedirectRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useQueryParams({});
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let redmineOriginUrl = new URL(params.get('redmineOrigin'), window.location.origin);
    const redmineOriginUrlSplited = redmineOriginUrl.pathname.split('/');
    const redmineOriginParams = Object.fromEntries(redmineOriginUrl.searchParams);
    redmineOriginUrl.searchParams.append('X-Lance-Skip-Redirection', true);
    let url = generateRedirectUrl({
      host: redmineOriginUrl.origin,
      path: redmineOriginUrl.pathname + redmineOriginUrl.search,
    });
    if (redmineOriginUrlSplited[1] == 'projects') {
      if (redmineOriginUrlSplited.length == 3 || redmineOriginUrlSplited.length == 2) {
        navigate(redmineOriginUrl.pathname);
      }
      if (redmineOriginUrlSplited.length == 4) {
        if (redmineOriginUrlSplited[3] == 'issues') {
          navigate('/' + redmineOriginUrlSplited[3] + '?project=' + redmineOriginUrlSplited[2]);
        } else if (redmineOriginUrlSplited[3] == 'time_entries') {
          async function getProjectId() {
            const project = await getProject(redmineOriginUrlSplited[2]);
            dispatch(setProject(project));
            const id = redmineOriginParams?.issue_id ? redmineOriginParams?.issue_id.replace(/~/, '') : '';
            let issue = redmineOriginParams?.issue_id ? '%24%24timesheet.issue%3Beq%3B' + id : '';
            navigate(
              '/spentTime?filters=timesheet.project%3Beq%3B' +
                project.redmineId +
                issue +
                '&locked=timesheet.project%3Fproject%3D' +
                project.redmineId,
            );
          }
          getProjectId(redmineOriginUrlSplited[2]);
        } else if (redmineOriginUrlSplited[3] == 'issues') {
          async function getProjectId() {
            const project = await getProject(redmineOriginUrlSplited[2]);
            dispatch(setProject(project));
            navigate('/issues?project=' + project.redmineId);
          }
          getProjectId(redmineOriginUrlSplited[2]);
        } else if (redmineOriginUrlSplited[3] == 'settings') {
          navigate('/' + redmineOriginUrlSplited[1] + '/' + redmineOriginUrlSplited[2] + '/edit');
        } else {
          window.location.href = url;
        }
      }
      if (redmineOriginUrlSplited.length == 5 || redmineOriginUrlSplited.length == 6) {
        window.location.href = url;
      }
      console.log('projects');
    } else if (redmineOriginUrlSplited[1] == 'issues') {
      console.log('issues');
      if (redmineOriginUrlSplited.length == 2) {
        navigate('/issues');
      } else if (redmineOriginUrlSplited.length === 3) {
        navigate('/issues/' + redmineOriginUrlSplited[2]);
      } else {
        window.location.href = url;
      }
    } else if (redmineOriginUrlSplited[1] == 'time_entries') {
      console.log('time_entries');
      if (redmineOriginUrlSplited.length == 2) {
        navigate('/spentTime');
      } else if (redmineOriginUrlSplited.length == 4) {
        if (redmineOriginUrlSplited[3] == 'edit') {
          async function fetchTimesheet(timesheetid) {
            const response = await getTimesheetInfo(timesheetid);
            url = `/spentTime/create?userId=${response.data.userId}&projectId=${response.data.projectId}&issueId=${response.data.issueId}&rowId=${response.data.redmineId}&spentOn=${response.data.spentOn}&hours=${response.data.hours}&isOverTime=${response.data.isOvertime}`;
            window.location.href = url;
          }
          fetchTimesheet(redmineOriginUrlSplited[2]);
        }
      } else {
        window.location.href = url;
      }
    } else if (redmineOriginUrlSplited[1] == 'resource_plan') {
      console.log('resource_plan');
      if (redmineOriginUrlSplited.length == 2) {
        navigate('/resourcePlan');
      } else {
        window.location.href = url;
      }
    } else {
      console.log('default');
      window.location.href = url;
    }
  }, [dispatch, location, navigate]);
  return null;
};

export default RedirectRouter;
