import dayjs from 'dayjs';

export const formatWeek = ({ startOfWeek, issue, intl, type = '' }) => {
  const weekDayNames = [
    'spreadsheet_table_mon',
    'spreadsheet_table_tue',
    'spreadsheet_table_wed',
    'spreadsheet_table_thu',
    'spreadsheet_table_fri',
    'spreadsheet_table_sat',
    'spreadsheet_table_sun',
  ];
  let formattedWeek = [];
  for (let i = 0; i < 7; i++) {
    let newWeekDay = {
      dayOfWeek: intl ? intl.formatMessage({ id: [weekDayNames[i]] }) : null,
      date: dayjs(startOfWeek).add(i, 'day').format(),
      hours: 0,
      overTimeHours: 0,
      isApproved: false,
      isError: false,
      spentOn: dayjs(startOfWeek).add(i, 'day').format('YYYY-MM-DD'),
      comments: '',
      redmineId: 0,
    };
    if (issue) {
      const currentTimeEntry = issue.timeEntries.find(time => {
        if (type === 'OVER_HOURS') {
          return time.isOvertime && dayjs(time.spentOn).format() === dayjs(startOfWeek).add(i, 'day').format();
        }
        return !time.isOvertime && dayjs(time.spentOn).format() === dayjs(startOfWeek).add(i, 'day').format();
      });
      newWeekDay = {
        ...newWeekDay,
        hours: currentTimeEntry?.isOvertime ? 0 : currentTimeEntry?.hours || 0,
        spentOn: dayjs(startOfWeek).add(i, 'day').format('YYYY-MM-DD'),
        issueId: issue.issueId,
        isApproved: currentTimeEntry?.isApproved,
        redmineId: currentTimeEntry?.redmineId || 0,
        overTimeHours: currentTimeEntry?.isOvertime ? currentTimeEntry?.hours : 0,
        isOvertime: currentTimeEntry?.isOvertime,
        comments: currentTimeEntry?.comments || '',
      };
      if (type === 'OVER_HOURS') {
        newWeekDay = {
          ...newWeekDay,
          hours: currentTimeEntry?.isOvertime ? currentTimeEntry?.hours : 0,
          isOvertime: true,
        };
      }
    }
    formattedWeek = [...formattedWeek, newWeekDay];
  }
  return formattedWeek;
};
