import React from 'react';
import { Box, Grid, Popover, Typography } from '@mui/material';
import clsx from 'clsx';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import useStyles from '../../../../packages/common/shared-ui/src/theme/components/ContextMenuSecondStep.styles';
const TableSecondStepPopover = ({
  value,
  isSecondStepVisible,
  contextPopperStylePosition,
  handleChooseSubItem,
  positionInfo = { itemPosition: 1, positionDelta: 1 },
}) => {
  const classes = useStyles();
  return (
    <Popover
      open={!!isSecondStepVisible}
      style={{
        left: contextPopperStylePosition.current.left + 182,
        top: contextPopperStylePosition.current.top + positionInfo.itemPosition * 32,
        maxWidth: 180,
      }}
      anchorPosition={contextPopperStylePosition.current}
      className={clsx(
        classes.contextPopover,
        classes.contextPopoverSubItems,
        {
          [classes.contextLeftBottomCorner]: positionInfo.itemPosition === 0 && positionInfo.positionDelta > 0,
        },
        {
          [classes.contextLeftUpperCorner]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta <= 0,
        },
        {
          [classes.contextLeftSideCorners]: positionInfo.itemPosition !== 0 && positionInfo.positionDelta > 0,
        },
      )}
      anchorReference="none"
      keepMounted
    >
      <Grid container direction="column">
        <Grid
          container
          className={classes.contextMenuSubItem}
          wrap="nowrap"
          alignItems="center"
          onClick={() => handleChooseSubItem('approve')}
          data-value="approve"
        >
          {value && (
            <Box width={16} height={16}>
              <DoneIcon color="rgba(55, 198, 112, 1)" viewBox="0 0 24 24" />
            </Box>
          )}
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h5" paddingLeft={value ? 1 : 5}>
            Да
          </Typography>
        </Grid>
        <Grid
          container
          className={classes.contextMenuSubItem}
          wrap="nowrap"
          alignItems="center"
          onClick={() => handleChooseSubItem('reject')}
          data-value="reject"
        >
          {!value && (
            <Box width={16} height={16}>
              <DoneIcon color="rgba(55, 198, 112, 1)" viewBox="0 0 24 24" />
            </Box>
          )}
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h5" paddingLeft={!value ? 1 : 5}>
            Нет
          </Typography>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default TableSecondStepPopover;
