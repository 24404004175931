import React from 'react';
import { SvgIcon } from '@mui/material';

const ProjectOpenIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2345">
        <path
          d="M6.99992 14.1666C7.91658 14.1666 8.66658 13.4166 8.66658 12.4999C8.66658 11.5833 7.91658 10.8333 6.99992 10.8333C6.08325 10.8333 5.33325 11.5833 5.33325 12.4999C5.33325 13.4166 6.08325 14.1666 6.99992 14.1666ZM11.9999 6.66658H11.1666V4.99992C11.1666 2.69992 9.29992 0.833252 6.99992 0.833252C4.69992 0.833252 2.83325 2.69992 2.83325 4.99992H4.41658C4.41658 3.57492 5.57492 2.41659 6.99992 2.41659C8.42492 2.41659 9.58325 3.57492 9.58325 4.99992V6.66658H1.99992C1.08325 6.66658 0.333252 7.41658 0.333252 8.33325V16.6666C0.333252 17.5833 1.08325 18.3333 1.99992 18.3333H11.9999C12.9166 18.3333 13.6666 17.5833 13.6666 16.6666V8.33325C13.6666 7.41658 12.9166 6.66658 11.9999 6.66658ZM11.9999 16.6666H1.99992V8.33325H11.9999V16.6666Z"
          fill="#7174AC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2345">
          <rect width="14" height="19" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ProjectOpenIcon;
