import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from './SettingsUpperLine.styles';

const SettingsUpperLine = ({ header, content }) => {
  const classes = useStyles();
  return (
    <Grid item container gap={10} marginBottom={3} direction="row" className={classes.SettingsBlock}>
      <Typography variant="h4" position="relative" xs={2} fontWeight={700} className={classes.SettingsFieldsHead}>
        {header}:
      </Typography>
      <Typography variant="h4" position="relative" xs={2} className={classes.SettingsFields}>
        {content}
      </Typography>
    </Grid>
  );
};

export default SettingsUpperLine;
