import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import {
  getContractStagesSettings,
  deleteContractStagesSettings,
  saveContractStageSettings,
} from '../../../../../packages/common/api';
import useStyles from './ContractStagesTable.styles';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import ReportDeleteIcon from '../../../../../packages/common/shared-ui/src/icons/ReportDeleteIcon';
import dayjs from 'dayjs';

const ContractStagesTable = ({ intl, setHasCustomerRecords, setErrorMessage }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [initialContracts, setInitialContracts] = useState([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  useEffect(() => {
    let ignore = false;

    const fetchContractsData = async () => {
      try {
        setLoading(true);
        const response = await getContractStagesSettings();

        if (!ignore) {
          setContracts(response.data);
          setInitialContracts(response.data);
          checkIfAnyContractIsChecked(response.data);
        }

        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    fetchContractsData();

    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkRowValue = useCallback(item => {
    const isValidCustomerId = item.customerId;

    const checkedStages = item.stage.filter(stage => stage.isChecked);

    const isValidCheckedStage = checkedStages.length === 1 && checkedStages[0].valueId;

    const customerIdInt = isValidCustomerId ? Number(item.customerId) : null;
    const checkedStageValueIdInt = isValidCheckedStage ? Number(checkedStages[0].valueId) : null;

    return {
      isValid: isValidCustomerId && isValidCheckedStage,
      customerId: customerIdInt,
      stageValueId: checkedStageValueIdInt,
    };
  }, []);
  const checkIfAnyContractIsChecked = useCallback(
    data => {
      const hasCheckedContract = data.some(contract => contract.isChecked);
      setHasCustomerRecords(hasCheckedContract);
    },
    [setHasCustomerRecords],
  );
  const addObjectHandler = useCallback(() => {
    setContracts([
      ...contracts,
      {
        id: `newRow-${contracts.length + 1}`,
        new: true,
        isChecked: true,
        customerId: '',
        customerName: '',
        stage: [],
      },
    ]);
    setHasNewRow(true);
  }, [contracts]);

  const selectHandler = useCallback(
    (event, id) => {
      const updatedContracts = contracts.map(item => {
        if (item.id === id) {
          const sourceContract = contracts.filter(contract => contract.customerId === event.target.value);
          return {
            ...item,
            customerName: sourceContract[0].customerName,
            stage: sourceContract[0].stage,
            customerId: event.target.value,
          };
        }
        return item;
      });
      setContracts(updatedContracts);
    },
    [contracts],
  );
  const selectStageHandler = useCallback(
    (event, id) => {
      const updatedContracts = contracts.map(contract => {
        if (contract.id === id) {
          const newStages = contract.stage.map(stage => ({
            ...stage,
            isChecked: stage.valueId === event.target.value,
          }));
          return {
            ...contract,
            stage: newStages,
          };
        }
        return contract;
      });
      setContracts(updatedContracts);
    },
    [contracts],
  );

  const toggleEdit = useCallback(
    item => {
      const updatedContracts = contracts.map(contract => {
        if (contract.id === item.id) {
          return {
            ...contract,
            edit: true,
          };
        }
        return contract;
      });
      setContracts(updatedContracts);
    },
    [contracts],
  );
  const resetItem = useCallback(
    itemId => {
      const initialItem = initialContracts.find(contract => contract.id === itemId);
      const updatedContracts = contracts.map(contract => {
        if (contract.id === itemId) {
          return {
            ...initialItem,
          };
        }
        return contract;
      });
      setContracts(updatedContracts);
    },
    [contracts, initialContracts],
  );

  const checkError = useCallback(
    (error, item, errorSource = 'default') => {
      if (
        error.response.data.message ===
        'The customer stage connot be removed since it is used within contract schedules'
      ) {
        const initialItem = initialContracts.find(contract => contract.id === item.id);
        const errorContractStage = initialItem.stage.find(stage => stage.isChecked).valueName;
        setErrorMessage(
          // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
          `Значение поля "${errorContractStage}" используется в матрице платежей. ${
            // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
            errorSource === 'edit' ? 'Изменение невозможно.' : ''
            // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
          } ${errorSource === 'delete' ? 'Удаление невозможно.' : ''}`,
        );
      } else {
        setErrorMessage(error.response.data.message);
      }
    },
    [initialContracts, setErrorMessage],
  );

  const saveEditHandler = useCallback(
    async item => {
      const checkedValue = checkRowValue(item);
      if (!checkedValue.isValid) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setErrorMessage('Заполните все обязательные поля.');
        return;
      }
      const data = {
        customerId: checkedValue.customerId,
        stageFieldId: checkedValue.stageValueId,
      };
      let newRow;
      if (hasNewRow) {
        newRow = contracts.find(contract => contract.new);
      }
      if (item.edit) {
        const initialItem = initialContracts.find(contract => contract.id === item.id);
        if (Number(initialItem.customerId) !== data.customerId) {
          try {
            await deleteContractStagesSettings(item.id);
          } catch (error) {
            console.error('ERROR WITH DELETE', error);
            checkError(error, item, 'edit');
            resetItem(item.id);
            return;
          }
        }
      }
      try {
        const response = await saveContractStageSettings(data);
        if (response) {
          setInitialContracts(response.data);
          if (item.edit && newRow && Array.isArray(response.data)) {
            response.data.push(newRow);
          }
          setContracts(response.data);
          if (item.new) {
            setHasNewRow(false);
          }
          checkIfAnyContractIsChecked(contracts);
        }
      } catch (error) {
        resetItem(item.id);
        checkError(error, item, 'edit');
        console.error('ERROR WITH SAVE', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkError, checkIfAnyContractIsChecked, checkRowValue, contracts, hasNewRow, resetItem, setErrorMessage],
  );
  const deleteHandler = useCallback(
    async item => {
      if (item.new) {
        const newContracts = contracts.filter(contract => contract.id !== item.id);
        setContracts(newContracts);
        setHasNewRow(false);
        return;
      }
      try {
        const response = await deleteContractStagesSettings(item.id);
        setInitialContracts(response.data);
        if (response) {
          const newContracts = contracts.filter(contract => contract.id !== item.id);
          setContracts(newContracts);
          setHasNewRow(false);
          checkIfAnyContractIsChecked(contracts);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
        checkError(error, item, 'delete');
        resetItem(item.id);
      }
    },
    [checkError, checkIfAnyContractIsChecked, contracts, resetItem],
  );
  const buttonsMap = new Map([
    ['edit', { handler: toggleEdit, icon: <UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['submit', { handler: saveEditHandler, icon: <SubmitIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['delete', { handler: deleteHandler, icon: <ReportDeleteIcon width="16" height="16" viewBox="0 0 16 16" /> }],
  ]);

  const RowButton = ({ children, action, rowIndex, item }) => {
    children = buttonsMap.get(action).icon;
    return (
      <IconButton onClick={() => buttonsMap.get(action).handler(item)} className={classes.rowButton}>
        {children}
      </IconButton>
    );
  };

  const RowComponent = ({ item, rowIndex, type }) => {
    let buttons = ['edit', 'delete'];
    item.new && (buttons = ['submit', 'delete']);
    item.edit && (buttons = ['submit', 'delete']);
    return (
      <Grid container className={classes.rowItem}>
        <Grid item sx={{ minWidth: '490px', p: item.new || item.edit ? 0 : '10px' }} className={classes.rowCell}>
          {item.new || item.edit ? (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                className={item.customerId === '' ? classes.selectLabelEmpty : classes.selectLabel}
                id="select-label1"
              >
                {intl.formatMessage({ id: 'choose' })}
              </InputLabel>
              <Select
                native={false}
                labelId="select-label1"
                label={intl.formatMessage({ id: 'choose' })}
                onChange={event => selectHandler(event, item.id)}
                variant="standard"
                sx={{ width: '100%', border: 'none' }}
                value={item.id}
                defaultValue="defaultValue"
                className={classes.rowSelect}
                MenuProps={{ classes: { paper: classes.paperDropdown } }}
                IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                renderValue={value => {
                  return item.customerId !== '' ? item.customerName : null;
                }}
              >
                {contracts
                  .filter(contract => contract.isChecked === false || contract.customerId === item.customerId)
                  .sort((a, b) => b.isChecked - a.isChecked)
                  .map((item, index) => (
                    <MenuItem key={item.customerId} value={item.customerId} className={classes.menuItem}>
                      {item.customerName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            item.customerName
          )}
        </Grid>
        <Grid item sx={{ flexShrink: 0, flexBasis: '400px' }} p={0} className={classes.rowCell}>
          <Grid container>
            <Grid item sx={{ flex: 1, p: item.new || item.edit ? 0 : '10px' }}>
              {item.new || item.edit ? (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel
                    className={item.customerId === '' ? classes.selectLabelDisabled : classes.selectLabel}
                    id="select-label"
                  >
                    {intl.formatMessage({ id: 'choose' })}
                  </InputLabel>
                  <Select
                    native={false}
                    labelId="select-label"
                    label={intl.formatMessage({ id: 'choose' })}
                    onChange={event => selectStageHandler(event, item.id)}
                    variant="standard"
                    disabled={item.customerId === ''}
                    sx={{ width: '100%', border: 'none' }}
                    value={item.stage.find(stage => stage.isChecked === true)?.valueId || ''}
                    defaultValue="defaultValue"
                    className={classes.rowSelect}
                    MenuProps={{ classes: { paper: classes.paperDropdown } }}
                    IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                    renderValue={value => {
                      const renderOption = item.stage.find(contract => contract.isChecked === true);
                      return renderOption ? renderOption.valueName : intl.formatMessage({ id: 'choose' });
                    }}
                  >
                    {item.stage
                      .sort((a, b) => b.isChecked - a.isChecked)
                      .map((item, index) => (
                        <MenuItem key={item.valueId} value={item.valueId} className={classes.menuItem}>
                          {item.valueName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                item?.stage?.find(stage => stage.isChecked === true)?.valueName
              )}
            </Grid>
            <Grid item>
              {buttons.map((action, index) => (
                <RowButton key={index} action={action} rowIndex={rowIndex} item={item}></RowButton>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item sx={{ maxWidth: '987px', flex: 1 }}>
              <Box className={classes.blockWrapper} p={0}>
                <Grid container>
                  <Grid item sx={{ minWidth: '490px' }} className={classes.rowHeader}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'contract_stages_customer' })}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ flexShrink: 0, flexBasis: '400px' }} className={classes.rowHeader}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({ id: 'contract_stages_field' })}
                    </Typography>
                  </Grid>
                </Grid>
                {contracts
                  .filter(contract => contract.isChecked === true)
                  .map((contract, index) => {
                    return <RowComponent item={contract} key={index} rowIndex={index}></RowComponent>;
                  })}
                <Box p={'8px'} sx={{ borderTop: '1px solid #E4E4EF' }}>
                  <IconButton disabled={hasNewRow} onClick={addObjectHandler} p={0}>
                    <PlusIcon width="24" height="24" viewBox="0 0 24 24" color={hasNewRow ? '#E4E4EF' : '#7174AC'} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default injectIntl(ContractStagesTable);
