import { makeStyles } from '@mui/styles';

const gridFilterPanel = makeStyles(theme => ({
  booleanLabel: {
    '&.MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  filterPopoverButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#fff',
      boxShadow: 'none',
      '&.MuiButton-root': {
        '&:focus': {
          backgroundColor: '#fff',
        },
      },
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: 'none',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
        boxShadow: 'none',
      },
    },
  },
  filterPopover: {
    '& .MuiPaper-root': {
      borderRadius: '16px',
      //boxShadow: 'none',
      width: '288px',
      boxShadow: 'none',
      border: '1px solid #E4E4EF',
      overflow: 'hidden',
    },
  },
  filterPopoverSelect: {
    minWidth: '110px',
    '&.MuiInputBase-root.MuiOutlinedInput-root': {
      height: '24px',
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        padding: '2px 8px 2px 16px',
      },
      '& .MuiSvgIcon-root': {
        width: '24px',
        right: '8px',
      },
      '& .MuiSelect-nativeInput': {
        height: '100%',
      },
      '&.Mui-focused': {
        borderRadius: '16px 16px 0 0',
      },
    },
  },
  filterPopoverSelectMenu: {
    '& .MuiPaper-root': {
      borderRadius: '0 0 16px 16px',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      border: '1px solid #E4E4EF',
      borderTop: 'none',
      '& ul': {
        padding: 0,
        '& li': {
          padding: '2px 16px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&.Mui-selected': {
            backgroundColor: '#DCDFF4',
          },
          '& .MuiTypography-root': {
            fontSize: '10px',
          },
        },
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '10px',
      lineHeight: '20px',
      transform: 'none',
      left: '16px',
      top: '2px',
      '&.Mui-focused': {
        transform: 'none',
        left: '16px',
        top: '2px',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
  filterPopoverTextfield: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: 'auto',
      '&.MuiInputBase-multiline': {
        padding: 0,
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        borderRadius: 0,
        fontSize: '12px',
        lineHeight: '20px',
        padding: '8px 16px',
        height: 'auto',
        color: '#212346',
        '&::placeholder': {
          color: '#7174AC',
          fontSize: '12px',
          lineHeight: '20px',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  filterPopoverAutocomplete: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '8px 16px',
      paddingRight: '16px',
      lineHeight: '20px',
      height: 'auto',
      '& .MuiChip-root': {
        display: 'none',
      },
      '& .MuiAutocomplete-input': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#7174AC',
        padding: 0,
        height: 'auto',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    '& + .MuiAutocomplete-popper': {
      position: 'static!important',
      transform: 'none!important',
      minWidth: 'auto!important',
      //width: '100%!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0',
        border: 'none',
        boxShadow: 'none',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            padding: '8px 16px',
            '& .MuiButtonBase-root.MuiCheckbox-root': {
              padding: '2px',
              marginRight: '10px',
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
  datePicker: {
    '&.MuiFormControl-root.MuiTextField-root': {
      //height: 'auto',
      //width: '116px',
      '& .MuiFormLabel-root': {
        display: 'none',
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        height: 'auto',
        padding: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiInputAdornment-root': {
          '& .MuiSvgIcon-root path': {
            fill: '#DCDFF4',
          },
        },
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        height: 'auto',
        padding: '8px 30px 8px 16px',
      },
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '20px',
    },
  },
  rangeDatePicker: {
    '& .MuiDateRangePickerDay-root': {
      '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
        backgroundColor: '#DCDFF4',
      },
    },
    '& .MuiFormControl-root.MuiTextField-root': {
      //height: 'auto',
      //width: '116px',
      '& .MuiFormLabel-root': {
        display: 'none',
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        height: 'auto',
        padding: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiInputAdornment-root': {
          '& .MuiSvgIcon-root path': {
            fill: '#DCDFF4',
          },
        },
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        height: 'auto',
        padding: '8px 30px 8px 16px',
      },
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: '20px',
    },
  },
}));

export default gridFilterPanel;
