import useStyles from './ResourcePlanDatagrid.styles';
import React, { useEffect, useState, useCallback, useMemo, useDeferredValue } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from '@mui/material/utils';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ResourcePlanTableCell from '../ResourcePlanTableCell/ResourcePlanTableCell';
import ResourcePlanTableCellGroup from '../ResourcePlanTableCellGroup/ResourcePlanTableCellGroup';
import ResourcePlanTableCellNew from '../ResourcePlanTableCellNew/ResourcePlanTableCellNew';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import dayjs from 'dayjs';
import {
  Grid,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  Box,
  Menu,
  TextField,
  Tooltip,
} from '@mui/material';
import useResPlanWebsockets from '../useResplanWebSockets';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import clsx from 'clsx';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';
import CalendarTimesheetIcon from '../../../../packages/common/shared-ui/src/icons/CalendarTimesheetIcon';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { store } from '../../app/redux';
import { useDispatch } from 'react-redux';
import ResourcePlanTableRow from '../ResourcePlanTableRow/ResourcePlanTableRow';
import SpentTimeRow from '../../spentTime/SpentTimeRow/SpentTimeRow';
import { useDebounce } from 'use-debounce';
import {
  clearResplanTableRows,
  setResplanRowsToUpdate,
  setResplanTableRows,
  setStopLoadingRespanTableBySocket,
} from '../../app/redux/reducers/userSlice';
import { Link } from 'react-router-dom';
import {
  getResplanContracts,
  getResplanProjects,
  getResplanTrackers,
  getResplanUsers,
} from '../../../../packages/common/api';

const ResourcePlanDatagrid = ({
  intl,
  fetchedQuery,
  isTableLoading,
  updateResPlanByMount,
  updateResPlanByPercent,
  addOrUpdateResPlanNewIssue,
  apiRef,
  showIssuesCheck,
  isGridLoading,
  deleteResPlanNewIssue,
  // handleShowDateCheck,
  incorrectDates,
  setIncorrectDates,
}) => {
  const classes = useStyles();
  // const apiRef = useGridApiRef();
  const resplanFields = useSelector(state => state.user?.resplanRows);
  const resplanRowsToUpdate = useSelector(state => state.user?.resplanRowsToUpdate);
  const hasResplanRows = useSelector(state => state.user?.hasResplanRows);
  const resplanNewIssue = useSelector(state => state.user?.resplanNewIssue);
  const resplanStopLoading = useSelector(state => state.user?.resplanStopLoading);
  const [resplanRows, setResplanRows] = useState([]);
  const [resplanColumnsformatted, setResplanColumnsformatted] = useState([]);
  const [resplanRowsR, setResplanRowsR] = useState([]);
  const resplanColumns = useSelector(state => state.user?.resplanColumns);
  const state = store.getState();
  const dispatch = useDispatch();
  const [groupType, setgGroupType] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const [contextMenuData, setContextMenuData] = useState({});
  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);
  const getRowHeight = () => {
    return 40;
  };
  const handleMonthFieldUpdate = useCallback(
    (rowGroupId, rowId, columnFieldId, targetValue) => {
      if (targetValue === '') {
        targetValue = 0;
      }
      const mountValue = {
        fieldId: columnFieldId,
        value: parseFloat(targetValue),
      };
      updateResPlanByMount(rowGroupId, rowId, mountValue);
    },
    [updateResPlanByMount],
  );

  const getProjectUserId = useCallback(
    rowId => {
      const rowData = apiRef?.current.getRow(rowId);
      let projectId;
      let userId;
      rowData.columns.map(column => {
        if (column.fieldId === 'resourcePlan.project') {
          projectId = column.valueId;
        }
        if (column.fieldId === 'resourcePlan.user') {
          userId = column.valueId;
        }
      });
      const projectUserId = {
        projectId: projectId,
        userId: userId,
      };
      return projectUserId;
    },
    [apiRef],
  );

  const handlePercentFieldUpdate = useCallback(
    (rowGroupId, rowId, startDate, endDate, targetValue, columnFieldId, percentLoad) => {
      let percentValue;
      if (columnFieldId == 'resourcePlan.date_from') {
        percentValue = {
          startDate: targetValue,
          endDate: endDate,
          percent: percentLoad,
        };
      }
      if (columnFieldId == 'resourcePlan.date_to') {
        percentValue = {
          startDate: startDate,
          endDate: targetValue,
          percent: percentLoad,
        };
      }
      if (columnFieldId == 'resourcePlan.loading_percent') {
        percentValue = {
          startDate: startDate,
          endDate: endDate,
          percent: targetValue,
        };
      }
      updateResPlanByPercent(rowGroupId, rowId, percentValue);
    },
    [updateResPlanByPercent],
  );

  const handleMonthPercentChange = useCallback(
    (rowIndex, columnIndex, columnFieldId, newValue) => {
      if (newValue === '') {
        newValue = 0;
      }
      // const rowData = apiRef?.current.getRow(rowIndex);
      const regex = /^\d{0,3}(\.\d{0,2})?$/;
      if (regex.test(newValue) || newValue === '') {
        // let updatedRow = rowData;
        // const updatedColumns = [...updatedRow.columns];
        // updatedColumns[columnIndex] = {
        //   ...updatedColumns[columnIndex],
        //   value: parseFloat(newValue),
        // };

        // updatedRow = { ...rowData, columns: updatedColumns, [columnFieldId]: parseFloat(newValue) };
        // dispatch(setResplanRowsToUpdate({ ...rowData, rows: [updatedRow] }));
        apiRef.current.setEditCellValue({ id: rowIndex, field: columnFieldId, value: newValue });
      }
    },
    [apiRef],
  );
  const handleIssueChange = useCallback(
    (rowIndex, columnFieldId, newValue) => {
      // const rowData = apiRef?.current.getRow(rowIndex);
      // let updatedRow = rowData;
      // const updatedColumns = [...updatedRow.columns];
      // updatedColumns[columnIndex] = {
      //   ...updatedColumns[columnIndex],
      //   value: newValue,
      // };

      // updatedRow = { ...rowData, columns: updatedColumns };
      apiRef.current.setEditCellValue({ id: rowIndex, field: columnFieldId, value: newValue });
      // apiRef?.current.updateRows([updatedRow]);
    },
    [apiRef],
  );

  const handleChangeDate = useCallback(
    (columnIndex, date, columnFieldId, rowGroupId, rowId, startDate, endDate, percentLoad, rowSocketId) => {
      const rowData = apiRef?.current.getRow(rowId);
      if (!date || !date.isValid()) {
        return;
      }
      if (rowData) {
        let updatedRow = rowData;
        const updatedColumns = [...updatedRow.columns];
        const dateValue = date.toISOString();

        if (columnFieldId === 'resourcePlan.date_from') {
          const dateToCompare = endDate ? new Date(endDate) : null;
          const selectedDate = date.toDate();
          if (dateToCompare && selectedDate > dateToCompare) {
            updatedColumns[columnIndex] = {
              ...updatedColumns[columnIndex],
              value: dateValue,
              valueId: dateValue,
              isIncorrect: true,
            };
            const updatedServices = { ...updatedRow.services, startDate: dateValue };
            updatedRow = {
              ...rowData,
              columns: updatedColumns,
              services: updatedServices,
              isIncorrect: 'resourcePlan.date_from',
            };
            apiRef?.current.updateRows([updatedRow]);
            apiRef.current.setEditCellValue({ id: rowId, field: columnFieldId, value: dateValue });
            apiRef.current.stopCellEditMode({ id: rowId, field: columnFieldId });
            setIncorrectDates(updatedRow);

            // if (!incorrectDates.includes(rowId)) {
            //   setIncorrectDates(prev => [...prev, rowId]);
            // }
            // setShowDateChecksetShowDateCheck(true);
            return;
          }
        } else if (columnFieldId === 'resourcePlan.date_to') {
          const dateFromCompare = startDate ? new Date(startDate) : null;
          const selectedDate = date.toDate();
          if (dateFromCompare && selectedDate < dateFromCompare) {
            updatedColumns[columnIndex] = {
              ...updatedColumns[columnIndex],
              value: dateValue,
              valueId: dateValue,
              isIncorrect: true,
            };
            const updatedServices = { ...updatedRow.services, endDate: dateValue };
            updatedRow = {
              ...rowData,
              columns: updatedColumns,
              services: updatedServices,
              isIncorrect: 'resourcePlan.date_to',
            };
            apiRef?.current.updateRows([updatedRow]);
            apiRef.current.setEditCellValue({ id: rowId, field: columnFieldId, value: dateValue });
            apiRef.current.stopCellEditMode({ id: rowId, field: columnFieldId });

            setIncorrectDates(updatedRow);

            // if (!incorrectDates.includes(rowId)) {
            //   setIncorrectDates(prev => [...prev, rowId]);
            // }
            // handleShowDateCheck(true);
            return;
          }
        } else {
          updatedColumns[columnIndex] = {
            ...updatedColumns[columnIndex],
            value: dateValue,
            valueId: dateValue,
          };
        }

        updatedRow = { ...rowData, columns: updatedColumns };
        setIncorrectDates([]);

        // apiRef?.current.updateRows([updatedRow]);
        apiRef.current.setEditCellValue({ id: rowId, field: columnFieldId, value: dateValue });
        apiRef.current.stopCellEditMode({ id: rowId, field: columnFieldId });

        // setIncorrectDates(prev => {
        //   const index = prev.indexOf(rowId);
        //   if (index !== -1) {
        //     return [...prev.slice(0, index), ...prev.slice(index + 1)];
        //   } else {
        //     return [...prev, rowId];
        //   }
        // });
        // if (!incorrectDates.includes(rowId)) {
        //   setIncorrectDates(prev => [...prev, rowId]);
        // }
        handlePercentFieldUpdate(rowGroupId, rowSocketId, startDate, endDate, dateValue, columnFieldId, percentLoad);
      }
    },
    [apiRef, handlePercentFieldUpdate, setIncorrectDates],
  );

  const addNewRowAbove = useCallback(
    async (rowId, groupId) => {
      const rowIds = apiRef?.current.getAllRowIds();
      const currentIndex = rowIds.indexOf(rowId);
      const prevRow = apiRef?.current.getRow(rowIds[currentIndex - 1]);
      const uniqueTime = new Date().toISOString();
      const newRowId = `${groupId}-${uniqueTime}`;
      let projectId = prevRow.projectId;
      const userId = prevRow.userId;
      if (groupType) {
        const newUsers = await getResplanProjects(userId);
        projectId = newUsers[0].valueId;
      }
      addOrUpdateResPlanNewIssue(groupId, newRowId, projectId, userId);
    },
    [addOrUpdateResPlanNewIssue, apiRef, groupType],
  );

  const handleIssueBlur = useCallback(
    (rowId, fieldId, targetValue, rowSocketId) => {
      const issueRow = apiRef?.current.getRow(rowId);
      if (!issueRow) {
        return;
      }

      const { groupId, columns } = issueRow;

      const mapping = {
        'resourcePlan.user': 'userId',
        'resourcePlan.project': 'projectId',
        'resourcePlan.issue': 'name',
        'resourcePlan.contract': 'contractId',
        'resourcePlan.tracker': 'trackerId',
      };

      const result = {};

      columns.forEach(column => {
        const key = mapping[column.fieldId];
        if (key === 'name') {
          result[key] = column.value;
        } else {
          result[key] = column.valueId;
        }
      });

      const targetKey = mapping[fieldId];
      if (targetKey) {
        result[targetKey] = targetValue;
      }

      const { userId, projectId, name, trackerId, contractId } = result;

      addOrUpdateResPlanNewIssue(groupId, rowSocketId, projectId, userId, name, trackerId, contractId);
    },
    [addOrUpdateResPlanNewIssue, apiRef],
  );
  const handleContextMenuOpen = e => {
    e.preventDefault();
    if (!e.currentTarget) {
      return;
    }
    const rowId = e.currentTarget.getAttribute('data-id');
    const rowToDelete = apiRef.current.getRow(rowId);
    if (rowToDelete.services.isNew) {
      const groupId = rowToDelete.groupId;
      const rowSocketId = rowToDelete.rowId;
      setContextMenuPosition({
        top: e.clientY,
        left: e.clientX,
      });
      setContextMenuData({ rowId: rowSocketId, groupId: groupId });
      setIsContextMenuOpen(true);
    }
  };
  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'issue':
        return `${window.location.origin}/issues/${id}`;
      default:
        return '';
    }
  }, []);
  const handleContextMenuClose = () => {
    setIsContextMenuOpen(false);
  };
  const handleDeleteIssue = (groupId, rowId) => {
    handleContextMenuClose();
    deleteResPlanNewIssue(groupId, rowId);
  };
  const getMonthName = useCallback(monthStr => {
    if (monthStr && monthStr.includes('-')) {
      const date = dayjs(monthStr);
      const formattedMonth = date.format('MMM');
      return `${formattedMonth.charAt(0).toUpperCase()}${formattedMonth.slice(1)} ${date.format('YYYY')}`;
    }
    return monthStr;
  }, []);

  const fetchProjectIssueData = async userId => {
    try {
      const newProjects = await getResplanProjects(userId);
      setProjects(newProjects);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchContractIssueData = async projectId => {
    try {
      const newContracts = await getResplanContracts(projectId);
      setContracts(newContracts);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchTrackersIssueData = async () => {
    try {
      const newTrackers = await getResplanTrackers();
      setTrackers(newTrackers);
      console.log(newTrackers);
    } catch (e) {
      console.error(e);
    }
  };
  const cellRendererMonthlyDistribution = useCallback(
    ({ row, column, canEdit }) => {
      return (
        <Box className={classes.dateCell} align="right">
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <>
              <TextField
                type="number"
                value={row[column.fieldId]}
                className={clsx(classes.dateCellField)}
                onChange={e => handleMonthPercentChange(row.id, column.position, column.fieldId, e.target.value)}
                onBlur={e => handleMonthFieldUpdate(row.groupId, row.rowId, column.field, e.target.value)}
                align="right"
                disabled={!canEdit}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.target.blur();
                  }
                }}
              />
            </>
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef, handleMonthPercentChange],
  );
  const cellRendererLoadingPercent = useCallback(
    ({ row, column, canEdit }) => {
      return (
        <Box className={classes.dateCell} align="right">
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <>
              <TextField
                type="number"
                value={row[column.fieldId]}
                className={clsx(classes.dateCellField, classes.dateCellCommentField)}
                onChange={e => handleMonthPercentChange(row.id, column.position, column.fieldId, e.target.value)}
                onBlur={e => {
                  handlePercentFieldUpdate(
                    row.groupId,
                    row.rowId,
                    row.services?.startDate,
                    row.services?.endDate,
                    e.target.value,
                    column.fieldId,
                    e.target.value,
                  );
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.target.blur();
                  }
                }}
                disabled={!canEdit}
                align="right"
              />
            </>
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const cellRendererIssue = useCallback(
    ({ row, column }) => {
      return (
        <Box className={classes.tableCell} align="left">
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <a
              href={handleGetRedirectUrl({
                id: row.services.issueId,
                columnType: 'issue',
              })}
              className={classes.tableCellLink}
              target="_blank"
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row[column.fieldId]}
              </Typography>
            </a>
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const cellRendererDate = useCallback(
    ({ row, column, canEdit }) => {
      return (
        <Box className={classes.tableCell} align="left">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Tooltip title={formatDate(row[column.fieldId])} PopperProps={{ className: classes.commentTooltip }}>
              <>
                <DatePicker
                  views={['day']}
                  // disableMaskedInput
                  className={classes.datePicker}
                  components={{
                    OpenPickerIcon: () => (
                      <CalendarTimesheetIcon
                        style={{ right: 10, width: 16, height: 16 }}
                        viewBox="0 0 16 16"
                        color={canEdit ? '#DCDFF4' : '#ffffff'} // Если canEdit истина, цвет серый, иначе дефолтный цвет
                      />
                    ),
                    LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                    SwitchViewIcon: () => <ChevronIcon direction="down" viewBox="0 0 24 24" width={24} height={24} />,
                    SwitchViewButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    RightArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    LeftArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                  }}
                  PopperProps={{
                    className: classes.datePickerPopper,
                  }}
                  renderInput={params => (
                    <Box display="flex" alignItems="center">
                      <TextField
                        {...params}
                        placeholder=""
                        inputProps={{
                          ...params.inputProps,
                          placeholder: ' ',
                        }}
                        helperText={null}
                        fullWidth
                      />
                    </Box>
                  )}
                  value={row[column.fieldId] || ''}
                  onChange={date => {
                    handleChangeDate(
                      column.position,
                      date,
                      column.fieldId,
                      row.groupId,
                      row.id,
                      row.services?.startDate,
                      row.services?.endDate,
                      row['resourcePlan.loading_percent'],
                      row.rowId,
                    );
                  }}
                  disabled={!canEdit}
                />
              </>
            </Tooltip>
          </LocalizationProvider>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const cellRenderer = useCallback(
    ({ row, column, canEdit }) => {
      let fieldData = row[column.fieldId];
      if (fieldData && column.fieldType === 'date' && typeof fieldData === 'string') {
        fieldData = formatDate(fieldData);
      }
      if (fieldData && (column.fieldType === 'cost_fact' || column.fieldType === 'labor_fact')) {
        fieldData = fieldData.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
      } else if (fieldData === 0 && (column.fieldType === 'cost_fact' || column.fieldType === 'labor_fact')) {
        fieldData = '0,00';
      }
      if (fieldData && (column.fieldType === 'monthly_distribution' || column.fieldType === 'loading_percent')) {
        fieldData = parseFloat(fieldData)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          .replace('.', ',');
      } else if (
        fieldData === 0 &&
        (column.fieldType === 'monthly_distribution' || column.fieldType === 'loading_percent')
      ) {
        fieldData = '0,00';
      }
      if (
        column.fieldType === 'monthly_distribution' ||
        column.fieldType === 'loading_percent' ||
        column.fieldType === 'cost_fact' ||
        column.fieldType === 'labor_fact' ||
        column.fieldType === 'date'
      ) {
        return (
          <Box className={classes.tableCell} align="left">
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {fieldData}
            </Typography>
          </Box>
        );
      } else if (column.fieldType === 'issues') {
        return (
          <Box className={classes.tableCell} align="left">
            <Tooltip title={fieldData} PopperProps={{ className: classes.commentTooltip }}>
              <a
                href={handleGetRedirectUrl({
                  id: row.services.issueId,
                  columnType: 'issue',
                })}
                className={classes.tableCellLink}
                target="_blank"
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {row[column.fieldId]}
                </Typography>
              </a>
            </Tooltip>
          </Box>
        );
      } else {
        return (
          <Box className={classes.tableCell} align="left">
            <Tooltip title={fieldData} PopperProps={{ className: classes.commentTooltip }}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {fieldData}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const cellRendererEditIssue = useCallback(
    ({ row, column }) => {
      return (
        <Box
          key={column.position}
          // className={clsx({
          //   [classes.redBorder]: !row.columns[column.position]?.value,
          //   [classes.tableCell]: row.columns[column.position]?.value,
          // })}
          className={clsx(classes.tableCell)}
          align="left"
        >
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <TextField
              value={row[column.fieldId]}
              className={clsx(classes.tableCellField, classes.tableCellCommentField)}
              onBlur={e => {
                handleIssueBlur(row.id, column.fieldId, e.target.value, row.rowId);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.target.blur();
                } else {
                  e.stopPropagation();
                }
              }}
              onChange={e => handleIssueChange(row.id, column.fieldId, e.target.value)}
              align="left"
            />
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const cellRendererGroup = useCallback(
    ({ row, column }) => {
      const hasIssues = useMemo(() => {
        return resplanColumns?.some(column => column?.fieldId === 'resourcePlan.issue');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [resplanColumns]);
      if (column.position === 0) {
        return (
          <Box>
            {hasIssues && (
              <Tooltip
                title={intl.formatMessage({ id: 'resPlan_add_new_issue' })}
                PopperProps={{ className: classes.commentTooltip }}
                placement="top-start"
              >
                <span style={{ display: 'block', position: 'relative', left: '0' }}>
                  <IconButton
                    disableRipple
                    className={classes.addRowButton}
                    onClick={() => addNewRowAbove(row.id, row.groupId)}
                  >
                    <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {!hasIssues && (
              <Tooltip
                title={intl.formatMessage({ id: 'resPlan_no_issue_column' })}
                PopperProps={{ className: classes.commentTooltip }}
                placement="top-end"
              >
                <span style={{ display: 'block', position: 'relative', left: '0' }}>
                  <IconButton
                    disableRipple
                    className={classes.addRowButton}
                    onClick={() => addNewRowAbove(row.id, row.groupId)}
                    disabled
                  >
                    <PlusIcon width={24} height={24} viewBox="0 0 24 24" color="#E4E4EF" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        );
      }
      if (column.fieldType === 'loading_percent') {
        return (
          <Box align="right" style={{ paddingRight: '10px' }}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
            </Typography>
          </Box>
        );
      }
      if (column.fieldType === 'monthly_distribution') {
        return (
          <Box className={classes.dateCell} align="right">
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row[column.fieldId]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                .replace('.', ',')}
            </Typography>
          </Box>
        );
      }
      return (
        <Box className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row[column.fieldId]}
            </Typography>
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [resplanColumns],
  );
  const cellRendererPerload = useCallback(
    ({ row, column }) => {
      const hasIssues = useMemo(() => {
        return resplanColumns?.some(column => column?.fieldId === 'resourcePlan.issue');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [resplanColumns]);
      if (column.position === 0) {
        return (
          <Box>
            {hasIssues && (
              <IconButton
                disableRipple
                className={classes.addRowButton}
                onClick={() => addNewRowAbove(row.id, row.groupId)}
              >
                <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
              </IconButton>
            )}
            {!hasIssues && (
              <Tooltip
                title={intl.formatMessage({ id: 'resPlan_no_issue_column' })}
                PopperProps={{ className: classes.commentTooltip }}
                placement="top-end"
              >
                <span style={{ display: 'block', position: 'relative', left: '0' }}>
                  <IconButton
                    disableRipple
                    className={classes.addRowButton}
                    onClick={() => addNewRowAbove(row.id, row.groupId)}
                    disabled
                  >
                    <PlusIcon width={24} height={24} viewBox="0 0 24 24" color="#E4E4EF" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>
        );
      }
      if (column.fieldType === 'loading_percent') {
        return (
          <Box align="right" style={{ paddingRight: '10px' }}>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_total" defaultMessage="Total" />
            </Typography>
            <Typography variant="h5" fontWeight={700}>
              <FormattedMessage id="resplan_table_perload" defaultMessage="Perload" />
            </Typography>
          </Box>
        );
      }

      if (column.fieldType === 'monthly_distribution') {
        return (
          <>
            <Box
              className={clsx(
                classes.perloadCell,
                {
                  [classes.redText]: row[column.fieldId] < 0,
                },
                {
                  [classes.blackText]: row[column.fieldId] == 0,
                },
                {
                  [classes.blueText]: row[column.fieldId] > 0,
                },
              )}
              align="right"
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row[`${column.fieldId}.group`]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  .replace('.', ',')}
              </Typography>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {row[column.fieldId]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  .replace('.', ',')}
              </Typography>
            </Box>
          </>
        );
      }
      return (
        <Box className={classes.tableCell} align="left">
          <Tooltip title={row.columns[column.position]?.value} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row[column.fieldId]}
            </Typography>
          </Tooltip>
        </Box>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [resplanColumns],
  );
  const CellRendererUser = props => {
    const { column, row, handleIssueBlur, apiRef } = props;
    if (groupType) {
      return (
        <Box className={classes.tableCell} align="left">
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row[column.fieldId]}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    const [users, setUsers] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newUsers = await getResplanUsers(row.projectId);
          setUsers(newUsers);
        } catch (e) {
          console.error(e);
        }
      };
      fetchData();
    }, [row.projectId]);

    const handleChange = (rowId, columnId, newValue, rowSocketId) => {
      const newUser = users.find(user => user.valueId === newValue);
      apiRef.current.setEditCellValue({ id: rowId, field: columnId, value: newUser.valueName });
      apiRef.current.stopCellEditMode({ id: rowId, field: columnId });
      handleIssueBlur(rowId, columnId, newValue, rowSocketId);
    };

    return (
      <Box key={column.position} className={classes.selectCell}>
        <Select
          IconComponent={() => (
            <Box className={classes.groupIcon}>
              <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
            </Box>
          )}
          MenuProps={{
            className: classes.optionsGroupMenu,
          }}
          variant="standard"
          disableUnderline
          className={classes.createSelect}
          value={row.userId}
          onChange={e => handleChange(row.id, column.fieldId, e.target.value, row.rowId)}
        >
          {users.length === 0 ? (
            <MenuItem value={row.userId} key={0}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                {row[column.fieldId]}
              </Typography>
            </MenuItem>
          ) : (
            users.map(item => (
              <MenuItem value={item.valueId} key={item.valueId}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                  {item.valueName}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </Box>
    );
  };

  const CellRendererTracker = props => {
    const { column, row, handleIssueBlur, apiRef } = props;
    const [trackers, setTrackers] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newTrackers = await getResplanTrackers(row.projectId, row.userId);
          setTrackers(newTrackers);
        } catch (e) {
          console.error(e);
        }
      };
      fetchData();
    }, [row.projectId, row.userId]);

    const handleChange = (rowId, columnId, newValue, rowSocketId) => {
      const newTracker = trackers.find(tracker => tracker.valueId === newValue);
      apiRef.current.setEditCellValue({ id: rowId, field: columnId, value: newTracker.valueName });
      apiRef.current.stopCellEditMode({ id: rowId, field: columnId });
      handleIssueBlur(rowId, columnId, newValue, rowSocketId);
    };

    return (
      <Box key={column.position} className={classes.selectCell}>
        <Select
          IconComponent={() => (
            <Box className={classes.groupIcon}>
              <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
            </Box>
          )}
          MenuProps={{
            className: classes.optionsGroupMenu,
          }}
          variant="standard"
          disableUnderline
          className={classes.createSelect}
          value={row.trackerId}
          onChange={e => handleChange(row.id, column.fieldId, e.target.value, row.rowId)}
        >
          {trackers.length === 0 ? (
            <MenuItem value={row.trackerId} key={0}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                {row[column.fieldId]}
              </Typography>
            </MenuItem>
          ) : (
            trackers.map(item => (
              <MenuItem value={item.valueId} key={item.valueId}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                  {item.valueName}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </Box>
    );
  };
  const CellRendererProject = props => {
    const { column, row, handleIssueBlur, apiRef } = props;
    if (!groupType) {
      return (
        <Box className={classes.tableCell} align="left">
          <Tooltip title={row[column.fieldId]} PopperProps={{ className: classes.commentTooltip }}>
            <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
              {row[column.fieldId]}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    const [projects, setProjects] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newProjects = await getResplanProjects(row.userId);
          setProjects(newProjects);
        } catch (e) {
          console.error(e);
        }
      };
      fetchData();
    }, [row.userId]);

    const handleChange = (rowId, columnId, newValue, rowSocketId) => {
      const newProject = projects.find(project => project.valueId === newValue);
      apiRef.current.setEditCellValue({ id: rowId, field: columnId, value: newProject.valueName });
      apiRef.current.stopCellEditMode({ id: rowId, field: columnId });
      handleIssueBlur(rowId, columnId, newValue, rowSocketId);
    };

    return (
      <Box key={column.position} className={classes.selectCell}>
        <Select
          IconComponent={() => (
            <Box className={classes.groupIcon}>
              <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
            </Box>
          )}
          MenuProps={{
            className: classes.optionsGroupMenu,
          }}
          variant="standard"
          disableUnderline
          className={classes.createSelect}
          value={row.projectId}
          onChange={e => handleChange(row.id, column.fieldId, e.target.value, row.rowId)}
        >
          {projects.length === 0 ? (
            <MenuItem value={row.projectId} key={0}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                {row[column.fieldId]}
              </Typography>
            </MenuItem>
          ) : (
            projects.map(item => (
              <MenuItem value={item.valueId} key={item.valueId}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                  {item.valueName}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </Box>
    );
  };
  const CellRendererContract = props => {
    const { column, row, handleIssueBlur, apiRef } = props;
    const [contracts, setContracts] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const newContracts = await getResplanContracts(row.projectId);
          setContracts(newContracts);
        } catch (e) {
          console.error(e);
        }
      };
      fetchData();
    }, [row.projectId]);

    const handleChange = (rowId, columnId, newValue, rowSocketId) => {
      const newContract = contracts.find(contract => contract.valueId === newValue);
      apiRef.current.setEditCellValue({ id: rowId, field: columnId, value: newContract.valueName });
      apiRef.current.stopCellEditMode({ id: rowId, field: columnId });
      handleIssueBlur(rowId, columnId, newValue, rowSocketId);
    };

    return (
      <Box key={column.position} className={classes.selectCell}>
        <Select
          IconComponent={() => (
            <Box className={classes.groupIcon}>
              <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
            </Box>
          )}
          MenuProps={{
            className: classes.optionsGroupMenu,
          }}
          variant="standard"
          disableUnderline
          className={classes.createSelect}
          value={row.contractId}
          onChange={e => handleChange(row.id, column.fieldId, e.target.value, row.rowId)}
        >
          {contracts.length === 0 ? (
            <MenuItem value={row.contractId} key={0}>
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                {row[column.fieldId]}
              </Typography>
            </MenuItem>
          ) : (
            contracts.map(item => (
              <MenuItem value={item.valueId} key={item.valueId}>
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5" color="#212346">
                  {item.valueName}
                </Typography>
              </MenuItem>
            ))
          )}
        </Select>
      </Box>
    );
  };

  const formattedResplanColumns = useCallback(
    () =>
      resplanColumns.map((column, index) => {
        let newColumn = {
          field: column.fieldId,
          headerName: getTranslatedText(intl, '', `resPlan_${column.field}`, column.fieldName),
          renderHeader: props => {
            if (column.fieldType === 'monthly_distribution') {
              return (
                <Box rowSpan={2} className={classes.tableCellHead} align="center">
                  <Typography variant="h4" fontWeight={700} style={{ marginBottom: '-6px' }}>
                    {getMonthName(props?.colDef?.headerName)}
                  </Typography>
                  <Typography variant="h5" fontWeight={400} style={{ marginTop: '-6px' }}>
                    {column.hours}
                  </Typography>
                </Box>
              );
            }
            return (
              <Box className={classes.tableCellHead} align="center">
                <Typography variant="h4" fontWeight={700}>
                  {props?.colDef?.headerName}
                </Typography>
              </Box>
            );
          },
          disableExport: false,
          sortable: false,
          position: column.position,
          disableReorder: index < 2,
          align:
            column.fieldType === 'loading_percent' ||
            column.fieldType === 'monthly_distribution' ||
            column.fieldType === 'labor_fact' ||
            column.fieldType === 'cost_fact'
              ? 'right'
              : 'left',
          cellClassName: params =>
            clsx(classes.tableCell, {
              [classes.blockedText]:
                params.row?.rowType !== 'group' &&
                params.row?.rowType !== 'perload' &&
                !params.row?.services?.canEdit &&
                (column.fieldType === 'monthly_distribution' ||
                  column.fieldType === 'loading_percent' ||
                  column.fieldType === 'date'),
              [classes.redBorder]:
                params.row?.columns[column.position]?.isIncorrect ||
                (params.row.columns[column.position]?.fieldId === 'resourcePlan.issue' &&
                  !params.row.columns[column.position]?.value &&
                  showIssuesCheck),
            }),
          headerAlign: 'center',
          flex: 1,
          editable:
            column.fieldType === 'monthly_distribution' ||
            column.fieldType === 'loading_percent' ||
            column.fieldType === 'issues' ||
            column.fieldType === 'user' ||
            column.fieldType === 'project' ||
            column.fieldType === 'tracker' ||
            column.fieldType === 'contract' ||
            column.fieldType === 'date'
              ? true
              : false,
          renderCell: props => {
            const canEdit = props.row?.services?.canEdit;
            const row = props.row;
            if (row.rowType === 'group') {
              return cellRendererGroup({ row, column, canEdit });
            } else if (row.rowType === 'perload') {
              return cellRendererPerload({ row, column, canEdit });
            } else {
              return cellRenderer({ row, column, canEdit });
            }
          },
          renderEditCell: props => {
            const canEdit = props.row?.services?.canEdit;
            const row = props.row;
            if (row.rowType === 'group') {
              return cellRendererGroup({ row, column, canEdit });
            } else if (row.rowType === 'perload') {
              return cellRendererPerload({ row, column, canEdit });
            } else if (row.services.isNew === true) {
              switch (column.fieldType) {
                case 'monthly_distribution':
                  return cellRendererMonthlyDistribution({ row, column, canEdit });
                case 'loading_percent':
                  return cellRendererLoadingPercent({ row, column, canEdit });
                case 'issues':
                  return cellRendererEditIssue({ row, column, canEdit });
                case 'date':
                  return cellRendererDate({ row, column, canEdit });
                case 'user':
                  return (
                    <CellRendererUser
                      row={row}
                      column={column}
                      canEdit={canEdit}
                      handleIssueBlur={handleIssueBlur}
                      apiRef={apiRef}
                    />
                  );
                case 'tracker':
                  return (
                    <CellRendererTracker
                      row={row}
                      column={column}
                      canEdit={canEdit}
                      handleIssueBlur={handleIssueBlur}
                      apiRef={apiRef}
                    />
                  );
                case 'contract':
                  return (
                    <CellRendererContract
                      row={row}
                      column={column}
                      canEdit={canEdit}
                      handleIssueBlur={handleIssueBlur}
                      apiRef={apiRef}
                    />
                  );
                case 'project':
                  return (
                    <CellRendererProject
                      row={row}
                      column={column}
                      canEdit={canEdit}
                      handleIssueBlur={handleIssueBlur}
                      apiRef={apiRef}
                    />
                  );
                default:
                  return cellRenderer({ row, column, canEdit });
              }
            } else {
              switch (column.fieldType) {
                case 'monthly_distribution':
                  return cellRendererMonthlyDistribution({ row, column, canEdit });
                case 'loading_percent':
                  return cellRendererLoadingPercent({ row, column, canEdit });
                case 'issues':
                  return cellRendererIssue({ row, column, canEdit });
                case 'date':
                  return cellRendererDate({ row, column, canEdit });
                default:
                  return cellRenderer({ row, column, canEdit });
              }
            }
          },
        };
        return newColumn;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      resplanColumns,
      intl,
      classes,
      getMonthName,
      handleMonthPercentChange,
      handleMonthFieldUpdate,
      handlePercentFieldUpdate,
      handleChangeDate,
      addNewRowAbove,
      groupType,
      getProjectUserId,
      handleIssueChange,
      handleIssueBlur,
      incorrectDates,
      showIssuesCheck,
    ],
  );

  const handleAddIssue = React.useCallback(() => {
    if (resplanNewIssue?.length > 0) {
      const rowIds = apiRef?.current.getAllRowIds();
      let firstPartIds = [];
      let secondPartIds = [];
      const firstRowId = resplanNewIssue[0].id;
      const lastRowId = resplanNewIssue[resplanNewIssue.length - 1].id;
      let firstRowsdata = [];
      let secondRowsdata = [];
      const firstIndex = rowIds.indexOf(firstRowId);
      const lastIndex = rowIds.indexOf(lastRowId);

      if (firstIndex !== -1 && lastIndex !== -1) {
        firstPartIds = rowIds.slice(0, firstIndex);
        secondPartIds = rowIds.slice(lastIndex + 1);
      }
      firstPartIds.forEach(id => {
        const rowData = apiRef.current.getRow(id);
        firstRowsdata.push(rowData);
      });
      secondPartIds.forEach(id => {
        const rowData = apiRef.current.getRow(id);
        secondRowsdata.push(rowData);
      });
      const updatedRowsData = firstRowsdata.concat(resplanNewIssue, secondRowsdata);
      apiRef.current.setRows(updatedRowsData);
    }
  }, [apiRef, resplanNewIssue]);
  useEffect(() => {
    if (resplanNewIssue?.length > 0) {
      handleAddIssue();
    }
  }, [resplanNewIssue, handleAddIssue]);

  const handleUpdateRows = React.useCallback(() => {
    if (resplanRowsToUpdate?.length > 0) {
      apiRef?.current.updateRows(resplanRowsToUpdate);
    }
  }, [apiRef, resplanRowsToUpdate]);

  useEffect(() => {
    if (resplanRowsToUpdate?.length > 0) {
      handleUpdateRows();
    }
  }, [resplanRowsToUpdate, handleUpdateRows]);

  useEffect(() => {
    if (resplanStopLoading) {
      setResplanRowsR(resplanFields?.rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resplanStopLoading, resplanFields]);

  useEffect(() => {
    if (resplanColumns?.length > 1) {
      setResplanColumnsformatted(formattedResplanColumns(resplanColumns));
      setIncorrectDates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resplanColumns, showIssuesCheck]);
  useEffect(() => {
    const checkGroupType = fetchedQuery.includes('resourcePlan.group_by;eq;1');
    setgGroupType(checkGroupType);
  }, [setgGroupType, fetchedQuery]);
  useEffect(() => {
    if (resplanNewIssue?.length > 0) {
      handleAddIssue();
      setIncorrectDates([]);
    }
  }, [resplanNewIssue, handleAddIssue, setIncorrectDates]);

  const getNoRowsText = () => {
    if (!hasResplanRows && resplanStopLoading && resplanRowsR.length === 0) {
      return { noRowsLabel: intl.formatMessage({ id: 'resplan_no_data' }) };
    }
    return { noRowsLabel: intl.formatMessage({ id: 'resPlan_is_loading' }) };
  };

  return (
    <>
      {isContextMenuOpen && (
        <Menu
          open={isContextMenuOpen}
          onClose={handleContextMenuClose}
          onBlur={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenuPosition.top, left: contextMenuPosition.left }}
          className={classes.contextMenuWrapper}
        >
          <MenuItem
            className={classes.contextMenuItem}
            onClick={() => handleDeleteIssue(contextMenuData.rowId, contextMenuData.groupId)}
          >
            <FormattedMessage id="cancel" />
          </MenuItem>
        </Menu>
      )}
      <DataGridPro
        autoHeight
        hideFooter
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnMenu
        className={classes.ResplanTableWrap}
        apiRef={apiRef}
        rows={resplanRowsR}
        columns={resplanColumnsformatted}
        getRowHeight={getRowHeight}
        // loading={!resplanStopLoading}
        isCellEditable={params => {
          if (incorrectDates?.length != 0) {
            if (
              (params?.row?.isIncorrect === 'resourcePlan.date_from' ||
                params?.row?.isIncorrect === 'resourcePlan.date_to') &&
              params?.field === params?.row?.isIncorrect
            ) {
              return true;
            }
            return false;
          } else {
            return params?.row?.services?.canEdit;
          }
        }}
        onCellClick={(params, event) => {
          if (params.isEditable && params.cellMode === 'view') {
            event.defaultMuiPrevented = true;
            apiRef.current.startCellEditMode({ id: params.id, field: params.field });
          }
        }}
        slots={{
          columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
          NoRowsOverlay: () => null,
        }}
        slotProps={{
          row: {
            onContextMenu: e => handleContextMenuOpen(e),
            style: { cursor: 'context-menu' },
          },
        }}
        localeText={getNoRowsText()}
      />
    </>
  );
};

export default injectIntl(ResourcePlanDatagrid);
