import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Popover, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import useStyles from './MenuItem.styles';
import clsx from 'clsx';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import {
  getIssuesSavedQueries,
  getIssuesSavedQueriesByProject,
  getSupersetToken,
  getTimesheetSavedQueries,
  getAgileSavedQueriesByProject,
  getConfirmationsSavedQueries,
} from '../../../../packages/common/api';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import { useQueryParams } from 'use-query-params';
import { store } from '../../app/redux';

const MenuItem = ({
  item,
  itemName,
  itemIcon,
  path,
  closeDrawer,
  redirectToExternalUrl,
  redirectToAnotherUrl,
  isDrawerOpen,
  currentItem,
}) => {
  const [query] = useQueryParams({
    savedQueryId: '',
  });
  const [dropdownListStatus, setDropdownListStatus] = useState(item?.isDropdown || false);
  const [isChecked, setIsChecked] = useState(item?.isDropdown || false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const classes = useStyles();
  const intl = useIntl();
  const state = store.getState();
  const [openTooltip, setOpenTooltip] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const open = Boolean(anchorEl);
  useEffect(() => {
    if (window.location.pathname === path) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    setDropdownListStatus(false);
    setAnchorEl(null);
  }, [isDrawerOpen, path]);

  const handleChangeStatus = useCallback(
    async e => {
      e.stopPropagation();
      if (!isSmallScreen) {
        setAnchorEl(e.currentTarget);
        if (item.lang_key === 'menu_item_work_costs') {
          setIsLoading(true);
          try {
            const { data: queries } = await getTimesheetSavedQueries();
            const projectQuery =
              typeof item.projectId != 'undefined' ? `?projectId=${item.projectId}&locked=timesheet.project` : '';
            item.dropdown = queries.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/spentTime/query/${currentQuery.id}${projectQuery}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_tasks') {
          setIsLoading(true);
          try {
            let queries = '';
            if (item.projectId) {
              queries = await getIssuesSavedQueriesByProject(item.projectId);
            } else {
              queries = await getIssuesSavedQueries(item.projectId);
            }

            const projectQuery =
              typeof item.projectId != 'undefined' ? `?projectId=${item.projectId}&locked=issue.project` : '';
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/issues/query/${currentQuery.id}${projectQuery}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_kanban') {
          setIsLoading(true);
          try {
            const queries = await getAgileSavedQueriesByProject(item.projectId);
            const projectQuery = `?projectId=${item.projectId}&locked=issue.project`;
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/agile/query/${currentQuery.id}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_confirmations') {
          setIsLoading(true);
          try {
            const queries = await getConfirmationsSavedQueries();
            item.dropdown = queries.data.map(currentQuery => {
              return {
                name: currentQuery.name,
                path: `/confirmations/query/${currentQuery.id}`,
                isChecked: Number(query.savedQueryId) === Number(currentQuery.id),
                isItemVisible: true,
              };
            });
          } catch (error) {
            console.error('ERROR WITH FETCH SAVED QUERIES', error);
          } finally {
            setIsLoading(false);
          }
        } else if (item.lang_key === 'menu_item_project_reports') {
          const supersetToken = await getSupersetToken();
          item.dropdown = state.user.project?.reports.map(query => {
            const reportUrl = new URL(query.url);
            reportUrl.searchParams.append('token', supersetToken.data);
            return { isChecked: false, isItemVisible: true, name: query.name, path: reportUrl, externalLink: true };
          });
        }
      }
      setDropdownListStatus(prevState => !prevState);
    },
    [isSmallScreen, item, query.savedQueryId, state],
  );

  const closeSubItemsMenu = useCallback(() => {
    setAnchorEl(null);
    setDropdownListStatus(false);
  }, []);

  return (
    <>
      {item.path ? (
        <Box width="100%" overflow="hidden">
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            xs={12}
            className={clsx(classes.MenuItemRoot, {
              [classes.dropdownItem]: dropdownListStatus || isChecked,
              [classes.projectItem]: item.projectItem,
            })}
          >
            {itemIcon && <Box className={classes.menuItemIcon}>{itemIcon}</Box>}
            {process.env.NODE_ENV === 'development' ? (
              <Link to={path} onClick={closeDrawer}>
                <Typography variant="h3" fontWeight={600}>
                  {getTranslatedText(intl, '', itemName, itemName)}
                </Typography>
              </Link>
            ) : (
              <Box className={classes.anotherUrlLink} onClick={_ => redirectToAnotherUrl(path)}>
                <Typography variant="h3" fontWeight={600}>
                  {getTranslatedText(intl, '', itemName, itemName)}
                </Typography>
              </Box>
            )}
            {item.dropdown && item.isDropdownVisible && (
              <Box
                onClick={item.hasOwnProperty('isDropdown') ? handleChangeStatus : null}
                className={clsx(classes.menuItemIcon, classes.dropdownIcon, { [classes.rotate]: dropdownListStatus })}
              >
                <ChevronIcon direction={isSmallScreen ? 'down' : 'right'} viewBox="0 0 24 24" />
              </Box>
            )}
          </Grid>
        </Box>
      ) : (
        <Box onClick={handleChangeStatus} className={classes.dropdownMenu}>
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            xs={12}
            className={clsx(classes.MenuItemRoot, { [classes.dropdownItem]: dropdownListStatus })}
          >
            {item.isDropdownVisible && (
              <Box className={clsx(classes.menuItemIcon, { [classes.rotate]: dropdownListStatus })}>{itemIcon}</Box>
            )}
            <Typography variant="h3" fontWeight={600}>
              {getTranslatedText(intl, '', itemName, itemName)}
            </Typography>
            {item.dropdown && item.isDropdownVisible && (
              <Box
                onClick={item.hasOwnProperty('isDropdown') ? handleChangeStatus : null}
                className={clsx(classes.menuItemIcon, classes.dropdownIcon, { [classes.rotate]: dropdownListStatus })}
              >
                <ChevronIcon direction={isSmallScreen ? 'down' : 'right'} viewBox="0 0 24 24" />
              </Box>
            )}
          </Grid>
        </Box>
      )}
      {isSmallScreen ? (
        dropdownListStatus &&
        item.isDropdownVisible &&
        item?.dropdown &&
        item.dropdown.map(item => {
          return (
            <Tooltip
              title={getTranslatedText(intl, '', item.name, item.name)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Box minHeight={32} key={item.path} className={classes.dropdownItem}>
                <Link onClick={closeDrawer} to={item.path} className={clsx({ [classes.itemLink]: currentItem })}>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    alignItems="center"
                    xs={12}
                    className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                  >
                    <Typography variant="h4">{getTranslatedText(intl, '', item.name, item.name)}</Typography>
                  </Grid>
                </Link>
              </Box>
            </Tooltip>
          );
        })
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={closeSubItemsMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            className: classes.menuSubItemsPaper,
          }}
          className={classes.menuSubItems}
        >
          {dropdownListStatus &&
            item.isDropdownVisible &&
            item?.dropdown &&
            !isLoading &&
            item.dropdown.map(item => {
              return (
                item.isItemVisible && (
                  <Box
                    height={32}
                    bgcolor={item.isChecked && '#DCDFF4'}
                    key={item.path}
                    onMouseOver={() => setOpenTooltip({ [item.path]: true })}
                    onMouseLeave={() => setOpenTooltip('')}
                  >
                    <Tooltip
                      title={getTranslatedText(intl, '', item.name, item.name)}
                      open={openTooltip[item.path] ? openTooltip[item.path] : false}
                      PopperProps={{ className: classes.commentTooltip }}
                      style={{ zIndex: 10000 }}
                    >
                      {process.env.NODE_ENV === 'development' ? (
                        <Box>
                          {item.externalLink ? (
                            <Box className={classes.anotherUrlLink}>
                              <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                xs={12}
                                className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                              >
                                <Typography variant="h4">
                                  <a href={item.path} target={'_blank'}>
                                    {getTranslatedText(intl, '', item.name, item.name)}
                                  </a>
                                </Typography>
                              </Grid>
                            </Box>
                          ) : (
                            <Link onClick={closeDrawer} to={item.path} className={{ [classes.itemLink]: currentItem }}>
                              <Grid
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                xs={12}
                                className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                              >
                                <Typography variant="h4">
                                  {getTranslatedText(intl, '', item.name, item.name)}
                                </Typography>
                              </Grid>
                            </Link>
                          )}
                        </Box>
                      ) : (
                        <Box className={classes.anotherUrlLink} onClick={_ => redirectToAnotherUrl(item.path)}>
                          <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            xs={12}
                            className={clsx(classes.MenuItemRoot, classes.menuSubItem)}
                          >
                            <Typography variant="h4">{getTranslatedText(intl, '', item.name, item.name)}</Typography>
                          </Grid>
                        </Box>
                      )}
                    </Tooltip>
                  </Box>
                )
              );
            })}
        </Popover>
      )}
    </>
  );
};

export default MenuItem;
