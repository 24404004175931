import React from 'react';
import { SvgIcon } from '@mui/material';

function NotificationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10.01 20.01C10.01 21.11 10.9 22 12 22C13.1 22 13.99 21.11 13.99 20.01H10.01ZM18.88 15.82V10C18.88 6.75 16.63 4.03 13.59 3.31V2.59C13.59 1.71 12.88 1 12 1C11.12 1 10.41 1.71 10.41 2.59V3.31C7.37 4.03 5.12 6.75 5.12 10V15.82L3 17.94V19H21V17.94L18.88 15.82Z" />
    </SvgIcon>
  );
}

export default NotificationIcon;
