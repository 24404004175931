import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

function Page({ title, children, ...rest }) {
  return (
    <Box {...rest}>
      <Helmet>
        <title>{title} | X-Lance</title>
      </Helmet>
      {children}
    </Box>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Page;
