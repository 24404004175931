export default {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: {
    xs: 350,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1600,
  },
  maxWidth(key) {
    const value = this.values[key] || key;
    return `@media (max-width: ${value}px)`;
  },
  minWidth(key) {
    const value = this.values[key] || key;
    return `@media (min-width: ${value}px)`;
  },
};
