import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import useStyles from './WarningModal.styles';

const WarningModal = ({ simple, isWarningModalOpen, closeWarningModal, handleToggle, title, issue }) => {
  const classes = useStyles();
  return (
    <Modal open={isWarningModalOpen} className={classes.calendarWarningRoot} disableRestoreFocus>
      <Grid container direction="column" className={issue ? classes.issueWarningPaper : classes.calendarWarningPaper}>
        <Grid container justifyContent="flex-end">
          <IconButton disableRipple onClick={closeWarningModal} value="close" sx={{ padding: issue ? 0 : '8px' }}>
            <Box width={24} height={24}>
              <ClearIcon viewBox="0 0 24 24" />
            </Box>
          </IconButton>
        </Grid>
        <Grid container marginTop={issue ? '40px' : 5} marginBottom={issue ? '40px' : 5}>
          <Typography
            variant={issue ? 'h3' : 'h1'}
            textAlign="center"
            width={simple ? '100%' : ''}
            fontWeight={issue ? '600' : '400'}
          >
            {title}
          </Typography>
        </Grid>
        <Grid container justifyContent={issue ? 'center' : 'space-around'} marginBottom={issue ? 0 : 5}>
          <Button
            disableRipple
            variant="defaultBluePrimary"
            value="save"
            onClick={handleToggle}
            sx={{ width: issue ? '100px' : 'auto' }}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="yes" />
            </Typography>
          </Button>
          <Button
            sx={{ marginLeft: issue ? '4px' : 0, width: issue ? '100px' : 'auto' }}
            disableRipple
            variant={issue ? 'defaultGreyPrimary' : 'defaultBluePrimary'}
            value="delete"
            onClick={handleToggle}
          >
            <Typography variant="h4" fontWeight={700}>
              <FormattedMessage id="no" />
            </Typography>
          </Button>
          {!simple && (
            <Button disableRipple variant="defaultBluePrimary" value="cancel" onClick={handleToggle}>
              <Typography variant="h4" fontWeight={700}>
                <FormattedMessage id="cancel" />
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WarningModal;
