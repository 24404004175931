import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import {
  deleteIssueContractStage,
  getIssueContractStage,
  getIssueContractStages,
  getIssueCreateContractStages,
  saveEditIssueContractStage,
  saveIssueContractStage,
} from '../../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import userPageStyles from '../../../administration/userPage/UserPage.styles';
import SortIcon from '../../../../../packages/common/shared-ui/src/icons/SortIcon';
import { DataGridPro, GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro';
import issuePageStyles from '../../IssuePage.styles';
import { getTranslatedText } from '../../../../../packages/common/utils/getTranslatedText';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import UserNewEditIcon from '../../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import issueFormFieldStyles from '../../../issueCreateEdit/components/IssueFormField.styles';

const IssueContractStages = ({ intl }) => {
  const params = useParams();
  const commonClasses = userPageStyles();
  const issueCommonStyles = issuePageStyles();
  const issueCreateEditClasses = issueFormFieldStyles();
  const [loading, setLoading] = useState(true);
  const [contractStagesColumns, setContractStagesColumns] = useState([]);
  const [contractStagesRows, setContractStagesRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function getContractStages() {
      const { data: contractStages } = await getIssueContractStages(params.id);
      if (!ignore) {
        return contractStages;
      }
    }
    getContractStages()
      .then(res => {
        const contractStagesRowsValue = formattedContractStagesRows(res.rows.filter(item => item.rowId !== 'total'));
        setContractStagesRows(contractStagesRowsValue);
        setContractStagesColumns(res.fields);
        setLoading(false);
      })
      .catch(error => console.error(error, 'ERROR IN GET'));
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleTextField = useCallback(
    (event, id) => {
      const index = contractStagesRows.findIndex(item => item.id === id);

      const updatedObject = { ...contractStagesRows[index], comment: event.target.value };
      setContractStagesRows([
        ...contractStagesRows.slice(0, index),
        updatedObject,
        ...contractStagesRows.slice(index + 1),
      ]);
    },
    [contractStagesRows],
  );
  const handleSelect = useCallback(
    (event, id) => {
      const index = contractStagesRows.findIndex(item => item.id === id);
      const updatedObject = { ...contractStagesRows[index], stageId: event.target.value };
      setContractStagesRows([
        ...contractStagesRows.slice(0, index),
        updatedObject,
        ...contractStagesRows.slice(index + 1),
      ]);
    },
    [contractStagesRows],
  );
  const dateHandler = useCallback(
    (value, id) => {
      const index = contractStagesRows.findIndex(item => item.id === id);
      const updatedObject = {
        ...contractStagesRows[index],
        startDate: value && value.$d ? dayjs(new Date(value.$d)) : dayjs(new Date()),
      };
      setContractStagesRows([
        ...contractStagesRows.slice(0, index),
        updatedObject,
        ...contractStagesRows.slice(index + 1),
      ]);
    },
    [contractStagesRows],
  );

  const handleEditClick = useCallback(
    async id => {
      const index = contractStagesRows.findIndex(item => item.id === id);
      try {
        const { data: response } = await getIssueContractStage(id);
        if (response) {
          const updatedObject = {
            ...response,
            id: id,
            ['contract_stage.customer']: response.customerName,
            ['contract_stage.author']: response.authorName,
            ['contract_stage.stage']: response.stageName,
            ['contract_stage.start_date']: response.startDate,
            ['contract_stage.comment']: response.comment,
          };
          setContractStagesRows([
            ...contractStagesRows.slice(0, index),
            updatedObject,
            ...contractStagesRows.slice(index + 1),
          ]);
          setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {
              mode: GridRowModes.Edit,
            },
          }));
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [contractStagesRows],
  );
  const validateRow = useCallback(
    index => {
      if (!contractStagesRows[index].stageId) {
        const updatedObject = {
          ...contractStagesRows[index],
          error: true,
        };
        setContractStagesRows([
          ...contractStagesRows.slice(0, index),
          updatedObject,
          ...contractStagesRows.slice(index + 1),
        ]);
        setError(true);
        return false;
      } else {
        setError(false);
        return true;
      }
    },
    [contractStagesRows],
  );
  const handleSaveClick = useCallback(
    async (id, row) => {
      const index = contractStagesRows.findIndex(item => item.id === id);
      const validation = validateRow(index);
      if (!validation) {
        return;
      }
      try {
        let response;
        if (rowModesModel[id].mode === 'edit' && !row.new) {
          const data = {
            stageId: Number(row.stageId),
            startDate: dayjs(row.startDate).format('YYYY-MM-DD'),
            comment: row.comment,
          };
          response = await saveEditIssueContractStage(id, data);
        }
        if (row.new) {
          const data = {
            issueId: row.issueId,
            customerId: row.customerId,
            authorId: row.authorId,
            stageId: Number(row.stageId),
            startDate: dayjs(row.startDate ? row.startDate : row['contract_stage.start_date']).format('YYYY-MM-DD'),
            comment: row.comment,
          };
          response = await saveIssueContractStage(data);
        }
        if (response) {
          const { data: res } = response;
          const updatedObject = {
            ...res,
            id: res.id,
            ['contract_stage.customer']: res.customerName,
            ['contract_stage.author']: res.authorName,
            ['contract_stage.stage']: res.stageName,
            ['contract_stage.start_date']: dayjs(res.startDate).format('DD.MM.YYYY'),
            ['contract_stage.comment']: res.comment,
          };
          setContractStagesRows([
            ...contractStagesRows.slice(0, index),
            updatedObject,
            ...contractStagesRows.slice(index + 1),
          ]);
          setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [contractStagesRows, rowModesModel, validateRow],
  );

  const handleDelete = useCallback(
    async (id, row) => {
      if (row.new) {
        const newArray = contractStagesRows.filter(item => item.id !== row.id);
        setContractStagesRows(newArray);
        return;
      }
      try {
        const response = await deleteIssueContractStage(id);
        if (response) {
          setContractStagesRows(contractStagesRows.filter(row => row.id !== id));
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [contractStagesRows],
  );

  const formattedContractStagesColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    const editableColumns = ['stage', 'start_date', 'comment'];
    const columns = contractStagesColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'issue', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 150,
        cellClassName: props =>
          props.field === 'users.admin'
            ? 'tableCell textCenter'
            : props.field === 'contract_stage.stage'
            ? 'tableCell select'
            : 'tableCell',
        editable: editableColumns.includes(column.field) && true,
        renderCell: props => {
          let showTooltip = false;
          const el = document.getElementById(props.id + props.field);
          if (el && el.offsetWidth > props.colDef.computedWidth) {
            showTooltip = true;
          }
          if (props.field === 'contract_stage.comment') {
            return (
              <Box id={props.id + props.field}>
                <Box visibility={'hidden'} height={0}>
                  {props.value}
                </Box>
                {showTooltip && (
                  <Tooltip
                    PopperProps={{ className: issueCreateEditClasses.tooltipForm }}
                    placement="top-start"
                    title={props.value}
                  >
                    <Box
                      sx={{
                        width: `${props.colDef.computedWidth - 20}px`,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {props.value}
                    </Box>
                  </Tooltip>
                )}
                {!showTooltip && <Box component={'span'}>{props.value}</Box>}
              </Box>
            );
          }
        },
        renderEditCell: props => {
          if (props.field === 'contract_stage.stage') {
            return (
              <FormControl sx={{ width: '100%' }} error={props.row.error}>
                <InputLabel className={issueCommonStyles.selectLabel} id="select-label">
                  {intl.formatMessage({ id: 'choose' })}
                </InputLabel>
                <Select
                  native={false}
                  labelId="select-label"
                  label={intl.formatMessage({ id: 'choose' })}
                  variant="standard"
                  sx={{ width: '100%', border: 'none' }}
                  className={issueCommonStyles.rowSelect}
                  MenuProps={{ classes: { paper: issueCommonStyles.paperDropdown } }}
                  IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                  value={props.row.stageId ? props.row.stageId : ''}
                  renderValue={value => {
                    return props.row.stages.find(stage => stage.valueId === value.toString()).valueName;
                  }}
                  onChange={event => handleSelect(event, props.row.id)}
                >
                  {props?.row?.stages.map((item, index) => (
                    <MenuItem key={index} value={item.valueId}>
                      {item.valueName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
          if (props.field === 'contract_stage.start_date') {
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={issueCommonStyles.datePicker}
                  format={'DD.MM.YYYY'}
                  value={props.row.startDate ? props.row.startDate : props.row['contract_stage.start_date']}
                  renderInput={params => <TextField {...params} error={false} />}
                  autoFocus={true}
                  onChange={value => dateHandler(value, props.row.id)}
                  components={{
                    LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowIcon: () => <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />,
                    RightArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                    LeftArrowButton: componentProps => (
                      <IconButton disableRipple {...componentProps}>
                        {componentProps.children}
                      </IconButton>
                    ),
                  }}
                />
              </LocalizationProvider>
            );
          }
          if (props.field === 'contract_stage.comment') {
            return (
              <TextField
                autoFocus
                className={issueCommonStyles.rowInput}
                placeholder={intl.formatMessage({ id: 'enter' })}
                variant="standard"
                defaultValue={props.row.comment ? props.row.comment : ''}
                onChange={event => handleTextField(event, props.row.id)}
              ></TextField>
            );
          }
        },
      };
    });
    return [
      ...columns,
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        headerClassName: 'actions',
        width: 70,
        cellClassName: 'actions tableCell',
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem onClick={() => handleSaveClick(id, row)} icon={<SubmitIcon />} label="Save" />,
              <GridActionsCellItem onClick={() => handleDelete(id, row)} icon={<DeleteIconBig />} label="Cancel" />,
            ];
          }
          return [
            <GridActionsCellItem
              onClick={() => handleEditClick(id)}
              icon={<UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" />}
              label="Edit"
            />,
            <GridActionsCellItem
              onClick={() => handleDelete(id, row)}
              icon={<DeleteIconBig />}
              label="Delete"
              color="inherit"
            />,
          ];
        },
      },
    ];
  }, [
    contractStagesColumns,
    dateHandler,
    handleDelete,
    handleEditClick,
    handleSaveClick,
    handleSelect,
    handleTextField,
    intl,
    issueCommonStyles.datePicker,
    issueCommonStyles.paperDropdown,
    issueCommonStyles.rowInput,
    issueCommonStyles.rowSelect,
    issueCommonStyles.selectLabel,
    issueCreateEditClasses.tooltipForm,
    rowModesModel,
  ]);

  const contractStagesColumnsValue = formattedContractStagesColumns();

  const formattedContractStagesRows = useCallback(
    data =>
      data.reduce((acc, row) => {
        const newCols = row.columns.reduce((columnAcc, _, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
          };
        }, {});
        return [...acc, { ...newCols, id: row.rowId }];
      }, []),
    [],
  );

  const addRowHandler = async () => {
    try {
      const { data: response } = await getIssueCreateContractStages(params.id);
      if (response) {
        setContractStagesRows(oldRows => [
          ...oldRows,
          {
            new: true,
            id: `${response.issueId}-row${contractStagesRows.length + 1}`,
            ...response,
            ['contract_stage.customer']: response.customerName,
            ['contract_stage.author']: response.authorName,
            ['contract_stage.start_date']: dayjs(new Date()),
          },
        ]);
        setRowModesModel(oldModel => ({
          ...oldModel,
          [`${response.issueId}-row${contractStagesRows.length + 1}`]: {
            mode: GridRowModes.Edit,
            fieldToFocus: 'contract_stage.customer',
          },
        }));
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  };

  const handleRowModesModelChange = useCallback(newRowModesModel => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setContractStagesRows(contractStagesRows.map(row => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    },
    [contractStagesRows],
  );
  const handleCellDoubleClick = useCallback(event => {
    event.stopPropagation();
  }, []);
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Typography variant="h3" color={'#082253'} fontWeight={600}>
            {intl.formatMessage({ id: 'issue_page_contract_stages_title' })}
          </Typography>
          <Grid container direction={'column'} marginTop={'18px'} sx={{ position: 'relative' }} maxWidth={'100%'}>
            {error && (
              <Box sx={{ position: 'absolute', top: '-20px', left: 0 }}>
                <Typography variant="h3" color={'#E03737'}>
                  {intl.formatMessage({ id: 'issue_page_error' })}
                </Typography>
              </Box>
            )}
            <Grid item width={'100%'}>
              <DataGridPro
                onRowDoubleClick={(params, event) => handleCellDoubleClick(event)}
                onCellDoubleClick={(params, event) => handleCellDoubleClick(event)}
                autoHeight
                hideFooter
                disableRowSelectionOnClick
                disableColumnMenu
                columns={contractStagesColumnsValue}
                rows={contractStagesRows}
                getRowClassName={params => !params.row.active && 'disabled'}
                className={issueCommonStyles.SpentTimeTableRoot}
                pageSize={10}
                slots={{
                  columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                  columnSortedAscendingIcon: () => (
                    <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                  columnSortedDescendingIcon: () => (
                    <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                  ),
                }}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
              />
            </Grid>
            <Grid
              item
              padding={'16px'}
              sx={{ borderRadius: '0 0 16px 16px', border: '1px solid #E4E4EF', borderTop: 'none' }}
            >
              <IconButton onClick={addRowHandler} sx={{ padding: '0' }}>
                <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
              </IconButton>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
export default injectIntl(IssueContractStages);
