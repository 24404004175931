import React from 'react';
import { SvgIcon } from '@mui/material';

const PlusIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2987_18224)">
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={props.color ? props.color : '#7174AC'} />
      </g>
      <defs>
        <clipPath id="clip0_2987_18224">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PlusIcon;
