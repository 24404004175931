import React, { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { getSavedQuery } from '../../../packages/common/api';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { store } from '../app/redux';

const AgileFormatSavedQuery = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  dayjs.extend(weekday);
  const state = store.getState();

  const getSubLabelsFromSavedQuery = useCallback(type => {
    switch (type) {
      case 'Today':
        return dayjs().format('YYYY-MM-DD');
      case 'Yesterday':
        return dayjs().subtract(1, 'd').format('YYYY-MM-DD');
      case 'ThisWeek':
        return `${dayjs().weekday(0).format('YYYY-MM-DD')};${dayjs().weekday(6).format('YYYY-MM-DD')}`;
      case 'LastWeek':
        return `${dayjs().subtract(1, 'w').weekday(0).format('YYYY-MM-DD')};${dayjs()
          .subtract(1, 'w')
          .weekday(6)
          .format('YYYY-MM-DD')}`;
      case 'LastTwoWeeks':
        return `${dayjs().subtract(2, 'w').weekday(0).format('YYYY-MM-DD')};${dayjs()
          .subtract(1, 'w')
          .weekday(6)
          .format('YYYY-MM-DD')}`;
      case 'ThisMonth':
        return `${dayjs().startOf('month').format('YYYY-MM-DD')};${dayjs().endOf('month').format('YYYY-MM-DD')}`;
      case 'LastMonth':
        return `${dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')};${dayjs()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD')}`;
      case 'ThisYear':
        return `${dayjs().startOf('year').format('YYYY-MM-DD')};${dayjs().endOf('year').format('YYYY-MM-DD')}`;
      default:
        return '';
    }
  }, []);

  const getSavedQueryInfo = useCallback(async () => {
    const { data } = await getSavedQuery({ id });
    let projectSelected =
      typeof state.user.project?.redmineId === 'undefined' ? '' : `&project=${state.user.project.redmineId}`;
    let locked = searchParams.get('locked')?.split(';')[0];
    locked = typeof locked === 'undefined' ? '' : `&locked=${locked}`;
    const newUrl = `/agile?${Object.entries(data.query)
      .map(([field, values]) => {
        switch (field) {
          case 'group':
            return values && `groups=${values.instance}.${values.field}`;
          case 'sorts':
            return (
              values &&
              `${field}=${values?.map(value => `${value.instance}.${value.field};${value.direction}`).join('$$')}`
            );
          case 'fields':
            return values && `${field}=${values?.map(value => `${value.instance}.${value.field}`).join('$$')}`;
          case 'totals':
            return values && `${field}=${values?.map(value => `${value.instance}.${value.field}`).join('$$')}`;
          case 'statuses':
            return values && `${field}=${values.values}`;
          case 'filters':
            let newValues = values.map(value => {
              if (value.hasOwnProperty('predefined')) {
                return (
                  value &&
                  `${value.instance}.${value.field};${value.predefined.type};${getSubLabelsFromSavedQuery(
                    value.predefined.type,
                  )}`
                );
              }
              if (value.hasOwnProperty('min') && value.hasOwnProperty('max')) {
                return value && `${value.instance}.${value.field};${value.operation};${value.min};${value.max}`;
              }
              return value && `${value.instance}.${value.field};${value.operation};${value.values}`;
            });
            return `${field}=${newValues.join('$$')}`;
        }
      })
      .join('&')}&savedQueryId=${id}${locked}${projectSelected}`;
    navigate(newUrl, { state: data });
  }, [getSubLabelsFromSavedQuery, id, navigate, searchParams, state]);

  useEffect(() => {
    getSavedQueryInfo();
  }, [getSavedQueryInfo]);

  return (
    <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
      <CircularProgress color="secondary" />
    </Grid>
  );
};

export default AgileFormatSavedQuery;
