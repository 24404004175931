const baseDark = '#18181B';
const baseLight = '#ffffff';
const baseGrey = '#F1F2F8';
const baseColor = '#212346';
const baseBlue = '#3448FF';

export default {
  primary: {
    main: baseLight,
    light: '#FFA883',
  },

  secondary: {
    main: baseColor,
  },

  common: {
    dark: baseDark,
    darkGrey: '#bdbdbd',
    grey: '#cccccc',
    lightGrey: baseGrey,
    lightestGrey: '#f9f9f9',
    light: baseLight,
    blue: baseBlue,
  },

  text: {
    main: '#082253',
    primary: '#212346',
    button: '#656985',
    info: '#7A9BFF',
  },

  background: {
    dark: '#212346',
    light: baseGrey,
    white: baseLight,
    mob: '#DCDFF4',
  },

  inactive: {
    main: '#ccc',
    primary: '#ccc111',
    secondary: '#ccc333',
  },

  danger: {
    main: '#FF514C',
    text: baseLight,
  },

  success: {
    main: '#B5D7FC',
    alt: '#00e394',
    tertiary: '#B4F5DE',
    altLight: '#B4F5DE',
    text: baseDark,
    altText: baseLight,
  },

  warning: {
    main: '#FFC6B5',
    alt: baseLight,
    text: baseDark,
    altText: '#BD00FF',
  },

  info: {
    main: baseGrey,
    text: baseDark,
  },

  shadow: {
    outer: {
      xl: '0px 6px 9px rgba(0, 0, 0, 0.04)',
    },
  },

  hexToRgb: hex =>
    hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16))
      .join(),
};
