import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import { Box, Grid, Checkbox, Typography } from '@mui/material';
import useStyles from './ResplanSettingsChecks.styles';

const ResplanSettingsChecks = ({ intl, settings, setSettings }) => {
  const employmentCheck = intl.formatMessage({ id: 'resplan_settings_employment_check' });
  const overEmployment = intl.formatMessage({ id: 'resplan_settings_over_employment' });
  const classes = useStyles();

  const handleEmploymentCheck = useRef(event => {
    setSettings(prevSettings => ({
      ...prevSettings,
      employmentCheck: event.target.checked,
    }));
  }).current;

  const handleOverEmployment = useRef(event => {
    setSettings(prevSettings => ({
      ...prevSettings,
      overEmploymentProhibition: event.target.checked,
    }));
  }).current;

  return (
    <>
      <Grid container alignItems="center" wrap="nowrap" marginBottom={8}>
        <Grid item marginLeft={4} xs={5}>
          <Typography variant="h5" color="#41424E">
            {employmentCheck}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Checkbox
              disableRipple
              checked={settings.employmentCheck}
              onChange={handleEmploymentCheck}
              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item marginLeft={4} xs={5}>
          <Typography variant="h5" color="#41424E">
            {overEmployment}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Checkbox
              disabled
              disableRipple
              checked={settings.overEmploymentProhibition}
              onChange={handleOverEmployment}
              icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
              checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default injectIntl(ResplanSettingsChecks);
