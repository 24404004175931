export default {
  fontFamily: 'Nunito, sans-serif',
  h1: {
    color: 'inherit',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '20px',
  },
  h2: {
    color: 'inherit',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '20px',
  },
  h3: {
    color: 'inherit',
    fontSize: '16px',
    lineHeight: '20px',
  },
  h4: {
    color: 'inherit',
    fontSize: '14px',
    lineHeight: '20px',
  },
  h5: {
    color: 'inherit',
    fontSize: '12px',
    lineHeight: '20px',
  },
  h6: {
    color: 'inherit',
    fontSize: '10px',
    lineHeight: '20px',
  },
};
