import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../../packages/common/shared-ui/src/components/Page';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  Select,
  CircularProgress,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
} from '@mui/material';
import { getContractStagesCommonSettings, saveContractStagesCommonSettings } from '../../../../../packages/common/api';
import useStyles from './ContractStagesContainer.styles';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import DeleteIcon from 'Common/shared-ui/src/icons/DeleteIcon';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import ContractStagesTable from '../ContractStagesTable/ContractStagesTable';

const ContractStages = ({ intl }) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'issue_tab_contract_stages' });
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialSettings, setInitialSettings] = useState([]);
  const [initialSelectedSettings, setInitialSelectedSettings] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasCustomerRecords, setHasCustomerRecords] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const selectLabel = intl.formatMessage({ id: 'resplan_settings_choose' });
  const fetchContractsData = async () => {
    try {
      setIsLoading(true);

      const response = await getContractStagesCommonSettings();
      setInitialSettings(response.data);
      setRoles(response.data.roles);
      setCustomers(response.data.customerField);
      setTrackers(response.data.trackers);
      setInitialSelectedSettings(getChecked(true, response.data));
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchContractsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = (event, fieldType) => {
    const selectedFieldIds = event.target.value;
    let setFunction;
    if (fieldType === 'roles') {
      setFunction = setRoles;
    } else if (fieldType === 'trackers') {
      setFunction = setTrackers;
    } else if (fieldType === 'customers') {
      setFunction = setCustomers;
    }
    setFunction(prevFilters =>
      prevFilters.map(filter =>
        selectedFieldIds.includes(filter.valueId) ? { ...filter, isChecked: true } : { ...filter, isChecked: false },
      ),
    );
  };
  const handleRemoveFilter = useCallback((valueId, fieldType) => {
    let setFunction;
    if (fieldType === 'roles') {
      setFunction = setRoles;
    } else if (fieldType === 'trackers') {
      setFunction = setTrackers;
    }
    setFunction(prevFilters => {
      return prevFilters.map(filter => (filter.valueId === valueId ? { ...filter, isChecked: false } : filter));
    });
  }, []);

  const handleCancel = useCallback(() => {
    setRoles(initialSettings.roles);
    setCustomers(initialSettings.customerField);
    setTrackers(initialSettings.trackers);
  }, [initialSettings]);

  const getChecked = useCallback(
    (isInitial = false, initialData = []) => {
      let rolesData;
      let customersData;
      let trackersData;
      if (isInitial) {
        rolesData = initialData.roles.filter(role => role.isChecked).map(role => Number(role.valueId, 10));
        customersData = Number(initialData.customerField.find(customer => customer.isChecked)?.valueId, 10);
        trackersData = initialData.trackers
          .filter(tracker => tracker.isChecked)
          .map(tracker => Number(tracker.valueId, 10));
      } else {
        rolesData = roles.filter(role => role.isChecked).map(role => Number(role.valueId, 10));
        customersData = Number(customers.find(customer => customer.isChecked)?.valueId, 10);
        trackersData = trackers.filter(tracker => tracker.isChecked).map(tracker => Number(tracker.valueId, 10));
      }
      const data = {
        trackers: trackersData,
        roles: rolesData,
        customerFieldId: customersData,
      };
      return data;
    },
    [customers, roles, trackers],
  );
  const handleSave = useCallback(async () => {
    const data = getChecked();
    const hasCustomerChanged = data.customerFieldId !== initialSelectedSettings.customerFieldId;
    if (hasCustomerChanged && hasCustomerRecords) {
      setErrorMessage(
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        'Для данного поля есть записи в таблице соответствия Стадий договора Заказчику. Изменение невозможно.',
      );
      return;
    }
    const response = await saveContractStagesCommonSettings(data);
    setInitialSelectedSettings(data);
    setInitialSettings(response.data);
    setHasChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, customers, trackers]);
  const handlePageClick = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1.customerFieldId !== obj2.customerFieldId) {
      return false;
    }
    const arraysEqual = (arr1, arr2) => {
      if (arr1.length !== arr2.length) {
        return false;
      }
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }
      return true;
    };
    return arraysEqual(obj1.roles, obj2.roles) && arraysEqual(obj1.trackers, obj2.trackers);
  };
  useEffect(() => {
    if (!isLoading) {
      const data = getChecked();
      const isEqual = areObjectsEqual(data, initialSelectedSettings);
      setHasChanged(!isEqual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, customers, trackers, handleSave]);

  return (
    <>
      {!isLoading ? (
        <Page title={pageTitle} width="100%" onClick={handlePageClick}>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginTop={2.5}
            marginBottom={1.5}
          >
            <Typography variant="h1">
              <FormattedMessage id="issue_tab_contract_stages" defaultMessage="Contract Stages" />
            </Typography>
            <Box display="flex">
              <Box marginRight={2.5}>
                <Button
                  onClick={handleSave}
                  disabled={!hasChanged}
                  type="submit"
                  disableRipple
                  variant="defaultBlueSecondary"
                >
                  <FormattedMessage id="save_text" defaultMessage="Save" />
                </Button>
              </Box>
              <Box>
                <Button type="submit" onClick={handleCancel} disableRipple variant="defaultGreySecondary">
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item marginBottom={2} className={classes.errorMessage}>
            {errorMessage && (
              <Typography variant="h3" fontWeight={600} color="#E03737">
                {errorMessage}
              </Typography>
            )}
          </Grid>

          <Grid container wrap="nowrap" direction="row" justifyContent="space-between" gap={5} marginBottom={5}>
            <Grid container spacing={5} wrap="nowrap" padding={4} position="relative" className={classes.trackersWrap}>
              <Grid item xs={4} marginRight={2}>
                <Typography variant="h5">
                  <FormattedMessage id="contract_stages_trackers_title" />
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.formControlWrap}>
                <FormControl className={classes.formControl}>
                  <Select
                    id="filters"
                    multiple
                    value={trackers.filter(filter => filter.isChecked).map(filter => filter.valueId)}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                      PaperProps: {
                        className: classes.paperDropdown,
                      },
                    }}
                    onChange={e => handleSelectChange(e, 'trackers')}
                    renderValue={selected => {
                      return (
                        <div className={classes.chips}>
                          {selected.map(value => {
                            const filter = trackers.find(filter => filter.valueId === value);
                            return (
                              <Chip
                                key={filter.valueId}
                                label={getTranslatedText(intl, '', filter.valueName, filter.valueName)}
                                clickable
                                deleteIcon={
                                  <DeleteIcon
                                    width={10}
                                    height={10}
                                    viewBox="0 0 10 10"
                                    onMouseDown={event => event.stopPropagation()}
                                  />
                                }
                                className={classes.chip}
                                onDelete={() => handleRemoveFilter(filter.valueId, 'trackers')}
                              />
                            );
                          })}
                        </div>
                      );
                    }}
                  >
                    {trackers &&
                      trackers
                        .sort((a, b) => b.isChecked - a.isChecked)
                        .map(filter => (
                          <MenuItem key={filter.valueId} value={filter.valueId}>
                            <Checkbox className={classes.menuItemCheckbox} checked={filter.isChecked} />
                            <ListItemText primary={getTranslatedText(intl, '', filter.valueName, filter.valueName)} />
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={5} wrap="nowrap" padding={4} position="relative" className={classes.trackersWrap}>
              <Grid item xs={4} marginRight={2}>
                <Typography variant="h5">
                  <FormattedMessage id="contract_stages_roles_title" />
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.formControlWrap}>
                <FormControl className={classes.formControl}>
                  <Select
                    id="filters"
                    multiple
                    value={roles.filter(filter => filter.isChecked).map(filter => filter.valueId)}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                      PaperProps: {
                        className: classes.paperDropdown,
                      },
                    }}
                    onChange={e => handleSelectChange(e, 'roles')}
                    renderValue={selected => {
                      return (
                        <div className={classes.chips}>
                          {selected.map(value => {
                            const filter = roles.find(filter => filter.valueId === value);
                            return (
                              <Chip
                                key={filter.valueId}
                                label={getTranslatedText(intl, '', filter.valueName, filter.valueName)}
                                clickable
                                deleteIcon={
                                  <DeleteIcon
                                    width={10}
                                    height={10}
                                    viewBox="0 0 10 10"
                                    onMouseDown={event => event.stopPropagation()}
                                  />
                                }
                                className={classes.chip}
                                onDelete={() => handleRemoveFilter(filter.valueId, 'roles')}
                              />
                            );
                          })}
                        </div>
                      );
                    }}
                  >
                    {roles &&
                      roles
                        .sort((a, b) => b.isChecked - a.isChecked)
                        .map(filter => (
                          <MenuItem key={filter.valueId} value={filter.valueId}>
                            <Checkbox className={classes.menuItemCheckbox} checked={filter.isChecked} />
                            <ListItemText primary={getTranslatedText(intl, '', filter.valueName, filter.valueName)} />
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container wrap="nowrap" direction="row" justifyContent="space-between" gap={5} marginBottom={5}>
            <Grid container spacing={5} wrap="nowrap" padding={4} position="relative" className={classes.trackersWrap}>
              <Grid item xs={4} marginRight={2}>
                <Typography variant="h5">
                  <FormattedMessage id="contract_stages_customers_title" />
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.formControlWrap}>
                <FormControl className={classes.formSingleControl}>
                  <Select
                    variant="outlined"
                    IconComponent={() => (
                      <Box className={classes.groupIcon}>
                        <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
                      </Box>
                    )}
                    value={customers.find(setting => setting.isChecked)?.valueId || '0'}
                    defaultValue={0}
                    onChange={e => handleSelectChange(e, 'customers')}
                    MenuProps={{
                      className: classes.optionsGroupMenu,
                      PaperProps: {
                        className: classes.paperDropdown,
                      },
                    }}
                    className={classes.createSelect}
                  >
                    <MenuItem disabled value={0} key={0}>
                      <Typography variant="h4" color="#212346">
                        {selectLabel}
                      </Typography>
                    </MenuItem>
                    {customers
                      .sort((a, b) => b.isChecked - a.isChecked)
                      .map(setting => (
                        <MenuItem key={setting.valueId} value={setting.valueId} className={classes.menuItem}>
                          <Typography variant="h4" color="#212346">
                            {setting.valueName}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              wrap="nowrap"
              padding={4}
              position="relative"
              className={classes.emptyWrap}
            ></Grid>
          </Grid>
          <ContractStagesTable setHasCustomerRecords={setHasCustomerRecords} setErrorMessage={setErrorMessage} />
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ContractStages);
