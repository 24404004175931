import { createAsyncThunk } from '@reduxjs/toolkit';

import { authUser } from 'Common/api';
import formatError from 'Common/utils/formatError';
import { getUserData } from '../../../packages/common/api';

export const signInWithLoginOrEmail = createAsyncThunk('user/sign-in', async (userData, thunkAPI) => {
  try {
    const { data } = await authUser(userData);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: formatError(error, 'Unauthorized') });
  }
});

export const getUser = createAsyncThunk('user/get', async (userData, thunkAPI) => {
  try {
    const { data } = await getUserData();
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('User not found');
  }
});
