import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  trackersWrap: {
    display: 'flex',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
    marginLeft: '0 !important',
    marginTop: '0 !important',
    // width: '48% !important',
    borderRadius: '14px',
  },
  paperDropdown: {
    '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
      maxHeight: '300px',
      borderRadius: '0 0 16px 16px',
      borderTop: '1px solid #E4E4EF',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiList-root.MuiMenu-list': {
        padding: '0',
        '& li': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#212346',
          '&.MuiButtonBase-root.MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
        },
      },
    },
  },
  emptyWrap: {
    display: 'none',
    backgroundColor: '#F1F2F8',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
    marginLeft: '0 !important',
    marginTop: '0 !important',
    // width: '48% !important',
    borderRadius: '14px',
  },
  formControlWrap: {
    padding: '0 !important',
    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& .MuiGrid-root': {
      margin: '0 !important',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346;',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px',
      marginRight: '8px !important',
      marginLeft: '4px !important',
    },
  },
  chip: {
    margin: 2,
    backgroundColor: '#FFF',
    '& .MuiChip-label': {
      padding: '8px 0px 8px 8px',
    },
  },
  menuItemCheckbox: {
    color: 'red',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 12000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      maxHeight: '40vh',
      overflow: 'auto',
      '& ul': {
        padding: 0,
      },
    },
  },
  createSelect: {
    minHeight: '40px',
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E4EF',
      },
      opacity: '70%',
    },
    border: '16px',
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  formControl: {
    marginRight: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
    '& .MuiSelect-select': {
      padding: '3px !important',
    },
  },
  formSingleControl: {
    marginRight: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
  },
  formControlMultiSelect: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
  errorMessage: {
    minHeight: '20px',
  },
}));

export default useStyles;
