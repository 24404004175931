import { makeStyles } from '@mui/styles';

const userPageStyles = makeStyles(theme => ({
  blockWrapper: {
    width: '100%',
    padding: '24px 32px',
    borderRadius: '16px',
    backgroundColor: '#fff',
  },
  userTabs: {
    '&.MuiTabs-root': {
      margin: '20px 0',
      minHeight: 'auto',
    },
    '& .MuiButtonBase-root.MuiTab-root': {
      color: '#656985',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      padding: '8px 16px',
      textTransform: 'capitalize',
      borderRadius: '16px 0 0 16px',
      backgroundColor: '#DCDFF4',
      minHeight: 'auto',
      minWidth: '79px',
      '& + .MuiButtonBase-root.MuiTab-root': {
        borderRadius: '0 16px 16px 0',
        marginLeft: '2px',
      },
      '&.Mui-selected': {
        backgroundColor: '#6270D0',
        color: '#fff',
      },
    },
  },
  buttonsBlock: {
    '& button': {
      backgroundColor: '#DCDFF4',
      color: '#656985',
      borderRadius: '16px !important',
      '&:hover': {
        backgroundColor: '#6C7AD9',
        color: '#fff',
      },
      '&:focus': {
        backgroundColor: '#7E8BE5',
        color: '#fff',
      },
      '& + button': {
        marginLeft: '8px',
      },
      '&.Mui-disabled': {
        backgroundColor: '#DCDFF4 !important',
        color: '#656985 !important',
      },
    },
  },
}));

export default userPageStyles;
