import React, { useCallback, useRef, useState } from 'react';
import { Box, Grid, IconButton, Popover, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import SearchInput from '../SearchInput/SearchInput';
import HeaderNavIcons from '../HeaderNavIcons/HeaderNavIcons';
import UserAvatar from '../UserAvatar/UserAvatar';

import useStyles from './Header.styles';

import LogoIconWhite from 'Common/shared-ui/src/icons/LogoIconWhite';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import MenuIcon from '../../../../packages/common/shared-ui/src/icons/MenuIcon';
import { toggleDrawer } from '../../app/redux/reducers/userSlice';
import { generateRedirectUrl } from '../../../../packages/common/utils/getRedirectUrl';

const Header = () => {
  const classes = useStyles();
  const userLastName = useSelector(state => state.user.info.lastName);
  const userFirstName = useSelector(state => state.user.info.firstName);
  const userFullNameArray = useSelector(state => state.user.info.fullName).split(' ');
  const userAvatar = useSelector(state => state.user.info.avatar);
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'avatar-menu' : undefined;

  const openDrawer = useCallback(() => dispatch(toggleDrawer(true)), [dispatch]);

  const { current: handleOpenAvatarMenu } = useRef(event => {
    setAnchorEl(event.currentTarget);
    setIsAvatarMenuOpen(true);
  });

  const { current: handleCloseAvatarMenu } = useRef(() => {
    setAnchorEl(null);
    setIsAvatarMenuOpen(false);
  });

  return (
    <Grid container className={classes.Header} justifyContent="center">
      <Grid
        container
        className={classes.headerContainer}
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid container>
          {isSmallScreen && (
            <IconButton disableRipple onClick={openDrawer}>
              <MenuIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          )}
          {process.env.NODE_ENV === 'development' ? (
            <Link to="/" className={classes.headerLogo}>
              <LogoIconWhite viewBox="0 0 88 40" />
            </Link>
          ) : (
            <a
              href={generateRedirectUrl({ host: window.location.origin, redmineToken })}
              className={classes.headerLogo}
            >
              <LogoIconWhite viewBox="0 0 88 40" />
            </a>
          )}
        </Grid>
        <Box display="flex">
          <SearchInput />
          <HeaderNavIcons />
          {!isSmallScreen && (
            <Box className={classes.avatarBlock}>
              <UserAvatar
                avatar={userAvatar}
                userFirstName={userFirstName}
                userLastName={userLastName}
                userFullNameArray={userFullNameArray}
                handleOpenAvatarMenu={handleOpenAvatarMenu}
                aria-describedby={id}
              />
              {isAvatarMenuOpen && (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseAvatarMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  className={classes.userSettingsLink}
                >
                  {process.env.NODE_ENV === 'development' ? (
                    <Link to="/my/account" onClick={handleCloseAvatarMenu}>
                      <Typography variant="h5" color="secondary">
                        <FormattedMessage id="my_account" />
                      </Typography>
                    </Link>
                  ) : (
                    <a href={generateRedirectUrl({ host: window.location.origin, path: '/my/account', redmineToken })}>
                      <Typography variant="h5" color="secondary">
                        <FormattedMessage id="my_account" />
                      </Typography>
                    </a>
                  )}
                  <Link to="/logout" className={classes.logoutButton}>
                    <Typography variant="h5">
                      <FormattedMessage id="logout" />
                    </Typography>
                  </Link>
                </Popover>
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Header;
