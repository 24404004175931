import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  customPopper: {
    zIndex: '2000 !important',
  },
  settingsWrap: {
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '19px 64px 17px 19px !important',
  },
  filtersWrap: {
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
  },
  notSelectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiList-root': {
      width: '100%',
    },
    '& .MuiListItem-root': {
      padding: '0',
      width: '100%',
      '& .MuiListItemButton-root': {
        padding: '6px 16px',
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  columnTtl: {
    padding: '0 16px 5px 16px',
    fontSize: '12px !important',
  },
  notSelectedFieldsSearch: {
    borderBottom: '1px solid #E4E4EF',
    padding: '6px 16px',
    fontSize: '12px !important',
    color: '#7174AC',
  },
  selectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiListItem-root': {
      padding: '0',
    },
  },
  formControl: {
    margin: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
  },
  menuItemCheckbox: {
    color: 'red',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  saveSettingButton: {
    borderRadius: '16px !important',
  },
  cancelReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
    '&.Mui-disabled': {
      backgroundColor: '#DCDFF4 !important',
      color: '#656985 !important',
    },
  },
  '.Mui-disabled': {
    background: '#DCDFF4  !important',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  allBox: {
    width: '70%',
  },
  txtField: {
    width: '30%',
  },
  saveReportButton: {
    marginRight: '10px !important',
    borderRadius: '16px !important',
    '& .MuiInputBase-root': {
      '&.Mui-disabled': {
        backgroundColor: '#DCDFF4 !important',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#DCDFF4 !important',
      color: '#656985 !important',
    },
  },
  notSelectedFields: {
    border: '1px solid #E4E4EF !important',
    borderRadius: '16px !important',
    height: 'auto !important',
    width: '100%',
  },
  selectedFields: {
    fontSize: '12px !important',
    '& .MuiListItemButton-root': {
      fontSize: '12px !important',
      padding: '6px 16px',
    },
    '& .MuiTypography-root': {
      fontSize: '12px !important',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346;',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px',
    },
  },
  formControlWrap: {
    padding: 'px 0px 3px 3px',
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
  },
  menuGroupWrap: {
    borderTop: '1px solid #E4E4EF',
  },
  radioButtonTxt: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    lineHeight: '20px !important',
    marginLeft: '10px',
  },

  subItemChip: {
    '&.MuiChip-root': {
      background: 'none',
      '& .MuiChip-label': {
        paddingLeft: '0px',
        paddingRight: '3px',
      },
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
    },
  },
  chip: {
    margin: 2,
    backgroundColor: '#FFF',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  tableCell: {
    '& .MuiInputBase-root': {
      background: 'none !important',
    },
  },
  radioButtonsGroup: {
    zIndex: 100000,
  },
  radioButtonWrap: {
    width: '100%',
  },
  autocompleteRolesList: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& .MuiAutocomplete-root': {
      width: '100% !important',
    },
    '& .MuiOutlinedInput-root': {
      height: 'auto !important',
    },
  },
  radioButtonsGroupWrap: {
    padding: '10px 18px',
  },
}));

export default useStyles;
