import React, { memo, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import { CalendarPicker } from '@mui/x-date-pickers-pro';
import CustomPickersDay from '../CustomPickersDay';
import useStyles from './CustomCalendar.styles';

const CustomCalendar = ({
  year,
  month,
  customDays,
  handleToggleExclusionMenu,
  getCurrentDay,
  setFieldValue,
  workHours,
}) => {
  const classes = useStyles();

  const { current: getDateColor } = useRef((date, days) => {
    const currentDayObj = getCurrentDay(date, days);
    if (date.$M !== dayjs(month, 'MM').get('month')) {
      return { color: '#E4E4EF' };
    }
    if (!currentDayObj) {
      return { color: '#212346' };
    }
    if (currentDayObj.isExclusion) {
      return { color: '#EB2FFC' };
    }
    if (currentDayObj.isDayOff) {
      return { color: '#E03737' };
    }
  });

  const renderPickerDay = useCallback(
    (date, selectedDates, pickersDayProps) => {
      const currentDayObj = getCurrentDay(date, customDays);
      const onDaySelect = selectedDate => {
        const currentDayObj = getCurrentDay(selectedDate, customDays);
        setFieldValue('hours', typeof currentDayObj.hours === 'number' ? currentDayObj.hours : workHours);
        handleToggleExclusionMenu(selectedDate);
      };
      return (
        <CustomPickersDay
          date={dayjs(currentDayObj.date).format()}
          value={dayjs(currentDayObj.date).format()}
          key={currentDayObj.date}
          style={getDateColor(date, customDays)}
          currentDayObj={currentDayObj}
          onDaySelect={onDaySelect}
        />
      );
    },
    [customDays, getCurrentDay, getDateColor, handleToggleExclusionMenu, setFieldValue, workHours],
  );

  return (
    <CalendarPicker
      className={classes.calendarRoot}
      dayOfWeekFormatter={day => `${day.charAt(0).toLowerCase()}${day.charAt(1).toLowerCase()}`}
      readOnly={true}
      value={null}
      date={dayjs(month, 'MM').year(year)}
      renderDay={renderPickerDay}
      showDaysOutsideCurrentMonth
      view="day"
      onChange={e => e.preventDefault()}
    />
  );
};

CustomCalendar.propTypes = {
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  workHours: PropTypes.number.isRequired,
  customDays: PropTypes.shape({}).isRequired,
  handleToggleExclusionMenu: PropTypes.func.isRequired,
  getCurrentDay: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default memo(CustomCalendar);
