import React, { useCallback } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import useStyles from './ProjectsListView.styles';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
const ProjectsListView = ({ projects, projectscColumns }) => {
  const classes = useStyles();
  const intl = useIntl();
  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      default:
        return '';
    }
  }, []);
  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project'];
      if (linkFields.includes(column.fieldType)) {
        if (column.fieldType === 'project') {
          return (
            <Link
              to={handleGetRedirectUrl({ id: props.id, columnType: column.fieldType })}
              target={'_blank'}
              className={classes.gridCell}
            >
              <Typography variant="h4" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                {props.value}{' '}
                <span
                  className={clsx(classes.ProjectStatus, {
                    [classes.ProjectOpened]: props.row.status === 1,
                    [classes.ProjectClosed]: props.row.status != 1,
                  })}
                >
                  •
                </span>
              </Typography>
            </Link>
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );
  const formattedProjectsColumns = useCallback(() => {
    const projectsColumns = projectscColumns;
    const smallFields = ['date', 'hours', 'float', 'bool'];
    return projectsColumns.map(column => {
      return {
        field: column.fieldId,
        headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        position: column.position,
        flex: smallFields.includes(column.fieldType) ? 0 : 1,
        width: smallFields.includes(column.fieldType) && 120,
        renderCell: props => cellRenderer({ props, column }),
        cellClassName: props => !props.formattedValue && 'tableCell',
      };
    });
  }, [cellRenderer, intl, projectscColumns]);
  const formattedProjectsRows = useCallback(
    () =>
      projects.reduce((acc, project) => {
        if (project.rowType === 'total') {
          const newCols = project.columns.reduce((columnAcc, _, i) => {
            return {
              ...columnAcc,
              [project.columns[i].fieldId]: project.columns[i].value,
            };
          }, {});
          return [...acc, { id: project.rowId, ...newCols, status: project.services.status }];
        }
        return [];
      }, []),
    [projects],
  );
  const projectRows = formattedProjectsRows();
  const projectsColumns = formattedProjectsColumns();

  return (
    <>
      <DataGridPro
        autoHeight
        hideFooter
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnMenu
        columns={projectsColumns}
        rows={projectRows}
        className={classes.projectsListTableRoot}
        getRowId={item => item.id}
        sortingOrder={['asc', 'desc', null]}
        slots={{
          columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedAscendingIcon: () => <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />,
          columnSortedDescendingIcon: () => <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />,
        }}
        getRowClassName={params => params.formattedValue && 'groupRow'}
        rowHeight={40}
      />
    </>
  );
};
export default injectIntl(ProjectsListView);
