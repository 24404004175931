import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  ProjectsContainerRoot: {
    width: '100%',
  },
  filtersWrap: {
    display: 'block !important',
    marginBottom: '10px',
  },
  projectsPageTtl: {
    float: 'left',
    paddingTop: '5px',
    marginRight: '15px !important',
    width: 'auto !important',
  },
  ProjectsViewChangeButtonWrap: {
    float: 'right',
    position: 'relative',
  },
  ProjectsViewProjectSettingsIconsWrap: {
    float: 'right',
    position: 'relative',
    '&.MuiButtonBase-root.MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'initial',
      },
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
  },
  ProjectsViewChangeButton: {
    border: '0px !important',
    padding: '0px !important',
  },
}));

export default useStyles;
