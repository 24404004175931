export default {
  styleOverrides: {
    colorSecondary: {
      backgroundColor: '#ffffff',
      minWidth: 180,
      '&:not(.Mui-error):hover': {
        backgroundColor: '#ffffff',
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    input: {
      color: '#212346',
      fontSize: 12,
      padding: '10px 16px',
      '&::-webkit-input-placeholder': {
        opacity: 1,
        color: 'rgba(113, 116, 172, 1)',
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        MozAppearance: 'none',
        margin: 0,
      },
      '&[type="number"]': {
        MozAppearance: 'textfield',
      },
    },
    notchedOutline: {
      borderColor: '#E4E4EF',
    },
    root: {
      backgroundColor: 'transparent',
      borderRadius: 16,
      height: 40,
      border: '0',
      fontSize: 14,
      outline: 'none',
      zIndex: 10,
      // paddingRight: 25,
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto 0',
        zIndex: 0,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        backgroundColor: 'transparent',
        border: '1px solid #E4E4EF',
        outline: 'none',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E03737',
      },
      '&:not(.Mui-error):hover': {
        backgroundColor: 'transparent',
        border: '0',
        outline: 'none',
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#E4E4EF',
        },
      },
      '&::after, &::before': {
        display: 'none',
      },
    },
  },
};
