import React, { useCallback, useReducer, useRef } from 'react';
import clsx from 'clsx';
import { Box, TableCell, TextField, Tooltip } from '@mui/material';
import useStyles from './SpreadsheetTable/SpreadsheetTable.styles';

const SpreadsheetWeekValue = ({
  projectIssue,
  day,
  handleUpdateDayHourWithWeekUpdate,
  handleChangeDayComment,
  timeType,
  intl,
}) => {
  const classes = useStyles();
  const defaultTimeSheetComment = intl.formatMessage({ id: 'comment' });
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const handleChangeComment = useCallback(
    ({ currentTarget: target }) => {
      handleChangeDayComment(target.value, projectIssue.issueUniqueId, target.dataset.date, timeType);
      return forceUpdate();
    },
    [handleChangeDayComment, projectIssue.issueUniqueId, timeType],
  );

  const { current: dayHourInputProps } = useRef({
    placeholder: '0',
    'data-date': day.date,
  });

  const { current: dayCommentInputProps } = useRef({
    'data-date': day.date,
  });

  return (
    <TableCell key={day.date} className={clsx(classes.tableCellMedium, classes.tableField)} align="right">
      <Box className={clsx(classes.tableFieldBox, { [classes.tableCellError]: day.isError })}>
        <TextField
          disabled={projectIssue.isClosed || day.isApproved || `${projectIssue.issueId}`.split('').includes('_')}
          type="text"
          name={timeType}
          value={day.hours !== 0 ? day.hours : ''}
          className={classes.tableCellField}
          onChange={handleUpdateDayHourWithWeekUpdate}
          inputProps={dayHourInputProps}
          onWheel={e => {
            e.target.blur();
          }}
        />
        <Box className={classes.dayComment}>
          <Tooltip title={day.comments || ''} PopperProps={{ className: classes.commentTooltip }}>
            <TextField
              disabled={projectIssue.isClosed || day.isApproved || `${projectIssue.issueId}`.split('').includes('_')}
              className={clsx(classes.tableCellField, classes.tableCellCommentField)}
              placeholder={defaultTimeSheetComment}
              value={day.comments}
              onChange={handleChangeComment}
              inputProps={dayCommentInputProps}
            />
          </Tooltip>
        </Box>
      </Box>
    </TableCell>
  );
};

export default SpreadsheetWeekValue;
