import React, { memo, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Box, Button, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CalendarIcon from 'Common/shared-ui/src/icons/CalendarIcon';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import useStyles from './CalendarExceptionCreate.styles';
import * as PropTypes from 'prop-types';

const CalendarExceptionCreate = ({
  values,
  setFieldValue,
  errors,
  handleChange,
  handleToggleExclusionMenu,
  isCreateModalOpen,
  newExceptionDate,
  setNewExceptionDate,
  handleUpdateCalendars,
}) => {
  const classes = useStyles();

  const { current: handleClearNewException } = useRef(() => {
    setFieldValue('hours', 0);
  });

  const { current: handleChangeDate } = useRef((value, e) => {
    setFieldValue('date', dayjs(value).format('YYYY-MM-DD'));
    setNewExceptionDate(dayjs(value).format());
  });

  const handleChangeHours = useCallback(
    e => {
      const regex = /^([1]?[0-9]|2[0-4])$/;
      if (e.currentTarget.value === '') {
        return setFieldValue('hours', 0);
      }
      if (regex.test(e.currentTarget.value)) {
        return handleChange(e);
      }
    },
    [handleChange, setFieldValue],
  );

  const { current: pickerProps } = useRef({
    paperClassName: {
      className: classes.paperRoot,
    },
    popperProps: {
      className: classes.popper,
    },
    paperProps: { disableRipple: true },
    buttonProps: { disableRipple: true },
    renderInput: params => <TextField {...params} />,
    components: {
      OpenPickerIcon: ({ ...props }) => <CalendarIcon {...props} />,
      SwitchViewButton: (...props) => (
        <Box display="flex" alignItems="center" width={36} height={36} {...props}>
          <IconButton disableRipple>
            <ChevronIcon direction="down" />
            {props.children}
          </IconButton>
        </Box>
      ),
      LeftArrowButton: ({ ...props }) => (
        <Box width={36} height={36}>
          <IconButton disableRipple {...props}>
            <ChevronIcon direction="left" />
          </IconButton>
        </Box>
      ),
      RightArrowButton: ({ ...props }) => (
        <Box width={36} height={36}>
          <IconButton disableRipple {...props}>
            <ChevronIcon direction="right" />
          </IconButton>
        </Box>
      ),
    },
  });

  return (
    <Modal open={isCreateModalOpen} className={classes.popperRoot} disableRestoreFocus>
      <Grid container direction="column" wrap="nowrap" className={classes.exclusionMenu} marginTop={2}>
        <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between" height={48} marginBottom={5}>
          <Typography variant="h1">
            <FormattedMessage id="parameters_text" />
          </Typography>
          <IconButton disableRipple onClick={handleToggleExclusionMenu} value="close">
            <Box width={24} height={24}>
              <ClearIcon viewBox="0 0 24 24" />
            </Box>
          </IconButton>
        </Grid>
        <Grid container direction="column">
          <Grid container wrap="nowrap" marginBottom={4}>
            <Grid item flex={1} display="flex" alignItems="center" paddingLeft="16px">
              <Typography variant="h5">
                <FormattedMessage id="hours" />
              </Typography>
            </Grid>
            <Grid item flex={1} display="flex" alignItems="center">
              <TextField
                className={classes.newExceptionField}
                variant="outlined"
                type="number"
                name="hours"
                value={values.hours}
                onChange={handleChangeHours}
                error={!!errors?.hours}
                aria-valuemax={24}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" wrap="nowrap" marginBottom={5}>
            <Grid item flex={1} display="flex" alignItems="center" paddingLeft="16px">
              <Typography variant="h5">
                <FormattedMessage id="prod_calendar_date" />
              </Typography>
            </Grid>
            <Grid item flex={1} display="flex" alignItems="center">
              <DatePicker
                components={pickerProps.components}
                className={classes.newExceptionDatePicker}
                name="date"
                value={dayjs(newExceptionDate).format('YYYY-MM-DD')}
                onChange={handleChangeDate}
                type="date"
                renderInput={pickerProps.renderInput}
                date={dayjs(newExceptionDate, 'YYYY-MM-DD')}
                OpenPickerButtonProps={pickerProps.buttonProps}
                PaperProps={pickerProps.paperClassName}
                PopperProps={pickerProps.popperProps}
                showDaysOutsideCurrentMonth={true}
              />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
            <Button disableRipple variant="cutGreyPrimaryBig" onClick={handleClearNewException}>
              <Typography variant="h5" fontWeight={700}>
                <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
              </Typography>
            </Button>
            <Box marginLeft={0.5}>
              <Button disableRipple variant="cutBlueSecondary" onClick={handleUpdateCalendars}>
                <Typography variant="h5" fontWeight={700}>
                  <FormattedMessage id="apply_text" defaultMessage="Apply" />
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

CalendarExceptionCreate.propTypes = {
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  newExceptionDate: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleToggleExclusionMenu: PropTypes.func.isRequired,
  setNewExceptionDate: PropTypes.func.isRequired,
  handleUpdateCalendars: PropTypes.func.isRequired,
};

export default memo(CalendarExceptionCreate);
