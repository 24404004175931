import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useIntl, FormattedDate } from 'react-intl';
import {
  Grid,
  OutlinedInput,
  ToggleButtonGroup,
  InputLabel,
  ToggleButton,
  FormControl,
  TextField,
  Typography,
  Select,
  MenuItem,
  Popover,
  Button,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  CircularProgress,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import useStyles from './ProjectsFilters.styles';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { getProjectsWithQuery, getTimesheets } from '../../../../packages/common/api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarIcon from 'Common/shared-ui/src/icons/CalendarIcon';
import ChevronIcon from 'Common/shared-ui/src/icons/ChevronIcon';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import LanguageArrowIcon from 'Common/shared-ui/src/icons/LanguageArrowIcon';
import CustomFilter from 'Common/shared-ui/src/components/CustomFilter';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import clsx from 'clsx';
import ProjectsTilesView from '../ProjectsTilesView/ProjectsTilesView';
import ProjectsListView from '../ProjectsListView/ProjectsListView';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
const ProjectsFilters = ({ filters, setProjects }) => {
  const intl = useIntl();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [filterValues, setFilterValues] = useState({
    chosenFirstStepItems: {
      'project.status': {
        valueName: 'Status',
        type: 'eq',
        fieldType: 'status',
        subLabels: [
          {
            valueName: 'Opened',
            valueId: '1',
            isChecked: true,
          },
        ],
      },
    },
  });
  const classes = useStyles();
  const setFilterItems = useCallback(newFirstStepChosenValues => {
    setFilterValues(prevState => {
      Object.keys(newFirstStepChosenValues).map(value => {
        if (value.at(-1) === '_') {
          delete prevState.chosenFirstStepItems[value.slice(0, -1)];
        }
      });
      return {
        chosenFirstStepItems: {
          ...prevState.chosenFirstStepItems,
          ...newFirstStepChosenValues,
        },
      };
    });
  }, []);
  useEffect(() => {
    const fetchProjectsDataWithQuery = async cleanedQuery => {
      try {
        const response = await getProjectsWithQuery(cleanedQuery);
        setProjects(response.rows);
      } catch (error) {
        return console.log(error);
      }
    };
    if (Object.keys(filterValues.chosenFirstStepItems).length > 0 && filterValues.chosenFirstStepItems) {
      let cleanedQuery = Object.entries(filterValues.chosenFirstStepItems)
        .map(([key, item]) => {
          if (item.type === 'null') {
            return `${item.value};${item.type}`;
          }
          if (item.type === 'all') {
            return;
          }
          if (key.split('').at(-1) != '_') {
            if (item.fieldType == 'status') {
              item.type = 'eq';
              if (item.subLabels.length > 0 && item.subLabels[0].valueId != '') {
                return `${key};${item.type};${item.subLabels[0].valueId}`;
              } else {
                return;
              }
            } else {
              if (item.fieldType == 'date') {
                let today = dayjs().format('YYYY-MM-DD');
                dayjs.extend(weekday);
                switch (item.type) {
                  case 'nd':
                  case 't':
                  case 'ld':
                    item.type = 'eq';
                    break;
                  case 'nw':
                  case 'w':
                  case 'lw':
                  case 'l2w':
                  case 'nm':
                  case 'm':
                  case 'lm':
                  case 'y':
                    item.type = 'btw';
                    break;
                }
              }
              let values;
              if (item.subLabels !== undefined && item.subLabels.length > 0) {
                values = item.subLabels.map(item => {
                  if (!item) {
                    return;
                  }
                  return item.valueId;
                });
              }

              if (item.type == '') {
                const notUndefined = anyValue => typeof anyValue !== 'undefined';
                let chosenItemsWithoutCurrent = Object.entries(filterValues.chosenFirstStepItems)
                  .map(([key, value]) => {
                    if (value.valueName === 'Status') {
                      return { ['project.status']: value };
                    } else if (item.value != value.value) {
                      return { [value.value]: value };
                    }
                  })
                  .filter(notUndefined)
                  .reduce((acc, obj) => Object.assign(acc, obj), {});
                setFilterValues(prevState => {
                  return {
                    chosenFirstStepItems: {
                      ...chosenItemsWithoutCurrent,
                    },
                  };
                });
                forceUpdate();
                return;
              } else if (values !== undefined && item.type == 'btw') {
                if (values[1] != '') {
                  return `${key};${item.type};${values.join(';')}`;
                } else {
                  return `${key};${item.type};${values.join('')}`;
                }
              } else if (values !== undefined && item.type != '') {
                if (values.length > 1 && values[1] != '') {
                  return `${key};${item.type};${values.join('||')}`;
                } else {
                  if (values[0] != '') {
                    return `${key};${item.type};${values[0]}`;
                  }
                }
              } else if (values !== undefined) {
                if (values.length > 1 && values[1] != '') {
                  return `${key};${values.join('||')}`;
                } else {
                  return `${key};${values[0]}`;
                }
              }
            }
          }
        })
        .filter(notUndefined => notUndefined !== undefined);
      // cleanedQuery = 'filters=' + cleanedQuery;
      if (cleanedQuery.length == 1) {
        cleanedQuery = 'filters=' + cleanedQuery[0];
      } else if (cleanedQuery.length > 1) {
        cleanedQuery = 'filters=' + cleanedQuery.join('$$');
      } else {
        cleanedQuery = 'filters';
      }
      if (cleanedQuery.length > 0) {
        fetchProjectsDataWithQuery(cleanedQuery);
      }
    }
  }, [filterValues, setFilterValues, setProjects]);

  return (
    <>
      {filters.map((filter, index) => {
        const newFirstStepValues = {
          value: filter.fieldId,
          valueName: filter.fieldName,
          fieldType: filter.fieldType,
          type: filter.fieldType !== 'text' ? 'eq' : 'in',
        };
        let prevFirstStepItems;
        if (filter.fieldType == 'user') {
          filter.values[0].valueName = getTranslatedText(intl, '', filter.values[0].valueName.toLowerCase(), 'Me');
        }
        if (filter.field == 'status') {
          prevFirstStepItems = {
            'project.status': {
              valueName: 'Status',
              type: 'eq',
              fieldType: 'status',
              subLabels: [
                {
                  valueName: 'Opened',
                  valueId: '1',
                  isChecked: true,
                },
              ],
            },
          };
          filter.values[2] = {
            isChecked: true,
            valueId: '',
            valueName: 'ALL',
          };
        }
        return (
          <Box
            width="100%"
            className={clsx(classes.filterWrap, {
              [classes.filterWrapStatus]: filter.field == 'status',
              [classes.filterWrapDisabledInput]:
                filter.fieldType == 'text' ||
                filter.fieldType == 'string' ||
                filter.fieldType == 'bool' ||
                filter.fieldType == 'boolean' ||
                filter.fieldType == 'int' ||
                filter.fieldType == 'float',
            })}
            key={index}
          >
            <CustomFilter
              intl={intl}
              secondStepItems={filter.values || []}
              chosenSecondStepItems={filterValues.chosenSecondStepItems}
              setValues={setFilterItems}
              defaultStep={1}
              firstStepValues={newFirstStepValues}
              chosenFirstStepItems={prevFirstStepItems || []}
            />
          </Box>
        );
      })}
    </>
  );
};
export default ProjectsFilters;
