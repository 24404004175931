import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Button, Tooltip } from '@mui/material';
import useStyles from './ResourcePlanFilter.styles';
import CustomFilter from 'Common/shared-ui/src/components/CustomFilter';
import clsx from 'clsx';
import DoneIcon from '../../../../packages/common/shared-ui/src/icons/DoneIcon';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import { getResourcePlanFilters } from '../../../../packages/common/api';
import { getResourcePlanFiltersQuery } from '../../../../packages/common/api';
import {
  clearResplanTableRows,
  setResplanTableRows,
  setStopLoadingRespanTableBySocket,
} from '../../app/redux/reducers/userSlice';
import { useDispatch } from 'react-redux';

const ResourcePlanFilter = ({
  filters,
  setQuery,
  topFilters = 3,
  setFetchedQuery,
  query,
  fetchedQuery,
  refreshKey,
}) => {
  const intl = useIntl();
  const [topFiltersList, setTopFiltersList] = useState([]);
  const [restFiltersList, setRestFiltersList] = useState([]);
  const [hasNewFilters, setHasNewFilters] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const applyTooltip = intl.formatMessage({ id: 'filter_resourcePlan_apply_tooltip' });
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('useEffect1');
    const fieldPriority = [
      'resourcePlan.group_by',
      'resourcePlan.date_from',
      'resourcePlan.date_to',
      'resourcePlan.business_unit',
      'issue.project_list',
      'resourcePlan.contract_issues',
      'resourcePlan.department',
      'resourcePlan.user_list',
      'resourcePlan.issues',
    ];
    let newModifiedFilters = filters.map(filter => {
      if (filter.fieldId === 'resourcePlan.date_to') {
        return { ...filter, fieldType: 'resPlan_date_to' };
      } else if (filter.fieldId === 'resourcePlan.date_from') {
        return { ...filter, fieldType: 'resPlan_date_from' };
      } else {
        return filter;
      }
    });

    newModifiedFilters.sort((a, b) => {
      const indexA = fieldPriority.indexOf(a.fieldId);
      const indexB = fieldPriority.indexOf(b.fieldId);

      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    });

    setTopFiltersList(newModifiedFilters.slice(0, topFilters));
    setRestFiltersList(newModifiedFilters.slice(topFilters));
  }, [filters, topFilters]);

  useEffect(() => {
    const isQueryNew = query !== fetchedQuery;
    setHasNewFilters(isQueryNew);
  }, [query, fetchedQuery]);
  // const fetchFiltersData = async query => {
  //   try {
  //     const response = await getResourcePlanFiltersQuery(query);
  //     console.log('resplanFilters', response?.data?.filters);
  //     return (response?.data)
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // useEffect(() => {
  //   fetchFiltersData(query);
  // }, [query]);

  const parseQuery = queryStr => {
    console.log('parseQuery');
    const items = queryStr.split('$$');
    const parsed = {};

    items.forEach(item => {
      const [key, operator, value] = item.split(';');
      // Проверим, содержит ли значение '||', и разделим его в таком случае. Если нет - вернем массив с одним значением.
      let values = '';
      if (value) {
        values = value.includes('||') ? value.split('||') : [value];
      }
      parsed[key] = {
        operator,
        value: values,
      };
    });
    console.log(parsed);
    return parsed;
  };

  const parsedQuery = useMemo(() => parseQuery(query), [query]);

  const [filterValues, setFilterValues] = useState({
    chosenFirstStepItems: {
      'resourcePlan.group_by': {
        valueName: 'GroupBy',
        type: 'eq',
        fieldType: 'group_by',
        subLabels: [
          {
            valueId: parsedQuery['resourcePlan.group_by'].value,
            isChecked: true,
          },
        ],
      },
      'resourcePlan.date_from': {
        valueName: 'DateFrom',
        type: 'gte',
        label: 'DateFrom',
        notParsedType: 'gte',
        fieldType: 'date',
        value: 'resourcePlan.date_from',
        subLabels: [
          {
            valueName: new Date(parsedQuery['resourcePlan.date_from'].value).toLocaleDateString(),
            valueId: parsedQuery['resourcePlan.date_from'].value,
          },
        ],
      },
      'resourcePlan.date_to': {
        valueName: 'DateTo',
        type: 'lte',
        label: 'DateTo',
        notParsedType: 'lte',
        fieldType: 'date',
        value: 'resourcePlan.date_to',
        subLabels: [
          {
            valueName: new Date(parsedQuery['resourcePlan.date_to'].value).toLocaleDateString(),
            valueId: parsedQuery['resourcePlan.date_to'].value,
          },
        ],
      },
      ...(parsedQuery['issue.project_list']?.value
        ? {
            'issue.project_list': {
              label: 'ProjectList',
              value: 'issue.project_list',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['issue.project_list'].value.map(val => ({
                valueId: val,
              })),
              valueName: 'ProjectList',
              fieldType: 'project',
            },
          }
        : {}),
      ...(parsedQuery['resourcePlan.business_unit']?.value
        ? {
            'resourcePlan.business_unit': {
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              label: 'Бизнес-юнит',
              value: 'resourcePlan.business_unit',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['resourcePlan.business_unit'].value.map(val => ({
                valueId: val,
              })),
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              valueName: 'Бизнес-юнит',
              fieldType: 'list',
            },
          }
        : {}),
      ...(parsedQuery['resourcePlan.user_list']?.value
        ? {
            'resourcePlan.user_list': {
              label: 'UserList',
              value: 'resourcePlan.user_list',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['resourcePlan.user_list'].value.map(val => ({
                valueId: val,
              })),
              valueName: 'UserList',
              fieldType: 'user_list',
            },
          }
        : {}),
      ...(parsedQuery['resourcePlan.contract_issues']?.value
        ? {
            'resourcePlan.contract_issues': {
              label: 'Contract Issue subject',
              value: 'resourcePlan.contract_issues',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['resourcePlan.contract_issues'].value.map(val => ({
                valueId: val,
              })),
              valueName: 'Contract Issue subject',
              fieldType: 'contract_issues',
            },
          }
        : {}),
      ...(parsedQuery['resourcePlan.issues']?.value
        ? {
            'resourcePlan.issues': {
              label: 'Issue subject',
              value: 'resourcePlan.issues',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['resourcePlan.issues'].value.map(val => ({
                valueId: val,
              })),
              valueName: 'Issue subject',
              fieldType: 'issues',
            },
          }
        : {}),
      ...(parsedQuery['resourcePlan.department']?.value
        ? {
            'resourcePlan.department': {
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              label: 'Департамент',
              value: 'resourcePlan.department',
              type: 'eq',
              notParsedType: 'eq',
              subLabels: parsedQuery['resourcePlan.department'].value.map(val => ({
                valueId: val,
              })),
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              valueName: 'Департамент',
              fieldType: 'list',
            },
          }
        : {}),
    },
    chosenSecondStepItems: {},
  });
  useEffect(() => {
    setFilterValues({
      chosenFirstStepItems: {
        'resourcePlan.group_by': {
          valueName: 'GroupBy',
          type: 'eq',
          fieldType: 'group_by',
          subLabels: [
            {
              valueId: parsedQuery['resourcePlan.group_by'].value,
              isChecked: true,
            },
          ],
        },
        'resourcePlan.date_from': {
          valueName: 'DateFrom',
          type: 'gte',
          label: 'DateFrom',
          notParsedType: 'gte',
          fieldType: 'date',
          value: 'resourcePlan.date_from',
          subLabels: [
            {
              valueName: new Date(parsedQuery['resourcePlan.date_from'].value).toLocaleDateString(),
              valueId: parsedQuery['resourcePlan.date_from'].value,
            },
          ],
        },
        'resourcePlan.date_to': {
          valueName: 'DateTo',
          type: 'lte',
          label: 'DateTo',
          notParsedType: 'lte',
          fieldType: 'date',
          value: 'resourcePlan.date_to',
          subLabels: [
            {
              valueName: new Date(parsedQuery['resourcePlan.date_to'].value).toLocaleDateString(),
              valueId: parsedQuery['resourcePlan.date_to'].value,
            },
          ],
        },
      },
      chosenSecondStepItems: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);
  const groupBySubLabels = filterValues?.chosenFirstStepItems['resourcePlan.group_by']?.subLabels;
  const currentValueID = groupBySubLabels?.[0]?.valueId; // предполагая, что вам нужно значение из первого элемента subLabels
  const [prevValueID, setPrevValueID] = useState(null);

  const classes = useStyles();
  const setFilterItems = useCallback(newFirstStepChosenValues => {
    setFilterValues(prevState => {
      Object.keys(newFirstStepChosenValues).map(value => {
        if (value.at(-1) === '_') {
          delete prevState.chosenFirstStepItems[value.slice(0, -1)];
        }
      });
      return {
        chosenFirstStepItems: {
          ...prevState.chosenFirstStepItems,
          ...newFirstStepChosenValues,
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeCurrentItemAndUpdateFilters = (item, filterValues, setFilterValues, forceUpdate) => {
    const chosenItemsWithoutCurrent = Object.entries(filterValues.chosenFirstStepItems).reduce((acc, [key, value]) => {
      if (value.valueName === 'Groupby') {
        return { ...acc, ['resourcePlan.group_by']: value };
      } else if (item.value !== value.value) {
        return { ...acc, [value.value]: value };
      }
      return acc;
    }, {});

    setFilterValues(prevState => ({
      chosenFirstStepItems: { ...chosenItemsWithoutCurrent },
    }));
  };
  useEffect(() => {
    if (Object.keys(filterValues.chosenFirstStepItems)?.length > 0) {
      let cleanedQuery = Object.entries(filterValues.chosenFirstStepItems)
        .map(([key, item]) => {
          if (item.type === 'null') {
            return `${item.value};${item.type}`;
          }
          if (item.type === 'all') {
            return;
          }
          if (key.split('').at(-1) !== '_') {
            if (item.fieldType === 'group_by') {
              item.type = 'eq';
              return item.subLabels?.length > 0 && item.subLabels[0].valueId !== ''
                ? `${key};${item.type};${item.subLabels[0].valueId}`
                : null;
            }

            if (item.type === '') {
              removeCurrentItemAndUpdateFilters(item, filterValues, setFilterValues, forceUpdate);
              return;
            }
            const values =
              item.subLabels && item.subLabels.length > 0 ? item.subLabels.map(subItem => subItem.valueId) : null;
            if (values !== undefined && values !== null) {
              return values.length > 1 && values[1] !== ''
                ? `${key};${item.type};${values.join('||')}`
                : values[0] !== ''
                ? `${key};${item.type};${values[0]}`
                : null;
            }
          }
        })
        .filter(item => item);
      cleanedQuery =
        cleanedQuery?.length > 0 ? (cleanedQuery?.length > 1 ? cleanedQuery.join('$$') : cleanedQuery[0]) : 'filters';
      if (cleanedQuery) {
        cleanedQuery = cleanedQuery.replace('date_from;eq', 'date_from;gte');
        cleanedQuery = cleanedQuery.replace('date_to;eq', 'date_to;lte');
      }
      if (query != cleanedQuery) {
        setQuery(cleanedQuery);
      }
      if (prevValueID !== null && currentValueID !== prevValueID) {
        dispatch(setResplanTableRows({ fields: [], rows: [] }));
        dispatch(clearResplanTableRows());
        dispatch(setStopLoadingRespanTableBySocket(true));
        setFetchedQuery(cleanedQuery);
      }

      setPrevValueID(currentValueID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, setFilterValues]);

  return (
    <>
      <Grid alignItems="center" display="inline-flex" marginLeft={0}>
        {topFiltersList.map((filter, index) => {
          const newFirstStepValues = {
            value: filter.fieldId,
            valueName: filter.fieldName,
            fieldType: filter.fieldType,
            type: filter.fieldType !== 'text' ? 'eq' : 'in',
          };
          const filterClass = classes.filterTopRow;
          let prevFirstStepItems;
          if (filter.field == 'group_by') {
            prevFirstStepItems = {
              'resourcePlan.group_by': {
                valueName: 'GroupBy',
                type: 'eq',
                fieldType: 'group_by',
                subLabels: [
                  {
                    valueId: parsedQuery['resourcePlan.group_by'].value,
                    isChecked: true,
                  },
                ],
              },
            };
            filter.values[1] = {
              isChecked: true,
              valueId: '1',
              valueName: 'Resource',
            };
          }
          if (filter.field == 'date_from') {
            prevFirstStepItems = {
              'resourcePlan.date_from': {
                valueName: 'DateFrom',
                type: 'gte',
                label: 'DateFrom',
                notParsedType: 'gte',
                fieldType: 'date',
                value: 'resourcePlan.date_from',
                subLabels: [
                  {
                    valueName: new Date(parsedQuery['resourcePlan.date_from'].value).toLocaleDateString(),
                    valueId: parsedQuery['resourcePlan.date_from'].value,
                  },
                ],
              },
            };
          }
          if (filter.field == 'date_to') {
            prevFirstStepItems = {
              'resourcePlan.date_to': {
                valueName: 'DateTo',
                type: 'lte',
                label: 'DateTo',
                notParsedType: 'lte',
                fieldType: 'date',
                value: 'resourcePlan.date_to',
                subLabels: [
                  {
                    valueName: new Date(parsedQuery['resourcePlan.date_to'].value).toLocaleDateString(),
                    valueId: parsedQuery['resourcePlan.date_to'].value,
                  },
                ],
              },
            };
          }
          return (
            <Grid item key={index}>
              <Box
                key={index}
                className={clsx(filterClass, {
                  [classes.filterWrapStatus]: filter.field == 'group_by',
                  [classes.filterWrapDisabledInput]:
                    filter.fieldType == 'text' ||
                    filter.fieldType == 'string' ||
                    filter.fieldType == 'bool' ||
                    filter.fieldType == 'boolean' ||
                    filter.fieldType == 'int' ||
                    filter.fieldType == 'float',
                })}
                marginBottom="22px"
                marginRight={index === topFiltersList.length - 2 ? 2 : 5}
              >
                <CustomFilter
                  intl={intl}
                  secondStepItems={filter.values || []}
                  chosenSecondStepItems={filterValues.chosenSecondStepItems}
                  setValues={setFilterItems}
                  defaultStep={1}
                  firstStepValues={newFirstStepValues}
                  chosenFirstStepItems={prevFirstStepItems}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={0}>
        {restFiltersList.map((filter, index) => {
          const newFirstStepValues = {
            value: filter.fieldId,
            valueName: filter.fieldName,
            fieldType: filter.fieldType,
            type: filter.fieldType !== 'text' ? 'eq' : 'in',
          };
          const filterClass = classes.filterWrap;
          if (filter.fieldType == 'user_list' && filter?.values[0]) {
            filter.values[0].valueName = getTranslatedText(intl, '', filter.values[0].valueName.toLowerCase(), 'Me');
          }
          let prevFirstStepItems;
          const secondStepItemsValues = filter.values || [];
          if (parsedQuery['issue.project_list']?.value && filter.field == 'project_list') {
            prevFirstStepItems = {
              'issue.project_list': {
                label: 'ProjectList',
                value: 'issue.project_list',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['issue.project_list'].value.map(val => ({
                  valueId: val,
                })),
                valueName: 'ProjectList',
                fieldType: 'project',
              },
            };
          }
          if (parsedQuery['resourcePlan.business_unit']?.value && filter.field == 'business_unit') {
            prevFirstStepItems = {
              'resourcePlan.business_unit': {
                // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                label: 'Бизнес-юнит',
                value: 'resourcePlan.business_unit',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['resourcePlan.business_unit'].value.map(val => ({
                  valueId: val,
                })),
                // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                valueName: 'Бизнес-юнит',
                fieldType: 'list',
              },
            };
          }
          if (parsedQuery['resourcePlan.user_list']?.value && filter.field == 'user_list') {
            prevFirstStepItems = {
              'resourcePlan.user_list': {
                label: 'UserList',
                value: 'resourcePlan.user_list',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['resourcePlan.user_list'].value.map(val => ({
                  valueId: val,
                })),
                valueName: 'UserList',
                fieldType: 'user_list',
              },
            };
          }
          if (parsedQuery['resourcePlan.contract_issues']?.value && filter.field == 'contract_issues') {
            prevFirstStepItems = {
              'resourcePlan.contract_issues': {
                label: 'Contract Issue subject',
                value: 'resourcePlan.contract_issues',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['resourcePlan.contract_issues'].value.map(val => ({
                  valueId: val,
                })),
                valueName: 'Contract Issue subject',
                fieldType: 'contract_issues',
              },
            };
          }
          if (parsedQuery['resourcePlan.issues']?.value && filter.field == 'issues') {
            prevFirstStepItems = {
              'resourcePlan.issues': {
                label: 'Issue subject',
                value: 'resourcePlan.issues',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['resourcePlan.issues'].value.map(val => ({
                  valueId: val,
                })),
                valueName: 'Issue subject',
                fieldType: 'issues',
              },
            };
          }
          if (parsedQuery['resourcePlan.department']?.value && filter.field == 'department') {
            prevFirstStepItems = {
              'resourcePlan.department': {
                // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                label: 'Департамент',
                value: 'resourcePlan.department',
                type: 'eq',
                notParsedType: 'eq',
                subLabels: parsedQuery['resourcePlan.department'].value.map(val => ({
                  valueId: val,
                })),
                // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                valueName: 'Департамент',
                fieldType: 'list',
              },
            };
          }

          return (
            <Grid item key={index}>
              <Box
                className={clsx(filterClass, {
                  [classes.filterWrapStatus]: filter.field == 'group_by',
                  [classes.filterWrapDisabledInput]:
                    filter.fieldType == 'text' ||
                    filter.fieldType == 'string' ||
                    filter.fieldType == 'bool' ||
                    filter.fieldType == 'boolean' ||
                    filter.fieldType == 'int' ||
                    filter.fieldType == 'float',
                })}
                marginBottom={3}
                marginRight={5}
              >
                <CustomFilter
                  intl={intl}
                  key={index + filter.values?.length + refreshKey}
                  parsedQuery={parsedQuery}
                  secondStepItems={secondStepItemsValues}
                  chosenSecondStepItems={filterValues.chosenSecondStepItems}
                  setValues={setFilterItems}
                  defaultStep={1}
                  resplan={true}
                  firstStepValues={newFirstStepValues}
                  chosenFirstStepItems={prevFirstStepItems}
                />
              </Box>
            </Grid>
          );
        })}
        {hasNewFilters ? (
          <Tooltip title={applyTooltip} PopperProps={{ className: classes.commentTooltip }} placement="top">
            <Button
              variant="defaultBluePrimaryWithIcon"
              onClick={() => {
                setQuery(query);
                dispatch(setResplanTableRows({ fields: [], rows: [] }));
                dispatch(clearResplanTableRows());
                dispatch(setStopLoadingRespanTableBySocket(true));
                setFetchedQuery(query);
              }}
              disableRipple
              className={classes.confirmButton}
            >
              <Box display="flex" alignItems="center">
                <DoneIcon color="#7174AC" width={20} height={20} viewBox="0 0 20 20" />
              </Box>
            </Button>
          </Tooltip>
        ) : (
          <Button variant="defaultGreyPrimaryWithIcon" disabled disableRipple className={classes.confirmButton}>
            <Box display="flex" alignItems="center">
              <DoneIcon color="#7174AC" width={20} height={20} viewBox="0 0 20 20" />
            </Box>
          </Button>
        )}
      </Grid>
    </>
  );
};
export default ResourcePlanFilter;
