import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.MuiModal-root': {
      zIndex: 2000,
    },
  },
  modalPaper: {
    backgroundColor: '#F1F2F8',
    borderRadius: theme.spacing(4),
    maxWidth: 592,
    maxHeight: '97%',
  },
  modalHeader: {
    padding: '20px 20px 20px 20px',
  },
  modalContent: {
    padding: '0px 20px 0px 20px',
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F1F2F8',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  modalFooter: {
    padding: '0px 20px 20px 20px',
  },
  filtersWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
    marginLeft: '0 !important',
    width: '100% !important',
  },
  formControlWrap: {
    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& .MuiGrid-root': {
      margin: '0 !important',
    },
  },
  formControl: {
    margin: '0 !important',
    padding: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
    '& .MuiSelect-select': {
      padding: '3px !important',
      minHeight: '36px !important',
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 3000,
    },
    '& .MuiPaper-root': {
      backgroundColor: '#ffffff',
      maxHeight: theme.spacing(81),
      borderRadius: theme.spacing(4),
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& ul': {
        padding: 0,
      },
    },
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    style: {
      maxHeight: 500,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346;',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px',
      marginRight: '8px !important',
      marginLeft: '4px !important',
    },
  },
  chip: {
    margin: 2,
    backgroundColor: '#FFF',
    '& .MuiChip-label': {
      padding: '8px 0px 8px 8px',
    },
  },
  menuItem: {
    height: theme.spacing(9),
    padding: '12px !important',
  },
  menuItemCheckbox: {
    padding: '0 !important',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  dragWrap: {
    width: '100% !important',
  },
  columnTtl: {
    padding: '0 16px 5px 16px',
    fontSize: '12px !important',
  },
  notSelectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiList-root': {
      width: '100%',
    },
    '& .MuiListItem-root': {
      padding: '0',
      width: '100%',
      '& .MuiListItemButton-root': {
        padding: '6px 16px',
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  notSelectedFieldsSearch: {
    borderBottom: '1px solid #E4E4EF',
    padding: '6px 16px',
    fontSize: '12px !important',
    color: '#7174AC',
  },
  selectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiListItem-root': {
      padding: '0',
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      // marginTop: '-5px',
      transform: 'none',
      left: '14px',
      top: '12px',
      '&.Mui-focused': {
        transform: 'none',
        left: '14px',
        top: '12px',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
}));

export default useStyles;
