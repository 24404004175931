import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Box, Select, Chip, MenuItem, Typography, FormControl, Checkbox } from '@mui/material';
import useStyles from './ResplanSettingsSelects.styles';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';

const ResplanSettingsSelects = ({ intl, settings, setSettings }) => {
  const classes = useStyles();
  const selectLabel = intl.formatMessage({ id: 'resplan_settings_choose' });
  const contractTracker = intl.formatMessage({ id: 'resplan_settings_contract_tracker' });
  const roleResource = intl.formatMessage({ id: 'resplan_settings_role_resource' });
  const dismissalsDate = intl.formatMessage({ id: 'resplan_settings_dismissals_date' });
  const department = intl.formatMessage({ id: 'resplan_settings_department' });
  const userAvailiability = intl.formatMessage({ id: 'resplan_settings_user_availiability' });
  const businessUnit = intl.formatMessage({ id: 'resplan_settings_business_unit' });
  const accessRole = intl.formatMessage({ id: 'resplan_settings_access_role' });

  const handleSelectChange = (event, settingKey, filterType) => {
    const newValueId = event.target.value;
    switch (filterType) {
      case 'value':
        setSettings(prevSettings => ({
          ...prevSettings,
          [settingKey]: prevSettings[settingKey].map(setting =>
            setting.valueId === newValueId
              ? { ...setting, isChecked: !setting.isChecked }
              : { ...setting, isChecked: false },
          ),
        }));
        break;
      case 'field':
        setSettings(prevSettings => ({
          ...prevSettings,
          [settingKey]: prevSettings[settingKey].map(setting =>
            setting.fieldId === newValueId
              ? { ...setting, isChecked: !setting.isChecked }
              : { ...setting, isChecked: false },
          ),
        }));
        break;
      case 'accessRole':
        const newValueIds = event.target.value;
        setSettings(prevSettings => ({
          ...prevSettings,
          [settingKey]: prevSettings[settingKey].map(setting => {
            const shouldBeChecked = newValueIds.includes(setting.valueId);
            return { ...setting, isChecked: shouldBeChecked };
          }),
        }));
        break;
    }
  };
  const handleRemoveRoles = useCallback(
    e => {
      const value = e.currentTarget.attributes.value.value;
      setSettings(prevSettings => ({
        ...prevSettings,
        accessRoles: prevSettings.accessRoles.map(setting =>
          setting.valueId === value ? { ...setting, isChecked: false } : { ...setting },
        ),
      }));
    },
    [setSettings],
  );

  return (
    <>
      <Typography marginBottom={1} variant="h5" color="#41424E">
        {accessRole}
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          multiple
          defaultValue={0}
          value={
            settings.accessRoles.every(setting => !setting.isChecked)
              ? [0]
              : settings.accessRoles.filter(setting => setting.isChecked).map(setting => setting.valueId)
          }
          MenuProps={{
            style: {
              maxHeight: 500,
            },
          }}
          IconComponent={() => (
            <Box className={classes.groupIcon}>
              <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
            </Box>
          )}
          onChange={event => handleSelectChange(event, 'accessRoles', 'accessRole')}
          renderValue={selected => {
            if (selected.length === 1 && selected[0] === 0) {
              return selectLabel;
            } else {
              return (
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  {selected.map(value => {
                    if (value < 0) {
                      return;
                    }
                    const chipLabel = settings.accessRoles.find(item => item.valueId == value).valueName;
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.chosenTotals}
                        key={value}
                      >
                        <Chip
                          label={chipLabel}
                          className={classes.totalsChip}
                          value={value}
                          deleteIcon={
                            <ClearIcon
                              width={16}
                              height={16}
                              value={value}
                              viewBox="0 0 24 24"
                              onMouseDown={event => event.stopPropagation()}
                              className={classes.totalsRemove}
                            />
                          }
                          onDelete={handleRemoveRoles}
                        />
                      </Box>
                    );
                  })}
                </Box>
              );
            }
          }}
        >
          <MenuItem disabled value={0} key={0}>
            <Typography variant="h4" color="#212346">
              {selectLabel}
            </Typography>
          </MenuItem>
          {settings.accessRoles
            .sort((a, b) => b.isChecked - a.isChecked)
            .map(setting => (
              <MenuItem key={setting.valueId} value={setting.valueId} className={classes.menuItemMultiply}>
                <Checkbox className={classes.menuItemCheckbox} checked={setting.isChecked} />
                <Typography variant="h4" color="#212346">
                  {setting.valueName}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Typography marginBottom={1} marginTop={5} variant="h5" color="#41424E">
        {contractTracker}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.contractTracker.find(setting => setting.isChecked)?.valueId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'contractTracker', 'value')}
        MenuProps={{ className: classes.optionsGroupMenu }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.contractTracker
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.valueId}
              value={setting.valueId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'contractTracker', 'value')}
            >
              <Typography variant="h4" color="#212346">
                {setting.valueName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      <Typography marginTop={5} marginBottom={1} variant="h5" color="#41424E">
        {roleResource}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.roleResource.find(setting => setting.isChecked)?.fieldId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'roleResource', 'field')}
        MenuProps={{ className: classes.optionsGroupMenu }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.roleResource
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.fieldId}
              value={setting.fieldId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'roleResource', 'field')}
            >
              <Typography variant="h4" color="#212346">
                {setting.fieldName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      <Typography marginTop={5} marginBottom={1} variant="h5" color="#41424E">
        {dismissalsDate}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.dismissalsDate.find(setting => setting.isChecked)?.fieldId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'dismissalsDate', 'field')}
        MenuProps={{ className: classes.optionsGroupMenu }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.dismissalsDate
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.fieldId}
              value={setting.fieldId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'dismissalsDate', 'field')}
            >
              <Typography variant="h4" color="#212346">
                {setting.fieldName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      <Typography marginTop={5} marginBottom={1} variant="h5" color="#41424E">
        {department}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.department.find(setting => setting.isChecked)?.fieldId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'department', 'field')}
        MenuProps={{ className: classes.optionsGroupMenu }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.department
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.fieldId}
              value={setting.fieldId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'department', 'field')}
            >
              <Typography variant="h4" color="#212346">
                {setting.fieldName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      <Typography marginTop={5} marginBottom={1} variant="h5" color="#41424E">
        {userAvailiability}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.userAvailability.find(setting => setting.isChecked)?.fieldId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'userAvailability', 'field')}
        MenuProps={{ className: classes.optionsGroupMenu }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.userAvailability
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.fieldId}
              value={setting.fieldId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'userAvailability', 'field')}
            >
              <Typography variant="h4" color="#212346">
                {setting.fieldName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
      <Typography marginTop={5} marginBottom={1} variant="h5" color="#41424E">
        {businessUnit}
      </Typography>
      <Select
        variant="outlined"
        IconComponent={() => (
          <Box className={classes.groupIcon}>
            <ChevronIcon direction="down" fontSize="small" viewBox="0 0 24 24" />
          </Box>
        )}
        value={settings.businessUnit.find(setting => setting.isChecked)?.fieldId || '0'}
        defaultValue={0}
        onChange={event => handleSelectChange(event, 'businessUnit', 'field')}
        MenuProps={{
          className: classes.optionsGroupMenu,
        }}
        className={classes.createSelect}
      >
        <MenuItem disabled value={0} key={0}>
          <Typography variant="h4" color="#212346">
            {selectLabel}
          </Typography>
        </MenuItem>
        {settings.businessUnit
          .sort((a, b) => b.isChecked - a.isChecked)
          .map(setting => (
            <MenuItem
              key={setting.fieldId}
              value={setting.fieldId}
              className={classes.menuItem}
              onClick={event => handleSelectChange(event, 'businessUnit', 'field')}
            >
              <Typography variant="h4" color="#212346">
                {setting.fieldName}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default injectIntl(ResplanSettingsSelects);
