import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  CalendarBodyRoot: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(5, 4, 1, 10),
    borderRadius: theme.spacing(4),
    [theme.breakpoints.maxWidth('lg')]: {
      padding: theme.spacing(4, 0, 4, 4),
    },
    [theme.breakpoints.maxWidth('xs')]: {
      padding: theme.spacing(5, 2.5),
    },
  },
}));

export default useStyles;
