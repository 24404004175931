import React from 'react';
import { injectIntl } from 'react-intl';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import useStyles from '../SettingsContainer.styles';
import SettingsTableCells from '../SettingsTableCells/SettingsTableCells';
import { TasksTypes } from '../SettingsData';

const SettingsTasksTypes = ({ intl }) => {
  const classes = useStyles();
  const taskType = intl.formatMessage({ id: 'settings_tasktype_type' });
  const field = intl.formatMessage({ id: 'settings_tasktype_placeholder' });
  const before = intl.formatMessage({ id: 'settings_tasktype_before' });
  const after = intl.formatMessage({ id: 'settings_tasktype_after' });
  const changeDate = intl.formatMessage({ id: 'settings_tasktype_change_date' });
  const author = intl.formatMessage({ id: 'settings_tasktype_author' });

  return (
    <div>
      <Table className={classes.settingsLowerTable}>
        <TableHead>
          <TableRow>
            <SettingsTableCells content={taskType} weight="700" />
            <SettingsTableCells content={field} weight="700" />
            <SettingsTableCells content={before} weight="700" />
            <SettingsTableCells content={after} weight="700" />
            <SettingsTableCells content={changeDate} weight="700" />
            <SettingsTableCells content={author} weight="700" />
          </TableRow>
        </TableHead>
        <TableBody>
          {TasksTypes.map(taskType => (
            <TableRow key={taskType.id}>
              <SettingsTableCells content={taskType.type} />
              <SettingsTableCells content={taskType.placeHolder} />
              <SettingsTableCells content={taskType.before} />
              <SettingsTableCells content={taskType.after} />
              <SettingsTableCells content={taskType.changeDate} />
              <SettingsTableCells content={taskType.author} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default injectIntl(SettingsTasksTypes);
