import { io } from 'socket.io-client';
import { useEffect, useState } from 'react';

export const useWebSockets = query => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const createSocket = () => {
      const newSocket = io(
        process.env.NODE_ENV === 'development'
          ? `${window.location.protocol}//${window.location.hostname}:${
              process.env.XL_BACKEND_PORT ? process.env.XL_BACKEND_PORT : 3000
            }/websocket`
          : `${window.location.origin}/websocket`,
        {
          path: '/socket.io',
          // query: {
          //   filters: query,
          // },
          transports: ['websocket'],
        },
      );

      return newSocket;
    };

    const newSocket = createSocket();

    if (socket) {
      socket.disconnect();
    }

    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return socket;
};
