import React from 'react';
import { Navigate } from 'react-router';

import BaseRouteLayout from '../base-routes/BaseRouteLayout';
import ErrorPage from '../base-routes/errors/Error404';
export default [
  {
    path: '/errors',
    element: <BaseRouteLayout />,
    children: [
      {
        path: 'error-401',
        element: <ErrorPage />,
      },
      {
        path: 'error-403',
        element: <ErrorPage />,
      },
      {
        path: 'error-404',
        element: <ErrorPage />,
      },
      {
        path: 'error-403',
        element: <ErrorPage />,
      },
      {
        path: 'error-400',
        element: <ErrorPage />,
      },
      {
        path: 'error-500',
        element: <ErrorPage />,
      },
      {
        path: 'error-502',
        element: <ErrorPage />,
      },
      { path: '*', element: <Navigate to="/errors/error-404" /> },
    ],
  },
];
