import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  LoginFormRoot: {
    maxWidth: 300,
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

export default useStyles;
