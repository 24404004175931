import React from 'react';
import { SvgIcon } from '@mui/material';

function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.66671 1.2735L8.72671 0.333496L5.00004 4.06016L1.27337 0.333496L0.333374 1.2735L4.06004 5.00016L0.333374 8.72683L1.27337 9.66683L5.00004 5.94016L8.72671 9.66683L9.66671 8.72683L5.94004 5.00016L9.66671 1.2735Z"
          fill="#7174AC"
        />
      </svg>
    </SvgIcon>
  );
}

export default DeleteIcon;
