import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  calendarExceptionRoot: {
    maxWidth: 228,
    borderRadius: theme.spacing(4),
    border: '1px solid #E4E4EF',
    borderCollapse: 'unset !important',
    marginBottom: theme.spacing(4),
    height: 'fit-content',
    minHeight: 110,
    [theme.breakpoints.maxWidth('lg')]: {
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.maxWidth('sm')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.maxWidth('xs')]: {
      marginRight: 0,
    },
  },
  tableRow: {
    height: 52,
    '& [class*=tableCell]:last-child': {
      borderRight: 0,
    },
    '& .MuiTableCell-sizeLarge': {
      borderRight: 0,
    },
  },
  tableCell: {
    borderRight: '1px solid #E4E4EF',
    width: 68,
    '&.MuiTableCell-sizeLarge': {
      width: 126,
    },
    '&.MuiTableCell-sizeSmall': {
      width: 36,
      padding: 0,
    },
  },
  tableCellSmall: {
    borderRight: '1px solid #E4E4EF',
    width: 68,
  },
  exclusions: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root:last-child': {
      borderRight: 'unset',
    },
    '& .MuiTableCell-root:first-child': {
      borderRight: '1px solid #E4E4EF',
    },
  },
}));

export default useStyles;
