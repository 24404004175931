const { makeStyles } = require('@mui/styles');

const useStyles = makeStyles(theme => ({
  editIssueButton: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#6270D0',
    },
  },
}));

export default useStyles;
