import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  IssueButtonRoot: {
    '&.MuiButton-root': {
      backgroundColor: '#ffffff',
      padding: theme.spacing(2.5, 2.5, 5),
      width: '100%',
      height: 'fit-content',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      '&:focus': {
        backgroundColor: '#ffffff',
      },
    },
  },
  issueProject: {
    wordBreak: 'break-all',
  },
}));

export default useStyles;
