import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  ResplanTableWrap: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    height: 52,
    '& [class*=tableCell]:last-child': {
      borderRight: 0,
    },
    '& .MuiTableRow-root': {
      borderRadius: theme.spacing(4),
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #e4e4ef',
      borderTop: 0,
      padding: 0,
    },
  },
  tableSettings: {
    backgroundColor: '#ffffff',
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #e4e4ef',
      borderTop: 0,
      padding: 0,
    },
    '& .MuiTableBody-root td:first-child': {
      borderRight: '1px solid #e4e4ef',
      paddingLeft: theme.spacing(3),
    },
    '& .MuiTableBody-root td:last-child': {
      borderLeft: '1px solid #e4e4ef',
    },
    '& .MuiTableBody-root': {
      borderBottom: '1px solid #e4e4ef',
      borderTop: '1px solid #e4e4ef',
    },
  },
  tableHead: {
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    zIndex: 1000,
  },
  tableCellHead: {
    borderRight: '1px solid #E4E4EF',
  },
  tableRow: {
    height: 52,
    '& [class*=tableCell]:last-child': {
      borderRight: 0,
    },
  },
  tableCell: {
    // borderRight: '1px solid #E4E4EF',
    // width: 220,
    padding: '10px !important',
    paddingRight: '2px !important',
    // '& .MuiTypography-root': {
    //   width: 200,
    // },
    // minWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dateCell: {
    // borderRight: '1px solid #E4E4EF',
    // minWidth: 100,
    padding: '10px !important',
  },
  perloadDateCell: {
    borderRight: '1px solid #E4E4EF',
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    paddingRight: '10px !important',
    paddingLeft: '10px !important',
    '& .MuiTypography-root': {
      width: 100,
      color: 'blue !important',
    },
    '& .MuiTableCell-root': {
      color: 'blue !important',
    },
    // borderBottom: '0 !important',
  },
  noBottom: {
    borderBottom: '0 !important',
  },
  totalCell: {
    borderRight: '1px solid #E4E4EF',
    textAlign: 'center !important',
    padding: '8px !important',
    width: 220,
  },
  perloadCell: {
    borderRight: '1px solid #E4E4EF',
    width: 220,
    '& .MuiTableCell-root': {
      padding: '0 !important',
    },
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 12000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      maxHeight: '40vh',
      overflow: 'auto',
      '& ul': {
        padding: 0,
      },
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  createSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
      opacity: '70%',
    },
    border: '0px',
    paddingLeft: '10px',
    paddingRight: '0px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100% !important',
    '& .MuiInput-root': {
      padding: 0,
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      width: '100% !important',
    },
    '& .MuiInput-input': {
      paddingRight: '0px !important',
      '&.Mui-disabled': {
        color: '#7174AC !important',
        '-webkit-text-fill-color': 'unset',
      },
      width: '100% !important',
    },
  },
  selectCell: {
    display: 'flex',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  tableCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      textAlign: 'left',
      padding: 0,
      marginRight: theme.spacing(8),
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: '#7174AC !important',
      },
    },
  },
  dateCellField: {
    position: 'relative',
    maxHeight: 20,
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      width: '100%',
      height: 20,
      border: '0 !important',
      textAlign: 'right',
      padding: 0,
      marginRight: theme.spacing(8),
    },
    '& .MuiInputBase-root.Mui-disabled input': {
      '-webkit-text-fill-color': 'unset',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      '-webkit-appearance': 'none',
    },
    '& input::placeholder': {
      color: '#7174AC',
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: '#7174AC !important',
      },
    },
  },
  tableCellCommentField: {
    '&:before': {
      content: '""',
      // width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      ...theme.typography.h5,
      marginRight: 0,
      textAlign: 'left',
    },
  },
  dateCellCommentField: {
    '&:before': {
      content: '""',
      // width: 15,
      height: '100%',
      position: 'absolute',
      right: 1,
      top: 0,
      zIndex: 100,
      background: 'linear-gradient(to right, transparent -15px, white)',
    },
    '& .MuiInputBase-input, & .MuiOutlinedInput-notchedOutline': {
      ...theme.typography.h5,
      marginRight: 0,
      textAlign: 'right',
    },
  },
  commentTooltip: {
    '& .MuiTooltip-tooltip': {
      marginTop: '0 !important',
      backgroundColor: '#F1F2F8',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2),
      color: '#212346',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '5px',
      '&.Mui-disabled': {
        color: '#7174AC !important',
        '-webkit-text-fill-color': 'unset',
      },
    },
  },
  tableCellLink: {
    '&:hover': {
      backgroundColor: '#DCDFF4',
      textDecoration: 'underline',
    },
  },
  redText: {
    color: 'red !important',
    '& .MuiOutlinedInput-root': {
      color: 'red !important',
    },
    '& .MuiTableCell-root': {
      color: 'red !important',
    },
    '& .MuiTypography-root': {
      color: 'red !important',
    },
  },
  blackText: {
    '& .MuiTableCell-root': {
      color: 'black !important',
    },
    '& .MuiTypography-root': {
      color: 'black !important',
    },
  },
}));

export default useStyles;
