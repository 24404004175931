import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import TextFieldMemo from '../../../../../packages/common/shared-ui/src/components/TextFieldMemo/TextFieldMemo';
import { EditPageContext } from '../EditPageContext';

const UserPageMainInfo = ({ intl, userInfo }) => {
  const edit = useContext(EditPageContext);
  return (
    <>
      <Typography p={0} variant="h3" sx={{ color: '#082253', fontWeight: '600', marginBottom: '23px' }}>
        {intl.formatMessage({ id: 'users_page_common_tab_information' })}
      </Typography>
      <Grid container direction={'column'} spacing={5} rowSpacing={edit ? 5 : 2}>
        {userInfo &&
          Object.entries(userInfo).map(
            (control, index) =>
              control[0] !== 'redmineId' && (
                <Grid item key={index}>
                  <TextFieldMemo
                    disabled={!edit}
                    value={control[1]}
                    label={intl.formatMessage({ id: `user_page_${control[0]}` })}
                  />
                </Grid>
              ),
          )}
      </Grid>
    </>
  );
};

export default injectIntl(UserPageMainInfo);
