import React from 'react';
import { SvgIcon } from '@mui/material';

function ArrowDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.66663 6.6665L7.99996 9.99984L11.3333 6.6665H4.66663Z" fill="#7174AC" />
      </svg>
    </SvgIcon>
  );
}

export default ArrowDownIcon;
