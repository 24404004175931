import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Page from 'Common/shared-ui/src/components/Page';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getResourcePlanFilters } from '../../../../packages/common/api';
import { CircularProgress, Grid, Button, Box, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import useStyles from './ResourcePlanContainer.styles';
import ResourcePlanTable from '../ResourcePlanTable/ResourcePlanTable';
import ResourcePlanDatagrid from '../ResourcePlanDatagrid/ResourcePlanDatagrid';
import ResourcePlanHeader from '../ResourcePlanHeader/ResourcePlanHeader';
import { getResourcePlanFiltersQuery } from '../../../../packages/common/api';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import useResPlanWebsockets from '../useResplanWebSockets';
import ResourcePlanSaveOptions from '../ResourcePlanSaveOptions/ResourcePlanSaveOptions';
import ResourcePlanCashModal from '../ResourcePlanCashModal';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import ResourcePlanUndefinedError from '../ResourcePlanUndefinedError/ResourcePlanUndefinedError';
import { setHasResplanUnsavedCash } from '../../app/redux/reducers/userSlice';
const ResourcePlanContainer = ({ timeTypes, intl }) => {
  const [filters, setFilters] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [headerFilters, setHeaderFilters] = React.useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [saveOption, setSaveOption] = useState('save_all');
  const [showSaveOptions, setShowSaveOptions] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [showDateCheck, setShowDateCheck] = useState(false);
  const [isStartDateIncorrect, setIsStartDateIncorrect] = useState(false);
  const [isEndDateIncorrect, setIsEndDateIncorrect] = useState(false);
  const [showUndefinedError, setShowUndefinedError] = useState(false);
  const [showIssuesCheck, setShowIssuesCheck] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const [incorrectDates, setIncorrectDates] = useState([]);
  const [isClearing, setIsClearing] = useState(false);
  const handleCashClose = () => {
    dispatch(setHasResplanUnsavedCash(false));
  };
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const startOfMonth = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`;
  const endOfYear = `${currentYear}-12-31`;
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const resplanHasUnsavedCash = useSelector(state => state.user?.hasResplanUnsavedCash);

  const [fetchedQuery, setFetchedQuery] = useState(
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    // `resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$resourcePlan.group_by;eq;1$$issue.project_list;eq;268`,
    // `resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$resourcePlan.group_by;eq;0`,
    `resourcePlan.group_by;eq;0$$resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}`,
    // `resourcePlan.group_by;eq;0$$resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$issue.project_list;eq;268`,
  );

  const [query, setQuery] = useState(
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    // `resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$resourcePlan.group_by;eq;1$$issue.project_list;eq;268`,
    // `resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$resourcePlan.group_by;eq;0`,
    `resourcePlan.group_by;eq;0$$resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}`,
    // `resourcePlan.group_by;eq;0$$resourcePlan.date_from;gte;${startOfMonth}$$resourcePlan.date_to;lte;${endOfYear}$$issue.project_list;eq;268`,
  );
  const pageTitle = intl.formatMessage({ id: 'page_title_resource_plan' });
  const {
    saveResPlan,
    checkIssuesUpdate,
    waitForCheckSaveResPlanError,
    waitForSaveResPlan,
    isTableLoading,
    updateResPlanByMount,
    updateResPlanByPercent,
    getResPlanTableData,
    addOrUpdateResPlanNewIssue,
    deleteResPlanNewIssue,
  } = useResPlanWebsockets(fetchedQuery, refreshKey, setShowUndefinedError);
  const fetchFiltersData = async () => {
    try {
      // setIsLoading(true);
      if (query) {
        console.log(query);
        const response = await getResourcePlanFiltersQuery(query);
        let dateFrom = response?.data?.filters[1];
        let dateTo = response?.data?.filters[2];
        dateFrom.fieldType = 'resPlan_date_from';
        dateTo.fieldType = 'resPlan_date_to';
        response.data.filters[1] = dateTo;
        response.data.filters[2] = dateFrom;
        setHeaderFilters(response?.data?.filters);
        setIsLoading(false);
        console.log('resplanFilters', response?.data?.filters);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    console.log('resplanUseEffect');
    fetchFiltersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, refreshKey]);

  const handleButtonClick = React.useCallback(async () => {
    console.log('handleButtonClick');
    setIsGridLoading(true);
    saveResPlan(saveOption);
    const fetchedSaveData = await waitForSaveResPlan();
    setQuery(fetchedQuery);
    setFetchedQuery(query);
    setShowSaveOptions(false);
    setShowIssuesCheck(false);
    setIsGridLoading(false);
    getResPlanTableData();
  }, [fetchedQuery, getResPlanTableData, query, saveOption, saveResPlan, waitForSaveResPlan]);
  const handleClearChangings = React.useCallback(async () => {
    setIsGridLoading(true);
    saveResPlan('cancel_changes');
    const fetchedSaveData = await waitForSaveResPlan();
    setQuery(fetchedQuery);
    setFetchedQuery(query);
    setIsClearing(false);
    setIsGridLoading(false);
    getResPlanTableData();
  }, [fetchedQuery, getResPlanTableData, query, saveResPlan, waitForSaveResPlan]);

  const handleIssuesCheck = React.useCallback(() => {
    const rowIds = apiRef?.current?.getAllRowIds();
    let rowsData = [];
    rowIds?.forEach(id => {
      const rowData = apiRef?.current?.getRow(id);
      if (rowData?.services?.isNew === true) {
        rowData?.columns?.map(item => {
          if (item.fieldId === 'resourcePlan.issue') {
            if (!item.value) {
              console.log(rowData);
              rowsData.push(rowData);
            }
          }
        });
      }
    });
    return rowsData;
  }, [apiRef]);
  const fetchIssuesUpdate = React.useCallback(async () => {
    setShowSaveOptions(false);
    checkIssuesUpdate();
    const fetchedErrorData = await waitForCheckSaveResPlanError();
    setErrorData(fetchedErrorData);
    if (fetchedErrorData.length === 0) {
      handleButtonClick();
    } else {
      setShowSaveOptions(true);
      setIsLoading(false);
      setIsGridLoading(false);
    }
    setIsSaving(false);
  }, [checkIssuesUpdate, handleButtonClick, waitForCheckSaveResPlanError]);
  useEffect(() => {
    const checkAndSave = async () => {
      console.log('Checking issues');
      const incorrectIssues = await handleIssuesCheck();
      console.log(incorrectIssues);
      if (incorrectIssues.length === 0) {
        setShowIssuesCheck(false);
        console.log('Issues are correct');
        await fetchIssuesUpdate();
      } else {
        console.log('There are incorrect issues');
        setShowIssuesCheck(true);
        setIsGridLoading(false);
        setIsSaving(false);
      }
    };
    if (isSaving) {
      setIsGridLoading(true);
      checkAndSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  useEffect(() => {
    if (isClearing) {
      setIsGridLoading(true);
      handleClearChangings();
    }
  }, [handleClearChangings, handleIssuesCheck, isClearing]);
  useEffect(() => {
    if (incorrectDates?.isIncorrect === 'resourcePlan.date_from') {
      setIsEndDateIncorrect(false);
      setIsStartDateIncorrect(true);
    } else if (incorrectDates?.isIncorrect === 'resourcePlan.date_to') {
      setIsEndDateIncorrect(true);
      setIsStartDateIncorrect(false);
    } else {
      setIsEndDateIncorrect(false);
      setIsStartDateIncorrect(false);
    }
  }, [incorrectDates, fetchedQuery]);
  // useEffect(() => {
  //   const fetchIssuesUpdate = async () => {
  //     setShowSaveOptions(false);
  //     checkIssuesUpdate();
  //     const fetchedErrorData = await waitForCheckSaveResPlanError();
  //     setErrorData(fetchedErrorData);
  //     if (fetchedErrorData.length === 0) {
  //       handleButtonClick();
  //     } else {
  //       setShowSaveOptions(true);
  //       setIsLoading(false);
  //     }
  //     setTriggerFetch(false);
  //     setIsSaving(false);
  //   };

  //   if (triggerFetch || isSaving) {
  //     fetchIssuesUpdate();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [triggerFetch, isSaving]);

  return (
    <>
      {!isLoading ? (
        <Page title={pageTitle} width="100%">
          <ResourcePlanHeader
            timeTypes={timeTypes}
            setRefreshKey={setRefreshKey}
            pageTitle={pageTitle}
            headerFilters={headerFilters}
            setQuery={setQuery}
            query={query}
            setFetchedQuery={setFetchedQuery}
            setIsSaving={setIsSaving}
            setIsLoading={setIsLoading}
            setIsClearing={setIsClearing}
            incorrectDates={incorrectDates}
            fetchedQuery={fetchedQuery}
            refreshKey={refreshKey}
          />
          {showSaveOptions && (
            <ResourcePlanSaveOptions
              errorData={errorData}
              saveOption={saveOption}
              setSaveOption={setSaveOption}
              handleButtonClick={handleButtonClick}
            />
          )}
          {showUndefinedError && (
            <ResourcePlanUndefinedError
              setIsClearing={setIsClearing}
              setIsSaving={setIsSaving}
              setShowUndefinedError={setShowUndefinedError}
            />
          )}
          {isStartDateIncorrect && (
            <Grid marginBottom={2}>
              <Typography variant="h3" fontWeight={600} color="#E03737">
                <FormattedMessage id="resPlan_dateFrom_error" />
              </Typography>
            </Grid>
          )}
          {isEndDateIncorrect && (
            <Grid marginBottom={2}>
              <Typography variant="h3" fontWeight={600} color="#E03737">
                <FormattedMessage id="resPlan_dateTo_error" />
              </Typography>
            </Grid>
          )}
          {showIssuesCheck && (
            <Grid marginBottom={2}>
              <Typography variant="h3" fontWeight={600} color="#E03737">
                <FormattedMessage id="resPlan_emptyFields_error" />
              </Typography>
            </Grid>
          )}
          {!isGridLoading ? (
            <ResourcePlanDatagrid
              fetchedQuery={fetchedQuery}
              isTableLoading={isTableLoading}
              updateResPlanByMount={updateResPlanByMount}
              updateResPlanByPercent={updateResPlanByPercent}
              addOrUpdateResPlanNewIssue={addOrUpdateResPlanNewIssue}
              apiRef={apiRef}
              showIssuesCheck={showIssuesCheck}
              isGridLoading={isGridLoading}
              deleteResPlanNewIssue={deleteResPlanNewIssue}
              // handleShowDateCheck={handleShowDateCheck}
              incorrectDates={incorrectDates}
              setIncorrectDates={setIncorrectDates}
            />
          ) : (
            <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
              <CircularProgress color="secondary" />
            </Grid>
          )}
          <ResourcePlanCashModal
            isCashOpen={resplanHasUnsavedCash}
            intl={intl}
            setIsClearing={setIsClearing}
            setIsSaving={setIsSaving}
            handleCashClose={handleCashClose}
          />
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ResourcePlanContainer);
