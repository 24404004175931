const RedmineIssueHistoryRelations = {
  COPIED_TO: 'copied_to',
  PRECEDES: 'precedes',
  BLOCKED: 'blocked',
  DUPLICATED: 'duplicated',
  COPIED_FROM: 'copied_from',
  BLOCKS: 'blocks',
  FOLLOWS: 'follows',
  DUPLICATES: 'duplicates',
  RELATES: 'relates',
};
export const relationsSelectValues = [
  {
    valueId: RedmineIssueHistoryRelations.COPIED_TO,
    valueName: 'Copied to',
  },
  {
    valueId: RedmineIssueHistoryRelations.PRECEDES,
    valueName: 'Precedes',
  },
  {
    valueId: RedmineIssueHistoryRelations.BLOCKED,
    valueName: 'Blocked by',
  },
  {
    valueId: RedmineIssueHistoryRelations.DUPLICATED,
    valueName: 'Has duplicate',
  },
  {
    valueId: RedmineIssueHistoryRelations.COPIED_FROM,
    valueName: 'Copied from',
  },
  {
    valueId: RedmineIssueHistoryRelations.BLOCKS,
    valueName: 'Blocks',
  },
  {
    valueId: RedmineIssueHistoryRelations.FOLLOWS,
    valueName: 'Follows',
  },
  {
    valueId: RedmineIssueHistoryRelations.DUPLICATES,
    valueName: 'Is duplicate of',
  },
  {
    valueId: RedmineIssueHistoryRelations.RELATES,
    valueName: 'Related to',
  },
];
