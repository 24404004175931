import React, { useCallback } from 'react';
import useStyles from './SpreadsheetMobileIssueButton.styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const SpreadsheetMobileIssueButton = ({ projectIssue, timeTypes, timeType, query, setQuery }) => {
  const classes = useStyles();

  const handleOpenDetailIssue = useCallback(() => {
    setQuery({ issue: projectIssue.issueUniqueId });
  }, [projectIssue.issueUniqueId, setQuery]);

  return (
    <Button className={classes.IssueButtonRoot} disableRipple onClick={handleOpenDetailIssue}>
      <Grid container direction="column">
        <Box marginBottom={2.5} className={classes.issueProject}>
          <Typography variant="h4" align="left" fontWeight={600}>
            {projectIssue.projectName}
          </Typography>
        </Box>
        <Box marginBottom={2.5}>
          <Typography variant="h5" color="#111322" align="left">
            <FormattedMessage id="spreadsheet_table_issues" />:
          </Typography>
          <Typography variant="h4" align="left">
            {projectIssue.issueName}
          </Typography>
        </Box>
        <Box>
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h5" align="left">
            <FormattedMessage id="spreadsheet_week_sum" />:
          </Typography>
          <Typography variant="h4" align="left">
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            {timeType === timeTypes.MAIN_HOURS ? projectIssue.totalNormalHours : projectIssue.totalOvertimeHours} ч
          </Typography>
        </Box>
      </Grid>
    </Button>
  );
};

export default SpreadsheetMobileIssueButton;
