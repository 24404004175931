import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import useStyles from './CalculationTypes.styles';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import PlusIcon from '../../../../packages/common/shared-ui/src/icons/PlusIcon';
import UserNewEditIcon from '../../../../packages/common/shared-ui/src/icons/UserNewEditIcon';
import ReportDeleteIcon from '../../../../packages/common/shared-ui/src/icons/ReportDeleteIcon';
import LockIcon from '../../../../packages/common/shared-ui/src/icons/LockIcon';
import SubmitIcon from '../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import {
  blockCalculationType,
  deleteCalculationType,
  deleteTracker,
  editCalculationType,
  getCalculationTypes,
  getTrackers,
  saveCalculationType,
  saveTracker,
  unblockCalculationType,
} from '../../../../packages/common/api';
import UnblockIcon from '../../../../packages/common/shared-ui/src/icons/UnblockIcon';
import { useDebouncedFunction } from '../../../../packages/common/utils/debounce';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';

const CalculationTypes = ({ intl }) => {
  const classes = useStyles();
  const pageTitle = intl.formatMessage({ id: 'menu_item_administration_calculationTypes' });
  const [types, setTypes] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [selectTrackers, setSelectTrackers] = useState([]);
  const [error, setError] = useState({ type: null, name: null, status: false });
  const Type = { CALCULATION: 'calculation', TRACKER: 'tracker' };

  const getTypes = useCallback(async () => {
    try {
      const { data: types } = await getCalculationTypes();
      setTypes(types);
    } catch (error) {
      console.error('ERROR WITH GET TYPES', error);
    }
  }, [setTypes]);

  const getObjectTrackers = useCallback(async () => {
    try {
      const { data: trackers } = await getTrackers();
      setTrackers(trackers.filter(item => item.isChecked));
      setSelectTrackers(trackers.filter(item => !item.isChecked));
    } catch (error) {
      console.error('ERROR WITH GET TRACKERS', error);
    }
  }, []);

  useEffect(() => {
    getTypes();
  }, [getTypes]);
  useEffect(() => {
    getObjectTrackers();
  }, [getObjectTrackers]);

  const handleTextField = useCallback(
    (event, index) => {
      const updatedObject = { ...types[index], name: event.target.value };
      setTypes([...types.slice(0, index), updatedObject, ...types.slice(index + 1)]);
    },
    [types],
  );
  const handleSelectField = useCallback(
    (event, index) => {
      const itemId = selectTrackers.find(item => item.valueName === event.target.value);
      const updatedObject = { ...trackers[index], valueName: event.target.value, valueId: itemId.valueId };
      setTrackers([...trackers.slice(0, index), updatedObject, ...trackers.slice(index + 1)]);
    },
    [selectTrackers, trackers],
  );
  const textField = useCallback(
    index => {
      return (
        <TextField
          autoFocus
          key={index}
          className={classes.rowInput}
          placeholder={intl.formatMessage({ id: 'enter' })}
          variant="standard"
          defaultValue={types[index].name}
          onChange={event => handleTextField(event, index)}
        ></TextField>
      );
    },
    [classes.rowInput, handleTextField, intl, types],
  );
  const selectField = useCallback(
    index => {
      return (
        <FormControl sx={{ width: '100%' }}>
          <InputLabel className={classes.selectLabel} id="select-label">
            {intl.formatMessage({ id: 'choose' })}
          </InputLabel>
          <Select
            labelId="select-label"
            label={intl.formatMessage({ id: 'choose' })}
            onChange={event => handleSelectField(event, index)}
            variant="standard"
            sx={{ width: '100%', border: 'none' }}
            value={trackers[index].valueName}
            className={classes.rowSelect}
            MenuProps={{ classes: { paper: classes.paperDropdown } }}
            IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
          >
            {selectTrackers.map((item, index) => (
              <MenuItem key={index} value={item.valueName}>
                {item.valueName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    },
    [classes.paperDropdown, classes.rowSelect, classes.selectLabel, handleSelectField, intl, selectTrackers, trackers],
  );
  const Error = ({ name }) => {
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    const textString = `Тип расчета ${name} уже существует`;
    return (
      <Typography padding={'4px 0'} lineHeight={'20px'} fontSize={16} fontWeight={600} color="#E03737">
        {textString}
      </Typography>
    );
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const typeMap = new Map([
    [
      Type.CALCULATION,
      {
        handler: saveCalculationType,
        deleteHandler: deleteCalculationType,
        input: textField,
        currentValue: types,
        getList: getTypes,
        setter: setTypes,
      },
    ],
    [
      Type.TRACKER,
      {
        handler: saveTracker,
        deleteHandler: deleteTracker,
        input: selectField,
        currentValue: trackers,
        getList: getObjectTrackers,
        setter: setTrackers,
      },
    ],
  ]);
  const saveEditHandler = useCallback(
    async (index, type) => {
      const itemId = types[index].id;
      const itemName = { name: types[index].name };
      const checkValue = types.filter(el => el.name === itemName.name);
      if (checkValue.length > 1) {
        setError({ type: type, name: itemName.name, status: true });
        return;
      }
      try {
        const response = await editCalculationType(itemId, itemName);
        if (response) {
          setError({ type: type, name: itemName.name, status: false });
          getTypes();
        }
      } catch (error) {
        console.error('ERROR WITH EDIT', error);
      }
    },
    [getTypes, types],
  );
  const saveHandler = useCallback(
    async (index, type) => {
      const item = typeMap.get(type).currentValue[index];
      const itemValue = item.name ? item.name : item.valueName;
      if (!itemValue) {
        return;
      }
      const checkValue = typeMap
        .get(type)
        .currentValue.filter(el => (el.name ? el.name === item.name : el.valueName === item.valueName));
      if (checkValue.length > 1) {
        setError({ type: type, name: itemValue, status: true });
        return;
      }
      const valueToPost =
        type === Type.CALCULATION ? { name: types[index].name } : { trackerId: trackers[index].valueId };
      try {
        const response = await typeMap.get(type).handler(valueToPost);
        if (response) {
          setError({ type: type, name: itemValue, status: false });
          typeMap.get(type).getList();
        }
      } catch (error) {
        console.error('ERROR WITH SAVE', error);
      }
    },
    [Type.CALCULATION, trackers, typeMap, types],
  );

  const deleteHandler = useCallback(
    async (index, type) => {
      const id =
        type === Type.CALCULATION
          ? typeMap.get(type).currentValue[index].id
          : typeMap.get(type).currentValue[index].valueId;
      if (typeMap.get(type).currentValue[index].new) {
        const newArray = typeMap.get(type).currentValue.filter((value, i) => i !== index);
        typeMap.get(type).setter(newArray);
        return;
      }
      try {
        const response = await typeMap.get(type).deleteHandler(id);
        if (response) {
          typeMap.get(type).getList();
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [Type.CALCULATION, typeMap],
  );

  const lockHandler = useCallback(
    async (index, type) => {
      const item = { id: types[index].id };
      try {
        const response = await blockCalculationType(item);
        if (response) {
          getTypes();
        }
      } catch (error) {
        console.error('ERROR WITH LOCK', error);
      }
    },
    [getTypes, types],
  );

  const unlockHandler = useCallback(
    async (index, type) => {
      const item = { id: types[index].id };
      try {
        const response = await unblockCalculationType(item);
        if (response) {
          getTypes();
        }
      } catch (error) {
        console.error('ERROR WITH UNLOCK', error);
      }
    },
    [getTypes, types],
  );

  const toggleEdit = useCallback(
    (index, type) => {
      const updatedObject = { ...types[index], edit: true };
      setTypes([...types.slice(0, index), updatedObject, ...types.slice(index + 1)]);
    },
    [types],
  );
  const addTypeHandler = useCallback(() => {
    setTypes([...types, { name: '', new: true }]);
  }, [types]);

  const addObjectHandler = useCallback(() => {
    setTrackers([...trackers, { valueName: '', new: true }]);
  }, [trackers]);

  const entityMap = new Map([
    ['edit', { handler: toggleEdit, icon: <UserNewEditIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['submit', { handler: saveHandler, icon: <SubmitIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['submitEdit', { handler: saveEditHandler, icon: <SubmitIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['delete', { handler: deleteHandler, icon: <ReportDeleteIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['block', { handler: lockHandler, icon: <LockIcon width="16" height="16" viewBox="0 0 16 16" /> }],
    ['unblock', { handler: unlockHandler, icon: <UnblockIcon width="16" height="16" viewBox="0 0 16 16" /> }],
  ]);

  const RowButton = ({ children, action, rowIndex, type }) => {
    children = entityMap.get(action).icon;
    return (
      <IconButton onClick={() => entityMap.get(action).handler(rowIndex, type)} className={classes.rowButton}>
        {children}
      </IconButton>
    );
  };

  const TypeItemComponent = ({ item, rowIndex, type }) => {
    let buttons = ['delete'];
    type !== Type.TRACKER && (buttons = ['edit', 'delete', 'block']);
    item.new && (buttons = ['submit', 'delete']);
    item.edit && type !== Type.TRACKER && (buttons = ['submitEdit', 'delete', 'block']);
    !item.active && type !== Type.TRACKER && !item.new && (buttons = ['edit', 'delete', 'unblock']);
    const value = item.name ? item.name : item.valueName;
    return (
      <Grid container className={classes.borderedItem}>
        <Grid item sx={{ flex: 1, p: item.new && type === Type.TRACKER ? 0 : '10px', fontSize: '14px' }}>
          {item.edit || item.new ? typeMap.get(type).input(rowIndex) : value}
        </Grid>
        <Grid item>
          {buttons.map((action, index) => (
            <RowButton key={index} action={action} rowIndex={rowIndex} type={type}></RowButton>
          ))}
        </Grid>
      </Grid>
    );
  };
  return (
    <Page title={pageTitle} width="100%">
      <Typography m={0} padding={'10px 0'} variant="h1" sx={{ mt: '10px', mb: '30px' }}>
        {pageTitle}
      </Typography>
      <Grid container>
        <Grid item lg={6}>
          {error.status && error.type === Type.CALCULATION && <Error name={error.name}></Error>}
        </Grid>
        <Grid item lg={6}>
          {error.status && error.type === Type.TRACKER && <Error name={error.name}></Error>}
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item lg={6}>
          <Box className={classes.settingsWrap}>
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <Typography variant="h4" sx={{ padding: '16px 10px', fontWeight: 'bold' }}>
              Типы расчета
            </Typography>
            {types.map((item, index) => (
              <TypeItemComponent key={index} item={item} rowIndex={index} type={Type.CALCULATION}></TypeItemComponent>
            ))}
            <Box className={classes.borderedItem}>
              <IconButton onClick={addTypeHandler}>
                <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box className={classes.settingsWrap}>
            <Typography variant="h4" sx={{ padding: '16px 10px', fontWeight: 'bold' }}>
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              Объекты
            </Typography>
            {trackers.map((item, index) => (
              <TypeItemComponent key={index} item={item} rowIndex={index} type={Type.TRACKER}></TypeItemComponent>
            ))}
            <Box className={classes.borderedItem}>
              <IconButton onClick={addObjectHandler}>
                <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
export default injectIntl(CalculationTypes);
