import React from 'react';
import { SvgIcon } from '@mui/material';

function CalendarFormIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14828_12532)">
          <path
            d="M12.4847 3.09091H11.9392V2H10.8483V3.09091H5.39378V2H4.30287V3.09091H3.75741C3.15741 3.09091 2.6665 3.58182 2.6665 4.18182V12.9091C2.6665 13.5091 3.15741 14 3.75741 14H12.4847C13.0847 14 13.5756 13.5091 13.5756 12.9091V4.18182C13.5756 3.58182 13.0847 3.09091 12.4847 3.09091ZM12.4847 12.9091H3.75741V5.81818H12.4847V12.9091Z"
            fill="#7174AC"
          />
        </g>
        <defs>
          <clipPath id="clip0_14828_12532">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default CalendarFormIcon;
