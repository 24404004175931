import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  settingsWrap: {
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '19px 64px 17px 19px !important',
  },
  filtersWrap: {
    margin: '0 !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiGrid-item': {
      padding: '0 !important',
    },
    '& $formControlWrap': {
      '& $formControl': {
        '& .MuiChip-deleteIcon': {
          position: 'relative',
          right: 0,
        },
      },
    },
  },
  notSelectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiList-root': {
      width: '100%',
    },
    '& .MuiListItem-root': {
      padding: '0',
      width: '100%',
      '& .MuiListItemButton-root': {
        padding: '6px 16px',
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  columnTtl: {
    padding: '0 16px 5px 16px',
    fontSize: '12px !important',
  },
  notSelectedFieldsSearch: {
    borderBottom: '1px solid #E4E4EF',
    padding: '6px 16px',
    fontSize: '12px !important',
    color: '#7174AC',
  },
  selectedFieldsWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    paddingTop: '8px',
    '& .MuiListItem-root': {
      padding: '0',
    },
  },
  formControl: {
    margin: '0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
  },
  menuItemCheckbox: {
    color: 'red',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  saveSettingButton: {
    borderRadius: '16px !important',
  },
  notSelectedFields: {
    border: '1px solid #E4E4EF !important',
    borderRadius: '16px !important',
    height: 'auto !important',
    width: '100%',
  },
  selectedFields: {
    fontSize: '12px !important',
    '& .MuiListItemButton-root': {
      fontSize: '12px !important',
      padding: '6px 16px',
    },
    '& .MuiTypography-root': {
      fontSize: '12px !important',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: '#212346;',
    gap: '5px',
    '& .MuiChip-root': {
      margin: 2,
      backgroundColor: '#FFF',
      border: '1px solid #E4E4EF',
      borderRadius: '14px',
    },
    '& .MuiChip-deleteIcon': {
      height: '10px',
    },
  },
  formControlWrap: {
    padding: 'px 0px 3px 3px',
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
  },
  chip: {
    margin: 2,
    backgroundColor: '#FFF',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
