import React, { useCallback, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import LoginForm from '../LoginForm/LoginForm';
import useStyles from './LeftContainer.styles';
import { signInWithLoginOrEmail } from '../actions';
import { useDispatch } from 'react-redux';
import cookies from 'Common/utils/cookies';
import { injectIntl } from 'react-intl';

const LeftContainer = ({ intl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSaveData, setUserSaveData] = useState(false);

  const { current: handleChangeDataSave } = useRef(() => {
    setUserSaveData(prevState => !prevState);
  });

  const formatAuthError = useCallback(
    err => {
      switch (err) {
        case 'Unauthorized': {
          return intl.formatMessage({ id: 'error_unauthorized' });
        }
        default:
          return intl.formatMessage({ id: 'error_unknown' });
      }
    },
    [intl],
  );

  const handleSignIn = useCallback(
    async ({ username, password, setErrors }) => {
      const { payload: userResponse } = await dispatch(signInWithLoginOrEmail({ username, password }));
      if (userResponse.error) {
        return setErrors({
          username: formatAuthError(userResponse.error),
          password: formatAuthError(userResponse.error),
          requestAnswer: formatAuthError(userResponse.error),
        });
      }
      return cookies.setCookie({
        key: 'access_token',
        value: userResponse.access_token,
        host: `${window.location.protocol}//${window.location.hostname}`,
      });
    },
    [dispatch, formatAuthError],
  );

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.LeftContainerRoot}>
      <LoginForm
        isSaveData={isSaveData}
        setUserSaveData={setUserSaveData}
        handleChangeDataSave={handleChangeDataSave}
        handleSignIn={handleSignIn}
      />
    </Grid>
  );
};

export default injectIntl(LeftContainer);
