import React, { useCallback, useEffect, useState } from 'react';
import {
  checkIsButtonDisplayed,
  createOrUpdateTimesheets,
  deleteTimesheets,
  getSpentTimeFilters,
  getSpentTimes,
  getTimesheetProjectInfo,
  queryForApproveTimesheet,
} from '../../../packages/common/api';
import { useQueryParams } from 'use-query-params';
import { useLocation } from 'react-router';

export const withSpentTimeRequests =
  Component =>
  ({ ...props }) => {
    const location = useLocation();
    const [timesheetsData, setTimesheetsData] = useState([]);
    const [timesheetsColumns, setTimesheetsColumns] = useState([]);
    const [allFilters, setAllFilters] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOptionsFetching, setIsOptionsFetching] = useState(true);
    const [tableErrorMessage, setTableErrorMessage] = useState(null);
    const [isAddButtonDisplayed, setIsAddButtonDisplayed] = useState(false);
    const [tableSelectedValues, setTableSelectedValues] = useState([]);

    const [query, setQuery] = useQueryParams({
      limit: '100',
      offset: '0',
      page: '1',
      filters: '',
      locked: '',
      totals: '',
      groups: '',
      fields: '',
      sorts: '',
      pivot: '',
      savedQueryId: '',
    });
    const [currentType, setCurrentType] = useState(Number(query.pivot?.length > 0));

    const getAllFilters = useCallback(async () => {
      try {
        const { data: allFilters } = await getSpentTimeFilters();
        if (allFilters.length > 0) {
          allFilters.forEach((element, index) => {
            if (element.fieldId === query.locked) {
              element.locked = true;
            }
          });
        }

        setAllFilters(allFilters);
        return allFilters;
      } catch (error) {
        console.error(error, 'ERROR IN GET ALL FILTERS');
      }
    }, [query.locked]);

    const getNewRowType = useCallback(value => {
      switch (value) {
        case '>t-':
          return 'gt';
        case '<t-':
          return 'lt';
        case '><t-':
          return 'gt';
        case 't-':
          return 'eq';
        default:
          return value;
      }
    }, []);

    const updateTable = useCallback(
      async ({ fields, groups, totals, filters }) => {
        try {
          const formattedFiltersQuery = filters
            ?.split('$$')
            .map(filter => {
              const splittedFilter = filter.split(';');
              const regex = /^[a-z]$/i;
              const typeFirstLetter = splittedFilter.length > 1 ? splittedFilter[1][0] : '';
              if (regex.test(typeFirstLetter) && typeFirstLetter === typeFirstLetter.toUpperCase()) {
                splittedFilter[1] = splittedFilter.slice(2).length > 1 ? 'btw' : 'eq';
              } else {
                splittedFilter[1] = getNewRowType(splittedFilter[1]);
              }
              return splittedFilter.filter(item => item).join(';');
            })
            .join('$$');
          setIsOptionsFetching(true);
          setTimesheetsData([]);
          const { data: timesheets } = await getSpentTimes({
            sorts: query.sorts,
            limit: Number(query.limit),
            offset: 0,
            filters: formattedFiltersQuery,
            totals: totals,
            fields: fields,
            groups,
            pivot: query.pivot,
          });
          setTimesheetsData(timesheets.rows);
          setTimesheetsColumns(timesheets.fields);
          setIsOptionsFetching(false);
        } catch (error) {
          console.error(error);
        }
      },
      [getNewRowType, query.limit, query.pivot, query.sorts],
    );

    const getTimesheets = useCallback(async () => {
      try {
        if (isLoading) {
          const formattedFiltersQuery = query.filters
            ?.split('$$')
            .map(filter => {
              const splittedFilter = filter.split(';');
              console.log(splittedFilter);
              const typeFirstLetter = splittedFilter.length > 1 ? splittedFilter[1][0] : '';
              const regex = /^[a-z]$/i;
              if (regex.test(typeFirstLetter) && typeFirstLetter === typeFirstLetter.toUpperCase()) {
                splittedFilter[1] = splittedFilter.slice(2).length > 1 ? 'btw' : 'eq';
              } else {
                splittedFilter[1] = getNewRowType(splittedFilter[1]);
              }
              return splittedFilter.filter(item => item).join(';');
            })
            .join('$$');
          const { data: timesheets } = await getSpentTimes({
            sorts: query.sorts,
            limit: Number(query.limit),
            offset: (Number(query.page) - 1) * Number(query.limit),
            filters: formattedFiltersQuery,
            fields: currentType !== 1 ? query.fields : '',
            groups: query.groups,
            totals: query.totals,
            pivot: query.pivot,
          });
          const { data: isDisplayAddButton } = await checkIsButtonDisplayed();
          setIsAddButtonDisplayed(isDisplayAddButton);
          if (timesheets.rows.length < 1) {
            setIsOptionsFetching(false);
          }
          setTimesheetsData(timesheets.rows);
          setTimesheetsColumns(timesheets.fields);
          setQuery({
            ...query,
            offset: (Number(query.page) - 1) * Number(query.limit),
            fields: currentType === 1 ? '' : timesheets.fields.map(field => `${field.fieldId.trim()}`).join('$$'),
          });
          setIsLoading(false);
        }
      } catch (error) {
        setIsOptionsFetching(false);
        console.error(error);
      }
    }, [currentType, isLoading, query, setQuery, getNewRowType]);

    const handleChangeSpentTimeType = useCallback(
      async e => {
        if (Number(e.currentTarget.attributes['data-type'].value) === 1) {
          setQuery({ pivot: 'month;timesheet.user', fields: '' });
        } else {
          setQuery({ pivot: '' });
        }
        setIsLoading(true);
        setCurrentType(Number(e.currentTarget.attributes['data-type'].value));
        await getTimesheets();
      },
      [getTimesheets, setQuery],
    );

    const handleDeleteTimesheet = useCallback(async ({ projectId, timesheetId, tableSelectedValues }) => {
      console.log(tableSelectedValues);
      try {
        const { data: currentProject } = await getTimesheetProjectInfo({
          projectId,
          timesheetId,
        });
        await deleteTimesheets(
          tableSelectedValues.map(item => {
            return { redmineId: item };
          }),
        );
        setIsLoading(true);
      } catch (error) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setTableErrorMessage('Удаление невозможно');
        setTimeout(() => setTableErrorMessage(null), 2500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const approveOrRejectTimesheets = useCallback(async values => {
      try {
        await queryForApproveTimesheet({ values });
        setIsLoading(true);
      } catch (error) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setTableErrorMessage('Учёт утверждения невозможен.');
        setTimeout(() => setTableErrorMessage(null), 2500);
      }
    }, []);

    const approveOrRejectOvertime = useCallback(async values => {
      try {
        await createOrUpdateTimesheets(values);
        setIsLoading(true);
      } catch (error) {
        // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
        setTableErrorMessage('Учёт переработок невозможен.');
        setTimeout(() => setTableErrorMessage(null), 2500);
      }
    }, []);
    const lockFilter = filters => {
      if (filters.filters?.length > 0) {
        filters.filters.forEach((element, index) => {
          if (element.fieldId === query.locked) {
            element.locked = true;
          }
        });
      }
      return filters;
    };

    useEffect(() => {
      if (location?.state?.isGetDataFromStorage) {
        getTimesheets();
      }
      if (!query.limit && !query.offset) {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1000);
      }
      const configureQueryFunc = async () => {
        if (allFilters && Object.values(allFilters).length === 0) {
          try {
            await getAllFilters();
          } catch (error) {
            console.error('ERROR WITH FILTERS QUERY', error);
          }
        }
        if (
          query.limit &&
          typeof Number(query.limit) === 'number' &&
          query.offset &&
          typeof Number(query.offset) === 'number' &&
          query.page &&
          typeof Number(query.page) === 'number'
        ) {
          getTimesheets();
          return;
        }
        setQuery({
          ...query,
          limit: 100,
          offset: 0,
          page: 1,
          totals: query.totals?.length > 0 ? query.totals : 'null',
          fields: query.pivot?.length > 0 ? '' : query.fields,
        });
      };
      configureQueryFunc();
    }, [
      allFilters,
      getAllFilters,
      getTimesheets,
      location?.state?.isGetDataFromStorage,
      query,
      setQuery,
      timesheetsColumns,
    ]);
    return (
      <>
        <Component
          {...props}
          timesheetsData={timesheetsData}
          timesheetsColumns={timesheetsColumns}
          isLoading={isLoading}
          isOptionsFetching={isOptionsFetching}
          updateTable={updateTable}
          setIsLoading={setIsLoading}
          setQuery={setQuery}
          query={query}
          allFilters={lockFilter(allFilters)}
          approveOrRejectTimesheets={approveOrRejectTimesheets}
          approveOrRejectOvertime={approveOrRejectOvertime}
          handleDeleteTimesheet={handleDeleteTimesheet}
          tableSelectedValues={tableSelectedValues}
          setTableSelectedValues={setTableSelectedValues}
          tableErrorMessage={tableErrorMessage}
          handleChangeSpentTimeType={handleChangeSpentTimeType}
          isAddButtonDisplayed={isAddButtonDisplayed}
          currentType={currentType}
        />
      </>
    );
  };
