export default {
  styleOverrides: {
    outlined: {
      position: 'relative',
      // width: 252,
      borderRadius: 16,
      paddingRight: 0,
      zIndex: 10,
      '& .MuiSelect-select': {
        padding: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
      },
    },
  },
};
