import * as React from 'react';
import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import components from './components';
import breakpoints from './breakpoints';

const baseTheme = {
  palette,
  typography,
  components,
  breakpoints,
  spacing: 4,
};

export const theme = createTheme(baseTheme);
