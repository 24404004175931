import React from 'react';
import { SvgIcon } from '@mui/material';

function LogoIconBlack(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M0 43.324L16.593 21.4469L0.368733 0H13.4587L22.9843 13.2737L32.5099 0H45.6614L29.4372 21.4469L45.9687 43.324H32.8172L22.9843 29.6816L13.1515 43.324H0Z"
        fill="#082253"
      />
      <path d="M48.6189 26.294V17.7521H68.2847V26.294H48.6189Z" fill="#082253" />
      <path d="M76.0473 43.324V0H87.3551V33.9218H106.652V43.324H76.0473Z" fill="#082253" />
      <path
        d="M121.768 44C119.432 44 117.363 43.5698 115.561 42.7095C113.799 41.8492 112.406 40.6816 111.382 39.2067C110.398 37.6909 109.907 35.9702 109.907 34.0447C109.907 31.8734 110.48 30.1527 111.628 28.8827C112.775 27.5717 114.618 26.6499 117.159 26.1173C119.699 25.5438 123.079 25.257 127.299 25.257H129.634V24.3966C129.634 22.9218 129.245 21.8771 128.466 21.2626C127.729 20.648 126.438 20.3408 124.595 20.3408C123.038 20.3408 121.317 20.5866 119.432 21.0782C117.548 21.5698 115.704 22.3277 113.901 23.352L111.136 16.162C112.16 15.4246 113.471 14.7691 115.069 14.1955C116.667 13.622 118.347 13.1918 120.108 12.905C121.87 12.5773 123.529 12.4134 125.086 12.4134C130.126 12.4134 133.854 13.4991 136.271 15.6704C138.729 17.8417 139.959 21.2626 139.959 25.933V43.324H129.757V39.0223C129.224 40.5791 128.262 41.8082 126.869 42.7095C125.476 43.5698 123.775 44 121.768 44ZM124.226 36.8715C125.742 36.8715 127.012 36.3799 128.036 35.3966C129.101 34.4134 129.634 33.1229 129.634 31.5251V30.419H127.299C124.881 30.419 123.099 30.6853 121.952 31.2179C120.846 31.7095 120.293 32.5084 120.293 33.6145C120.293 34.5568 120.621 35.3352 121.276 35.9497C121.973 36.5642 122.956 36.8715 124.226 36.8715Z"
        fill="#082253"
      />
      <path
        d="M146.188 43.324V13.0894H156.819V17.2067C157.885 15.6499 159.257 14.4618 160.937 13.6425C162.617 12.8231 164.501 12.4134 166.591 12.4134C170.196 12.4134 172.859 13.4786 174.58 15.6089C176.342 17.7393 177.223 21.0168 177.223 25.4413V43.324H166.284V25.8715C166.284 24.0279 165.956 22.7169 165.3 21.9385C164.686 21.1601 163.764 20.771 162.535 20.771C160.896 20.771 159.585 21.3035 158.602 22.3687C157.618 23.3929 157.127 24.7858 157.127 26.5475V43.324H146.188Z"
        fill="#082253"
      />
      <path
        d="M198.882 44C195.482 44 192.511 43.365 189.971 42.095C187.472 40.825 185.526 39.0019 184.133 36.6257C182.74 34.2495 182.043 31.4227 182.043 28.1453C182.043 24.8678 182.74 22.0615 184.133 19.7263C185.526 17.3501 187.472 15.5475 189.971 14.3184C192.511 13.0484 195.482 12.4134 198.882 12.4134C200.89 12.4134 202.877 12.7002 204.843 13.2737C206.851 13.8063 208.49 14.5642 209.76 15.5475L206.871 23.0447C205.929 22.3073 204.823 21.7337 203.553 21.324C202.324 20.8734 201.156 20.648 200.05 20.648C197.879 20.648 196.199 21.3035 195.011 22.6145C193.822 23.9255 193.228 25.7691 193.228 28.1453C193.228 30.5624 193.822 32.4469 195.011 33.7989C196.199 35.1099 197.879 35.7654 200.05 35.7654C201.156 35.7654 202.324 35.5605 203.553 35.1508C204.823 34.7002 205.929 34.1061 206.871 33.3687L209.76 40.8659C208.49 41.8492 206.851 42.6276 204.843 43.2011C202.836 43.7337 200.849 44 198.882 44Z"
        fill="#082253"
      />
      <path
        d="M229.094 44C225.407 44 222.211 43.365 219.507 42.095C216.844 40.784 214.796 38.9404 213.362 36.5642C211.928 34.1881 211.211 31.3818 211.211 28.1453C211.211 25.0726 211.887 22.3482 213.239 19.9721C214.632 17.5959 216.516 15.7523 218.893 14.4413C221.31 13.0894 224.055 12.4134 227.128 12.4134C230.16 12.4134 232.782 13.0484 234.994 14.3184C237.247 15.5885 238.968 17.4115 240.156 19.7877C241.385 22.1229 242 24.8678 242 28.0223V30.419H221.658C222.027 32.3855 222.846 33.7989 224.116 34.6592C225.387 35.5196 227.148 35.9497 229.402 35.9497C230.959 35.9497 232.577 35.7244 234.257 35.2737C235.977 34.7821 237.493 34.0857 238.804 33.1844L241.508 40.3743C239.951 41.4804 238.046 42.3613 235.793 43.0168C233.54 43.6723 231.307 44 229.094 44ZM227.619 19.4804C225.981 19.4804 224.629 19.9721 223.563 20.9553C222.539 21.9385 221.884 23.3724 221.597 25.257H233.028C232.905 23.3315 232.372 21.8976 231.43 20.9553C230.528 19.9721 229.258 19.4804 227.619 19.4804Z"
        fill="#082253"
      />
      <path d="M0 92V64H242L0 92Z" fill="#6270D0" />
    </SvgIcon>
  );
}

export default LogoIconBlack;
