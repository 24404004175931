import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  SpreadsheetContainerRoot: {
    position: 'relative',
    width: '100%',
  },
  mobileCreate: {
    '&.MuiIconButton-root': {
      position: 'fixed',
      right: 10,
      bottom: 10,
      backgroundColor: '#6270D0',
      color: '#fff',
      zIndex: 1990,
    },
  },
  hideEmptyRows: {
    '&.MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
}));

export default useStyles;
