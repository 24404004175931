import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { deleteIssueWatcher, getIssueWatchers, saveIssueWatchers } from '../../../../../packages/common/api';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import issueWatchersStyles from './IssueWatchers.styles';
import CheckboxIconChecked from '../../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import SelectCheckboxIcon from '../../../../../packages/common/shared-ui/src/icons/SelectCheckboxIcon';
import ClearSelectChipIcon from '../../../../../packages/common/shared-ui/src/icons/ClearSelectChipIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import issuePageStyles from '../../IssuePage.styles';
import { generateRedirectUrl } from '../../../../../packages/common/utils/getRedirectUrl';
import { useSelector } from 'react-redux';

const IssueWatchers = ({ intl, permissions }) => {
  const params = useParams();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [watchers, setWatchers] = useState([]);
  const [selectWatchers, setSelectWatchers] = useState([]);
  const [selectedWatchers, setSelectedWatchers] = useState([]);
  const [addWatcher, setAddWatcher] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = issueWatchersStyles();
  const issueCommonStyles = issuePageStyles();
  useEffect(() => {
    let ignore = false;
    async function getWatchers() {
      const { data: watchers } = await getIssueWatchers(params.id);
      if (!ignore) {
        return watchers;
      }
    }
    getWatchers()
      .then(res => {
        if (res) {
          setWatchers(res.filter(el => el.isChecked));
          setSelectWatchers(res.filter(el => !el.isChecked));
        }
        setLoading(false);
      })
      .catch(error => console.error(error, 'ERROR IN GET'));
    return () => {
      ignore = true;
    };
  }, [params.id]);

  const handleSaveIssueWatchers = useCallback(async () => {
    if (selectedWatchers.length === 0) {
      return;
    }
    try {
      const response = await saveIssueWatchers(params.id, { users: selectedWatchers });
      if (response) {
        const { data: watchers } = response;
        setWatchers(watchers.filter(el => el.isChecked));
        setAddWatcher(false);
      }
    } catch (error) {
      console.error('ERROR WITH SAVE', error);
    }
  }, [params.id, selectedWatchers]);

  const handleDeleteIssueWatcher = useCallback(
    async id => {
      try {
        const response = await deleteIssueWatcher(params.id, id);
        if (response) {
          const newArray = watchers.filter(watcher => watcher.userId !== id);
          setWatchers(newArray);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [params.id, watchers],
  );

  const handleSelectWatchers = useCallback((event, value) => {
    setSelectedWatchers(value.map(el => el.userId));
  }, []);
  const handleAddWatcher = useCallback(() => {
    setAddWatcher(true);
  }, []);
  const handleRemoveWatcher = useCallback(() => {
    setAddWatcher(false);
    setSelectedWatchers([]);
  }, []);

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant="h3" color={'#082253'} fontWeight={600}>
                {intl.formatMessage({ id: 'issue_page_watchers_title' })}
              </Typography>
            </Grid>
            <Grid item>
              {permissions.canAddWatchers && (
                <IconButton
                  disabled={addWatcher}
                  onClick={handleAddWatcher}
                  sx={{ padding: '6px 4px' }}
                  className={issueCommonStyles.addButton}
                >
                  <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {addWatcher && (
            <Grid container alignItems={'flex-start'} marginTop={'10px'}>
              <Grid item>
                <Autocomplete
                  disableClearable
                  forcePopupIcon={false}
                  disablePortal
                  multiple
                  className={classes.filterSelect}
                  options={selectWatchers}
                  disableCloseOnSelect
                  getOptionLabel={option => option.userName}
                  onChange={(event, newValue) => handleSelectWatchers(event, newValue)}
                  ChipProps={{
                    variant: 'outlined',
                    deleteIcon: <ClearSelectChipIcon />,
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        checked={selected}
                        icon={<SelectCheckboxIcon />}
                        checkedIcon={<CheckboxIconChecked width="16" height="16" viewBox="0 0 16 16" />}
                      />
                      {option.userName}
                    </li>
                  )}
                  style={{ width: 240 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder={intl.formatMessage({ id: 'issue_page_watchers_placeholder' })}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Grid container marginLeft={'16px'}>
                  <Grid item>
                    <IconButton onClick={handleSaveIssueWatchers} sx={{ padding: '8px', height: '100%' }}>
                      <SubmitIcon width={'16px'} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    {permissions.canDeleteWatchers && (
                      <IconButton
                        onClick={handleRemoveWatcher}
                        sx={{ padding: '8px', height: '100%' }}
                        className={issueCommonStyles.hoveredIcon}
                      >
                        <DeleteIconBig />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {watchers.length > 0 && (
            <Grid container direction={'column'} rowSpacing={1} sx={{ lineHeight: '20px' }} marginTop={'6px'}>
              {watchers.map((watcher, index) => (
                <Grid item key={index}>
                  <Grid container alignItems={'center'}>
                    <Grid item sx={{ width: '250px' }}>
                      <Typography variant="h4" color={'#212346'}>
                        <Link
                          target={'_blank'}
                          className={issueCommonStyles.issueLink}
                          to={generateRedirectUrl({
                            host: window.location.origin,
                            path: `/users/${watcher.userId}`,
                            redmineToken,
                          })}
                        >
                          {watcher.userName}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'inline-flex' }}>
                      <IconButton
                        className={issueCommonStyles.hoveredIcon}
                        onClick={() => handleDeleteIssueWatcher(watcher.userId)}
                        sx={{ padding: '0 8px', height: '100%', marginLeft: '10px' }}
                      >
                        <DeleteIconBig />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default injectIntl(IssueWatchers);
