import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  RightContainerRoot: {
    selectMethod: {
      background: 'red',
    },
  },
  [theme.breakpoints.maxWidth('md')]: {
    RightContainerRoot: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
