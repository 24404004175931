import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  forProjectsPaper: {
    backgroundColor: '#fff',
    borderRadius: theme.spacing(0, 0, 4, 4),
    overflow: 'hidden',
    border: '1px solid #E4E4EF',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
  },
  forProjectsPopper: {
    width: '100%',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      width: '100%',
      '& .MuiAutocomplete-option': {
        // padding: theme.spacing(0, 2),
      },
    },
    '& $forProjectsPaper .MuiAutocomplete-option': {
      padding: theme.spacing(0, 4),
    },
  },
  forProjectsInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      borderRadius: theme.spacing(4, 4, 0, 0),
      border: '1px solid #E4E4EF',
      borderBottom: 0,
      padding: 0,
      '&:hover': {
        border: '1px solid #E4E4EF',
        borderBottom: 0,
      },
      '& input.MuiAutocomplete-input': {
        padding: theme.spacing(2.5, 4),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderBottom: '1px solid #E4E4EF',
      },
    },
  },
}));

export default useStyles;
