import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  SearchInputRoot: {
    position: 'relative',
    width: 200,
    maxWidth: 200,
    marginRight: theme.spacing(8),
    [theme.breakpoints.maxWidth('sm')]: {
      width: 'unset',
      marginRight: 0,
    },
  },
  inputIcon: {
    position: 'absolute !important',
    left: 4,
    top: 2,
    bottom: 0,
    margin: 'auto 0',
    zIndex: 10,
    '& svg': {
      color: '#7174AC',
    },
    [theme.breakpoints.maxWidth('sm')]: {
      left: 0,
      top: 0,
      margin: 'unset',
      position: 'unset !important',
    },
  },
  searchInput: {
    '& input': {
      padding: '8px 12px 8px 30px !important',
    },
  },
}));

export default useStyles;
