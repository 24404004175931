import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './CustomSelect.styles';
import { FormControl, MenuItem, Select } from '@mui/material';

function CustomSelect({
  className,
  label,
  required,
  isDisabled,
  multiple,
  items,
  value,
  handleSelect,
  size = 'medium',
  IconComponent,
  MenuProps,
  dataAttr,
  children,
}) {
  const classes = useStyles();

  return (
    <FormControl
      className={clsx(classes.CustomSelectRoot, className)}
      variant="outlined"
      required={required}
      fullWidth
      size={size}
    >
      {children}
      <Select
        multiple={multiple}
        value={value}
        disabled={isDisabled}
        onChange={handleSelect}
        label={label}
        fullWidth
        IconComponent={IconComponent}
        MenuProps={MenuProps}
      >
        <MenuItem value="" disabled>
          {label || 'Select'}
        </MenuItem>
        {items.map(({ id, value, name, title, isDisabled }) => (
          <MenuItem value={id} key={id} disabled={isDisabled} {...dataAttr}>
            {value || name || title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  className: null,
  description: '',
  required: false,
  multiple: false,
  dataAttr: {},
};

CustomSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  dataAttr: PropTypes.shape({}),
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.shape({}))]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default memo(CustomSelect);
