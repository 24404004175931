import React from 'react';
import { SvgIcon } from '@mui/material';

function CheckboxIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.6667 1.33333V10.6667H1.33333V1.33333H10.6667ZM10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0Z"
        fill={props.color ? props.color : '#E4E4EF'}
      />
    </SvgIcon>
  );
}

export default CheckboxIcon;
