import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Grid, OutlinedInput, Typography } from '@mui/material';
import { CalendarPicker, PickersDay } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import ChevronIcon from '../../../../icons/ChevronIcon';
import { FormattedMessage } from 'react-intl';

const FilterDateBetween = ({ showMonthPicker, filterOptions, dateParams, classes, handleChooseSubItem, isClear }) => {
  const defaultValueName = filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]
    ? filterOptions.firstStepValues.value
    : `${filterOptions.firstStepValues.value}_`;
  const [firstDateValue, setFirstDateValue] = useState(dayjs());
  const [secondDateValue, setSecondDateValue] = useState(dayjs());
  const [selectedMonth, setSelectedMonth] = useState(dayjs()); // Для нового блока
  const [calendarView, setCalendarView] = useState('month');

  useEffect(() => {
    if (isClear) {
      setFirstDateValue(null);
      setSecondDateValue(null);
      setSelectedMonth(null);
    }
    if (filterOptions.chosenFirstStepItems[defaultValueName]?.subLabels?.length > 0) {
      setFirstDateValue(dayjs(filterOptions.chosenFirstStepItems[defaultValueName]?.subLabels[0]?.valueId));
      setSecondDateValue(dayjs(filterOptions.chosenFirstStepItems[defaultValueName]?.subLabels[1]?.valueId));
      setSelectedMonth(dayjs(filterOptions.chosenFirstStepItems[defaultValueName]?.subLabels[0]?.valueId));
    }
  }, [defaultValueName, filterOptions.chosenFirstStepItems, isClear]);

  const handleChooseMonth = selectedDate => {
    if (selectedDate.isSame(selectedMonth, 'month')) {
      return;
    }
    let month, year;

    if (calendarView === 'month') {
      setSelectedMonth(selectedDate);
      month = selectedDate.month() + 1;
      year = selectedDate.year();
    } else {
      const newSelectedMonth = dayjs(selectedDate).set('month', selectedMonth.month());
      setSelectedMonth(newSelectedMonth);
      setCalendarView('month');
      month = newSelectedMonth.month() + 1;
      year = newSelectedMonth.year();
    }

    const dateOption = {
      month,
      year,
      valueName: dayjs(selectedDate).format('DD.MM.YYYY'),
      valueId: dayjs(selectedDate).format('YYYY-MM-DD'),
    };

    handleChooseSubItem({
      option: dateOption,
      isMultiple: false,
    });
  };

  const handleChooseFirstCalendarDay = useCallback(
    date => {
      const onDaySelect = selectedDate => {
        setFirstDateValue(dayjs(selectedDate).format() === dayjs(firstDateValue).format() ? dayjs() : selectedDate);
        setSecondDateValue(dayjs());
        handleChooseSubItem({
          option:
            dayjs(selectedDate).format() === dayjs(firstDateValue).format()
              ? { delete: true }
              : {
                  valueName: dayjs(selectedDate).format('DD.MM.YYYY'),
                  valueId: dayjs(selectedDate).format('YYYY-MM-DD'),
                },
          isMultiple: false,
        });
      };
      return (
        <PickersDay
          className={classes.dateDay}
          key={date}
          outsideCurrentMonth={false}
          day={date}
          disableRipple
          onDaySelect={onDaySelect}
          selected={dayjs(firstDateValue).format() === dayjs(date).format()}
        />
      );
    },
    [classes.dateDay, firstDateValue, handleChooseSubItem],
  );
  const handleChooseSecondCalendarDay = useCallback(
    date => {
      const onDaySelect = selectedDate => {
        setSecondDateValue(dayjs(selectedDate).format() === dayjs(secondDateValue).format() ? dayjs() : selectedDate);
        handleChooseSubItem({
          option:
            dayjs(selectedDate).format() === dayjs(secondDateValue).format()
              ? { delete: true }
              : {
                  valueName: dayjs(selectedDate).format('DD.MM.YYYY'),
                  valueId: dayjs(selectedDate).format('YYYY-MM-DD'),
                },
          isMultiple: false,
          type:
            filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels.length === 1
              ? 'btw'
              : '',
          itemIndex: 1,
        });
      };
      return (
        <PickersDay
          className={classes.dateDay}
          key={date}
          outsideCurrentMonth={false}
          day={date}
          disableRipple
          onDaySelect={onDaySelect}
          selected={dayjs(secondDateValue).format() === dayjs(date).format()}
        />
      );
    },
    [
      classes.dateDay,
      filterOptions.chosenFirstStepItems,
      filterOptions.firstStepValues.value,
      handleChooseSubItem,
      secondDateValue,
    ],
  );

  return (
    <Grid container direction="row" wrap="nowrap">
      {showMonthPicker ? (
        <CalendarPicker
          className={classes.calendarResplanRoot}
          dayOfWeekFormatter={dateParams.formatter}
          date={selectedMonth}
          view={calendarView}
          onChange={handleChooseMonth}
          components={{
            SwitchViewIcon: () => null,
          }}
        />
      ) : (
        <>
          {filterOptions.chosenFirstStepItems[defaultValueName]?.notParsedType.includes('>') ||
          filterOptions.chosenFirstStepItems[defaultValueName]?.notParsedType.includes('<') ||
          filterOptions.chosenFirstStepItems[defaultValueName]?.notParsedType.includes('-') ? (
            <>
              <Grid container wrap="nowrap" alignItems="center">
                <OutlinedInput
                  type="number"
                  placeholder="0"
                  className={classes.filterIntInput}
                  value={
                    filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.subLabels[0]?.value || ''
                  }
                  onChange={e =>
                    handleChooseSubItem({
                      option: {
                        valueName:
                          e.target.value.length === 0
                            ? e.target.value
                            : dayjs().subtract(Number(e.target.value), 'day').format('DD.MM.YYYY'),
                        valueId:
                          e.target.value.length === 0
                            ? e.target.value
                            : dayjs().subtract(Number(e.target.value), 'day').format('YYYY-MM-DD'),
                        value: e.target.value,
                      },
                      isMultiple: false,
                    })
                  }
                />
                <Typography variant="h5" textTransform="lowercase">
                  <FormattedMessage id="days" />
                </Typography>
              </Grid>
            </>
          ) : (
            <CalendarPicker
              className={classes.calendarRoot}
              dayOfWeekFormatter={dateParams.formatter}
              date={firstDateValue}
              renderDay={handleChooseFirstCalendarDay}
              showDaysOutsideCurrentMonth
              view="day"
              components={{
                SwitchViewIcon: () => null,
              }}
              onChange={e => e.preventDefault()}
            />
          )}
          <Divider orientation="vertical" flexItem />
          {filterOptions.chosenFirstStepItems[filterOptions.firstStepValues.value]?.type === 'btw' && (
            <>
              <Divider orientation="vertical" flexItem />
              <CalendarPicker
                className={classes.calendarRoot}
                dayOfWeekFormatter={dateParams.formatter}
                readOnly={true}
                date={secondDateValue}
                renderDay={handleChooseSecondCalendarDay}
                showDaysOutsideCurrentMonth
                view="day"
                components={{
                  SwitchViewIcon: () => null,
                }}
                onChange={e => e.preventDefault()}
              />
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default FilterDateBetween;
