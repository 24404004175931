import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  LeftContainerRoot: {
    maxWidth: '48%',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    borderRadius: theme.spacing(4),
    [theme.breakpoints.maxWidth('md')]: {
      maxWidth: '100%',
      padding: theme.spacing(0, 5),
    },
  },
}));

export default useStyles;
