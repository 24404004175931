import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import ClearIcon from 'Common/shared-ui/src/icons/ClearIcon';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import gridFilterModal from '../GridFiltersModal/GridFilterModal.styles';
import UncheckedRadioIcon from '../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import SpentTimeQueryAutocomplete from '../issues/IssuesQueryAutocomplete/IssuesQueryAutocomplete';
import useStyles from '../issues/IssuesQueryModal/IssuesQueryModal.styles';
import gridQueryModalStyles from './GridSaveQueryModal.styles';
import { getIssueProjects, getQueryRoles } from '../../../packages/common/api';
import { useNavigate } from 'react-router';

const GridSaveQueryModal = ({ intl, open, handleSave, handleClose, queryData }) => {
  const classes = gridQueryModalStyles();
  const fieldsClasses = useStyles();
  const [modalData, setModalData] = useState({
    name: null,
    accessLevel: 0,
    roles: [],
    projectLevel: 2,
    projectId: null,
  });
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  useEffect(() => {
    if (queryData) {
      setModalData({
        name: queryData.name,
        accessLevel: queryData.accessLevel,
        roles: queryData.roles,
        projectLevel: queryData.projectLevel,
        projectId: queryData.projectId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  useEffect(() => {
    let ignore = false;
    async function getRoles() {
      const { data: response } = await getQueryRoles();
      if (!ignore) {
        return response;
      }
    }
    if (modalData.accessLevel === 1) {
      getRoles()
        .then(res => setRoles(res))
        .catch(error => handleIssueError(error.response));
      return () => {
        ignore = true;
      };
    }
  }, [handleIssueError, modalData]);

  const handleField = useCallback(
    (data, field) => {
      let value = data;
      if (data.target && data.target.hasOwnProperty('checked')) {
        data.target.checked && (value = 2);
        !data.target.checked && (value = 0);
      }
      setModalData({ ...modalData, [field]: value });
    },
    [modalData],
  );

  const handleSaveButton = useCallback(() => {
    if (modalData) {
      handleSave(modalData);
    }
  }, [handleSave, modalData]);

  return (
    <Dialog open={open} className={classes.modalRoot} disableRestoreFocus fullWidth={true}>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" marginBottom={'24px'}>
        <Typography variant="h4" fontWeight={600} paddingLeft={'8px'}>
          <FormattedMessage id="spent_time_save" />
        </Typography>
        <IconButton disableRipple value="close" sx={{ padding: 0 }} onClick={handleClose}>
          <Box width={24} height={24}>
            <ClearIcon width={24} height={24} viewBox="0 0 24 24" />
          </Box>
        </IconButton>
      </Grid>
      <Grid container padding={'0 8px 0 24px'} marginBottom={'24px'} alignItems={'center'}>
        <Grid item xs={5}>
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h5">Название запроса</Typography>
        </Grid>
        <Grid item xs={7} paddingRight={4}>
          <OutlinedInput
            fullWidth
            onChange={event => handleField(event.target.value, 'name')}
            placeholder={`${intl.formatMessage({ id: 'enter' })}`}
            value={modalData.name ? modalData.name : ''}
          />
        </Grid>
      </Grid>
      <Grid container padding={'0 8px 0 24px'}>
        <Grid item xs={5} alignItems="flex-start">
          {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
          <Typography variant="h5">Видимое</Typography>
        </Grid>
        <Grid item xs={7} paddingRight={4}>
          <RadioGroup
            value={modalData.accessLevel}
            onChange={event => handleField(Number(event.target.value), 'accessLevel')}
          >
            <FormControlLabel
              value={0}
              control={
                <Radio
                  disableRipple
                  icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                  checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                />
              }
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              label="только мне"
            />
            <FormControlLabel
              value={2}
              control={
                <Radio
                  disableRipple
                  icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                  checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                />
              }
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              label="всем пользователям"
            />
            <FormControlLabel
              value={1}
              control={
                <Radio
                  disableRipple
                  icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                  checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                />
              }
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              label="только этим ролям"
            />
          </RadioGroup>
          {Number(modalData.accessLevel) === 1 && (
            <Grid container width="100%" marginTop={2} className={classes.forProjectsAutocomplete}>
              <SpentTimeQueryAutocomplete
                options={roles}
                value={modalData.roles}
                onChange={(event, newValue) => handleField(newValue, 'roles')}
                renderTags={() => null}
                intl={intl}
              />
            </Grid>
          )}
          <FormControlLabel
            //className={classes.booleanLabel}
            checked={modalData.projectLevel === 2}
            onChange={event => handleField(event, 'projectLevel')}
            control={
              <Checkbox
                disableRipple
                icon={<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />}
                checkedIcon={<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />}
              />
            }
            label={
              // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
              <Typography variant="h5">Для всех проектов</Typography>
            }
          />
          <Grid container wrap="nowrap" justifyContent="flex-end" alignItems="center">
            <Box>
              <Button onClick={handleSaveButton} variant="defaultBlue">
                <FormattedMessage id="save_text" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default injectIntl(GridSaveQueryModal);
