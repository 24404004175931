import { makeStyles } from '@mui/styles';

const confirmationSettingsTable = makeStyles(theme => ({
  ConfirmationSettingsTable: {
    '&.MuiDataGrid-root': {
      border: 'none',
      backgroundColor: '#ffffff',
      '& .MuiDataGrid-cell': {
        '&.tableCell': {
          '&.MuiDataGrid-cell--editing': {
            padding: 0,
          },
        },
        '& a': {
          color: '#3448FF',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '& .MuiTextField-root .MuiInputBase-root.MuiInput-root': {
          padding: '8px',
          '& .MuiInputBase-input.MuiInput-input': {
            color: '#3448FF',
            //textDecoration: 'underline',
            '&::placeholder': {
              //textDecoration: 'none',
              color: '#7174AC',
            },
          },
        },
      },
    },
  },
}));

export default confirmationSettingsTable;
