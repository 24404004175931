import React, { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Button, Tooltip } from '@mui/material';
import useStyles from './AgileFilters.styles';
import CustomFilter from 'Common/shared-ui/src/components/CustomFilter';
import clsx from 'clsx';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import {
  getFilterIssuePriorities,
  getFilterIssueRoles,
  getFilterIssueStatuses,
  getFilterIssueUserGroups,
  getFilterIssueWatcher,
  getFilterProjectStatuses,
  getQueryIssues,
  getTimesheets,
  getFilterSubItems,
  getQueryActivity,
  getQueryProjects,
  getQueryTrackers,
  getQueryUser,
  getFilterCalculationTypes,
} from '../../../../packages/common/api';
import { useSelector } from 'react-redux';

const AgileFilters = ({ filters, projectId, query, setQuery }) => {
  const intl = useIntl();
  const filterValuesToDelete = useSelector(state => state.user.agileFiltersToDelete);
  const [filtersList, setFiltersList] = useState([]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [filterValues, setFilterValues] = useState({
    chosenFirstStepItems: {},
    chosenSecondStepItems: {},
  });
  useEffect(() => {
    if (filterValuesToDelete) {
      const newFilterValues = { ...filterValues.chosenFirstStepItems };
      Object.keys(filterValuesToDelete).forEach(key => {
        if (newFilterValues[key]) {
          delete newFilterValues[key];
        }
      });
      setFilterValues({ ...filterValues, chosenFirstStepItems: newFilterValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValuesToDelete]);
  useEffect(() => {
    const getFilterData = async activeFilters => {
      let rawSubItems = [];
      await Promise.all(
        activeFilters.map(async option => {
          switch (option.fieldType) {
            case 'project': {
              const { data: queryProjects } = await getQueryProjects();
              rawSubItems = queryProjects;
              break;
            }
            case 'tracker': {
              const { data: queryTrackers } = await getQueryTrackers(projectId);
              rawSubItems = queryTrackers;
              break;
            }
            case 'user': {
              const { data: queryUser } = await getQueryUser(option.fieldId, projectId);
              queryUser[0].valueName = getTranslatedText(intl, '', queryUser[0].valueName.toLowerCase(), 'Me');
              rawSubItems = queryUser;
              break;
            }
            case 'activity': {
              const { data: queryActivity } = await getQueryActivity();
              rawSubItems = queryActivity;
              break;
            }
            case 'calculation_types': {
              const { data: filterSubItems } = await getFilterCalculationTypes();
              rawSubItems = filterSubItems;
              break;
            }
            case 'issue': {
              const queryFilters = query?.filters?.split('$$');
              const issueFilter = queryFilters.find(filter => filter.includes(option.fieldId));
              if (issueFilter) {
                const [queryFiltersId, queryFiltersType, ...queryFiltersParts] = issueFilter.split(';');

                const queryFiltersValue = queryFiltersParts.join('||');

                const { data: filterSubItems } = await getQueryIssues({
                  searchText: queryFiltersValue,
                });
                rawSubItems = filterSubItems;
              }
              break;
            }
            case 'list': {
              const { data: filterSubItems } = await getFilterSubItems(option.fieldId);
              rawSubItems = filterSubItems;
              break;
            }
            case 'issue_status': {
              const { data: issueStatuses } = await getFilterIssueStatuses(projectId);
              rawSubItems = issueStatuses;
              break;
            }
            case 'issue_watchers': {
              const { data: issueWatchers } = await getFilterIssueWatcher(projectId);
              issueWatchers[0].valueName = getTranslatedText(intl, '', issueWatchers[0].valueName.toLowerCase(), 'Me');
              rawSubItems = issueWatchers;
              break;
            }
            case 'user_groups': {
              const { data: userGroups } = await getFilterIssueUserGroups();
              rawSubItems = userGroups;
              break;
            }
            case 'issue_priority': {
              const { data: issuePriorities } = await getFilterIssuePriorities();
              rawSubItems = issuePriorities;
              break;
            }
            case 'user_roles': {
              const { data: userRoles } = await getFilterIssueRoles();
              rawSubItems = userRoles;
              break;
            }
            case 'status': {
              const { data: projectStatuses } = await getFilterProjectStatuses();
              rawSubItems = projectStatuses;
              break;
            }
            default: {
              break;
            }
          }
          option.values = rawSubItems;
        }),
      );
      setFiltersList([...activeFilters]);

      const queryFilters = query?.filters?.split('$$');

      if (query.filters && query.filters !== '') {
        queryFilters.forEach(filter => {
          const [queryFiltersId, queryFiltersType, ...queryFiltersParts] = filter.split(';');
          const queryFiltersValue = queryFiltersParts.join('||');

          const sourceFilter = activeFilters.find(item => item.fieldId === queryFiltersId);
          const filterSublabels = queryFiltersValue?.split('||').map(sublabel => {
            if (sourceFilter.fieldType === 'date') {
              const modifiedSublabel = sublabel.replace(/-/g, '.');
              return { valueId: sublabel, valueName: modifiedSublabel, isChecked: true };
            }
            const sourceValue = sourceFilter.values.find(item => item.valueId === sublabel);
            return { valueId: sublabel, valueName: sourceValue?.valueName, isChecked: true };
          });
          setFilterValues(prevState => {
            const updatedChosenFirstStepItems = {
              ...prevState.chosenFirstStepItems,
              [queryFiltersId]: {
                ...prevState.chosenFirstStepItems.queryFiltersId,
                subLabels: filterSublabels,
                label: sourceFilter.fieldName,
                valueName: sourceFilter.fieldName,
                type: queryFiltersType,
                value: queryFiltersId,
                notParsedType: queryFiltersType === 'btw' ? 'btw' : 'eq',
                fieldType: sourceFilter.fieldType,
              },
            };
            window.localStorage.setItem('storageAgileFilters', JSON.stringify(updatedChosenFirstStepItems));
            return {
              ...prevState,
              chosenFirstStepItems: updatedChosenFirstStepItems,
            };
          });
        });
      }
    };
    if (filters) {
      const queryFilterss = query?.filters?.split('$$').map(filter => filter.split(';')[0]);
      let activeFilters = filters.filter(filter => filter.isChecked || queryFilterss.includes(filter.fieldId));
      getFilterData(activeFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, query?.filters]);
  const classes = useStyles();
  const setFilterItems = useCallback(newFirstStepChosenValues => {
    setFilterValues(prevState => {
      Object.keys(newFirstStepChosenValues).map(value => {
        if (value.at(-1) === '_') {
          delete prevState.chosenFirstStepItems[value.slice(0, -1)];
        }
      });
      const filtersToSet = { ...prevState.chosenFirstStepItems, ...newFirstStepChosenValues };
      window.localStorage.setItem('storageAgileFilters', JSON.stringify(filtersToSet));

      return {
        chosenFirstStepItems: {
          ...prevState.chosenFirstStepItems,
          ...newFirstStepChosenValues,
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const parseQuery = queryStr => {
    if (queryStr && queryStr !== '') {
      const items = queryStr.split('$$');
      const parsed = {};

      items.forEach(item => {
        const [key, type, ...parts] = item.split(';');
        const value = parts.join('||');
        let values = '';
        if (value) {
          values = value.includes('||') ? value.split('||') : [value];
        }
        parsed[key] = {
          key,
          type,
          value: values,
          notParsedType: type === 'btw' ? 'btw' : 'eq',
        };
      });
      return parsed;
    }
    return '';
  };

  const parsedQuery = useMemo(() => parseQuery(query.filters), [query]);
  const removeCurrentItemAndUpdateFilters = (item, filterValues, setFilterValues) => {
    const chosenItemsWithoutCurrent = Object.entries(filterValues.chosenFirstStepItems).reduce((acc, [key, value]) => {
      if (item.value !== value.value) {
        return { ...acc, [value.value]: value };
      }
      return acc;
    }, {});

    setFilterValues(prevState => ({
      chosenFirstStepItems: { ...chosenItemsWithoutCurrent },
    }));
  };
  useEffect(() => {
    if (Object.keys(filterValues.chosenFirstStepItems)?.length > 0) {
      let cleanedQuery = Object.entries(filterValues.chosenFirstStepItems)
        .map(([key, item]) => {
          if (item.type === 'null') {
            return `${item.value};${item.type}`;
          }
          if (item.type === 'all') {
            return;
          }
          if (key.split('').at(-1) !== '_') {
            if (item.fieldType === 'group_by') {
              item.type = 'eq';
              return item.subLabels?.length > 0 && item.subLabels[0].valueId !== ''
                ? `${key};${item.type};${item.subLabels[0].valueId}`
                : null;
            }

            if (item.type === '') {
              removeCurrentItemAndUpdateFilters(item, filterValues, setFilterValues);
              return;
            }
            const values =
              item.subLabels && item.subLabels.length > 0 ? item.subLabels.map(subItem => subItem.valueId) : null;
            if (values !== undefined && values !== null) {
              if (item.type == 'btw') {
                if (values[1] != '') {
                  return `${key};${item.type};${values.join(';')}`;
                } else {
                  return `${key};${item.type};${values.join('')}`;
                }
              }
              return values.length > 1 && values[1] !== ''
                ? `${key};${item.type};${values.join('||')}`
                : values[0] !== ''
                ? `${key};${item.type};${values[0]}`
                : null;
            }
          }
        })
        .filter(item => item);
      cleanedQuery =
        cleanedQuery?.length > 0 ? (cleanedQuery?.length > 1 ? cleanedQuery.join('$$') : cleanedQuery[0]) : '';
      if (query.filters != cleanedQuery) {
        setQuery({
          ...query,
          filters: cleanedQuery,
          offset: 0,
          page: 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  return (
    <>
      <Grid container display="inline-flex" marginLeft={0} marginBottom={filtersList.length > 0 ? 5 : 0} marginTop={5}>
        {filtersList.map((filter, index) => {
          const newFirstStepValues = {
            value: filter.fieldId,
            valueName: filter.fieldName,
            fieldType: filter.fieldType,
            type: filter.fieldType !== 'text' ? 'eq' : 'in',
          };
          let prevFirstStepItems;
          if (parsedQuery && parsedQuery[filter.fieldId]) {
            prevFirstStepItems = {
              [filter.fieldId]: {
                subLabels:
                  parsedQuery[filter.fieldId].type === 'null'
                    ? []
                    : parsedQuery[filter.fieldId].value.map(val => {
                        const valName = filter?.values.find(item => item.valueId === val)?.valueName;
                        return {
                          valueId: val,
                          valueName: valName,
                        };
                      }),
                value: parsedQuery[filter.fieldId].key,
                type: parsedQuery[filter.fieldId].type,
                notParsedType: parsedQuery[filter.fieldId].notParsedType,
              },
            };
            let newFilterValues = filter.values.map(field => {
              if (parsedQuery[filter.fieldId].value?.includes(field.valueId)) {
                return { ...field, isChecked: true };
              }
              return field;
            });
            const sortAlphabetically = (a, b) => {
              return (
                a.valueName.localeCompare(b.valueName, 'en', { numeric: true, sensitivity: 'base' }) ||
                a.valueName.localeCompare(b.valueName, 'ru', { numeric: true, sensitivity: 'base' })
              );
            };
            const [checkedItems, uncheckedItems] = newFilterValues.reduce(
              ([checked, unchecked], item) => {
                if (item.isChecked) {
                  checked.push(item);
                } else {
                  unchecked.push(item);
                }
                return [checked, unchecked];
              },
              [[], []],
            );
            checkedItems.sort(sortAlphabetically);
            uncheckedItems.sort(sortAlphabetically);

            newFilterValues = [...checkedItems, ...uncheckedItems];
            filter.values = newFilterValues;
          }

          return (
            <Grid item key={index} marginRight="10px">
              <Box
                key={index}
                className={clsx(classes.filterWrap, {
                  [classes.filterWrapStatus]: filter.field == 'status',
                })}
                marginBottom="4px"
              >
                <CustomFilter
                  key={index + filter.fieldId + filter?.values?.length}
                  intl={intl}
                  secondStepItems={filter.values || []}
                  chosenSecondStepItems={filterValues.chosenSecondStepItems}
                  setValues={setFilterItems}
                  defaultStep={1}
                  firstStepValues={newFirstStepValues}
                  chosenFirstStepItems={prevFirstStepItems}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default AgileFilters;
