import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  CustomSelectRoot: {
    '&.MuiFormControl-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: theme.spacing(4),
      padding: theme.spacing(2, 2.5),
      height: 40,
    },
    '& .MuiOutlinedInput-root': {
      height: 'unset',
      '& .MuiSelect-select': {
        padding: 0,
        paddingLeft: theme.spacing(2),
      },
      '& .MuiSvgIcon-root': {
        width: 'unset',
        height: 'unset',
        fontSize: 24,
        right: 0,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
  },
}));

export default useStyles;
