import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  optionsGroupMenu: {
    '&.MuiPopover-root': {
      zIndex: 12000,
    },
    '& .MuiPaper-root': {
      borderRadius: theme.spacing(4),
      maxHeight: '40vh',
      overflow: 'auto',
      '& ul': {
        padding: 0,
      },
    },
  },
  createSelect: {
    '&.MuiInputBase-root.Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E4E4EF',
      },
      opacity: '70%',
    },
    border: '16px',
  },
  groupIcon: {
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      right: 10,
    },
  },
  menuItemChecked: {
    backgroundColor: '#F5F5DC',
    '&:hover': {
      backgroundColor: '#F5F5DC',
    },
  },
  menuItemCheckbox: {
    color: 'red',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
      color: '#3448FF',
    },
  },
  formControl: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiInputBase-formControl': {
      width: '100%',
      height: 'auto !important',
    },
  },
  menuItemUnchecked: {},
  chosenTotals: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
    border: '1px solid #E4E4EF',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    maxHeight: 30,
    zIndex: 1400,
    ...theme.typography.h5,
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  totalsRemove: {
    '&.MuiSvgIcon-root.MuiChip-deleteIcon': {
      position: 'relative !important',
      zIndex: 3000,
      padding: 0,
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
  },
  totalsChip: {
    '&.MuiChip-root': {
      height: 'unset',
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    '&.Mui-selected': {
      background: '#DCDFF4 !important',
    },
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
  menuItemMultiply: {
    '& .MuiTypography-root': {
      textTransform: 'unset',
      color: '#212346',
    },
  },
}));

export default useStyles;
