import React from 'react';
import { SvgIcon } from '@mui/material';

const SubmitIcon = props => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13476_5838)">
          <path
            d="M6.00006 10.8L3.20006 8L2.26672 8.93333L6.00006 12.6667L14.0001 4.66666L13.0667 3.73333L6.00006 10.8Z"
            fill="#37C670"
          />
        </g>
        <defs>
          <clipPath id="clip0_13476_5838">
            <rect width="16" height="16" rx="8" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SubmitIcon;
