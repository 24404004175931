import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Box, Button, CircularProgress, Grid, PaginationItem, Typography } from '@mui/material';
import {
  createNewSavedQuery,
  deleteSavedQuery,
  getConfirmations,
  getConfirmationsFilters,
  getSavedQuery,
} from '../../../packages/common/api';
import { useLocation, useNavigate } from 'react-router';
import Page from '../../../packages/common/shared-ui/src/components/Page';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import CheckboxIcon from '../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import SortIcon from '../../../packages/common/shared-ui/src/icons/SortIcon';
import dataGridTableStyles from '../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import GridFiltersModal from '../GridFiltersModal/GridFiltersModal';
import MuiPagination from '@mui/material/Pagination';
import PaginationNextButtonIcon from '../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';
import { Link, useParams } from 'react-router-dom';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import FilterIcon from '../../../packages/common/shared-ui/src/icons/FilterIcon';
import GridFiltersPanel from '../GridFiltersPanel/GridFiltersPanel';
import GridSaveQueryModal from '../GridSaveQueryModal/GridSaveQueryModal';
import dayjs from 'dayjs';
import { filterDateValues, filtersToQuery } from '../../../packages/common/utils/gridFiltersHelper';
import WarningModal from '../../../packages/common/shared-ui/src/components/WarningModal';

const ConfirmationsPage = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'page_title_confirmations' });
  const dataGridTableStyle = dataGridTableStyles();
  const navigate = useNavigate();
  const pageUseParams = useParams();
  const [queryData, setQueryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paramsLoading, setParamsLoading] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [openDeleteQueryModal, setOpenDeleteQueryModal] = useState(false);
  const [confirmationsFiltersData, setConfirmationsFiltersData] = useState(null);
  const [confirmationsGridRows, setConfirmationsGridRows] = useState([]);
  const [confirmationsGridColumns, setConfirmationsGridColumns] = useState([]);
  const [pageParams, setPageParams] = useState({ checked: false, params: {} });
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeFields, setActiveFields] = useState({ availableFields: [], selectedFields: [] });
  const [query, setQuery] = useQueryParams({
    filters: ArrayParam,
    fields: ArrayParam,
    project: NumberParam,
  });
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  const location = useLocation();
  useEffect(() => {
    if (location.search === '') {
      setSelectedFilters([]);
    }
  }, [location.search]);
  //useEffect(() => {
  //  if (selectedFilters.length > 0) {
  //    setQuery(prev => ({
  //      ...prev,
  //      filters: filtersToQuery(selectedFilters.filter(filter => filter.hasOwnProperty('values'))),
  //    }));
  //  }
  //}, [selectedFilters, setQuery]);
  //useEffect(() => {
  //  if (activeFields.selectedFields.length > 0 && activeFields.availableFields.length > 0) {
  //    setQuery(prev => ({ ...prev, fields: activeFields.selectedFields.map(item => item.fieldId) }));
  //  }
  //}, [activeFields, setQuery]);

  //Получение query по id
  useEffect(() => {
    setQueryData(null);
    let ignore = false;
    async function getSavedQueries() {
      const { data: response } = await getSavedQuery({ id: pageUseParams.queryId });
      if (!ignore) {
        return response;
      }
    }
    if (pageUseParams.queryId) {
      getSavedQueries()
        .then(res => {
          res && setQueryData(res);
        })
        .catch(error => handleError(error.response));
    }
  }, [handleError, pageUseParams.queryId]);

  useEffect(() => {
    if (query.filters && selectedFilters.length === 0 && confirmationsFiltersData) {
      const currentFilters = query.filters.map(filter => {
        const splitFilter = filter.split(';');
        const currentFilter = confirmationsFiltersData.filters.find(filter => filter.fieldId === splitFilter[0]);
        if (currentFilter !== undefined) {
          return {
            ...currentFilter,
            operation: splitFilter[1],
            values: splitFilter[2] ? splitFilter[2].split('||') : null,
          };
        }
      });
      setSelectedFilters(currentFilters);
      setQuery(prev => ({
        ...prev,
        filters: filtersToQuery(currentFilters.filter(filter => filter.hasOwnProperty('values'))),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationsFiltersData, query.filters]);

  useEffect(() => {
    if (
      query.fields &&
      confirmationsFiltersData &&
      activeFields.selectedFields.length === 0 &&
      activeFields.availableFields.length === 0
    ) {
      const currentFields = confirmationsFiltersData.fields.map(field => {
        const currentField = query.fields.find(el => el === field.fieldId);
        if (currentField !== undefined) {
          return {
            ...field,
            isChecked: true,
          };
        } else {
          return {
            ...field,
            isChecked: false,
          };
        }
      });
      setActiveFields({
        availableFields: currentFields.filter(field => !field.isChecked),
        selectedFields: currentFields.filter(field => field.isChecked),
      });
    }
  }, [activeFields.availableFields.length, activeFields.selectedFields.length, confirmationsFiltersData, query.fields]);

  useEffect(() => {
    if (pageUseParams.queryId && queryData && confirmationsFiltersData) {
      setSelectedFilters(
        confirmationsFiltersData.filters
          .filter(item => item.isChecked)
          .map(item => {
            //const queryFilter = queryData.query.filters.find(el => el.field === item.field);
            //let operation;
            //queryFilter !== undefined && (operation = queryFilter.operation);
            let value = item.values;
            Array.isArray(item.values) &&
              (value = item.values.filter(item => item.isChecked).map(item => item.valueId));
            return { ...item, values: value };
          }),
      );
      setActiveFields({
        availableFields: confirmationsFiltersData.fields.filter(field => !field.isChecked),
        selectedFields: confirmationsFiltersData.fields.filter(field => field.isChecked),
      });
    }
  }, [confirmationsFiltersData, pageUseParams.queryId, queryData]);

  //Получение фильтров для списка согласований
  useEffect(() => {
    let ignore = false;
    let params = {};
    pageUseParams.queryId && (params.query = Number(pageUseParams.queryId));
    async function getConfirmationsFiltersData() {
      const response = await getConfirmationsFilters(params);
      if (!ignore) {
        return response;
      }
    }

    getConfirmationsFiltersData()
      .then(res => {
        const { data: confirmationsFiltersData } = res;
        const data = {};
        confirmationsFiltersData.fields && (data.fields = confirmationsFiltersData.fields);
        confirmationsFiltersData.filters && (data.filters = confirmationsFiltersData.filters);
        setConfirmationsFiltersData(data);
      })
      .catch(error => handleError(error.response));
  }, [handleError, pageUseParams.queryId, queryData, setQuery]);

  //Получение списка согласований
  useEffect(() => {
    let ignore = false;
    setParamsLoading(true);
    let params = {};
    query.fields && (params.fields = query.fields.join('$$'));
    if (query.filters) {
      const filters = query.filters.map(filter => {
        const splitFilter = filter.split(';');
        if (filterDateValues.has(splitFilter[1])) {
          let value = splitFilter[2];
          splitFilter[2].split('||').length > 1 && (value = splitFilter[2].split('||').join(';'));
          return `${splitFilter[0]};${filterDateValues.get(splitFilter[1]).operator};${value}`;
        } else {
          return filter;
        }
      });
      params.filters = filters.join('$$');
    }
    query.project && (params.project = query.project);
    pageUseParams.queryId && (params.query = pageUseParams.queryId);
    async function getConfirmationsData() {
      const response = await getConfirmations(params);
      if (!ignore) {
        return response;
      }
    }
    if (params) {
      getConfirmationsData()
        .then(res => {
          const { data: confirmationsData } = res;
          confirmationsData.fields && setConfirmationsGridColumns(confirmationsData.fields);
          confirmationsData.rows &&
            setConfirmationsGridRows(formattedGridRows(confirmationsData.rows.filter(item => item.rowId !== 'total')));
          setLoading(false);
          setParamsLoading(false);
        })
        .catch(error => handleError(error.response));
    }
    return () => {
      ignore = true;
    };
  }, [formattedGridRows, handleError, pageUseParams.queryId, query.fields, query.filters, query.project]);

  const handleGetRedirectUrl = useCallback(({ id, columnType }) => {
    switch (columnType) {
      case 'unapprover':
      case 'createdBy':
      case 'updatedBy':
      case 'approver':
        return generateRedirectUrl({ host: window.location.origin, path: `/users/${id}` });
      case 'document':
        return `${window.location.origin}/issues/${id}`;
      case 'project':
        return `${window.location.origin}/projects/${id}`;
      case 'confirmation':
        return `${window.location.origin}/confirmations/${id}`;
      default:
        return '';
    }
  }, []);

  const cellRenderer = useCallback(
    ({ props, column }) => {
      const linkFields = ['project', 'confirmation', 'createdBy', 'updatedBy'];
      const multiLinkFields = ['unapprover', 'document', 'approver'];
      if (!props.value && props.row.groupName) {
        if (props.colDef.position === 0) {
          return (
            <Typography variant="h4" fontWeight={700}>
              {props.row.groupName}
            </Typography>
          );
        }
      } else {
        if (linkFields.includes(column.field)) {
          return (
            <Link
              to={handleGetRedirectUrl({ id: props.row[column.fieldId + 'Id'], columnType: column.field })}
              target={'_blank'}
            >
              {props.value}
            </Link>
          );
        }
        if (multiLinkFields.includes(column.field)) {
          return (
            <Grid container flexDirection={'column'}>
              {props.value?.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={handleGetRedirectUrl({
                      id: props.row[column.fieldId + 'Id'].find((item, itemIndex) => itemIndex === index),
                      columnType: column.field,
                    })}
                    target={'_blank'}
                  >
                    {item}
                  </Link>
                );
              })}
            </Grid>
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetRedirectUrl],
  );

  const formattedGridColumns = useCallback(() => {
    const smallFields = ['date', 'bool'];
    const mediumFields = ['project', 'unapprover', 'created_by', 'updated_by'];
    const bigFields = ['confirmation'];
    const columns = confirmationsGridColumns.map(column => {
      let columnWidth = 200;
      let columnFlex = 0;
      smallFields.includes(column.field) && (columnWidth = 100);
      mediumFields.includes(column.field) && (columnWidth = 220);
      bigFields.includes(column.field) && (columnWidth = 240);
      return {
        field: column.fieldId,
        headerName: intl.formatMessage({
          id: `confirmations_page_${column.field.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}`,
        }),
        renderHeader: props => (
          <Typography variant="h4" fontWeight={700}>
            {props.colDef.headerName}
          </Typography>
        ),
        disableExport: false,
        flex: columnFlex,
        width: columnWidth,
        cellClassName: props => (props.row.rowType === 'group' ? 'tableCell tableGroupCell' : 'tableCell'),
        renderCell: props => cellRenderer({ props, column }),
      };
    });
    return [...columns];
  }, [cellRenderer, confirmationsGridColumns, intl]);

  const confirmationsColumnsValue = formattedGridColumns();

  const formattedGridRows = useCallback(
    data =>
      data.reduce((acc, row, index) => {
        const newCols = row.columns.reduce((columnAcc, item, i) => {
          return {
            ...columnAcc,
            [row.columns[i].fieldId]: row.columns[i].value,
            [row.columns[i].fieldId + 'Id']: row.columns[i].valueId,
          };
        }, {});
        delete row.columns;
        return [...acc, { ...newCols, ...row, id: row.rowId }];
      }, []),
    [],
  );

  const handleParamsModalOpen = useCallback(() => {
    setOpenParamsModal(true);
  }, []);
  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSaveQueryModalOpen = useCallback(() => {
    setOpenSaveQueryModal(true);
  }, []);
  const handleSaveQueryModalClose = useCallback(() => {
    setOpenSaveQueryModal(false);
  }, []);
  const handleDeleteQueryModalOpen = useCallback(() => {
    setOpenDeleteQueryModal(true);
  }, []);
  const handleDeleteQueryModalClose = useCallback(() => {
    setOpenDeleteQueryModal(false);
  }, []);

  const handleParamsModalSave = useCallback(
    data => {
      data.filters && setSelectedFilters(data.filters);
      data.fields && setActiveFields(data.fields);
      setQuery(prev => ({
        ...prev,
        filters: filtersToQuery(data.filters.filter(filter => filter.hasOwnProperty('values'))),
        fields: data.fields.selectedFields.map(item => item.fieldId),
      }));
      setOpenParamsModal(false);
    },
    [setQuery],
  );
  const handleParamsModalClear = useCallback(
    data => {
      data.filters && setSelectedFilters(data.filters);
      //data.fields && apllyFields(data.fields);
      setQuery({ filters: filtersToQuery(data.filters.filter(filter => filter.hasOwnProperty('values'))) });
      setOpenParamsModal(false);
    },
    [setQuery],
  );

  const handleSelectFilters = useCallback(
    data => {
      const pos = selectedFilters.map(e => e.fieldId).indexOf(data.fieldId);
      if (JSON.stringify(data) === JSON.stringify(selectedFilters[pos])) {
        return;
      }
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const filters = [...selectedFilters.slice(0, pos), data, ...selectedFilters.slice(pos + 1)];
      setSelectedFilters(filters);
      setQuery(prev => ({
        ...prev,
        filters: filtersToQuery(filters.filter(filter => filter.hasOwnProperty('values'))),
      }));
    },
    [selectedFilters, setQuery],
  );

  const handleSaveQueryModalSave = useCallback(
    async modalData => {
      const validFilters = selectedFilters.filter(item => item.operation);
      const data = {
        type: 'confirmation',
        name: modalData.name,
        query: {
          filters: validFilters.map(filter => {
            let value = filter.values;
            Array.isArray(filter.values) && (value = filter.values.map(item => (item.valueId ? item.valueId : item)));
            return {
              instance: filter.instance,
              operation: filterDateValues.has(filter.operation)
                ? filterDateValues.get(filter.operation).operator
                : filter.operation,
              field: filter.field,
              values: value,
            };
          }),
          fields: activeFields.selectedFields.map((field, index) => {
            return {
              instance: field.instance,
              position: index,
              field: field.field,
            };
          }),
          sorts: [],
          totals: [],
        },
        accessLevel: modalData.accessLevel,
        roles: modalData.roles,
        projectLevel: modalData.projectLevel,
        projectId: modalData.projectId,
      };
      try {
        const queryId = pageUseParams.queryId ? pageUseParams.queryId : null;
        const { data: response } = await createNewSavedQuery(data, queryId);
        if (response) {
          window.open(`/confirmations/query/${response.id}`, '_self');
        }
      } catch (error) {
        handleError(error.response);
      }
      setOpenSaveQueryModal(false);
    },
    [activeFields.selectedFields, handleError, pageUseParams.queryId, selectedFilters],
  );

  const handleDeleteQueryModal = useCallback(
    async data => {
      data.currentTarget.attributes.value.value === 'delete' && setOpenDeleteQueryModal(false);
      if (data.currentTarget.attributes.value.value === 'save') {
        try {
          const { data: response } = await deleteSavedQuery({ id: pageUseParams.queryId });
          if (response) {
            window.open(`/confirmations`, '_self');
          }
        } catch (error) {
          handleError(error.response);
        }
      }
    },
    [handleError, pageUseParams.queryId],
  );

  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        siblingCount={1}
        boundaryCount={0}
        page={page + 1}
        renderItem={item => {
          if (item.type === 'next') {
            return (
              !item.disabled && (
                <Box>
                  {intl.formatMessage({ id: 'pagination_next' })}
                  <PaginationItem
                    {...item}
                    slots={{
                      next: PaginationNextButtonIcon,
                    }}
                  ></PaginationItem>
                </Box>
              )
            );
          } else if (item.type === 'previous') {
            return (
              !item.disabled && (
                <Box>
                  <PaginationItem
                    {...item}
                    slots={{
                      previous: PaginationPreviousButtonIcon,
                    }}
                  ></PaginationItem>
                  {intl.formatMessage({ id: 'pagination_previous' })}
                </Box>
              )
            );
          } else {
            return <PaginationItem {...item}></PaginationItem>;
          }
        }}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }
  function CustomPagination(props) {
    return (
      <GridPagination labelDisplayedRows={() => ''} rowsPerPageOptions={[]} ActionsComponent={Pagination} {...props} />
    );
  }
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {queryData && (
            <WarningModal
              simple
              isWarningModalOpen={openDeleteQueryModal}
              handleToggle={handleDeleteQueryModal}
              closeWarningModal={handleDeleteQueryModalClose}
              title={`${intl.formatMessage({ id: 'spent_time_delete' })}?`}
            />
          )}
          {confirmationsFiltersData && (
            <GridFiltersModal
              selectedFilters={selectedFilters}
              activeFields={activeFields}
              handleSave={data => handleParamsModalSave(data)}
              handleClear={data => handleParamsModalClear(data)}
              handleClose={handleParamsModalClose}
              open={openParamsModal}
              data={confirmationsFiltersData}
            />
          )}
          <GridSaveQueryModal
            queryData={queryData}
            open={openSaveQueryModal}
            handleSave={data => handleSaveQueryModalSave(data)}
            handleClose={handleSaveQueryModalClose}
          />
          <Page
            title={pageTitle}
            width="100%"
            sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
          >
            <Grid container justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
              <Grid item>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <Typography variant="h1">{pageTitle}</Typography>
                  </Grid>
                  {queryData && queryData.name && (
                    <Grid item>
                      <Typography variant="h2" color={'#082253'} marginLeft={'12px'} fontWeight={'400'}>
                        {queryData.name}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  {/*                  <Button variant="defaultGreyPrimary" disableRipple onClick={handleSaveQueryModalOpen}>
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="spent_time_save" />
                    </Typography>
                  </Button>*/}
                  {/*                  {queryData && (
                    <>
                      <Button
                        variant="defaultGreyPrimary"
                        disableRipple
                        onClick={handleSaveQueryModalOpen}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_edit" />
                        </Typography>
                      </Button>
                      <Button
                        variant="defaultGreyPrimary"
                        disableRipple
                        onClick={handleDeleteQueryModalOpen}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Typography variant="h4" fontWeight={600}>
                          <FormattedMessage id="spent_time_delete" />
                        </Typography>
                      </Button>
                    </>
                  )}*/}
                  {confirmationsFiltersData && (
                    <Button
                      variant="defaultGreyPrimaryWithIcon"
                      disableRipple
                      onClick={handleParamsModalOpen}
                      sx={{ marginLeft: '10px' }}
                    >
                      <Box display="flex" alignItems="center" marginRight={'4px'}>
                        <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
                      </Box>
                      <Typography variant="h4" fontWeight={600}>
                        <FormattedMessage id="parameters_text" />
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {selectedFilters.length > 0 && (
              <GridFiltersPanel data={selectedFilters} handleCloseFilter={handleSelectFilters}></GridFiltersPanel>
            )}
            {confirmationsGridRows && confirmationsGridColumns && (
              <Grid
                container
                flexDirection={'column'}
                flex={1}
                height={'100%'}
                overflow={'hidden'}
                position={'relative'}
              >
                {paramsLoading && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    zIndex={99}
                    position={'absolute'}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
                <DataGridPro
                  pagination
                  autoPageSize
                  disableColumnFilter
                  disableRowSelectionOnClick
                  disableColumnMenu
                  columns={confirmationsColumnsValue}
                  rows={confirmationsGridRows}
                  className={dataGridTableStyle.DataGridTableRoot}
                  getRowId={item => item.id}
                  //sortModel={sortModel ? sortModel : []}
                  //onSortModelChange={model => setSortModel(model?.length > 0 ? model : sortModel)}
                  //sortingMode="server"
                  //sortingOrder={['asc', 'desc', null]}
                  slotProps={{
                    baseCheckbox: {
                      icon: <CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />,
                      checkedIcon: <CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />,
                    },
                  }}
                  slots={{
                    pagination: CustomPagination,
                    columnUnsortedIcon: () => <SortIcon width="20" height="20" viewBox="0 0 20 20" />,
                    columnSortedAscendingIcon: () => (
                      <SortIcon direction="up" width="20" height="20" viewBox="0 0 20 20" />
                    ),
                    columnSortedDescendingIcon: () => (
                      <SortIcon direction="down" width="20" height="20" viewBox="0 0 20 20" />
                    ),
                  }}
                  getRowClassName={params => params.row.groupName && 'groupRow'}
                  isRowSelectable={params => !params.row.groupName}
                  getRowHeight={() => 'auto'}
                />
              </Grid>
            )}
          </Page>
        </>
      )}
    </>
  );
};
export default injectIntl(ConfirmationsPage);
