import React from 'react';
import { SvgIcon } from '@mui/material';

const UncheckedRadioIcon = props => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_8501_24828)">
        <path
          d="M7.99967 1.33337C4.31967 1.33337 1.33301 4.32004 1.33301 8.00004C1.33301 11.68 4.31967 14.6667 7.99967 14.6667C11.6797 14.6667 14.6663 11.68 14.6663 8.00004C14.6663 4.32004 11.6797 1.33337 7.99967 1.33337ZM7.99967 13.3334C5.05301 13.3334 2.66634 10.9467 2.66634 8.00004C2.66634 5.05337 5.05301 2.66671 7.99967 2.66671C10.9463 2.66671 13.333 5.05337 13.333 8.00004C13.333 10.9467 10.9463 13.3334 7.99967 13.3334Z"
          fill={props.color ? props.color : '#E4E4EF'}
        />
      </g>
      <defs>
        <clipPath id="clip0_8501_24828">
          <rect width="16" height="16" fill={props.color ? props.color : 'white'} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default UncheckedRadioIcon;
