import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'Common/shared-ui/src/components/Page';
import { store } from '../app/redux/index';
import { injectIntl } from 'react-intl';
import {
  Box,
  Grid,
  Select,
  IconButton,
  MenuItem,
  Typography,
  ToggleButton,
  FormControl,
  ToggleButtonGroup,
  CircularProgress,
} from '@mui/material';
import PlusIcon from 'Common/shared-ui/src/icons/PlusIcon';
import ProjectSettingsIcons from 'Common/shared-ui/src/icons/ProjectSettingsIcons';
import ProjectsViewTypeTilesIcon from 'Common/shared-ui/src/icons/ProjectsViewTypeTilesIcon';
import ProjectsViewTypeListIcon from 'Common/shared-ui/src/icons/ProjectsViewTypeListIcon';
import ProjectsTilesView from './ProjectsTilesView';
import ProjectsFilters from './ProjectsFilters';
import ProjectsListView from './ProjectsListView';
import useStyles from './ProjectsContainer.styles';
import { getProjects, getProjectsFilters, getProjectsWithQuery, getUserCanCreate } from '../../../packages/common/api';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import { useNavigate, useLocation } from 'react-router';
import { setProject, toggleDrawer } from '../app/redux/reducers/userSlice';
const ProjectsContainer = ({ intl }) => {
  const classes = useStyles();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDrawer = useCallback(() => dispatch(toggleDrawer(false)), [dispatch]);
  const [view, setView] = React.useState(window.localStorage.getItem('projectsView') || 'tiles');
  const [userCanCreate, setUserCanCreate] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [projectscColumns, setProjectscColumns] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const goProjectSettings = () => {
    const projectUrl = '/projects-settings';
    navigate('/projects-settings/', { state: { url: 'projects-settings' } });
  };
  const goCreateProject = () => {
    const projectNewUrl = '/projects/create';
    navigate(projectNewUrl);
  };
  useEffect(() => {
    async function fetchProjectsData() {
      try {
        const status = 'filters=project.status;eq;1';
        const response = await getProjectsWithQuery(status);
        setProjects(response.rows);
        setProjectscColumns(response.fields);
      } catch (e) {
        console.error(e);
      }
    }
    async function fetchUserCanCreate() {
      try {
        const response = await getUserCanCreate();
        setUserCanCreate(response);
      } catch (e) {
        console.error(e);
      }
    }

    async function fetchFiltersData() {
      try {
        const response = await getProjectsFilters();
        setFilters(response.filters);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    fetchProjectsData();
    fetchUserCanCreate();
    fetchFiltersData();
    dispatch(setProject(''));
  }, [redmineToken, location, closeDrawer, dispatch]);

  const handleChangeView = (event, nextView) => {
    setView(nextView);
    window.localStorage.setItem('projectsView', nextView);
  };
  const pageTitle = intl.formatMessage({ id: 'page_title_projects' });
  return (
    <Page title={pageTitle} width="100%" className={classes.ProjectsContainerRoot}>
      <Grid container position="relative" className={classes.filtersWrap}>
        <Grid container item display="flex" alignItems="center" spacing={1} className={classes.projectsPageTtl}>
          <Typography variant="h1">{pageTitle}</Typography>
          {userCanCreate && (
            <IconButton disableRipple className={classes.addRowButton} onClick={goCreateProject}>
              <PlusIcon width={24} height={24} viewBox="0 0 24 24" />
            </IconButton>
          )}
        </Grid>
        <ToggleButtonGroup
          orientation="vertical"
          value={view}
          exclusive
          className={classes.ProjectsViewChangeButtonWrap}
          onChange={handleChangeView}
        >
          {view !== 'list' ? (
            <ToggleButton value="list" aria-label="list" className={classes.ProjectsViewChangeButton}>
              <ProjectsViewTypeListIcon width={32} height={32} viewBox="0 0 32 32" />
            </ToggleButton>
          ) : null}
          {view !== 'tiles' ? (
            <ToggleButton value="tiles" aria-label="tiles" className={classes.ProjectsViewChangeButton}>
              <ProjectsViewTypeTilesIcon width={32} height={32} viewBox="0 0 32 32" />
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
        <IconButton className={classes.ProjectsViewProjectSettingsIconsWrap} onClick={goProjectSettings}>
          <ProjectSettingsIcons width={24} height={24} viewBox="0 0 24 24" />
        </IconButton>
        {!isLoading ? <ProjectsFilters setProjects={setProjects} filters={filters}></ProjectsFilters> : <></>}
      </Grid>

      {!isLoading ? (
        <Grid>
          {view == 'tiles' ? (
            <ProjectsTilesView projects={projects} projectscColumns={projectscColumns}></ProjectsTilesView>
          ) : null}
          {view == 'list' ? (
            <ProjectsListView projects={projects} projectscColumns={projectscColumns}></ProjectsListView>
          ) : null}
        </Grid>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Page>
  );
};
export default injectIntl(ProjectsContainer);
