import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  projectMemberTtl: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ProjectsStatusChangeButtonBox: {
    display: 'flex',
    alignItems: 'baseline',
  },
  ProjectTabContentWrap: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  ProjectsTabsWrap: {
    marginTop: '30px',
  },
  ProjectTabContentGridContainer: {
    '& .MuiBox-root': {
      padding: '0px !important',
    },
  },
  Url: {
    color: '#3448FF',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  projectFieldsWrap: {
    margin: '0 0 20px 0 !important',
    alignItems: 'baseline',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    padding: '19px 64px 17px 19px !important',
  },
  projectFieldWrap: {
    width: '100% !important',
    marginBottom: '5px !important',
  },
  projectFieldRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& $projectFieldLeft': {
      fontWeight: 700,
      fontSize: '14px',
    },
    '& $projectFieldRight': {
      fontSize: '14px',
    },
  },
  projectCloseMessage: {
    margin: '30px 0 0 0 !important',
    padding: '19px 17px !important',
    alignItems: 'baseline',
    borderRadius: '16px',
    display: 'inline-flex',
    alignContent: 'center',
    backgroundColor: '#FFF',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
    },
  },
  ProjectsViewChangeButton: {
    border: '0px !important',
    padding: '0px !important',
  },
  projectFieldLeft: {
    fontWeight: 700,
    width: '40%',
  },
  projectsPageTtl: {
    marginLeft: '0px !important',
  },
  projectFieldRight: {
    width: '60%',
    overflowWrap: 'break-word',
  },
  projectFieldMemberWrap: {
    width: '100% !important',
    display: 'flex',
    marginBottom: '10px !important',
    position: 'relative',
    '& .MuiSvgIcon-root': {
      width: '16px',
      left: 0,
      top: '5px',
      position: 'absolute',
    },
    '& .MuiTypography-h5': {
      width: '50%',
      paddingLeft: '20px',
    },
  },
  projectFieldMemberRolesWrap: {
    width: '50%',
    '& .MuiTypography-h5': {
      width: '100%',
      fontSize: '12px !important',
    },
  },
  projectFieldsTtl: {
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '20px !important',
    color: '#082253 !important',
    marginBottom: '18px !important',
  },
  projectFiledTtl: {
    fontSize: '12px!important',
    lineHeight: '20px!important',
  },
  projectFiledTtlActiveUsers: {
    color: '#41424E !important',
  },
  projectFiledTtlBlockedUsers: {
    color: '#7174AC !important',
  },
  projectFiledValue: {
    fontSize: '14px!important',
    lineHeight: '20px!important',
  },
  projectFiledValueOpened: {
    color: '#41424E !important',
  },
  projectFiledValueClosed: {
    color: '#7174AC !important',
  },
  ProjectsTabs: {
    minHeight: '36px!important',
    '& .MuiTabs-indicator': { display: 'none' },
    '& .MuiTab-root': {
      background: '#DCDFF4',
      color: '#656985',
      fontSize: '14px',
      textTransform: 'none',
      padding: '8px 16px',
      minHeight: '36px',
      '&:first-child': {
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
      },
      '&:last-child': {
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
      },
    },
    '& .Mui-selected': { background: '#6270D0', color: '#FFFFFF' },
    '& .MuiTabs-flexContainer': { gap: '2px' },
  },
  ProjectsListViewTableRootWrap: {
    border: '1px solid #E4E4EF',
    borderRadius: '16px',
    '& .MuiTableCell-root': {
      border: '1px solid #E4E4EF',
      padding: '10px',
      color: '#212346',
      fontWeight: 700,
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:last-child': {
        borderRight: 'none',
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        padding: '18px 10px',
        color: '#082253',
      },
    },
  },
}));

export default useStyles;
