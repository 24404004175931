import React from 'react';
import { SvgIcon } from '@mui/material';

function LanguageArrowIcon(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M12 16.5 6 10.5 7.41 9.09 12 13.67 16.59 9.09 18 10.5 12 16.5Z" fill="#7174AC" />
    </SvgIcon>
  );
}

export default LanguageArrowIcon;
