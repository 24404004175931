import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  filterSelect: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid transparent',
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
        border: 'none',
      },
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
          position: 'static',
        },
      },
      '& .MuiAutocomplete-input': {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#7174AC',
        padding: '0',
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderColor: '#3448FF',
        '&:hover': {
          borderColor: '#3448FF',
        },
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      top: '-5px',
      zIndex: 99,
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: '180px!important',
      width: 'auto!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        borderTop: '1px solid #E4E4EF',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: ' nowrap',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
  SpentTimeTableRoot: {
    '&.MuiDataGrid-root': {
      marginBottom: theme.spacing(4),
      border: 0,
      '& .MuiDataGrid-main': {
        backgroundColor: '#ffffff',
        borderRadius: theme.spacing(4),
      },
      '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
        borderRight: 0,
        boxShadow: '1px 1px #E4E4EF',
        borderColor: 'transparent',
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          whiteSpace: 'initial',
        },
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
        borderRight: 0,
      },
      '& .MuiDataGrid-row.disabled': {
        color: '#7174AC',
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-main .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiSvgIcon-root': {
          width: '24px',
        },
      },
      '& .MuiDataGrid-cell': {
        '&.tableCell': {
          borderColor: 'transparent',
          boxShadow: '1px 1px #E4E4EF',
          lineHeight: '20px',
          '&:last-child': {
            boxShadow: 0,
          },
          '& .MuiCheckbox-root': {
            padding: 0,
          },
          '&.textCenter': {
            justifyContent: 'center',
          },
        },
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    '& .MuiDataGrid-footerContainer': {
      marginTop: '20px',
      minHeight: 'auto',
      border: 'none',
      justifyContent: 'flex-start',
      '& .MuiToolbar-root.MuiTablePagination-toolbar': {
        minHeight: 'auto',
        padding: 0,
        '& .MuiPagination-root': {
          marginLeft: 0,
          '& li': {
            fontSize: '16px',
            lineHeight: '20px',
          },
          '& li + li': {
            marginLeft: '2px',
          },
          '& .MuiButtonBase-root.MuiPaginationItem-root': {
            color: '#41424E',
            fontSize: '16px',
            lineHeight: '20px',
            borderRadius: '4px',
            minWidth: '28px',
            height: '28px',
            margin: 0,
            '&.Mui-selected': {
              backgroundColor: '#6270D0',
              color: '#FAFAFC',
              '&:hover': {
                color: '#FAFAFC',
                backgroundColor: '#6270D0',
              },
            },
            '& .MuiSvgIcon-root.MuiPaginationItem-icon': {
              width: 'auto',
              height: 'auto',
            },
            '&:hover': {
              color: '#6270D0',
              backgroundColor: 'transparent',
            },
          },
          '& .MuiPaginationItem-ellipsis': {
            fontSize: '16px',
            lineHeight: '20px',
            color: '#41424E',
            padding: 0,
            margin: 0,
            minWidth: ' 28px',
          },
        },
      },
    },
  },
}));

export default useStyles;
